<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Добавить лид</h2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="valid ? submitHandler() : validate()"
        >
          <v-select
            :items="leadTypes"
            v-model="formData.leadTypeId"
            label="Тип лида"
          >
          </v-select>
          <v-text-field
            v-if="formData.leadTypeId === 2"
            v-model="formData.nameCompany"
            label="Название компании"
            :rules="[() => !!formData.nameCompany || 'Обязательное поле']"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.individual"
            label="ФИО"
            :rules="[() => !!formData.individual || 'Обязательное поле']"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.phone"
            label="Номер телефона"
            maxlength="18"
            v-maska="'+7 (###) ###-##-##'"
            :rules="[
              () => !!formData.phone || 'Обязательное поле',
              () => formData.phone.length === 18 || 'Не верный формат телефона',
            ]"
            required
          ></v-text-field>

          <v-text-field
            label="Электронная почта"
            v-model="formData.email"
            :rules="[
              () =>
                !!formData.email ||
                'Адрес электронной почты обязателен для заполнения',
              () =>
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) || 'Недействительный адрес электронной почты',
            ]"
          ></v-text-field>

          <v-textarea
            v-model="formData.comment"
            :rules="[() => !!formData.comment || 'Обязательное поле']"
            label="Комментарий"
            required
            no-resize
            rows="2"
            row-height="15"
            maxlength="100"
            counter
          ></v-textarea>

          <v-btn type="submit" block color="success"> Создать </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,

      leadTypes: [
        { value: 2, title: "Юридическое лицо" },
        { value: 1, title: "Физическое лицо" },
      ],

      formData: {
        leadTypeId: 1,
        nameCompany: null,
        individual: null,
        phone: null,
        comment: null,
        email: null,
      },
    };
  },
  methods: {
    async submitHandler() {
      if (this.$store.getters.USER.selectedRole.role === "DIRECTOR") {
        await this.$store.dispatch("itroom/director/createCall", this.formData);
      }
      if (this.$store.getters.USER.selectedRole.role === "MANAGER") {
        await this.$store.dispatch("itroom/manager/createCall", this.formData);
      }

      this.$emit("close");
      this.$refs.form.reset();
      this.formData.leadTypeId = 1;
    },
  },
};
</script>