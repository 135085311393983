<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление программного обеспечения</div>
        <v-form
          ref="form"
          class="form_cabinet"
          @submit.prevent="submitHandler"
          v-model="valid"
        >
          <v-text-field
            class="text_field_input"
            v-model="formData.title"
            label="Название ПО"
            :rules="[() => !!formData.title || 'Обязательное поле']"
            required
          >
          </v-text-field>
          <v-text-field
            type="number"
            class="text_field_input"
            v-model="formData.price"
            label="Цена"
            :rules="[() => !!formData.price || 'Обязательное поле']"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            v-model="formData.subscriptionDate"
            label="Подписка от"
            v-maska="'##.##.####'"
            :rules="[() => !!formData.subscriptionDate || 'Обязательное поле']"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            v-model="formData.debitDate"
            label="Подписка до"
            v-maska="'##.##.####'"
            :rules="[() => !!formData.debitDate || 'Обязательное поле']"
            required
          >
          </v-text-field>
          <v-textarea
            class="text_field_input"
            v-model="formData.misc"
            label="Заметки"
          ></v-textarea>
          <v-btn type="submit" class="cabinet_btn">Добавить ПО</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      formData: {
        title: null,
        price: null,
        subscriptionDate: null,
        debitDate: null,
        misc: null,
      },
    };
  },
  methods: {
    async submitHandler() {
      this.$refs.form.validate();
      if (this.valid) {
        try {
          await this.$store.dispatch(
            'techroom/specialist/createSoftware',
            this.formData
          );
          await this.$store.dispatch(
            'techroom/specialist/requestAddressSoftware',
            this.$store.getters['techroom/specialist/getAddress'].id
          );
          this.$emit('close');
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>
