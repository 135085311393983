<template>
  <v-tabs v-model="tab">
    <v-tab v-for="item in navbarItems" :key="item" :value="item.value" @click="$router.push(item.url)">{{ item.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      navbarItems: [
        {
          title: 'Кабинеты',
          url: `/techroom/organization/${this.$route.params.id}/cabinets`,
          value: 1,
        },
        {
          title: 'Компьютеры',
          url: `/techroom/organization/${this.$route.params.id}/computers`,
          value: 2,
        },
        {
          title: 'Оборудование',
          url: `/techroom/organization/${this.$route.params.id}/equipment`,
          value: 3,
        },
        // {
        //   title: 'Сотрудники',
        //   url: `/techroom/organization/${this.$route.params.id}/workers`,
        //   value: 4,
        // },
        {
          title: 'Программное обеспечение',
          url: `/techroom/organization/${this.$route.params.id}/software`,
          value: 5,
        },
        {
          title: 'Обслуживающие организации',
          url: `/techroom/organization/${this.$route.params.id}/service`,
          value: 6,
        },
        // {
        //   title: "Обращения",
        //   url: `/techroom/organization/${this.$route.params.id}/appeals`,
        //   value: 7,
        // },
        {
          title: 'Структура здания',
          url: `/techroom/organization/${this.$route.params.id}/structure`,
          value: 8,
        },
      ],
    };
  },

  computed: {
    tab: {
      get() {
        return this.$route.meta.tab;
      },
      set() {
        return this.$route.meta.tab;
      },
    },
  },
};
</script>

<style>

</style>
