<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="user">
        <div class="user__content">
          <div class="user__start">
            <v-avatar
              :color="colors[Math.floor(Math.random() * colors.length)]"
              size="small"
            >
              <span class="initial">
                {{
                  `${$store.getters.USER?.name?.charAt()}${$store.getters.USER?.lastName?.charAt()}`
                }}
              </span>
            </v-avatar>
            <div class="user__data">
              <div>
                {{
                  `${$store.getters.USER?.name} ${$store.getters.USER?.lastName}`
                }}
              </div>
              <div>
                {{
                  `${$filters.filterRole(
                    $store.getters.USER?.selectedRole?.role
                  )}`
                }}
              </div>
            </div>
          </div>
          <div class="user__append">
            <IconChevron height="20" width="20" :bottom="true"></IconChevron>
          </div>
        </div>
      </v-btn>
    </template>
    <v-card class="user__overlay">
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar color="brown" size="large">
            <span class="initial initial-large">
              {{
                `${$store.getters.USER?.name?.charAt()}${$store.getters.USER?.lastName?.charAt()}`
              }}
            </span>
          </v-avatar>
          <h3>
            {{
              `${$store.getters.USER?.name} ${$store.getters.USER?.lastName}`
            }}
            <p class="text-caption">
              {{ `${$store.getters.USER?.email}` }}
            </p>
          </h3>
          <a @click="changeRole">Сменить роль</a>
          <router-link to="/techroom">Личный кабинет</router-link>
          <paper-button @click="exit" class="techroom_button">
            <IconExit height="24" width="24"></IconExit>
            <span>Выйти из аккаунта</span>
          </paper-button>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      colors: ["brown"],
    };
  },
  methods: {
    async changeRole() {
      await this.$router.push("/");
      await this.$store.commit("updateIsAuthenicated", true);
    },
    async exit() {
      await this.$router.push("/");
      await this.$store.dispatch("logout");
    },
  },
};
</script>
<style lang="scss">
.sidebar {
  .user,
  .organization {
    display: flex;
    padding: rem(32) 0;
    border-bottom: 1px solid
      rgba(var(--v-border-color), var(--v-border-opacity));
    font-weight: unset;
    letter-spacing: unset;
    text-transform: unset;
    width: 100%;
    line-height: unset;
    box-shadow: unset;
    border-radius: unset;
    justify-content: space-between;
    padding-inline-start: rem(16);

    .v-btn__content {
      justify-content: space-between;
      width: 100%;
      padding-right: rem(14);
    }

    .user__content {
      width: 100%;
    }

    .user__start {
      display: flex;
      gap: rem(16);
    }

    &:hover {
      background-color: #e5e5e5;
      cursor: pointer;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: rem(12);

      .v-avatar {
        align-self: center;
      }
    }

    &__append {
      display: flex;
    }

    &__data {
      font-size: rem(14);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      div {
        &:nth-child(2) {
          letter-spacing: rem(0.9);
          font-size: rem(12);
          color: var(--white-14);
          font-weight: 300;
        }
      }
    }
  }
}

.user__overlay {
  a {
    color: var(--red-0);
    font-weight: 400;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .mx-auto {
    max-width: rem(250);

    @media screen and (max-width: $large) {
      max-width: rem(120);
    }
  }

  .v-avatar {
    margin: 0 0 rem(8) 0;
  }

  h3 {
    font-weight: 500;
    color: var(--white-18);
  }

  p {
    color: var(--white-14);
    margin: 0 0 rem(8) 0;
  }

  paper-button {
    margin: rem(12) 0 0 0;
    width: 100%;
    border-radius: unset;
  }

  @media screen and (max-width: $large) {
    max-width: rem(300);
  }
}
</style>