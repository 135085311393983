<template>
  <Loading v-if="loadingPage"></Loading>
  <section v-else class="page__account account">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="account__title itroom__title">Личный кабинет</h1>
      <v-tooltip text="Добавить задачу">
        <template v-slot:activator="{ props }">
          <v-btn
            @click="dialogTask = true"
            v-bind="props"
            color="var(--blue-0)"
            icon
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <v-card class="mb-6">
      <v-card-title>Информация о пользователе</v-card-title>
      <v-card-text>
        <p>ФИО: {{ `${user.surname} ${user.name} ${user.lastName}` }}</p>
        <p>Почта: {{ user.email }}</p>
        <p>Дата рождения: {{ $filters.filterDate(user.dateBirth) }}</p>
        <div v-if="user.itroom_jobs.length">
          Стек:
          <p v-for="item in user.itroom_jobs" :key="item">
            {{ item.jobName }}
          </p>
        </div>
        <div v-if="user.itroom_stacks.length">
          Работа:
          <p v-for="item in user.itroom_stacks" :key="item">
            {{ item.stackName }}
          </p>
        </div>
      </v-card-text>
    </v-card>

    <div class="account__tasks task">
      <v-card
        v-if="
          $store.getters.USER.selectedRole.role === 'MANAGER' ||
          $store.getters.USER.selectedRole.role === 'TEAMLEAD'
        "
      >
        <v-card-title>Задачи от Директора</v-card-title>
        <v-banner v-for="item in directorTask" :key="item" :border="0">
          <v-banner-text>
            <p class="task__title">
              {{ `${item.title} До: ${$filters.filterDate(item?.timeline)}` }}
              <v-icon color="green">mdi-check-bold</v-icon>
            </p>
            <p class="task__desc">{{ `${item.description}` }}</p>
          </v-banner-text>
        </v-banner>
      </v-card>
      <v-card>
        <v-card-title>Личные задачи</v-card-title>
        <v-banner
          v-for="item in $store.getters['itroom/account/getTasks']"
          :key="item"
          :border="0"
        >
          <v-banner-text class="d-flex justify-space-between">
            <p class="task__title">
              {{ `${item.title} До: ${$filters.filterDate(item?.timeline)}` }}
            </p>

            <v-tooltip text="Удалить" location="top">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="deleteTask(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-tooltip>
          </v-banner-text>
        </v-banner>
      </v-card>
    </div>
  </section>

  <DialogAddTask v-model="dialogTask" @close="dialogTask = false" />
</template>

<script>
import DialogAddTask from "@/components/app/itroom/account/DialogAddTask.vue";

export default {
  components: { DialogAddTask },
  data() {
    return {
      user: null,
      directorTask: null,
      dialogTask: false,
      loadingPage: true,
    };
  },
  methods: {
    async deleteTask(id) {
      this.$store.dispatch("itroom/account/removeTask", id);
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/account/requestUser");
    this.user = await this.$store.getters["itroom/account/getUser"];
    await this.$store.dispatch("itroom/account/requestTasks");

    if (this.$store.getters.USER.selectedRole.role === "TEAMLEAD") {
      await this.$store.dispatch("itroom/teamlead/requestDirectorTasks");
      this.directorTask =
        this.$store.getters["itroom/teamlead/getDirectorTask"];
    } else if (this.$store.getters.USER.selectedRole.role === "MANAGER") {
      await this.$store.dispatch("itroom/manager/requestDirectorTasks");
      this.directorTask = this.$store.getters["itroom/manager/getDirectorTask"];
    }

    setTimeout(() => {
      this.loadingPage = false;
    }, 500);
  },
};
</script>

<style lang='scss' scoped>
.account__tasks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(24);
  align-items: start;

  .task {
    &__title {
    }

    &__desc {
    }
  }
}
.account__add-tasks {
  margin: rem(30);
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>