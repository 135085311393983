<template>
  <Loading v-if="loadingPage"></Loading>
  <section v-else class="page__account account">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="account__title itroom__title">Пользователи</h1>
      <v-tooltip text="Добавить пользователя">
        <template v-slot:activator="{ props }">
          <v-btn
            @click="dialogAdd = true"
            v-bind="props"
            color="var(--blue-0)"
            icon
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <v-table density="comfortable">
      <thead>
        <tr>
          <th class="text-left">ФИО</th>
          <th class="text-left">Почта</th>
          <th class="text-left">Роль</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in $store.getters['itroom/director/getUsers']"
          :key="item.id"
        >
          <td>{{ `${item.surname} ${item.name} ${item.lastName}` }}</td>
          <td>{{ item.email }}</td>
          <td>
            <div v-for="role in item.roles" :key="role.id">
              {{ $filters.filterRole(role.name) }}
            </div>
          </td>
          <td class="text-right">
            <!-- Важно -->
            <!-- <v-btn variant="text" size="x-small" class="fill-height">
              <svg height="20" width="20">
                <path
                  d="M7.292 14.958 10 13.354l2.729 1.604-.708-3.041 2.354-2.021-3.125-.292L10 6.708 8.75 9.625l-3.125.271 2.396 2Zm-.938 1.25.979-4.062-3.229-2.729 4.229-.375L10 5.208l1.667 3.854 4.229.355-3.229 2.729.979 4.062L10 14.042ZM10 11.042Z"
                />
              </svg>
            </v-btn> -->
            <!-- Важно -->

            <!-- Подробнее -->
            <v-btn
              variant="text"
              size="x-small"
              class="manage-item-more manage-item fill-height"
              :value="item.id"
              @click="openDialog(item.id)"
            >
              <v-tooltip activator="parent" location="top">Подробнее</v-tooltip>
              <IconView />
            </v-btn>
            <!-- Подробнее -->

            <!-- Редактировать -->
            <v-btn
              variant="text"
              size="x-small"
              class="manage-item-edit manage-item fill-height"
              :value="item.id"
              @click="openDialogEdit(item)"
            >
              <v-tooltip activator="parent" location="top">
                Редактирование
              </v-tooltip>
              <IconEdit />
            </v-btn>
            <!-- Редактировать -->

            <!-- Удаление -->
            <v-btn
              variant="text"
              size="x-small"
              class="manage-item-delete manage-item fill-height"
              :value="item.id"
              @click="openDialogDelete(item)"
            >
              <v-tooltip activator="parent" location="top">
                Перенести в архив
              </v-tooltip>
              <IconDelete />
            </v-btn>
            <!-- Удаление -->
          </td>
        </tr>
      </tbody>
    </v-table>
    <DialogAddUser
      v-model="dialogAdd"
      :settingUser="settingUser"
      @close="dialogAdd = false"
    />
    <DialogUser v-model="dialog" :user="user" @close="dialog = false" />
    <DialogEditUser
      v-model="dialogEdit"
      :user="user"
      @close="dialogEdit = false"
    />
    <DialogDeleteUser
      v-model="dialogDelete"
      :user="user"
      @close="dialogDelete = false"
    />
  </section>
</template>

<script>
import DialogUser from "@/components/app/itroom/director/users/DialogUser.vue";
import DialogEditUser from "@/components/app/itroom/director/users/DialogEditUser.vue";
import DialogAddUser from "@/components/app/itroom/director/users/DialogAddUser.vue";
import DialogDeleteUser from "@/components/app/itroom/director/users/DialogDeleteUser.vue";

export default {
  components: { DialogAddUser, DialogUser, DialogEditUser, DialogDeleteUser },
  data() {
    return {
      user: null,
      settingUser: {},

      dialog: false,
      dialogEdit: false,
      dialogAdd: false,
      dialogDelete: false,

      loadingPage: true,
    };
  },
  methods: {
    async openDialog(id) {
      await this.$store.commit("itroom/director/updateUser", id);
      this.user = this.$store.getters["itroom/director/getUser"];
      this.dialog = true;
    },
    async openDialogEdit(item) {
      this.user = item;
      this.dialogEdit = true;
    },
    async openDialogDelete(item) {
      this.user = item;
      this.dialogDelete = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/director/requestUsers");
    await this.$store.dispatch("itroom/director/requestSettingsUser");
    this.settingUser = this.$store.getters["itroom/director/getSettingsUser"];
    setTimeout(() => {
      this.loadingPage = false;
    }, 500);
  },
};
</script>