<template>
  <v-chip @click="exit" class="window__back ma-2" color="#5272ff" label text-color="white">
    <IconChevron height="21" width="21" viewBox="0 0 20 20" fill="rgb(82, 114, 255)" :left="true"></IconChevron>
    Сменить аккаунт
  </v-chip>
  <section id="selectWindow" class="window select-wrap-window">
    <div class="window__user">
      <v-avatar color="red" size="small">
        <span class="text-h5 initial">{{
            `${$store.getters.USER?.name?.charAt()}${$store.getters.USER?.lastName?.charAt()}`
        }}</span>
      </v-avatar>
      <p>
        {{
            `${$store.getters.USER?.surname} ${$store.getters.USER?.name} ${$store.getters.USER?.lastName}`
        }}
      </p>
    </div>
    <div class="window__organizations">
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="org of $store.getters.USER.organizations" :key="org">
          <v-expansion-panel-title>
            <template v-slot:actions>
              <IconChevron height="20" width="20" :bottom="true"></IconChevron>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                <path d="m10 11.417-3-3h6Z" />
              </svg> -->
            </template>
            <IconItroom v-if="org.title === 'itroom'" :mini="true" width="28" height="28" fill="#5272ff"
              viewBox="0 0 500 500"></IconItroom>
            <IconTechroom v-if="org.title === 'techroom'" :mini="true" width="28" height="28" fill="#ff5757"
              viewBox="0 0 500 500">
            </IconTechroom>
            {{ $filters.filterOrganization(org.title) }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="organizations__end">
              <v-chip @click="transition(org.title, role.userRoleId)" v-for="role of org.roles" :key="role" label>
                <IconAccount width="24" height="18" viewBox="0 0 24 24"></IconAccount>
                {{ $filters.filterRole(role.role) }}
              </v-chip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <span class="continue">Войдите в организацию для продолжения</span>
  </section>
</template>

<script>
export default {
  name: "SelectForm",
  data() {
    return {
      colors: ["brown", "red", "purple", "gray", "orange"],
    };
  },
  methods: {
    exit() {
      localStorage.removeItem("token");
      this.$store.commit("updateIsAuthenicated", false);
    },
    async transition(organization, userRoleId) {
      console.log(organization)
      console.log(userRoleId)

      await this.$store.dispatch("refreshToken", userRoleId);
      await this.$store.commit("updateIsAuthenicated", true);
      console.log(await this.$store.getters.USER)
      if(await this.$store.getters.USER.selectedRole.role === 'TECHNICAL') this.$router.push(`/${organization}/plans`)
      else this.$router.push(`/${organization}`)
   
      
      
      
     
      
    },
  },
};
</script> 

<style lang="scss">
.initial {
  display: flex;
  align-content: center;
  justify-content: center;
  color: var(--white-1) !important;
  font-size: rem(14) !important;

  &-large {
    font-size: rem(16) !important;
  }
}

.organizations__end {
  font-size: rem(14);
  display: flex;
  gap: rem(16);
  flex-direction: column;

  .v-chip {
    justify-content: center;
  }
}

.window__back {
  position: absolute;
  left: 0;
  top: 0;
}

.continue {
  font-size: rem(13);
  color: var(--blue-11);
}

.select-wrap-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: rem(24);
  width: 100%;
  height: 100%;
  padding: rem(96) 0;
  text-align: center;

  .v-expansion-panel-title__icon {
    transition: var(--transition-out);
  }

  .v-expansion-panel-title--active {
    .v-expansion-panel-title__icon {
      transition: var(--transition-in);
      transform: rotate(-180deg);
    }
  }

  .v-expansion-panel-title--active>.v-expansion-panel-title__overlay,
  .v-expansion-panel-title[aria-haspopup="menu"][aria-expanded="true"]>.v-expansion-panel-title__overlay {
    background-color: var(--white-18);
  }

  .v-expansion-panels {
    overflow: hidden;
    overflow-y: scroll;
    max-height: rem(260);
  }

  .v-expansion-panel-title {
    justify-content: center;
    gap: rem(8);
    padding: 0;
  }

  .v-expansion-panel-title__icon {
    margin-inline-start: 0;
    position: absolute;
    right: 10px;
  }

  .v-expansion-panel__shadow {
    display: none;
  }

  .v-expansion-panel-title__overlay {
    background-color: var(--white-12);
  }

  .window__title {
    margin: rem(60) 0 0 0;
  }

  .window__user {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(15);
    gap: rem(8);

    span {
      color: var(--blue-0);
      font-size: rem(12);
    }

  }

  .window__organizations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(18);

    .organizations__item {
      width: 100%;
      padding: rem(16) 0;
      background: var(--white-3);
      border: 1px solid var(--block-border-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: rem(8);

      .organizations__start {
        display: flex;
        align-items: self-end;
        gap: rem(8);
      }

      &:hover {
        cursor: pointer;
        background: var(--white-5);
      }

      &:nth-child(1) {
        &[selected] {
          background-color: #d4dcff;
        }
      }

      &:nth-child(2) {
        &[selected] {
          background-color: #ffbeb4;
        }
      }
    }

    paper-button {
      opacity: 1;
      border: 0;
      color: hsl(155, 64%, 54%);
      background: hsla(155, 64%, 54%, 0.3);

      svg {
        fill: hsl(155, 64%, 54%);
      }
    }
  }

  paper-button {
    width: 100%;
    @include --body-button-set;
  }
}
</style>