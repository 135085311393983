import axios from 'axios'
import { url } from '@/store'

export const account = {
  namespaced: true,
  state: {
    user: null,
    tasks: null,
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getTasks(state) {
      return state.tasks
    }
  },
  mutations: {
    updateUser(state, res) {
      state.user = res
    },
    updateTasks(state, res) {
      state.tasks = res
    },
  },
  actions: {
    async requestUser({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/user`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateUser', result.data)
      } catch (error) {
        console.log(error);
      }
    },

    async requestTasks({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/personalTasks`,
          headers: {
            Authorization: localStorage.getItem('token')
          }
        })
        console.log(result);
        commit('updateTasks', result.data)
      } catch (error) {
        console.log(error);
      }
    },

    async addTask(ctx, { title, timeline }) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/personalTasks`,
          headers: {
            Authorization: localStorage.getItem('token')
          },
          data: {
            title,
            timeline
          }
        })
        console.log(result);
      } catch (error) {
        console.log(error);
        throw error
      }
    },

    async removeTask({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/personalTasks/delete`,
          headers: {
            Authorization: localStorage.getItem('token')
          },
          data: { id }
        })
        console.log(result);
        await dispatch('requestTasks')
      } catch (error) {
        console.log(error);
      }
    }
  },
};