<template>
    <v-dialog class="dialog">
        <v-card class="dialog_content">
            <div class="action">
                <v-card-actions>
                    <v-btn color="#ff5757" block @click="$emit('close')">
                        <v-icon size="40">mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </div>
            <v-card-text>
                <div class="dialog_title">Основная информация</div>
                <v-form ref="form" class="form_cabinet" @submit.prevent="editClient">
                    <v-text-field class="text_field_input" v-model="formData.head" label="Контактное лицо"
                        :rules="[() => !!formData.head || 'Обязательно']" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.fullTitle"
                        label="Полное наименование организации" :rules="[() => !!formData.fullTitle || 'Обязательно']"
                        required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.smallTitle"
                        label="Краткое наименование организации">
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.address" label="Юридический адрес"
                        :rules="[() => !!formData.address || 'Обязательно']" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.phone" maxlength="18"
                        v-maska="'+7 (###) ###-##-##'" label="Номер телефона организации"
                        :rules="[() => !!formData.phone || 'Обязательно']" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.email" :rules="[
                    () =>
                        !formData.email ||
                        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                        formData.email
                        ) ||
                        'Неверный формат электронной почты',
                    ]" label="Почта">
                    </v-text-field>
                    <v-text-field class="text_field_input" v-maska="'#*'" v-model="formData.ITN" label="ИНН" :rules="[
                    () => !!formData.ITN || 'Обязательно',
                    () => formData.ITN.length === 10 || 'ИНН состоит из 10 цифр',]" required maxlength="10">
                    </v-text-field>
                    <v-text-field class="text_field_input" v-maska="'#*'" v-model="formData.KPP" label="КПП"
                        :rules="[() => !!formData.KPP || 'Обязательно', () => formData.KPP.length === 9 || 'КПП состоит из 9 цифр',]"
                        required maxlength="9">
                    </v-text-field>
                    <v-text-field class="text_field_input" v-maska="'#*'" v-model="formData.PSRN" label="ОГРН"
                        :rules="[() => !!formData.PSRN || 'Обязательно', () => formData.PSRN.length === 13 || 'ОГРН состоит из 13 цифр',]"
                        required maxlength="13">
                    </v-text-field>
                    <v-textarea class="text_field_input_area" v-model="formData.description" label="Описание">
                    </v-textarea>
                    <v-btn type="submit" variant="outlined" class="cabinet_btn" @click="validate($event)"> Редактировать
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        client: {
            type: Object,
        },
    },
    computed: {
        formData() {
            return this.client;
        },
    },
    methods: {
        async editClient() {
            try {
                await this.$store.dispatch("techroom/manager/editClient", this.formData);
                this.$emit("close");
            } catch (error) {
                console.error(error);
            }
        },
        async validate(e) {
            let a = await this.$refs.form.validate();
            if (!a.valid) {
                e.preventDefault();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.form_cabinet {
    width: 100%;
    justify-content: unset;
    align-items: unset;
    display: grid !important;
    grid-template: repeat(7, auto) / repeat(2, auto);
    gap: rem(24);

    @media screen and (max-width: $medium) {
        display: flex !important;
        flex-direction: column;
    }

    .v-textarea {
        grid-area: 6 / 1 / 7 / 3;
    }

    .v-btn {
        width: 100%;
        grid-area: 7 / 1 / 8 / 3;
        background-color: unset;
        color: #ff5757;
        border-radius: 0;
    }

}

.bank__title {
    text-align: center;
    font-size: 18px;
    border-bottom: 2px solid #ff5757;
    line-height: 60px;
}
</style>
