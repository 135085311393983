<template>
  <section class="project__section">
    <div class="project__list">
      <h3 class="project__list-subheader">Информация о проекте</h3>
      <div class="project__list-item project-item">
        <div class="project-item__title">Название проект</div>
        <div class="project-item__subtitle">
          {{ project.nameProject }}
        </div>
      </div>
      <div class="project__list-item project-item">
        <div class="project-item__title">Название компании</div>
        <div class="project-item__subtitle">
          {{ project.nameCompany }}
        </div>
      </div>
      <div class="project__list-item project-item">
        <div class="project-item__title">ФИО Клиента</div>
        <div class="project-item__subtitle">
          {{ project.individual }}
        </div>
      </div>
      <div class="project__list-item project-item">
        <div class="project-item__title">Срок</div>
        <div class="project-item__subtitle">
          {{ $filters.filterDate(project.timeline) }}
        </div>
      </div>
      <div class="project__list-item project-item">
        <div class="project-item__title">Статус</div>
        <div class="project-item__subtitle">{{ project.status }}</div>
      </div>
    </div>
  </section>
  <v-divider></v-divider>
  <section class="project__section" v-if="project.membersProject.length">
    <div class="project__list">
      <h3 class="project__list-subheader">Разработчики</h3>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="item in project.membersProject" :key="item">
          <v-expansion-panel-title>
            {{ `${item.surname} ${item.name} ${item.lastName}` }}
            <span v-for="job in item.job" :key="job">
              {{ job }}
            </span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div v-for="stack in item.stacks" :key="stack">
              {{ stack }}
            </div>
            <div v-for="task in item.tasks" :key="task">
              <p>Название: {{ task.title }}</p>
              <p>Описание: {{ task.descriptionTitle }}</p>
              <p>Срок: {{ $filters.filterDate(task.timeline) }}</p>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
  },
};
</script>

<style>
</style>