import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    equipments: [],
    stockEquipments: [],
  },
  getters: {
    getEquipments(state) {
      return state.equipments;
    },
    getStockEquipments(state) {
      return state.stockEquipments;
    },
  },
  mutations: {
    updateEquipments(state, res) {
      state.equipments = res;
    },
    updateStockEquipments(state, res) {
      state.stockEquipments = res;
    },
  },
  actions: {
    async requestStockEquipments({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/equipments`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateStockEquipments', result.data);
      } catch (error) {
        console.error(error);
      }
    },

    async requestAddressEquipments({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/equipments/address/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateEquipments', result.data.equipments);
      } catch (error) {
        console.error(error);
      }
    },

    async requestFloorEquipments({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/equipments/floor/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateEquipments', result.data.equipments);
      } catch (error) {
        console.error(error);
      }
    },

    async createEquipment(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/equipment`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            type: formData.type,
            model: formData.model,
            num: formData.num,
            serialnum: formData.serialnum,
            statusId: formData.statusId,
            cabinetId: formData.cabinetId,
            workerId: formData.workerId,
            misc: formData.misc,
            stock: formData.stock ? formData.stock : 0,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editEquipment(ctx, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/equipment/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            type: formData.type,
            model: formData.model,
            num: formData.num,
            serialnum: formData.serialnum,
            statusId: formData.statusId,
            cabinetId: formData.cabinetId?.id || formData.cabinetId,
            workerId: formData.workerId,
            misc: formData.misc,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async stockEquipment(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/equipment/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            stock: true,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async outOfStockEquipment(ctx, { ids, cabinetId, stock }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/massStock/equipment`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            ids,
            cabinetId,
            stock,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async editEquipmentCabinet(ctx, { id, cabinetId, stock }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/equipment/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            cabinetId,
            stock,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async removeEquipment(ctx, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/equipment/${data.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            deleteStatus: data.status,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
