<template>
  <div class="wrapper">
    <sidebar></sidebar>
    <main class="page">
      <div class="page__container container">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "@/components/app/itroom/Sidebar";
export default {
  components: { Sidebar },
};
</script>

<style lang="scss">
.page {
  padding: 0 0 0 rem(256);
  @media screen and (max-width: 1279px) {
    padding: 0 0 0 0;
  }
}
.container {
  padding: rem(25);
}
</style>