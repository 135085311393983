<template>
  <v-toolbar dark color="primary" class="header_tasks">
    <v-btn icon dark @click="$emit('closeOverView')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>Задачи на {{ date }}</v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  name: 'HeaderView',
  data() {
    return {

    }
  },
  props: {
    date: String,
  }
}
</script>

<style scoped lang="scss">
.header_tasks {
  min-height: rem(64);
}
</style>