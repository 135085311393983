<template>
  <v-dialog fullscreen transition="dialog-bottom-transition">
    <v-card class="project">
      <v-toolbar color="var(--blue-12)">
        <v-btn icon @click="$emit('close')">
          <v-icon color="var(--white-0)">mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ project.nameProject }}
        </v-toolbar-title>
      </v-toolbar>
      <section class="project__section">
        <div class="project__list">
          <h3 class="project__list-subheader">Информация о проекте</h3>
          <div class="project__list-item project-item">
            <div class="project-item__title">Название проект</div>
            <div class="project-item__subtitle">{{ project.nameProject }}</div>
          </div>
          <div class="project__list-item project-item">
            <div class="project-item__title">Название компании</div>
            <div class="project-item__subtitle">{{ project.nameCompany }}</div>
          </div>
          <div class="project__list-item project-item">
            <div class="project-item__title">ФИО Клиента</div>
            <div class="project-item__subtitle">{{ project.individual }}</div>
          </div>
          <div class="project__list-item project-item">
            <div class="project-item__title">Срок</div>
            <div class="project-item__subtitle">
              {{ $filters.filterDate(project.timeline) }}
            </div>
          </div>
          <div class="project__list-item project-item">
            <div class="project-item__title">Статус</div>
            <div class="project-item__subtitle">{{ project.status }}</div>
          </div>
        </div>
      </section>
      <v-divider></v-divider>
      <section class="project__section" v-if="project.tasks.length">
        <div class="project__list">
          <h3 class="project__list-subheader">Мои задачи</h3>
          <div
            class="project__list-item project-item"
            v-for="item in project.tasks"
            :key="item"
          >
            <div class="project-item__title">
              {{ item.title }}
            </div>
            <div class="project-item__subtitle">
              {{ $filters.filterDate(item.timeline) }}
            </div>
          </div>
        </div>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
  },
};
</script>
