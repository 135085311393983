<template>
  <v-chip-group filter v-model="floorId" class="selector-floors">
    <v-chip
      class="floor"
      v-for="(item, index) in $store.getters[
        'techroom/specialist/getAddress'
      ].floors?.filter((floor) => !floor.delete)"
      :key="index"
      :value="item.id"
    >
      <span>Этаж {{ item.floor }}</span>
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  data() {
    return {
      floorId: this.$store.getters['techroom/specialist/getFloorId'],
    };
  },
  computed: {
    getAddress() {
      return this.$store.getters['techroom/specialist/getAddress'];
    },
  },
  watch: {
    floorId() {
      this.$store.commit('techroom/specialist/updateFloorId', this.floorId);
    },
    getAddress() {
      this.floorId = null;
    },
  },
};
</script>

<style lang="scss">
.selector-floors {
  padding-bottom: 8px;

  @media screen and (max-width: $large) {
    width: 100%;
    overflow: auto;

    .v-chip {
      display: inline-table;

      .v-chip__filter {
        vertical-align: middle !important;
      }

      span {
        vertical-align: sub;
      }
    }
  }

  @media screen and (max-width: $x-small) {
    width: 100%;
    overflow: auto;

    .v-chip {
      display: inline-table;

      .v-chip__filter {
        vertical-align: middle !important;
      }

      span {
        vertical-align: sub;
      }
    }
  }
}
</style>
