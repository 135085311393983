<template>
  <div class="wrapper">
    <SidebarOrganization></SidebarOrganization>
    <main class="page">
      <div class="page__container container">
        <v-card class="page__organizations">
          <NavbarOrganization v-if="$route.name !== 'OrganizationGeneral' && $route.name !== 'Organizations'"></NavbarOrganization>
          <router-view></router-view>
        </v-card>
      </div>
    </main>
  </div>
</template>

<script>
import SidebarOrganization from "@/components/app/techroom/specialist/organization/SidebarOrganization.vue";
import NavbarOrganization from "@/components/app/techroom/specialist/organization/NavbarOrganization.vue";
export default {
  components: { SidebarOrganization, NavbarOrganization },
};
</script>

<style lang="scss">
  .button-add {
    position: fixed;
    bottom: rem(25);
    right: rem(25);
    color: #fff;
    z-index: 999;

    &.blur {
      opacity: 0;
      z-index: -1;
    }
.item_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: rem(16) 0;

  @media screen and (max-width: $x-small) {
    padding: 0.5rem;
  }

  }

  .header_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

  }

  .header_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 16px;

    .organization_icon {
      display: none;
      color: white;

      @media screen and (max-width: $x-small) {
        display: block;
      }
    }

    .organization_btn {
      background-color: #ff5757;
      color: white;
      display: block;
      border-radius: 20px;
      text-transform: none;

      @media screen and (max-width: $x-small) {
        display: none;
      }

      h5 {
        color: white;
        font-size: 13px;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: rem(25);

  &__title {
    display: flex;
    justify-content: start;
    gap: rem(12);
    align-items: center;
    padding-top: rem(12);
    border-top: 1px solid #ff5757;

    &_icon {
      color: #ff5757;
    }
  }

  .add_computers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(8);
    background: var(--white-6);
    cursor: pointer;
    border-radius: 2px;
    padding: rem(8);
  }

  .add_block {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: rem(8);
    background: var(--white-6);
    height: max-content;
    width: max-content;
    cursor: pointer;
    border-radius: 2px;
    padding: rem(8);
  }

  &_blocks {
    // display: grid;
    // grid-template: 1fr / repeat(3, 1fr);
    // gap: rem(28);

    @media screen and (max-width: $large) {
      grid-template: 1fr / repeat(2, 1fr);
      gap: rem(18);
    }

    @media screen and (max-width: $x-small) {
      grid-template: 1fr / repeat(1, 1fr);
      gap: rem(14);
    }

    .block {
      border-radius: rem(28);
      padding: rem(16) rem(16) rem(16) rem(16);
      background-color: var(--white-6);
      display: flex;
      flex-direction: column;
      gap: rem(24);

      .blocks__secondary {
        display: flex;
        gap: rem(28);
        flex-direction: column;

        @media screen and (max-width: $large) {
          flex-direction: column;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: rem(14);

        &_title {
          display: flex;
          justify-content: space-between;

          &_fields {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &_field {
            padding: 0 rem(10) 0 rem(10);
          }
        }

        &_container_main {
          display: grid;
          grid-template: 1fr / repeat(4, 1fr);
          width: min-content;
          gap: rem(48);

          @media screen and (max-width: $large) {
            grid-template: 1fr / repeat(1, 1fr);
            gap: rem(24);
          }

          @media screen and (max-width: $x-small) {
            grid-template: 1fr / repeat(1, 1fr);
            gap: rem(14);
          }
        }

        &_container {
          display: grid;
          grid-template: 1fr / repeat(3, 1fr);
          width: min-content;
          gap: rem(26);

          @media screen and (max-width: $large) {
            grid-template: 1fr / repeat(2, 1fr);
            gap: rem(18);
          }

          @media screen and (max-width: $x-small) {
            grid-template: 1fr / repeat(1, 1fr);
            gap: rem(18);
          }

          &_fields {
            display: grid;
            grid-template: 1fr / repeat(2, 1fr);
            gap: rem(26);

            @media screen and (max-width: $large) {
              grid-template: 1fr / repeat(1, 1fr);
              gap: rem(18);
            }
          }
        }

        &_item {
          display: flex;
          flex-direction: column;

          &_label {
            color: var(--white-15);
            width: max-content;
          }

          &_value {

            &_field {
              padding: rem(10);
            }
          }
        }
      }
    }
  }
}

// .actions-list {

//   @media screen and (max-width: 1199.98px) {
//     width: 250px;
//   } 
// }

.start {

  @media screen and (max-width: 1199.98px) {
    background-color: hsl(0, 0%, 98%);
    position: sticky;
    left: 0;
    z-index: 100;
  }
}

// .page__organizations {

//   @media screen and (max-width: 1199.98px) {
//     padding: rem(10) rem(20);
//   }
// }
</style>