<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление обслуживающей организации</div>
        <div>Организация: {{ service.organization }}</div>
        <div>Услуга: {{ service.service }}</div>
        <div>Цена: {{ $filters.filterCurrency(service.price) }}</div>
        <div>Номер договора: {{ service.contractNumber }}</div>
        <div v-if="service.misc" style="white-space: break-spaces;">
          {{ service.misc }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
    },
  },
};
</script>
