<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование кабинета</div>
        <v-form ref="form" @submit.prevent="submitHandler" class="form_cabinet">
          <v-text-field
            type="number"
            class="text_field_input"
            v-model="formData.num"
            label="Номер кабинета"
            v-maska="'####'"
            :rules="[() => !!formData.num || 'Обязательное поле']"
            required
          >
          </v-text-field>
          <v-select
            label="Выберите этаж"
            v-model="formData.floor"
            :items="
              $store.getters['techroom/specialist/getAddress'].floors.filter(
                (item) => !item.delete
              )
            "
            item-title="floor"
            item-value="id"
            :rules="[() => !!formData.floor || 'Обязательное поле']"
            required
          >
          </v-select>
          <v-textarea
            class="text_field_input"
            v-model="formData.misc"
            label="Заметки"
          ></v-textarea>
          <v-btn type="submit" class="cabinet_btn" @click="validate($event)">
            Сохранить
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    cabinet: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        num: null,
        floor: null,
        misc: null,
      },
    };
  },

  watch: {
    cabinet() {
      this.formData.num = this.cabinet.num;
      this.formData.floor = this.cabinet.floor;
      this.formData.misc = this.cabinet.misc;
    },
  },

  methods: {
    async submitHandler() {
      try {
        await this.$store.dispatch('techroom/specialist/editCabinet', {
          formData: this.formData,
          id: this.cabinet.id,
        });
        if (this.$store.getters['techroom/specialist/getFloorId']) {
          await this.$store.dispatch(
            'techroom/specialist/requestFloorCabinets',
            this.$store.getters['techroom/specialist/getFloorId']
          );
        } else {
          await this.$store.dispatch(
            'techroom/specialist/requestAddressCabinets',
            this.$store.getters['techroom/specialist/getAddress'].id
          );
        }
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },
    async validate(e) {
      let a = await this.$refs.form.validate();
      if (!a.valid) {
        e.preventDefault();
      }
    },
  },
};
</script>
