<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Добавить проект</h2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="valid ? submitHandler() : validate()"
        >
          <v-text-field
            v-model="formData.nameProject"
            label="Название проекта"
            required
            :rules="rules"
          ></v-text-field>

          <v-select
            v-model="formData.clientId"
            :items="$store.getters['itroom/manager/getClients']"
            item-title="individual"
            item-value="id"
            label="Физическое лицо ФИО"
            :rules="rules"
          ></v-select>

          <v-text-field
            v-model="formData.phone"
            label="Номер телефона"
            required
            maxlength="18"
            v-maska="'+7 (###) ###-##-##'"
            :rules="rules"
          ></v-text-field>

          <v-text-field
            v-model="formData.nameCompany"
            label="Название компании"
            required
            :rules="rules"
          ></v-text-field>

          <v-text-field
            v-model="formData.timeline"
            label="Сроки"
            required
            :rules="rules"
          ></v-text-field>

          <v-select
            v-model="formData.teamLeadId"
            label="Лидер проекта"
            :items="$store.getters['itroom/manager/getTeamLeads']"
            item-title="name"
            item-value="id"
            :rules="rules"
          >
          </v-select>

          <v-text-field
            v-model="formData.comment"
            label="Комментарий"
            required
            :rules="rules"
          ></v-text-field>

          <v-text-field
            v-model="formData.description"
            label="Описание"
            required
            :rules="rules"
          ></v-text-field>

          <v-btn type="submit" color="success" class="mr-4"> Создать </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,

      formData: {
        nameProject: null,
        clientId: null,
        phone: null,
        nameCompany: null,
        timeline: null,
        teamLeadId: null,
        comment: null,
        description: null,
        loadingPage: true,
      },

      rules: [(value) => !!value || "Обязательное поле"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async submitHandler() {
      try {
        await this.$store.dispatch(
          "itroom/manager/createProject",
          this.formData
        );
        this.$emit("close");
        this.$refs.form.reset();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>