import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    workers: [],
  },
  getters: {
    getWorkers(state) {
      return state.workers;
    },
  },
  mutations: {
    updateWorkers(state, res) {
      state.workers = res;
    },
  },
  actions: {
    async requestAddressWorkers({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/workers/address/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateWorkers', result.data.workers);
      } catch (error) {
        console.error(error);
      }
    },

    async createWorker({ getters }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/worker`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            fullName: formData.fullName,
            position: formData.position,
            phone: formData.phone,
            email: formData.email,
            dob: formData.dob,
            passport: formData.passport,
            issuedWhom: formData.issuedWhom,
            issuedWhen: formData.issuedWhen,
            addressId: getters.getAddress.id,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editWorker(ctx, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/worker/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            fullName: formData.fullName,
            position: formData.position,
            phone: formData.phone,
            email: formData.email,
            dob: formData.dob,
            passport: formData.passport,
            issuedWhom: formData.issuedWhom,
            issuedWhen: formData.issuedWhen,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async removeWorker(ctx, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/worker/${data.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            deleteStatus: data.status,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
