<template>
  <v-calendar class="custom-calendar max-w-full" :masks="masks"
    :attributes="$store.getters['techroom/specialist/getObjects']" disable-page-swipe>
    <template v-slot:day-content="{ day, attributes }">
      <div class="day-window">
        <span class="day-label" v-if="this.wi > 575.98">{{ day.day }}</span>
        <span class="day-label" v-else>{{ day.day + ', ' + new Date(day.date).toLocaleString('ru-ru', {
            weekday: 'short'
          })
        }}</span>
        <div class="day-objects">
          <p v-for="attr in attributes" :key="attr.key" class="object-item" :class="attr.customData.class"
            style="display: flex; justify-content: space-between">
            <span>{{ attr.customData.title }}</span>
            <svg
              v-if="$store.getters.USER?.selectedRole?.role === 'MANAGER' || $store.getters.USER?.selectedRole?.role === 'DIRECTOR'"
              height="16" width="16" style="fill: #e5e5e5; cursor: pointer" @click="taskDelete(attr.key)">
              <path
                d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z" />
            </svg>
          </p>
        </div>
        <div v-if="($store.getters.USER?.selectedRole?.role === 'MANAGER' || $store.getters.USER?.selectedRole?.role === 'DIRECTOR') && new Date(day.date).getTime() >= new Date().getTime() - 86400000"
          class="add-label" @click="openDialogAddObject(day)"
          title="Добавить объект">
          <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20" fill="#38b2ac">
            <path d="M9.25 15v-4.25H5v-1.5h4.25V5h1.5v4.25H15v1.5h-4.25V15Z" />
          </svg>
        </div>
      </div>
    </template>
  </v-calendar>
  <DialogAddObject v-model="dialogAddTask" :day="day" @close="dialogAddTask = false" />
</template>

<script>
import DialogAddObject from '@/components/app/techroom/manager/DialogAddObject.vue';

export default {
  components: { DialogAddObject },
  async mounted() {
    window.addEventListener("resize", () => {
      this.wi = document.documentElement.clientWidth;
    });
    await this.$store.dispatch('techroom/specialist/requestObjects');
  },
  data() {
    return {
      dialogAddTask: false,
      day: null,
      key: 1,
      wi: document.documentElement.clientWidth,
      customData: {
        title: 'Задача №1',
        class: 'bg-red-600',
      },
      dates: new Date(),
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
    };
  },
  methods: {
    async taskDelete(id) {
      await this.$store.dispatch('techroom/specialist/deleteObject', id)
    },
    openDialogAddObject(day) {
      this.day = day;
      console.log(day);
      this.dialogAddTask = true;
    },
  },

};
</script>

<style lang="scss">
.lightblue {
  background-color: hsl(207, 73%, 57%);
}

.blue {
  background-color: hsl(229, 76%, 66%);
}

.lightgreen {
  background-color: rgb(56, 178, 172);
}

.red {
  background-color: hsl(0, 76%, 57%);
}

.pink {
  background-color: hsl(331, 79%, 66%);
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;


  .day-window {
    position: relative;

    .add-label {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 0;
      font-size: rem(12);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .day-objects {
    height: 60px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: rem(8);

    .object-item {
      padding: 0.25rem;
      line-height: 1.25;
      font-size: 0.75rem;
      border-radius: 0.125rem;
      word-break: break-all;
      padding-right: 17px;
      position: relative;

      svg {
        position: absolute;
        right: 3px;
        top: 0;
      }
    }
  }

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }

  & .vc-weeks {
    padding: 0;

    @media screen and (max-width: $x-small) {
      grid-template-columns: repeat(3, 1fr)
    }
  }

  & .vc-day {
    @media screen and (max-width: $medium) {
      min-width: unset !important;
    }
  }

  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;

    @media screen and (max-width: $x-small) {
      display: none;
    }
  }

  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);

      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
