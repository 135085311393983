import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    appeals: [],
    specialists: [],
  },
  getters: {
    getAppeals(state) {
      return state.appeals;
    },
  },
  mutations: {
    updateAppeals(state, res) {
      state.appeals = res;
    },
  },
  actions: {
    async requestAppeals({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/manager/appeals`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log("----");
        console.log(result);
        console.log("----");
        commit('updateAppeals', result.data.appeals);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editAppeal(ctx, formData) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/manager/appeal/${formData.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            task: formData.task,
            addressId: formData.address.id,
            statusId: formData.status.id,
            deleteStatus: formData.deleteStatus,
          }
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async createAppeal(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/appeal`,
          data: {
            statusId: formData.statusId,
            task: formData.task,
            addressId: formData.addressId,
          },
          headers: {
            Authorization: localStorage.getItem('token'),
          }
        })
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    },
    async moveArchiveAppeal(ctx, id) {
      try {
        const result = await axios({
          method: 'delete',
          url: `${url}/api/techroom/appeal/moveArchive/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          }
        })
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    }
  },
};
