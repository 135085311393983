<template>
  <div
    class="item_header"
    style="display: flex; flex-direction: column; gap: 1rem"
  >
    <div class="page_menu_title">Склад</div>
    <div>
      <v-chip-group filter v-model="selectItem" class="selector-floors">
        <v-chip
          class="floor"
          v-for="(item, index) in items"
          :key="index"
          :value="item"
          @click="getItems(item)"
        >
          <span>{{ item }}</span>
        </v-chip>
      </v-chip-group>
      <div class="page_menu_title">
        <div
          class="link"
          :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }"
          @click="this.archive = null"
        >
          Все
        </div>
        <div
          class="link"
          :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
          @click="this.archive = false"
        >
          Активные
        </div>
        <div
          class="link"
          :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }"
          @click="this.archive = true"
        >
          В архиве
        </div>
      </div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            color="#ff5757"
            class="button-add"
            v-bind="props"
            icon="mdi-plus"
          >
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="openDialogCreate(true)">
            Добавить компьютер
          </v-list-item>
          <v-list-item @click="openDialogCreate(false)">
            Добавить оборудование
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
  <div class="content">
    <v-btn
      @click="openDialogChange(null, true)"
      v-if="
        (selectedComputers[0] && selectItem === 'Компьютеры') ||
        selectItem === null ||
        selectItem === undefined
      "
      >Переместить</v-btn
    >
    <v-btn
      @click="openDialogChange(null, false)"
      v-if="selectedEquipment[0] && selectItem === 'Оборудование'"
      >Переместить</v-btn
    >
    <div class="content_block">
      <v-table
        v-if="
          selectItem === 'Компьютеры' ||
          selectItem === null ||
          selectItem === undefined
        "
      >
        <thead>
          <tr>
            <th class="text-left start">Компьютер №</th>
            <th class="text-left">Мат. Плата</th>
            <th class="text-left">ЦП</th>
            <th class="text-left">ГП</th>
            <th class="text-center">
              <v-icon>mdi-cogs</v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getComputers"
            :key="index"
            :style="{
              color: item.delete ? '#7c7c7c' : '#000',
              transition: '.15s ease',
            }"
          >
            <td class="text-left start">
              <v-checkbox
                :label="item.num || 'Не указано'"
                v-model="selectedComputers"
                :value="item.id"
              ></v-checkbox>
            </td>
            <td class="text-left">{{ item.motherboard }}</td>
            <td class="text-left">{{ item.cpu }}</td>
            <td class="text-left">{{ item.gpu || 'Не указано' }}</td>
            <td class="text-center">
              <v-menu class="actions-list">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" variant="text" size="small">
                    <v-icon size="16">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item min-height="1" @click="openDialog(item, true)">
                    <v-list-item-title>Подробнее</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    min-height="1"
                    @click="openDialogEdit(item, true)"
                  >
                    <v-list-item-title>Редактировать</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    min-height="1"
                    @click="openDialogChange(item, true)"
                  >
                    <v-list-item-title>Переместить со склада</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!item.delete"
                    min-height="1"
                    :value="item.id"
                    @click="removeItem(item.id, true, 'computer')"
                  >
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    min-height="1"
                    :value="item.id"
                    @click="removeItem(item.id, false, 'computer')"
                  >
                    <v-list-item-title>Восстановить</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-table v-if="selectItem === 'Оборудование'">
        <thead>
          <tr>
            <th class="text-left start">Тип</th>
            <th class="text-left">Модель</th>
            <th class="text-left">Состояние</th>
            <th class="text-center">
              <v-icon>mdi-cogs</v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getEquipments"
            :key="index"
            :style="{
              color: item.delete ? '#7c7c7c' : '#000',
              transition: '.15s ease',
            }"
          >
            <td class="text-left start">
              <v-checkbox
                :label="item.type || 'Не указано'"
                v-model="selectedEquipment"
                :value="item.id"
              ></v-checkbox>
            </td>
            <td class="text-left">{{ item.model || 'Не указано' }}</td>
            <td class="text-left">{{ item.condition || 'Не указано' }}</td>
            <td class="text-center">
              <v-menu class="actions-list">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" variant="text" size="small">
                    <v-icon size="16">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item min-height="1" @click="openDialog(item, false)">
                    <v-list-item-title>Подробнее</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    min-height="1"
                    @click="openDialogEdit(item, false)"
                  >
                    <v-list-item-title>Редактировать</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    min-height="1"
                    @click="openDialogChange(item, false)"
                  >
                    <v-list-item-title>Переместить со склада</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!item.delete"
                    min-height="1"
                    :value="item.id"
                    @click="removeItem(item.id, true, 'equipment')"
                  >
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    min-height="1"
                    :value="item.id"
                    @click="removeItem(item.id, false, 'equipment')"
                  >
                    <v-list-item-title>Восстановить</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
  <v-snackbar v-model="snackbar" timeout="2000">
    {{ snackbarTitle }}

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="snackbar = false">
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
  <DialogChangeCabinet
    v-model="dialogChangeCabinet"
    @clearchecks="clear()"
    @close="dialogChangeCabinet = false"
    :item="itemChange"
    :ids="ids"
    :type="typeChange"
  />
  <DialogEditComputer
    v-model="dialogEditComputer"
    @close="dialogEditComputer = false"
    :stock="addStockComputer"
    :computer="computer"
  />
  <DialogEditEquipment
    v-model="dialogEditEquipment"
    @close="dialogEditEquipment = false"
    :stock="addStockEquipment"
    :equipment="equipment"
  />
  <DialogEquipment
    v-model="dialogEquipment"
    @close="dialogEquipment = false"
    :equipment="equipment"
  />
  <DialogComputer
    v-model="dialogComputer"
    @close="dialogComputer = false"
    :computer="computer"
  />
  <DialogAddEquipment
    v-model="dialogAddEquipment"
    @close="dialogAddEquipment = false"
    @snackbar="
      (snackbar = true),
        (snackbarTitle = `Оборудование успешно добавлено на склад!`)
    "
    :stock="addStockEquipment"
  />
  <DialogAddComputer
    v-model="dialogAddComputer"
    @close="dialogAddComputer = false"
    @snackbar="
      (snackbar = true),
        (snackbarTitle = `Компьютер успешно добавлен на склад!`)
    "
    :stock="addStockComputer"
  />
</template>

<script>
import DialogChangeCabinet from '@/components/app/techroom/specialist/organization/stock/DialogChangeCabinet';
import DialogEditComputer from '@/components/app/techroom/specialist/organization/computers/DialogEditComputer';
import DialogEditEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogEditEquipment';
import DialogEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogEquipment';
import DialogComputer from '@/components/app/techroom/specialist/organization/computers/DialogComputer';
import DialogAddComputer from '@/components/app/techroom/specialist/organization/computers/DialogAddComputer';
import DialogAddEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogAddEquipment';
export default {
  components: {
    DialogChangeCabinet,
    DialogAddComputer,
    DialogAddEquipment,
    DialogComputer,
    DialogEquipment,
    DialogEditComputer,
    DialogEditEquipment,
  },
  name: 'Stock',
  data() {
    return {
      items: ['Оборудование', 'Компьютеры'],
      archive: null,
      typeChange: null,
      itemChange: null,
      dialogChangeCabinet: false,
      dialogEditComputer: false,
      dialogEditEquipment: false,
      computer: null,
      equipment: null,
      dialogComputer: false,
      dialogEquipment: false,
      snackbarTitle: null,
      snackbar: false,
      dialogAddComputer: false,
      dialogAddEquipment: false,
      addStockComputer: true,
      addStockEquipment: true,
      selectItem: 'Компьютеры',
      computers: null,
      equipments: null,
      selectedComputers: [],
      selectedEquipment: [],
      ids: [],
    };
  },
  computed: {
    getComputers() {
      if (this.archive === true) {
        return this.$store.getters[
          'techroom/specialist/getStockComputers'
        ].filter((item) => item.delete);
      }
      if (this.archive === false) {
        return this.$store.getters[
          'techroom/specialist/getStockComputers'
        ].filter((item) => !item.delete);
      }
      return this.$store.getters['techroom/specialist/getStockComputers'];
    },
    getEquipments() {
      if (this.archive === true) {
        return this.$store.getters[
          'techroom/specialist/getStockEquipments'
        ].filter((item) => item.delete);
      }
      if (this.archive === false) {
        return this.$store.getters[
          'techroom/specialist/getStockEquipments'
        ].filter((item) => !item.delete);
      }
      return this.$store.getters['techroom/specialist/getStockEquipments'];
    },
  },
  async mounted() {
    await this.$store.dispatch('techroom/specialist/requestStockComputers');
    await this.$store.dispatch('techroom/specialist/requestStockEquipments');
    this.computers =
      this.$store.getters['techroom/specialist/getStockComputers'];
    this.equipments =
      this.$store.getters['techroom/specialist/getStockEquipments'];
  },
  methods: {
    clear() {
      (this.selectedComputers = []),
        (this.selectedEquipment = []),
        (this.ids = []);
    },
    async getItems(item) {
      if (item === 'Оборудование') {
        await this.$store.dispatch(
          'techroom/specialist/requestStockEquipments'
        );
        this.equipments =
          this.$store.getters['techroom/specialist/getStockEquipments'];
      }

      if (item === 'Компьютеры') {
        await this.$store.dispatch('techroom/specialist/requestStockComputers');
        this.computers =
          this.$store.getters['techroom/specialist/getStockComputers'];
      }
    },
    async openDialogCreate(computer) {
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      if (computer === true) {
        this.dialogAddComputer = true;
      }
      if (computer === false) {
        this.dialogAddEquipment = true;
      }
    },
    openDialog(item, computer) {
      if (computer) {
        this.computer = item;
        this.dialogComputer = true;
      } else {
        this.equipment = item;
        this.dialogEquipment = true;
      }
    },
    async openDialogEdit(item, computer) {
      if (computer) {
        this.computer = item;
        this.dialogEditComputer = true;
      } else {
        this.equipment = item;
        this.dialogEditEquipment = true;
      }
    },
    openDialogChange(item, computer) {
      if (computer) {
        if (this.selectedComputers[0]) {
          this.ids = this.selectedComputers;
        }
        this.itemChange = item;
        this.typeChange = 'computer';
        this.$store.dispatch('techroom/specialist/requestOrganizations');
        this.dialogChangeCabinet = true;
      } else {
        if (this.selectedEquipment[0]) {
          this.ids = this.selectedEquipment;
        }
        this.itemChange = item;
        this.typeChange = 'equipment';
        this.$store.dispatch('techroom/specialist/requestOrganizations');
        this.dialogChangeCabinet = true;
      }
    },
    async removeItem(id, status, type) {
      if (type === 'computer') {
        await this.$store.dispatch('techroom/specialist/removeComputer', {
          id,
          status,
        });
        await this.$store.dispatch('techroom/specialist/requestStockComputers');
      } else {
        await this.$store.dispatch('techroom/specialist/removeEquipment', {
          id,
          status,
        });
        await this.$store.dispatch(
          'techroom/specialist/requestStockEquipments'
        );
      }
    },
  },
};
</script>

<style scoped></style>
