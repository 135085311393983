<template>
  <v-table density="comfortable">
    <thead>
      <tr>
        <th class="text-left">Название компании</th>
        <th class="text-left">ФИО</th>
        <th class="text-left">Номер телефона</th>
        <th class="text-left">Электронная почта</th>
        <th class="text-left">Номер договора</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in $store.getters['itroom/manager/getClients']"
        :key="item.id"
      >
        <td v-if="!item.nameCompany" class="empty">- не указано -</td>
        <td v-else>{{ item.nameCompany }}</td>
        <td>{{ item.individual }}</td>
        <td>{{ $filters.filterNumber(item.phone) }}</td>
        <td>{{ item.email }}</td>
        <td>
          <span class="dogovor-link">{{ item.dogovor }}</span>
        </td>
        <td class="text-right">
          <!-- Важно -->
          <!-- <v-btn variant="text" size="x-small" class="fill-height">
              <svg height="20" width="20">
                <path
                  d="M7.292 14.958 10 13.354l2.729 1.604-.708-3.041 2.354-2.021-3.125-.292L10 6.708 8.75 9.625l-3.125.271 2.396 2Zm-.938 1.25.979-4.062-3.229-2.729 4.229-.375L10 5.208l1.667 3.854 4.229.355-3.229 2.729.979 4.062L10 14.042ZM10 11.042Z"
                />
              </svg>
            </v-btn> -->
          <!-- Важно -->

          <!-- Подробнее -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-more manage-item fill-height"
            :value="item.id"
            @click="openDialog(item.id)"
          >
            <v-tooltip activator="parent" location="top"> Подробнее </v-tooltip>
            <IconView />
          </v-btn>
          <!-- Подробнее -->

          <!-- Редактировать -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-edit manage-item fill-height"
            :value="item.id"
            @click="openDialogEdit(item.id)"
          >
            <v-tooltip activator="parent" location="top">
              Редактирование
            </v-tooltip>
            <IconEdit />
          </v-btn>
          <!-- Редактировать -->

          <!-- Удаление -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-delete manage-item fill-height"
            :value="item.id"
            @click="openDialogDelete(item)"
          >
            <v-tooltip activator="parent" location="top">
              Перенести в Лиды
            </v-tooltip>
            <IconDelete />
          </v-btn>
          <!-- Удаление -->
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogClient :client="client" v-model="dialog" @close="dialog = false" />
  <DialogEditClient
    :client="client"
    v-model="dialogEdit"
    @close="dialogEdit = false"
  />
  <DialogDeleteClient
    :client="client"
    v-model="dialogDelete"
    @close="dialogDelete = false"
  />
</template>

<script>
import DialogClient from "@/components/app/itroom/manager/clients/DialogClient.vue";
import DialogEditClient from "@/components/app/itroom/manager/clients/DialogEditClient.vue";
import DialogDeleteClient from "@/components/app/itroom/manager/clients/DialogDeleteClient.vue";
export default {
  components: { DialogClient, DialogEditClient, DialogDeleteClient },
  emits: ["loading"],
  data() {
    return {
      clients: null,
      client: null,

      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
    };
  },
  methods: {
    async openDialog(id) {
      await this.$store.dispatch("itroom/manager/requestClient", id);
      this.client = this.$store.getters["itroom/manager/getClient"];
      this.dialog = true;
    },
    async openDialogEdit(id) {
      await this.$store.dispatch("itroom/manager/requestClient", id);
      this.client = this.$store.getters["itroom/manager/getClient"];
      this.dialogEdit = true;
    },
    async openDialogDelete(item) {
      this.client = item;
      this.dialogDelete = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/manager/requestClients");
    setTimeout(() => {
      this.$emit("loading");
    }, 500);
  },
};
</script>

<style>
</style>