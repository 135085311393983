import { createStore } from 'vuex';
import axios from 'axios';

import authorization from '@/store/modules/authorization';
import { itroom } from '@/store/itroom';
import { techroom } from '@/store/techroom';
import { store } from '@/store/';
import router from '@/router/router';

export const wsUrl = 'ws://crm.techroom18.ru:9000';
export const url = 'http://crm.techroom18.ru:8001';

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      await store.dispatch('logout');
      router.push('/');
    }
    return Promise.reject(err);
  }
);

export default createStore({
  state: {
    width: document.documentElement.clientWidth,
  },
  getters: {},
  mutations: {
    updateWidth(state, payload) {
      state.width = payload;
    },
  },
  actions: {
    resizeWidth({ commit }) {
      window.addEventListener('resize', () => {
        commit('updateWidth', document.documentElement.clientWidth);
      });
    },
  },
  modules: {
    authorization,
    itroom,
    techroom,
  },
});
