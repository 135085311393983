<template>
  <div class="item_header">
    <!-- <div>
      <v-responsive class="mx-auto" max-width="344">
        <v-text-field
          label="Поиск по сотруднкам"
          hide-details="auto"
        ></v-text-field>
      </v-responsive>
    </div> -->
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title">
        <h2 class="font-weight-bold">
          Программное обеспечение
        </h2>
      </div>
      <div class="page_menu_title">
        <div class="link" :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }" @click="this.archive = null">
          Все</div>
        <div class="link" :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
          @click="this.archive = false">Активные</div>
        <div class="link" :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }" @click="this.archive = true">В
          архиве</div>
      </div>
    </div>
    <div class="header_right">
      <div class="d-flex justify-space-around align-center flex-column flex-md-row">
        <v-btn class="button-add" color="#ff5757" @click="dialogAddSoftware = true" icon="mdi-plus"></v-btn>
      </div>
    </div>
  </div>
  <v-table>
    <thead>
      <tr>
        <th class="text-left start">Название ПО</th>
        <th class="text-left">Цена</th>
        <th class="text-left">Подписка</th>
        <th class="text-left">Дата списания</th>
        <th class="text-center">
          <v-icon>mdi-cogs</v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in getSoft" :key="item.id"
        :style="{ color: item.delete ? '#7c7c7c' : '#000', transition: '.15s ease' }"
        @click="openDialog(item)">
        <td class="text-left start">{{ item.title }}</td>
        <td class="text-left">{{ $filters.filterCurrency(item.price) }}</td>
        <td class="text-left">
          {{ item.subscriptionDate }}
        </td>
        <td class="text-left">{{ item.debitDate }}</td>
        <td class="text-center">
          <v-menu class="actions-list">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small">
                <v-icon size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item min-height="1" :value="item.id" @click="openDialog(item)">
                <v-list-item-title> Подробнее </v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="openDialogEdit(item)">
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!item.delete" min-height="1" :value="item.id" @click="removeSoftware(item.id, true)">
                <v-list-item-title> Удалить </v-list-item-title>
              </v-list-item>
              <v-list-item v-else min-height="1" :value="item.id" @click="removeSoftware(item.id, false)">
                <v-list-item-title> Восстановить </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogAddSoftware v-model="dialogAddSoftware" @close="dialogAddSoftware = false" />
  <DialogSoftware v-model="dialogSoftware" @close="dialogSoftware = false" :software="software" />
  <DialogEditSoftware v-model="dialogEditSoftware" @close="dialogEditSoftware = false" :software="software" />
</template>

<script>
import DialogAddSoftware from "@/components/app/techroom/specialist/organization/software/DialogAddSoftware.vue";
import DialogSoftware from "@/components/app/techroom/specialist/organization/software/DialogSoftware.vue";
import DialogEditSoftware from "@/components/app/techroom/specialist/organization/software/DialogEditSoftware.vue";
export default {
  components: { DialogAddSoftware, DialogSoftware, DialogEditSoftware },
  data() {
    return {
      software: null,
      archive: null,
      dialogAddSoftware: false,
      dialogSoftware: false,
      dialogEditSoftware: false,
    };
  },
  computed: {
    getAddress() {
      return this.$store.getters["techroom/specialist/getAddress"];
    },
    getSoft() {
      if (this.archive === true) {
        return this.$store.getters['techroom/specialist/getSoftware'].filter(item => item.delete);
      }
      if (this.archive === false) {
        return this.$store.getters['techroom/specialist/getSoftware'].filter(item => !item.delete);
      }
      return this.$store.getters['techroom/specialist/getSoftware']
    }
  },
  watch: {
    async getAddress() {
      await this.$store.dispatch(
        "techroom/specialist/requestAddressSoftware",
        this.$store.getters["techroom/specialist/getAddress"].id
      );
    },
  },
  methods: {
    openDialog(item) {
      this.software = item;
      this.dialogSoftware = true;
    },
    openDialogEdit(item) {
      this.software = item;
      this.dialogEditSoftware = true;
    },
    async removeSoftware(id, status) {
      await this.$store.dispatch("techroom/specialist/removeSoftware", { id, status });

      await this.$store.dispatch(
        "techroom/specialist/requestAddressSoftware",
        this.$store.getters["techroom/specialist/getAddress"].id
      );
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "techroom/specialist/requestAddressSoftware",
      this.$store.getters["techroom/specialist/getAddress"].id
    );
  },
};
</script>
<style>

</style>