<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление сотрудника</div>
        <v-form ref="form" lazy-validation class="form_cabinet" @submit.prevent="createUser()" v-model="valid">
          <v-text-field class="text_field_input" v-model="formData.surname" label="Фамилия" :rules="[
            () => !!formData.surname || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.name" label="Имя" :rules="[
            () => !!formData.name || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.lastName" label="Отчество" :rules="[
            () => !!formData.lastName || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.dateBirth" label="Дата рождения" :rules="[
            () => !!formData.dateBirth || 'Обязательное поле',
          ]" required v-maska="'##.##.####'">
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.email" label="Эл. почта" :rules="[() => (!formData.email || /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
              formData.email
            ) || 'Недействительный адрес электронной почты')]"  required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.phone" label="Телефон" :rules="[
            () => !!formData.phone || 'Обязательное поле',
          ]" required v-maska="'+7 (###) ###-##-##'">
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.login" label="Логин" :rules="[
            () => !!formData.login || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.password" label="Пароль" :rules="rules.password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
                        @click:append="show = !show" required>
          </v-text-field>
          <v-select
              v-model="formData.roles"
              :items="$store.getters['techroom/director/getTechRoles']"
              item-title="name"
              item-value="id"
              filled
              chips
              label="Выберите роли"
              multiple
              :rules="[
            () => !!formData.roles[0] || 'Минимум 1 роль']"
              required
          ></v-select>
          <v-btn type="submit" class="cabinet_btn">Добавить сотрудника</v-btn>
          <div v-if="error">
            <h4>{{ error }}</h4>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogAddUser",
  data() {
    return {
      error: false,
      valid: false,
      show: false,
      formData: {
        surname: null,
        name: null,
        lastName: null,
        dateBirth: null,
        email: null,
        phone: null,
        login: null,
        password: null,
        roles: []
      },
      rules: {
        email: [
          (value) =>
              !!value || "Электронная почта обязательна",
          (this.valid = true),
        ],
        password: [
          (value) => !!value || "Пароль обязателен",
          (this.valid = true),
        ],
      },
    }
  },
  methods: {
    async createUser() {
      if (!this.valid) {
        return
      }
      const res = await this.$store.dispatch('techroom/director/createUser', this.formData)
      if (res?.status === 400) {
        return this.error = res.data.error
      }
      this.error = false
      await this.$store.dispatch('techroom/director/requestUsers')
      this.formData.surname = null
      this.formData.name = null
      this.formData.lastName = null
      this.formData.dateBirth = null
      this.formData.email = null
      this.formData.phone = null
      this.formData.login = null
      this.formData.password = null
      this.formData.roles = []
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>