<template>
  <div class="account__page">
    <div class="header__block">
      <div class="item_header">
        <div class="page_menu_title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            width="32"
            viewBox="0 0 40 40"
          >
            <path
              d="M5.833 33.583V11.958h6.75V5.25h14.875v13.458h6.709v14.875h-11.5v-6.75h-5.334v6.75Zm1.417-1.416h5.333v-5.334H7.25Zm0-6.709h5.333v-5.333H7.25Zm0-6.75h5.333v-5.333H7.25Zm6.75 6.75h5.292v-5.333H14Zm0-6.75h5.292v-5.333H14Zm0-6.75h5.292V6.667H14Zm6.708 13.5h5.334v-5.333h-5.334Zm0-6.75h5.334v-5.333h-5.334Zm0-6.75h5.334V6.667h-5.334Zm6.75 20.209h5.292v-5.334h-5.292Zm0-6.709h5.292v-5.333h-5.292Z"
            ></path>
          </svg>
          {{ $store.getters["techroom/specialist/getOrganization"].fullTitle }}
        </div>
      </div>
    </div>
    <div class="organization-blocks">
      <!-- <BlockAppeals></BlockAppeals> -->
      <!-- <StatisticsOrganization></StatisticsOrganization> -->
    </div>
    <div class="account__user">
      <div class="user__info">
        <span>Наименование:</span>
        <div>
          {{ $store.getters["techroom/specialist/getOrganization"].fullTitle }}
        </div>
      </div>
      <div class="user__info">
        <span>Email:</span>
        <div>{{ $store.getters["techroom/specialist/getOrganization"].email }}</div>
      </div>
      <div class="user__info">
        <span>Номер телефона:</span>
        <div>{{ $filters.filterNumber($store.getters["techroom/specialist/getOrganization"].phone) }}</div>
      </div>
      <div class="user__info">
        <span>Адрес:</span>
        <div>{{ $store.getters["techroom/specialist/getOrganization"].address }}</div>
      </div>
      <div class="user__info">
        <span>Глава организации:</span>
        <div>{{ $store.getters["techroom/specialist/getOrganization"].head }}</div>
      </div>
    </div>
  </div>
  <div>
    <v-card class="card_filials"
      ><v-list class="addresses" nav mandatory>
        <div class="addresses__title">Здания организации</div>
        <v-list-item
          v-for="item in $store.getters['techroom/specialist/getOrganization'].addresses"
          :key="item.id"
          :title="item.title"
          :value="item"
          :active="isActive(item)"
          active-color="red"
          :prepend-icon="'mdi-office-building-marker-outline'"
          @click="getAddress(item)"
        >
        </v-list-item> </v-list
    ></v-card>
  </div>
</template>

<script>
// import BlockAppeals from '@/components/app/techroom/specialist/organization/appeals/BlockAppeals/BlockAppeals.vue';
// import StatisticsOrganization from '@/components/app/techroom/specialist/organization/statistics/StatisticsOrganization.vue';
export default {
  // components: { BlockAppeals, StatisticsOrganization },
  async mounted() {
    await this.$store.dispatch(
      "techroom/specialist/requestOrganization",
      this.$route.params.id
    );
  },
  methods: {
    async getAddress(item) {
      if (this.$route.name === "OrganizationGeneral") {
        this.$router.push(
          `/techroom/organization/${this.$route.params.id}/cabinets/`
        );
      }
      // this.$router.push({ path: `/techroom/organization/${this.$route.params.id}/cabinets/`, query: { address: `${item.address}` } });
      await this.$store.commit("techroom/specialist/updateAddress", item);
    },

    isActive(item) {
      return (
        item.address ===
          this.$store.getters["techroom/specialist/getAddress"].address &&
        this.$route.name != "OrganizationGeneral"
      );
    },
  },
};
</script>

<style lang="scss">
.card_filials {
  margin: 20px;
  width: 300px;
  @media screen and (max-width: $x-small) {
    width: calc(100% - 40px);
  }
}
.organization-blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(24);

  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
  }
}
</style>
