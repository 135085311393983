<template>
  <section class="project__section">
    <h3 class="project__list-subheader">Управление проектом</h3>
    <v-form ref="form" class="w-50">
      <v-select
        v-model="developersId"
        :items="developers"
        label="Разработчики"
        chips
        multiple
      >
      </v-select>

      <v-select v-model="status" :items="items" label="Статус проекта">
      </v-select>
      <v-btn color="success" class="mr-4" @click="updateProject">
        Сохранить
      </v-btn>
    </v-form>
  </section>
  <v-divider></v-divider>
  <section class="project__section">
    <h3 class="project__list-subheader">Управление разработчиками</h3>
    <v-select v-model="membersId" :items="members" label="Участники">
    </v-select>
    <div v-if="membersId">
      <p>Добавление задачи</p>
      <v-form ref="form" class="w-50" @submit.prevent="addTask">
        <v-text-field
          v-model="formAddTask.title"
          label="Название задачи"
        ></v-text-field>
        <v-text-field
          v-model="formAddTask.descriptionTitle"
          label="Описание задачи"
        ></v-text-field>
        <v-text-field
          v-model="formAddTask.timeline"
          label="Срок"
        ></v-text-field>
        <v-btn type="submit">Добавить</v-btn>
      </v-form>

      <p v-if="memberTasks.length">Задачи</p>
      <div v-for="item in memberTasks" :key="item">
        {{ item.title }}
        <v-icon @click="deleteTask(item.id)">mdi-delete</v-icon>
      </div>
      <div>
        <v-btn @click="deleteMemberProject">Удалить разработчика</v-btn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
    developers: {
      type: Array,
    },
  },
  data() {
    return {
      status: null,
      items: ["Начальная стадия", "Разработка макета", "Завершён"],
      developersId: [],
      membersId: null,
      formAddTask: {
        title: null,
        descriptionTitle: null,
        timeline: null,
        projectId: null,
        userId: null,
      },
    };
  },
  computed: {
    members() {
      const allMembers = this.project.membersProject;
      const members = [];
      allMembers.forEach((e) => {
        members.push({
          title: `${e.surname} ${e.name} ${e.lastName}`,
          value: e.id,
        });
      });
      return members;
    },
    memberTasks() {
      const memberTasks = this.project.membersProject.filter(
        (item) => item.id === this.membersId
      );
      const data = memberTasks.map((item) => item.tasks).flat(Infinity);
      return data;
    },
  },
  methods: {
    async addTask() {
      this.formAddTask.projectId = this.project.id;
      this.formAddTask.userId = this.membersId;
      console.log(this.formAddTask);
      await this.$store.dispatch(
        "itroom/teamlead/addMemberTask",
        this.formAddTask
      );
    },

    async deleteTask(id) {
      await this.$store.dispatch("itroom/teamlead/deleteMemberTask", id);
    },

    async deleteMemberProject() {
      await this.$store.dispatch("itroom/teamlead/deleteMemberProject", {
        idProject: this.project.id,
        idUser: this.membersId,
      });
    },

    async updateProject() {
      await this.$store.dispatch("itroom/teamlead/updateProject", {
        id: this.project.id,
        status: this.status,
        userId: this.developersId,
      });
    },
  },
};
</script>

<style>
</style>