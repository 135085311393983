<template>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
        <path d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z" />
    </svg>
</template>
<script>
export default {
    name: "IconAdd"
}
</script>
<style>

</style>