<template>
  <div id="authWindow" class="authorization-wrap">
    <IconItroom width="300" height="130" viewBox="0 0 113 71"></IconItroom>
    <v-form ref="authForm" v-model="valid" lazy-validation method="post">
      <input-wrap>
        <v-text-field class="auth_text_input" v-model="formData.login" density="comfortable"
          label="Электронная почта или логин" variant="solo" placeholder="Начните ввод данных" :rules="rules.email">
        </v-text-field>
      </input-wrap>
      <input-wrap>
        <v-text-field class="auth_text_input" v-model="formData.password" density="comfortable" label="Ваш пароль"
          variant="solo" placeholder="Начните ввод данных" :rules="rules.password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
          @click:append="show = !show">
        </v-text-field>
      </input-wrap>
    </v-form>
    <div class="form-manage">
      <paper-button :disabled="!valid" @click="valid ? authorize() : validate()">
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <IconAccount v-else></IconAccount>
        <span v-if="loading">Загрузка</span>
        <span v-else>Войти в аккаунт</span>
      </paper-button>
      <div class="error" v-if="error.length > 0">
        <IconError fill="#f4553d" width="16px" height="16px" viewBox="0 0 24 24"></IconError>
        <span>{{ error }}</span>
      </div>
      <div class="success" v-if="success.length > 0">
        <IconSuccess fill="#00c06c" width="16px" height="16px" viewBox="0 0 24 24"></IconSuccess>
        <span>{{ success }}</span>
      </div>
    </div>
    <!-- <div class="lost-password">
          Забыли данные?
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/6130773?hl=ru"
            >Восстановить</a
          >
        </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      error: "",
      success: "",
      loading: false,
      show: false,
      formData: {
        login: "",
        password: "",
      },
      rules: {
        email: [
          (value) =>
            !!value || "Электронная почта обязательна" || console.log("ok"),
          (this.valid = true),
        ],
        password: [
          (value) => !!value || "Пароль обязателен",
          (this.valid = true),
        ],
      },
    };
  },
  methods: {
    validate() {
      this.$refs.authForm.validate();
    },
    async authorize() {
      this.loading = true;
      this.$emit("loadingOn");
      if (document.querySelector(`#authWindow`).hasAttribute("error-active")) {
        document.querySelector(`#authWindow`).removeAttribute("error-active");
        // document.querySelector(`.lost-password`).removeAttribute("animate");
        this.error = "";
      }
      if (
        document.querySelector(`#authWindow`).hasAttribute("success-active")
      ) {
        document.querySelector(`#authWindow`).removeAttribute("success-active");
        this.success = "";
      }
      setTimeout(async () => {
        try {
          await this.$store.dispatch("authorize", this.formData);
          document
            .querySelector(`#authWindow`)
            .setAttribute("success-active", "");
        } catch (error) {
          if (error.code === "ERR_NETWORK") {
            this.error = "Что-то пошло не так";
            document
              .querySelector(`#authWindow`)
              .setAttribute("error-active", "");
          }
          if (
            error.response.statusText === "Not Found" ||
            error.response.statusText === "Forbidden"
          ) {
            this.error = "Данные не соответствуют аккаунту";
            document
              .querySelector(`#authWindow`)
              .setAttribute("error-active", "");
          }
        }
        this.$emit("loadingOff");
        this.loading = false;
        setTimeout(() => {
          this.$emit("loadingLineOff");
        }, 550);
      }, 700);
    },
  },
};
</script>

<style lang="scss">
h1 {
  font-size: rem(13) !important;
  color: var(--blue-11);
}

.auth_text_input {
  margin-bottom: 5px;
}

.authorization-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(32);

  &[error-active] {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: rem(2);
      background: var(--input-error-border-color);
    }
  }

  .v-messages__message {
    margin-bottom: -7px;
  }

  .form-manage {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(14);

    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(14);
      gap: rem(8);
      color: var(--input-error-color);
    }

    .success {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(14);
      gap: rem(8);
      color: var(--input-success-color);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(24);
    width: 100%;
    font-family: var(--palette-fonts);

    input-wrap {
      width: 100%;

      .v-input {
        position: relative;

        &--error {
          .v-field {
            outline: 1px solid var(--input-error-border-color) !important;
          }

          .v-messages {
            color: var(--input-error-color);

            &__message {
              display: flex;
              align-items: center;
              gap: rem(4);

              &::before {
                content: url("data:image/svg+xml, %3Csvg fill='%23f4553d' width='16px' height='16px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'%3E%3C/path%3E%3C/svg%3E");
              }
            }
          }
        }

        .v-field {
          @include --body-input-set;

          &__input {
            padding-inline-end: rem(52);
          }
        }

        &__details {
          max-width: rem(300);
          align-items: center;
          margin: 0;
          padding: rem(6) 0 rem(6) 0;
        }

        &__append {
          opacity: 0.5;
          position: absolute;
          right: rem(16);
        }
      }
    }
  }

  .lost-password {
    font-size: rem(15);
    color: var(--text-hint-color);

    &[animate] {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }

  span {
    white-space: normal;
  }

  paper-button {
    width: 100%;
    @include --body-button-set;
  }
}
</style>