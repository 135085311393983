<template>
  <div id="loadingPage">
    <v-progress-circular indeterminate></v-progress-circular>
    <div>Идёт загрузка</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#loadingPage {
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: rem(8);
  align-items: center;
  justify-content: center;
  color: #ff5757;
}
</style>