<template>
  <Loading v-if="loadingPage"></Loading>
  <section v-else class="page__account account">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="account__title itroom__title">Персональные задачи</h1>
    </div>
    <div class="tasks__columns">
      <div>
        <v-card title="Менеджеры" class="tasks__column">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel
              v-for="item in $store.getters[
                'itroom/director/getManagersAndTeamleads'
              ].managers"
              :key="item.id"
            >
              <v-expansion-panel-title>
                {{ `${item.name} ${item.surname} ${item.lastName}` }}
                <template v-slot:actions>
                  <v-btn
                    color="var(--blue-0)"
                    variant="text"
                    size="small"
                    @click.stop="openDialog(item)"
                  >
                    Дать задачу
                  </v-btn>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="px-4">
                <ul>
                  <li v-for="task in item.itroom_tasks" :key="task">
                    {{ `${task.title} ${task.timeline}` }}
                    <v-icon @click="deleteTask(task.id)"> mdi-delete </v-icon>
                    <ul class="px-4">
                      <li>
                        {{ task.descriptionTitle }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </div>
      <div>
        <v-card title="Тимлиды" class="tasks__column">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel
              v-for="item in $store.getters[
                'itroom/director/getManagersAndTeamleads'
              ].teamleads"
              :key="item.id"
            >
              <v-expansion-panel-title>
                {{ `${item.name} ${item.surname} ${item.lastName}` }}
                <template v-slot:actions>
                  <v-btn
                    color="var(--blue-0)"
                    variant="text"
                    size="small"
                    @click.stop="openDialog(item)"
                  >
                    Дать задачу
                  </v-btn>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="px-4">
                <ul>
                  <li v-for="task in item.itroom_tasks" :key="task">
                    {{ `${task.title} ${task.timeline}` }}
                    <v-icon @click="deleteTask(task.id)"> mdi-delete </v-icon>
                    <ul class="px-4">
                      <li>
                        {{ task.descriptionTitle }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </div>
    </div>
  </section>
  <DialogAddTask v-model="dialog" :user="user" @close="dialog = false" />
</template>

<script>
import DialogAddTask from "@/components/app/itroom/director/tasks/DialogAddTask.vue";
export default {
  components: { DialogAddTask },
  data() {
    return {
      user: null,
      projects: null,

      dialog: false,
      loadingPage: true,
    };
  },
  methods: {
    async openDialog(item) {
      this.user = item;
      if (item.roles[0].name === "TEAMLEAD") {
        await this.$store.dispatch("itroom/director/requestProjects");
      }
      this.dialog = true;
    },
    async deleteTask(id) {
      await this.$store.dispatch("itroom/director/deleteTask", id);
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/director/requestManagersAndTeamleads");

    setTimeout(() => {
      this.loadingPage = false;
    }, 500);
  },
};
</script>

<style scoped>
.tasks__columns {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  gap: 25px;
}

.tasks__column {
  max-height: 800px;
  overflow: auto;
}
</style>