<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Переместить со склада</div>
        <v-form ref="form" @submit.prevent="submitHandler" class="form_cabinet">
          <v-select
              label="Выберите организацию"
              :items="$store.getters['techroom/specialist/getOrganizations']"
              item-title="fullTitle"
              item-value="id"
              v-model="orgId"
              no-data-text="Нет доступных организаций"
          />
          <v-select
              v-if="orgId"
              label="Выберите адрес"
              :items="$store.getters['techroom/specialist/getOrganization'].addresses"
              item-title="title"
              item-value="id"
              v-model="addressId"
              no-data-text="В организации нет адресов"
          />
          <v-select
              v-if="addressId"
              label="Выберите Кабинет"
              :items="$store.getters['techroom/specialist/getCabinets']"
              item-title="num"
              item-value="id"
              v-model="cabinetId"
              no-data-text="На адресе нет кабинетов"
          />
          <v-btn v-if="cabinetId" type="submit" class="cabinet_btn">Переместить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    type: {
      type: String,
    },
    ids: Array,
  },
  name: "DialogChangeCabinet",
  data() {
    return {
      orgId: null,
      addressId: null,
      cabinetId: null,
    }
  },
  watch: {
    async addressId() {
      await this.$store.dispatch('techroom/specialist/requestAddressCabinets', this.addressId)
    },

    async orgId() {
      await this.$store.dispatch('techroom/specialist/requestOrganization', this.orgId)
    }
  },
  methods: {
    async submitHandler() {
      if (this.type === 'computer') {
        if(this.ids[0]) {
          await this.$store.dispatch('techroom/specialist/outOfStockComputers', {ids: this.ids, cabinetId: this.cabinetId, stock: false})
          this.$emit('clearchecks')
        } else {
          await this.$store.dispatch('techroom/specialist/editComputerCabinet', {id: this.item.id, cabinetId: this.cabinetId, stock: false })
        }
        await this.$store.dispatch('techroom/specialist/requestStockComputers')
      } else {
        if(this.ids[0]) {
          await this.$store.dispatch('techroom/specialist/outOfStockEquipment', {ids: this.ids, cabinetId: this.cabinetId, stock: false})
          this.$emit('clearchecks')
        } else {
          await this.$store.dispatch('techroom/specialist/editEquipmentCabinet', {id: this.item.id, cabinetId: this.cabinetId, stock: false})
        }
        await this.$store.dispatch('techroom/specialist/requestStockEquipments')
      }
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>