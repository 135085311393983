<template>
  <div class="item_header">
    <!-- <div>
      <v-responsive class="mx-auto" max-width="344">
        <v-text-field
          label="Поиск по сотруднкам"
          hide-details="auto"
        ></v-text-field>
      </v-responsive>
    </div> -->
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title">
        <h2 class="font-weight-bold">Обслуживающие организации</h2>
      </div>
      <div class="page_menu_title">
        <div class="link" :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }" @click="this.archive = null">
          Все</div>
        <div class="link" :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
          @click="this.archive = false">Активные</div>
        <div class="link" :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }" @click="this.archive = true">В
          архиве</div>
      </div>
    </div>
    <div class="header_right">
      <div class="d-flex justify-space-around align-center flex-column flex-md-row">
        <v-btn class="button-add" color="#ff5757" @click="dialogAddService = true" icon="mdi-plus"></v-btn>
      </div>
    </div>
  </div>
  <v-table>
    <thead>
      <tr>
        <th class="text-left start">Организации</th>
        <th class="text-left">Услуга</th>
        <th class="text-left">Цена</th>
        <th class="text-left">Номер договора</th>
        <th class="text-center">
          <v-icon>mdi-cogs</v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in getServices" :key="item.id"
        :style="{ color: item.delete ? '#7c7c7c' : '#000', transition: '.15s ease' }"
        @click="openDialog(item)">
        <td class="text-left start">{{ item.organization }}</td>
        <td class="text-left">{{ item.service }}</td>
        <td class="text-left">{{ $filters.filterCurrency(item.price) }}</td>
        <td class="text-left">{{ item.contractNumber }}</td>
        <td class="text-center">
          <v-menu class="actions-list">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small">
                <v-icon size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item min-height="1" :value="item.id" @click="openDialog(item)">
                <v-list-item-title> Подробнее </v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="openDialogEdit(item)">
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="removeService(item.id, true)" v-if="!item.delete">
                <v-list-item-title> Удалить </v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="removeService(item.id, false)" v-else>
                <v-list-item-title> Восстановить </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogAddService v-model="dialogAddService" @close="dialogAddService = false" />
  <DialogService v-model="dialogService" @close="dialogService = false" :service="service" />
  <DialogEditService v-model="dialogEditService" @close="dialogEditService = false" :service="service" />
</template>

<script>
import DialogAddService from '@/components/app/techroom/specialist/organization/services/DialogAddService.vue';
import DialogService from '@/components/app/techroom/specialist/organization/services/DialogService.vue';
import DialogEditService from '@/components/app/techroom/specialist/organization/services/DialogEditService.vue';
export default {
  components: { DialogAddService, DialogService, DialogEditService },
  data() {
    return {
      service: null,
      archive: null,
      dialogAddService: false,
      dialogService: false,
      dialogEditService: false,
    };
  },
  computed: {
    getAddress() {
      return this.$store.getters['techroom/specialist/getAddress'];
    },
    getServices() {
      if (this.archive === true) {
        return this.$store.getters['techroom/specialist/getServices'].filter(item => item.delete);
      }
      if (this.archive === false) {
        return this.$store.getters['techroom/specialist/getServices'].filter(item => !item.delete);
      }
      return this.$store.getters['techroom/specialist/getServices'];
    }
  },
  watch: {
    async getAddress() {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressServices',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    },
  },
  methods: {
    openDialog(item) {
      this.service = item;
      this.dialogService = true;
    },
    openDialogEdit(item) {
      this.service = item;
      this.dialogEditService = true;
    },
    async removeService(id, status) {
      await this.$store.dispatch('techroom/specialist/removeService', { id, status });

      await this.$store.dispatch(
        'techroom/specialist/requestAddressServices',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    },
  },
  async mounted() {
    await this.$store.dispatch(
      'techroom/specialist/requestAddressServices',
      this.$store.getters['techroom/specialist/getAddress'].id
    );
  },
};
</script>
<style>

</style>
