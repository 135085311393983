<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление в клиенты</div>
        <v-form ref="form" class="form_cabinet client_add style-scoped" @submit.prevent="createClient" v-model="valid">
          <div class="form_cabinet_row">
            <div class="row_title">Основная информация</div>
            <v-text-field class="text_field_input" v-model="nameorganizationfull"
              label="Полное наименование организации" :rules="[() => !!nameorganizationfull || 'Обязательно']" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="nameorganizationsmall"
              label="Краткое наименование организации">
            </v-text-field>
            <v-text-field class="text_field_input" v-model="head" label="Директор"
              :rules="[() => !!head || 'Обязательно']" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="adressOrg" label="Юридический адрес"
              :rules="[() => !!adressOrg || 'Обязательно']" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="phone" maxlength="18" v-maska="'+7 (###) ###-##-##'"
              label="Номер телефона организации" :rules="[
                () => !!phone || 'Обязательное поле',
                () => phone.length === 18 || 'Не верный формат телефона',
              ]" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="email" :rules="[
              () =>
                !email ||
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  email
                ) ||
                'Неверный формат электронной почты',
            ]" label="Почта">
            </v-text-field>
            <v-text-field class="text_field_input" v-model="docNumberFirstOrg" max-length="10" label="ИНН"
              v-maska="'#*'" :rules="[
                () => !!docNumberFirstOrg || 'Обязательное поле',
                () =>
                  docNumberFirstOrg.length === 10 || 'ИНН состоит из 10 цифр',
              ]" required maxlength="10">
            </v-text-field>
            <v-text-field class="text_field_input" v-model="docNumberSecondOrg" max-length="9" v-maska="'#*'"
              label="КПП" :rules="[
                () => !!docNumberSecondOrg || 'Обязательное поле',
                () =>
                  docNumberSecondOrg.length === 9 || 'КПП состоит из 9 цифр',
              ]" required maxlength="9">
            </v-text-field>
            <v-text-field class="text_field_input" v-model="docNumberThirdOrg" max-length="13" v-maska="'#*'"
              label="ОГРН" :rules="[
                () => !!docNumberThirdOrg || 'Обязательное поле',
                () =>
                  docNumberThirdOrg.length === 13 || 'ОГРН состоит из 13 цифр',
              ]" required maxlength="13">
            </v-text-field>
            <v-textarea class="text_field_input_area" v-model="description" label="Комментарий">
            </v-textarea>
          </div>
          <div class="form_cabinet_row">
            <div class="row_title">Банковские реквизиты</div>
            <v-text-field class="text_field_input" v-model="bankNameOrg" label="Наименование банка" maxlength="24"
              :rules="[() => !!bankNameOrg || 'Обязательное поле']" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="docNumberFourthOrg" label="Расчётный счёт" v-maska="'#*'"
              maxlength="20" :rules="[
                () => !!docNumberFourthOrg || 'Обязательное поле',
                () =>
                  docNumberFourthOrg.length === 20 ||
                  'Расчётный счёт состоит из 20 цифр',
              ]" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="correspondentAccount" label="Корреспондентский счёт"
              maxlength="20" v-maska="'#*'" :rules="[
                () => !!correspondentAccount || 'Обязательное поле',
                () =>
                  correspondentAccount.length === 20 ||
                  'Корреспондентский счёт состоит из 20 цифр',
              ]" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="BIC" label="БИК" v-maska="'#*'" maxlength="9" :rules="[
              () => !!BIC || 'Обязательное поле',
              () => BIC.length === 9 || 'БИК состоит из 9 цифр',
            ]" required>
            </v-text-field>
          </div>
          <div class="form_cabinet_row">
            <div class="row_title">Адреса обслуживания</div>
            <div class="form_cabinet_block_row" v-for="(item, index) in adresses" :key="index">
              <div class="addresses_wrap">
                <span class="address_count">Адрес №{{ index + 1 }}</span>
                <svg v-if="index !== 0" fill="#f94c39" height="20" width="20" @click="removeNewAddress(index)">
                  <path
                    d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z" />
                </svg>
              </div>
              <v-text-field class="text_field_input" v-model="adresses[index].title" label="Адрес обслуживания"
                :rules="[() => !!adresses[index].title || 'Обязательное поле']" required>
              </v-text-field>
              <v-select v-model="adresses[index].floors" :items="floors" chips label="Этажи" :rules="[
                () =>
                  !!adresses[index].floors.length > 0 || 'Обязательное поле',
              ]" multiple></v-select>
              <v-text-field class="text_field_input" v-model="adresses[index].head" label="Контактное лицо на адресе"
                :rules="[() => !!adresses[index].head || 'Обязательное поле']" required>
              </v-text-field>
              <v-text-field class="text_field_input" v-model="adresses[index].phone" label="Номер контактного лица"
                maxlength="18" v-maska="'+7 (###) ###-##-##'" :rules="[
                  () => !!adresses[index].phone || 'Обязательное поле',
                  () =>
                    adresses[index].phone.length === 18 ||
                    'Не верный формат телефона',
                ]" required>
              </v-text-field>
            </div>
            <v-btn class="add__adress" @click="addAdress">
              Нажмите для добавления
            </v-btn>
          </div>
          <v-btn type="submit" variant="outlined" class="cabinet_btn" :disabled="!valid" @click="validate()">
            Добавить лида в клиента
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    lead: {
      type: Object,
    },
  },

  data: () => ({
    valid: false,
    floors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    typesLeads: ['Физическое лицо', 'Юридическое лицо'],
    typeLead: null,
    head: '',
    phone: '',
    description: '',
    nameorganizationfull: '',
    nameorganizationsmall: '',
    adressOrg: '',
    email: '',
    docNumberFirstOrg: '',
    docNumberSecondOrg: '',
    docNumberThirdOrg: '',
    bankNameOrg: '',
    docNumberFourthOrg: '',
    docNumberFifthOrg: '',
    docNumberSixthOrg: '',
    correspondentAccount: '',
    BIC: '',
    directorOrg: '',
    descriptionOrg: '',
    adresses: [
      {
        title: '',
        head: '',
        phone: '',
        floors: [],
      },
    ],
  }),

  computed: {
    leadInfo() {
      return this.lead;
    },
  },

  methods: {
    validate() {
      console.log('1');
    },
    addAdress() {
      this.adresses.push({
        title: '',
        head: '',
        phone: '',
        floors: [],
      });
    },

    removeNewAddress(index) {
      this.adresses.splice(index, 1);
    },

    async createClient() {
      try {
        const addresses = [];

        this.adresses.forEach((address) => {
          let techroom_floors = [];
          address.floors.forEach((floor) => {
            techroom_floors.push({
              floor,
            });
          });
          addresses.push({
            title: address.title,
            head: address.head,
            phone: address.phone,
            techroom_floors,
          });
        });

        const formData = {
          leadId: this.lead?.id,
          fullTitle: this.nameorganizationfull,
          smallTitle: this.nameorganizationsmall,
          address: this.adressOrg,
          phone: this.phone,
          head: this.head,
          email: this.email,
          description: this.description,
          ITN: this.docNumberFirstOrg,
          KPP: this.docNumberSecondOrg,
          PSRN: this.docNumberThirdOrg,
          bankTitle: this.bankNameOrg,
          bankAccount: this.docNumberFourthOrg,
          correspondentAccount: this.correspondentAccount,
          BIC: this.BIC,
          addresses,
        };
        await this.$store.dispatch('techroom/manager/createClient', formData);
        await this.$store.dispatch('techroom/manager/requestLeads');
        await this.$store.dispatch("techroom/manager/requestClients");
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.client_add.style-scoped {
  gap: rem(36);
  max-width: rem(1200);

  button[type='submit'] {
    width: 100%;
    background-color: unset;
    color: #ff5757;
    border-radius: 0;
  }

  .form_cabinet_block_row {
    display: flex;
    flex-direction: column;
    gap: rem(22);
  }

  .form_cabinet_row {
    border-bottom: 1px solid var(--white-11);
    padding: 0 0 rem(45) 0;

    &:last-child {
      border-bottom: none;
    }

    .row_title {
      width: 100%;
      grid-area: 1/1/1/3;
      text-align: center;
    }

    .addresses_wrap {
      display: flex;
      gap: rem(4);

      @media screen and (max-width: $medium) {
        justify-content: center;
      }

      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &:nth-child(1) {
      width: 100%;
      display: grid;
      grid-template: repeat(4, auto) / repeat(2, auto);
      gap: rem(22);

      @media screen and (max-width: $medium) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .v-input {
          width: 100%;
        }
      }

      .v-textarea {
        width: 100%;
        grid-area: 7 / 1 / 7 / 3;
      }
    }

    &:nth-child(2) {
      width: 100%;
      display: grid;
      grid-template: repeat(2, auto) / repeat(2, auto);
      gap: rem(22);

      @media screen and (max-width: $medium) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .v-input {
          width: 100%;
        }
      }
    }

    &:nth-child(3) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: rem(22);

      .v-input {
        width: rem(380);
      }

      @media screen and (max-width: $medium) {
        width: 100%;

        .form_cabinet_block_row {
          width: 100%;
        }

        .v-input {
          width: 100%;
        }
      }

      .address_count {
        font-size: rem(12);
      }

      .v-btn {
        width: rem(380);
        height: rem(314);
        box-shadow: none;
        background-color: hsl(0, 0%, 96%);
        color: #000;

        .v-btn__content {
          text-transform: none;
          font-size: rem(14);
          letter-spacing: rem(0.5);
          font-family: monospace;
        }

        @media screen and (max-width: $medium) {
          width: 100%;
          height: rem(175);
        }
      }
    }
  }
}
</style>
