<template>
  <div class="notFound__container">
    <section class="notFound__section">404</section>
    <section class="notFound__text">Страница не найдена</section>
    <paper-button @click="this.$router.push('/techroom')">
      Вернуться на главную
    </paper-button>
  </div>
</template>

<style lang="scss">
.notFound {
  &__container {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(32);
  }
  &__section {
    user-select: none;
    font-weight: bolder;
    font-size: rem(180);
    color: var(--white-15);
  }
  &__text {
    font-size: rem(20);
    color: var(--white-18);
  }
}
</style>