import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    cabinets: [],
  },
  getters: {
    getCabinets(state) {
      return state.cabinets;
    },
  },
  mutations: {
    updateCabinets(state, res) {
      state.cabinets = res;
    },
  },
  actions: {
    async requestAddressCabinets({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/cabinets/address/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateCabinets', result.data.cabinets);
      } catch (error) {
        console.error(error);
      }
    },

    async requestFloorCabinets({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/cabinets/floor/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateCabinets', result.data.cabinets);
      } catch (error) {
        console.error(error);
      }
    },

    async createCabinet({ commit }, formData) {
      try {
        console.log(formData);
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/cabinet`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            floorId: formData.floorId,
            computers: formData.computers,
            equipments: formData.equipments,
            num: formData.numCabinet,
            workerId: formData.workerId,
            misc: formData.misc,
          },
        });
        console.log(result);
        commit('pushCabinet', result.data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editCabinet(ctx, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/cabinet/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            floorId: formData.floor.id || formData.floor,
            num: formData.num,
            workerId: formData.workerId,
            misc: formData.misc,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async removeCabinet({ commit }, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/cabinet/${data.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            deleteStatus: data.status,
          },
        });
        console.log(result);
        commit('filterCabinet', data.id);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
