<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование обращения</div>
        <v-form ref="form" lazy-validation class="form_cabinet" @submit.prevent="editAppeal">
          <v-select v-model="formData.address.id" :items="$store.getters['techroom/manager/getClientsAddresses']"
            label="Адрес" item-title="title" item-value="id" :rules="[
              () => !!formData.address.id || 'Обязательное поле',
            ]" required></v-select>
          <v-text-field class="text_field_input" v-model="formData.task" :rules="[
            () => !!formData.task || 'Обязательное поле',
          ]" required label="Задача">
          </v-text-field>
          <v-select v-model="formData.status.id" :items="items" item-title="title" item-value="id" label="Статус заявки"
            :rules="[
              () => !!formData.status.id || 'Обязательное поле',
            ]" required></v-select>
          <v-btn type="submit" @click="validate($event)" class="cabinet_btn">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: "В обработке",
        },
        {
          id: 2,
          title: "Утверждение",
        },
        {
          id: 3,
          title: "В процессе",
        },
        {
          id: 4,
          title: "Готово",
        },
        {
          id: 5,
          title: "Что-то нужно для решения",
        },
        {
          id: 6,
          title: "Не получается сделать",
        },
      ],
    };
  },
  props: {
    appeal: {
      type: Object,
    },
  },
  computed: {
    formData() {
      return this.appeal;
    },
  },
  methods: {
    async editAppeal() {
      try {
        await this.$store.dispatch("techroom/manager/editAppeal", this.formData);
        await this.$store.dispatch("techroom/manager/requestAppeals");
        this.$emit("close");
      } catch (error) {
        console.error(error);
      }
    },
    async validate(e) {
      let a = await this.$refs.form.validate();
      if (!a.valid) {
        e.preventDefault();
      }
    },
  },
};
</script>
