<template>
  <div>
    <div class="header_block">Архив</div>
      <div class="item_header">
        <div class="page_menu_title">
          <router-link to="/techroom/plans" class="v-navigation-icon">
            Задачи
          </router-link>
          <router-link to="/techroom/plans/calendar" class="v-navigation-icon">
            Календарь
          </router-link>
          <router-link to="/techroom/plans/archive" class="v-navigation-icon">
            Архив
          </router-link>
        </div>
      </div>
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="page" class="my-4" :length="countPages?.pages" @click="getPageTask">
              </v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <div>
        <div class="plans__week">
          <v-card class="d-flex flex-column week_item" v-for="task in store.getters['techroom/manager/getTaskPages']
  .tasksArchive" :key="task.id">
            <div>{{ task.date }}</div>
            <div>{{ task.name }}</div>
            <div>{{ task.description }}</div>
            <v-btn v-if="store.getters.USER.selectedRole.role != 'TECHNICAL'" prepend-icon="mdi-archive-arrow-down"
              @click="fromArchive(task.id)">
              Восстановить
            </v-btn>
          </v-card>
        </div>
      </div>
      </div>
    </div>
</template>



<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { wsUrl } from '@/store';
const store = useStore();
const myWs = new WebSocket(wsUrl);

let page = ref(1);
let countPages = ref(null);

onMounted(async () => {
  myWs.onopen = function () {
    console.log('подключился');
  };

  myWs.onmessage = async function (message) {
    if (message.data === 'task') {
      await store.dispatch("techroom/manager/requestTaskPages");
      await store.dispatch("techroom/manager/requestTaskPage", page.value);
      countPages.value = await store.getters[
        "techroom/manager/getTaskPages"
      ];
    }
  };
  await store.dispatch("techroom/manager/requestTaskPages");
  await store.dispatch("techroom/manager/requestTaskPage", page.value);
  countPages.value = await store.getters[
    "techroom/manager/getTaskPages"
  ];

  // await store.dispatch('techroom/manager/requestPlanTask');
  // tasks.value = store.getters['techroom/manager/getPlanTask'];
});
onUnmounted(() => {
  // myWs.close();
  // console.log('отключился');
});

async function getPageTask() {
  await store.dispatch("techroom/manager/requestTaskPage", page);
  countPages.value = store.getters["techroom/manager/getTaskPages"];
}

async function fromArchive(task) {
  console.log(task)
  const ws = new WebSocket(wsUrl);
  setTimeout(() => {
    ws.send(JSON.stringify({ event: 'task' }));
  }, 500);
  await store.dispatch('techroom/manager/editPlanTask', {
    id: task,
    deleted: 0,
    statusId: 1,
    date: null,
  });
  await store.dispatch("techroom/manager/requestTaskPages");
  await store.dispatch("techroom/manager/requestTaskPage", page.value);
  countPages.value = await store.getters["techroom/manager/getTaskPages"];
}

</script>


<style lang="scss">
.plans__week {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(24);

  @media screen and (max-width: $medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $small) {
    grid-template-columns: repeat(1, 1fr);
  }

  .week_item {
    gap: rem(8);
    padding: rem(16);

    &_plans {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 180px;
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(3, auto);

      @media screen and (max-width: $medium) {
        grid-template-columns: repeat(2, auto);
      }

      .v-chip {
        position: relative;
        padding-right: 20px;

        .chip-delete {
          position: absolute;
          right: 2px;

          &:hover {
            color: var(--red-0);
            cursor: pointer;
          }
        }
      }
    }

    .v-chip {
      max-width: 100%;
      height: auto;
      min-height: 32px;
      white-space: pre-wrap;
      // word-break: break-all;
      border-radius: 0;
    }

    .v-card-item {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: $small) {
  .plans__week {
    flex-direction: column;
  }

  .week_item {
    width: 100%;
  }
}
</style>