<template>
  <div class="item_header">
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title flex-column align-start">
        <h2 class="font-weight-bold">Структура здания</h2>
        <small>по адресу
          {{ store.getters['techroom/specialist/getAddress'].title }}</small>
      </div>
      <div class="header_floors">
        <SelectFloor />
      </div>
    </div>
    <div class="header_right">
      <div></div>
    </div>
  </div>
  <div class="main_container">
    <div class="container_items">
      <div class="save_btn">
        <v-btn variant="tonal" @click="saveMap">Сохранить</v-btn>
      </div>
      <div @drop="onDropCabinetToMap($event)" @dragover.prevent @dragenter.prevent>
        <canvas id="canvas" width="600" height="600" style="border: 1px solid #ccc"></canvas>
      </div>
      <div class="select_file">
        <v-file-input v-model="map" prepend-icon="" prepend-inner-icon="mdi-paperclip" accept=".png,.jpg,.jpeg"
          label="Загрузить карту" hide-details="true" class="file_input" />
        <div class="file_btn">
          <v-btn variant="tonal" v-if="map.length" @click="SendMap">
            Отправить
          </v-btn>
        </div>
      </div>
    </div>
    <div class="container_right">
      <div class="cabinets_data">
        <h3>Кабинеты</h3>
        <div v-for="cabinet in cabinets.filter((item) => !item.onMap)" :key="cabinet.id" class="block block_cabinet"
          @drop="onDrop($event, cabinet.id)" @dragstart.stop="onDragStartCabinet($event, cabinet)" draggable="true"
          @dragover.prevent @dragenter.prevent>
          <h4>Кабинет №{{ cabinet.num }}</h4>
          <div v-for="computer in computers.filter(
            (item) => item.cabinet.id === cabinet.id && !item.stock
          )" :key="computer.id" class="item" @dragstart.stop="onDragStart($event, computer)" draggable="true">
            Компьютер №{{ computer.num }}
          </div>
        </div>
      </div>

      <div class="stock_data" @drop="onDropToStock($event)" @dragover.prevent @dragenter.prevent>
        <h3>На складе</h3>
        <div class="block">
          <div v-for="computer in computers.filter((item) => item.stock)" :key="computer.id" class="item"
            @dragstart="onDragStart($event, computer)" draggable="true">
            Компьютер №{{ computer.num }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { fabric } from 'fabric';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SelectFloor from '@/components/app/techroom/specialist/organization/SelectFloor.vue';

let canvas;

onMounted(async () => {
  canvas = new fabric.Canvas('canvas');
  fabric.Object.prototype.set({
    transparentCorners: false,
    cornerSize: 7,
    borderColor: '#607D8B',
    cornerColor: '#FFFFFF',
    cornerStrokeColor: '#607D8B',
    rotatingPointOffset: 25,
    lockScalingFlip: true,
  });
  canvas.selection = false;

  fabric.Object.prototype.setControlsVisibility({
    mtr: false,
  });

  const deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

  const deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;

  function renderIcon(icon) {
    return function renderIcon(ctx, left, top, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon(deleteImg),
    cornerSize: 24,
  });

  canvas.on('object:moving', function (e) {
    console.log(e);
    if (
      e.target.left >
      e.target.canvas.width - e.target.width * e.target.scaleX
    ) {
      e.target.left = e.target.canvas.width - e.target.width * e.target.scaleX;
    }
    if (e.target.left < 0) {
      e.target.left = 0;
    }
    if (
      e.target.top >
      e.target.canvas.height - e.target.height * e.target.scaleY
    ) {
      e.target.top = e.target.canvas.height - e.target.height * e.target.scaleY;
    }
    if (e.target.top < 0) {
      e.target.top = 0;
    }
  });
  const maxScaleX = 0.3;
  const maxScaleY = 0.3;
  canvas.on('object:scaling', function (e) {
    console.log(e);
    if (
      e.target.left >
      e.target.canvas.width - e.target.width * e.target.scaleX
    ) {
      e.target.left = e.target.canvas.width - e.target.width * e.target.scaleX;
    }
    if (e.target.left < 0) {
      e.target.left = 0;
    }
    if (
      e.target.top >
      e.target.canvas.height - e.target.height * e.target.scaleY
    ) {
      e.target.top = e.target.canvas.height - e.target.height * e.target.scaleY;
    }
    if (e.target.top < 0) {
      e.target.top = 0;
    }

    if (e.target.scaleX < maxScaleX) {
      e.target.scaleX = maxScaleX;
    }
    if (e.target.scaleY < maxScaleY) {
      e.target.scaleY = maxScaleY;
    }
  });
});

async function deleteObject(eventData, transform) {
  const target = transform.target;
  const canvas = target.canvas;
  const cabinetId = target.cabinet.id;
  console.log(target);
  canvas.remove(target);
  canvas.requestRenderAll();
  cabinets.value = cabinets.value.map((item) => {
    if (item.id === cabinetId) {
      item.onMap = false;
    }
    return item;
  });
}

const store = useStore();
const cabinets = ref([]);
const computers = ref([]);
const map = ref([]);

const floorId = computed(() => store.getters['techroom/specialist/getFloorId']);

watch(floorId, async () => {
  canvas.clear();

  await store.dispatch(
    'techroom/specialist/requestFloorCabinets',
    store.getters['techroom/specialist/getFloorId']
  );

  await store.dispatch(
    'techroom/specialist/requestFloorComputers',
    store.getters['techroom/specialist/getFloorId']
  );
  cabinets.value = store.getters['techroom/specialist/getCabinets'];

  cabinets.value.forEach((item) => {
    item.onMap = false;
  });

  computers.value = store.getters['techroom/specialist/getComputers'];

  await store.dispatch(
    'techroom/specialist/requestMap',
    store.getters['techroom/specialist/getFloorId']
  );
  if (store.getters['techroom/specialist/getMap']) {
    const background = `${store.getters['techroom/specialist/getMap'].url
      }?token=${localStorage.getItem('token')}`;
    fabric.Image.fromURL(background, function (img) {
      // canvas.setHeight(img.height);
      // canvas.setWidth(img.width);
      img.scaleX = canvas.width / img.width;
      img.scaleY = canvas.height / img.height;
      canvas.setBackgroundImage(img);
      // console.log(img);
      canvas.requestRenderAll();
    });

    canvas.loadFromJSON(
      store.getters['techroom/specialist/getMap'].map.canvas,
      function () {
        canvas.renderAll();
      }
    );
    const foo = JSON.parse(
      store.getters['techroom/specialist/getMap'].map.canvas
    );

    if (foo) {
      const bar = foo.objects.map((item) => {
        return item.cabinet.id;
      });

      console.log(bar);

      cabinets.value.map((item) => {
        if (bar.includes(item.id)) {
          item.onMap = true;
        }
      });
    }
  }
});

function onDragStart(e, computer) {
  e.dataTransfer.dropEffect = 'move';
  e.dataTransfer.effectAllowed = 'move';
  e.dataTransfer.setData('itemId', computer.id.toString());
}

async function onDrop(e, cabinetId) {
  const itemId = parseInt(e.dataTransfer.getData('itemId'));
  if (itemId) {
    computers.value = computers.value.map((item) => {
      if (item.id == itemId) {
        item.cabinet.id = cabinetId;
        item.stock = false;
      }
      return item;
    });
    console.log('cabinetId:', cabinetId, 'itemID:', itemId);
    await store.dispatch('techroom/specialist/editComputerCabinet', {
      cabinetId,
      id: itemId,
      stock: false,
    });
  }
}

async function onDropToStock(e) {
  const itemId = parseInt(e.dataTransfer.getData('itemId'));
  if (itemId) {
    computers.value = computers.value.map((item) => {
      if (item.id == itemId) item.stock = true;
      return item;
    });
    await store.dispatch('techroom/specialist/stockComputer', itemId);
  }
}

function onDragStartCabinet(e, cabinet) {
  e.dataTransfer.dropEffect = 'move';
  e.dataTransfer.effectAllowed = 'move';
  e.dataTransfer.setData('cab', JSON.stringify(cabinet));
}

function onDropCabinetToMap(e) {
  if (e.dataTransfer.getData('cab')) {
    const itemId = JSON.parse(e.dataTransfer.getData('cab'));
    cabinets.value = cabinets.value.map((item) => {
      if (item.id == itemId.id) {
        item.onMap = true;
      }
      return item;
    });
    if (itemId) add(e.layerX, e.layerY, 150, 100, itemId);
  }
}

const rectColors = ['#FFCC66', '#FF6633', '#FFCCFF', '#6699FF', '#66CC99'];

async function add(x, y, w, h, cabinet) {
  console.log(Math.floor(Math.random() * rectColors.length));
  console.log(rectColors[Math.floor(Math.random() * rectColors.length)]);
  const rect = new fabric.Rect({
    top: y,
    left: x,
    width: w,
    height: h,
    fill: rectColors[Math.floor(Math.random() * rectColors.length)],
    opacity: 0.9,
  });
  const text = new fabric.IText(`${cabinet.num}`, {
    fontFamily: 'Arial',
    originX: 'center',
    originY: 'center',
    top: h / 2 + y,
    left: w / 2 + x,
    centeredRotation: true,
    fontSize: 32,
    fill: '#000000',
  });
  const group = new fabric.Group([rect, text], { cabinet });
  console.log(group);
  canvas.add(group);
}

async function SendMap() {
  const map1 = {
    floorId: store.getters['techroom/specialist/getFloorId'],
    map: map.value[0],
  };
  console.log(map1.map);
  await store.dispatch('techroom/specialist/sendMap', map1);
}

async function saveMap() {
  canvas.includeDefaultValues = false;
  var json = canvas.toJSON(['cabinet']);
  delete json.backgroundImage;
  console.log(json);
  await store.dispatch('techroom/specialist/editMap', {
    canvas: JSON.stringify(json),
    id: store.getters['techroom/specialist/getMap'].map.id,
  });
}
</script>

<style lang="scss" scoped>
.cabinets_data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @media screen and (max-width: $large) {
    display: none;
  }
}

.stock_data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @media screen and (max-width: $large) {
    display: none;
  }
}

.main_container {
  gap: 10px;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 300px;
  }

  .container_items {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    gap: rem(20);
  }

  @media screen and (max-width: $large) {
    padding: 0;
    justify-content: center;
  }
}

.select_file {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.block {
  border: 1px solid black;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.block_cabinet {
  cursor: grab;
}

.item {
  border: 1px solid black;
  padding: 5px;
  cursor: grab;
}
</style>
