<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Просмотр программного обеспечения</div>
        <div>Название ПО: {{ software.title }}</div>
        <div>Цена: {{ $filters.filterCurrency(software.price) }}</div>
        <div>Подписка: {{ software.subscriptionDate }}</div>
        <div>Дата списания: {{ software.debitDate }}</div>
        <div v-if="software.misc" style="white-space: break-spaces;">
          {{ software.misc }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    software: {
      type: Object,
    },
  },
};
</script>
