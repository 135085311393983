<template>
  <div class="projects__grid">
    <v-card
      class="d-flex flex-column pa-2"
      v-for="item in projects"
      :key="item"
    >
      <v-card-title>{{ item.nameProject }}</v-card-title>
      <v-card-subtitle>
        До {{ $filters.filterDate(item.timeline) }}
      </v-card-subtitle>
      <v-card-text>
        {{ item.description }}
      </v-card-text>
      <v-card-actions>
        <v-btn variant="outlined" @click="openDialog(item.id)">
          Подробнее
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>

  <DialogProject v-model="dialog" :project="project" @close="dialog = false" />
</template>

<script>
import DialogProject from "@/components/app/itroom/teamlead/projects/DialogProject.vue";

export default {
  components: { DialogProject },
  emits: ["loading"],
  data() {
    return {
      projects: null,
      project: null,
      dialog: false,
    };
  },
  methods: {
    async openDialog(id) {
      await this.$store.dispatch("itroom/teamlead/requestProject", id);
      this.project = this.$store.getters["itroom/teamlead/getProject"];
      this.dialog = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/teamlead/requestProjects");
    this.projects = this.$store.getters["itroom/teamlead/getProjects"];
    setTimeout(() => {
      this.$emit("loading");
    }, 500);
  },
};
</script>

<style lang="scss">
.projects__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: rem(24);

  @media screen and (max-width: $x-small) {
    grid-template-columns: 1fr;
  }
}
</style>