import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    specialists: [],
},
getters: {
    getSpecialists(state) {
      return state.specialists
    },

  },
  mutations: {
    updateSpecialists(state, specialists) {
      state.specialists = specialists
    },
  },
  actions: {
    async requestSpecialists({ commit }) {
        try {
          const result = await axios({
            method: 'get',
            url: `${url}/api/specialists`,
            headers: {
              Authorization: localStorage.getItem("token")
            }
          })
          console.log(result)
          const specialists = result.data
          const arr = []
          await specialists.forEach(el => {
            arr.push({
                fio: el.surname + ' ' + el.name + ' ' + el.lastName,
                id: el.id,
            })
          });
          commit('updateSpecialists', arr)
        } catch (error) {
          console.error(error)
        }
      },
  }
}