<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление компьютера</div>
        <v-form ref="form" @submit.prevent="submitHandler" class="form_cabinet">
          <v-select
            v-if="!stock"
            label="Выберите кабинет"
            v-model="formData.cabinetId"
            :items="
              $store.getters['techroom/specialist/getCabinets'].filter(
                (item) => !item.delete
              )
            "
            item-title="num"
            item-value="id"
            :rules="[() => !!formData.cabinetId || 'Обязательно']"
            required
            no-data-text="Нет"
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.num"
            label="Инвентарный №"
            :rules="[() => !!formData.num || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.remote"
            label="Удаленный доступ"
          />
          <h3>Корпус</h3>
          <v-select
            label="Размер корпуса"
            v-model="formData.caseSize"
            :items="['Стандартный', 'Компактный', 'Ноутбук']"
            :rules="[() => !!formData.caseSize || 'Обязательно']"
            no-data-text="Нет"
            clearable
          />
          <h3>Процессор</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.cpu.brand"
            label="Бренд"
            :rules="[() => !!formData.cpu.brand || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.cpu.core"
            label="Ядра"
            :rules="[() => !!formData.cpu.core || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.cpu.socket"
            label="Сокет"
            :rules="[() => !!formData.cpu.socket || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.cpu.model"
            label="Модель"
            :rules="[() => !!formData.cpu.model || 'Обязательно']"
            required
          />
          <h3>Материнская плата</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.motherboard.model"
            label="Модель"
            :rules="[() => !!formData.motherboard.model || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.motherboard.socket"
            label="Сокет"
            :rules="[() => !!formData.motherboard.socket || 'Обязательно']"
            required
          />
          <h3>Оперативная память</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.ram.brand"
            :rules="[() => !!formData.ram.brand || 'Обязательно']"
            label="Название"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.ram.vol"
            :rules="[() => !!formData.ram.vol || 'Обязательно']"
            label="Объем"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.ram.frq"
            :rules="[() => !!formData.ram.frq || 'Обязательно']"
            label="Частота"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.ram.form"
            :rules="[() => !!formData.ram.form || 'Обязательно']"
            label="Форм фактор"
            required
          />
          <h3>Блок питания</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.power.pw"
            :rules="[() => !!formData.power.pw || 'Обязательно']"
            label="Мощность"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.power.brand"
            :rules="[() => !!formData.power.brand || 'Обязательно']"
            label="Название"
            required
          />
          <h3>Видеокарта</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.gpu.brand"
            label="Бренд"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.gpu.model"
            label="Модель"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.gpu.vol"
            label="Объем видеопамяти"
            required
          />
          <h3>Жесткий диск</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.hdd.vol"
            label="Объем"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.hdd.brand"
            label="Название"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.hdd.form"
            label="Форм фактор"
            required
          />
          <h3>SSD диск</h3>
          <v-text-field
            class="text_field_input"
            v-model="formData.ssd.vol"
            label="Объем"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.ssd.brand"
            label="Название"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.ssd.form"
            label="Форм фактор"
            required
          />
          <h3>Состояние компьютера</h3>
          <v-select
            label="Состояние компьютера"
            v-model="formData.statusId"
            :items="$store.getters['techroom/specialist/getStatuses']"
            item-title="title"
            item-value="id"
            no-data-text="Нет"
            clearable
          />
          <v-textarea
            class="text_field_input"
            v-model="formData.misc"
            :rules="[
              () => formData.statusId !== 4 || !!formData.misc || 'Обязательно',
            ]"
            label="Заметки"
          />
          <v-btn type="submit" class="cabinet_btn" @click="validate($event)">
            Добавить компьютер
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    stock: {
      type: Boolean,
    },
  },
  data: () => ({
    formData: {
      cabinetId: null,
      num: null,
      remote: null,
      caseSize: null,
      cpu: {
        brand: null,
        core: null,
        socket: null,
        model: null,
      },
      motherboard: {
        model: null,
        socket: null,
      },
      ram: {
        brand: null,
        vol: null,
        frq: null,
        form: null,
      },
      power: {
        pw: null,
        brand: null,
      },
      gpu: {
        brand: null,
        model: null,
        vol: null,
      },
      hdd: {
        vol: null,
        brand: null,
        form: null,
      },
      ssd: {
        brand: null,
        vol: null,
        form: null,
      },
      statusId: null,
      misc: null,
    },
  }),

  methods: {
    async submitHandler() {
      try {
        if (this.stock) {
          this.formData.stock = true;
          this.$emit('snackbar');
        }
        await this.$store.dispatch(
          'techroom/specialist/createComputer',
          this.formData
        );

        if (this.$store.getters['techroom/specialist/getFloorId']) {
          await this.$store.dispatch(
            'techroom/specialist/requestFloorComputers',
            this.$store.getters['techroom/specialist/getFloorId']
          );
        } else {
          await this.$store.dispatch(
            'techroom/specialist/requestAddressComputers',
            this.$store.getters['techroom/specialist/getAddress'].id
          );
        }
        if (this.stock) {
          await this.$store.dispatch(
            'techroom/specialist/requestStockComputers'
          );
        }
        this.$refs.form.reset();
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },
    async validate(e) {
      let a = await this.$refs.form.validate();
      if (!a.valid) {
        e.preventDefault();
      }
    },
  },
};
</script>
