import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    projects: null,
  },
  getters: {
    getProjects(state) {
      return state.projects
    }
  },
  mutations: {
    updateProjects(state, projects) {
      state.projects = projects
    }
  },
  actions: {
    async requestProjects({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/projects`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result)
        commit('updateProjects', result.data.projects)
      } catch (error) {
        console.error(error)
      }
    }
  }
}