<template>
  <Loading v-if="loadingPage"></Loading>
  <section v-else class="page__statistics statistics">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="account__title itroom__title">Статистика</h1>
    </div>
    <v-card>
      <v-card-title>Общая информация</v-card-title>
      <v-card-text>
        <p>Проекты: {{ statistics.CountAll.projects }}</p>
        <p>Пользователи: {{ statistics.CountAll.users }}</p>
        <p>Задачи: {{ statistics.CountAll.tasks }}</p>
        <p>Лиды: {{ statistics.CountAll.calls }}</p>
        <p>Клиенты: {{ statistics.CountAll.clients }}</p>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Информация о сотрудниках</v-card-title>
      <v-card-text>
        <p>Разработчики: {{ statistics.informationUsers.developers }}</p>
        <p>Менеджеры: {{ statistics.informationUsers.managers }}</p>
        <p>Тимлиды: {{ statistics.informationUsers.temleads }}</p>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Информация о квалификации сотрудников</v-card-title>
      <v-card-text>
        <p>
          Laravel: {{ statistics.informationUsers.developerStackUsers.laravel }}
        </p>
        <p>
          Photoshop:
          {{ statistics.informationUsers.developerStackUsers.photoshop }}
        </p>
        <p>
          ReactJs: {{ statistics.informationUsers.developerStackUsers.react }}
        </p>
        <p>VueJs: {{ statistics.informationUsers.developerStackUsers.vue }}</p>
        <p>
          Тестировщики:
          {{ statistics.informationUsers.developerStackUsers.testical }}
        </p>
        <v-divider></v-divider>
        <p>
          Бэкенд разработчики:
          {{ statistics.informationUsers.developersJobUsers.backend }}
        </p>
        <p>
          Фронтенд разработчики:
          {{ statistics.informationUsers.developersJobUsers.frontend }}
        </p>
        <p>
          Дизайнеры:
          {{ statistics.informationUsers.developersJobUsers.designer }}
        </p>
        <p>
          СММ специалисть:
          {{ statistics.informationUsers.developersJobUsers.smm }}
        </p>
      </v-card-text>
    </v-card>
  </section>
  <!-- <div style="width: 600px">
    <Pie :chart-data="chartData" />
  </div> -->
</template>

<script>
// import { Pie } from "vue-chartjs";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   CategoryScale,
// } from "chart.js";
// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
export default {
  // components: { Pie },
  data() {
    return {
      statistics: null,

      loadingPage: true,
      // chartData: {
      //   labels: ["Проекты", "Пользователи", "Задачи", "Лиды", "Клиенты"],
      //   datasets: [
      //     {
      //       backgroundColor: [
      //         "hsl(156, 100%, 48%)",
      //         "hsl(228, 100%, 66%)",
      //         "hsl(45, 100%, 58%)",
      //         "hsl(6, 94%, 60%)",
      //         "hsl(34, 97%, 51%)",
      //       ],
      //       data: null,
      //     },
      //   ],
      // },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("itroom/director/requestStatistics");
      // this.chartData.datasets[0].data = Object.values(
      //   this.$store.getters["itroom/director/getStatistics"].CountAll
      // );
      this.statistics = this.$store.getters["itroom/director/getStatistics"];
      this.loadingPage = false;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
</style>