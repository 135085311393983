<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Вы действительно хотите удалить?</h2>
        <v-btn @click="updateStatus" color="success" class="mr-10"> Да </v-btn>
        <v-btn @click="$emit('close')" color="error"> Нет </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
    },
  },
  methods: {
    async updateStatus() {
      await this.$store.dispatch("itroom/manager/updateStatusClient", {
        id: this.client.id,
        status: this.client.status,
      });
      this.$emit("close");
    },
  },
};
</script>