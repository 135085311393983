<template>
  <v-table density="comfortable">
    <thead>
      <tr>
        <th class="text-left">Название компании</th>
        <th class="text-left">Сроки</th>
        <th class="text-left">Лидер проекта</th>
        <th class="text-left">Стадия проекта</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in $store.getters['itroom/director/getProjects']"
        :key="item.id"
      >
        <td>{{ item.nameCompany }}</td>
        <td>{{ $filters.filterDate(item.timeline) }}</td>
        <td>Лидер</td>
        <td>{{ item.status }}</td>
        <td class="text-right">
          <!-- Важно -->
          <!-- <v-btn variant="text" size="x-small" class="fill-height">
              <svg height="20" width="20">
                <path
                  d="M7.292 14.958 10 13.354l2.729 1.604-.708-3.041 2.354-2.021-3.125-.292L10 6.708 8.75 9.625l-3.125.271 2.396 2Zm-.938 1.25.979-4.062-3.229-2.729 4.229-.375L10 5.208l1.667 3.854 4.229.355-3.229 2.729.979 4.062L10 14.042ZM10 11.042Z"
                />
              </svg>
            </v-btn> -->
          <!-- Важно -->

          <!-- Подробнее -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-more manage-item fill-height"
            :value="item.id"
            @click="openDialog(item.id)"
          >
            <v-tooltip activator="parent" location="top">Подробнее</v-tooltip>
            <IconView />
          </v-btn>
          <!-- Подробнее -->

          <!-- Редактировать -->
          <!-- <v-btn
            variant="text"
            size="x-small"
            class="manage-item-edit manage-item fill-height"
            :value="item.id"
            @click="openDialogEdit(item.id)"
          >
            <v-tooltip activator="parent" location="top">
              Редактирование
            </v-tooltip>
            <IconEdit />
          </v-btn> -->
          <!-- Редактировать -->

          <!-- Удаление -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-delete manage-item fill-height"
            :value="item.id"
            @click="openDialogDelete(item)"
          >
            <v-tooltip activator="parent" location="top">
              Перенести в архив
            </v-tooltip>
            <IconDelete />
          </v-btn>
          <!-- Удаление -->
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogProject v-model="dialog" :project="project" @close="dialog = false" />
  <DialogDeleteProject
    v-model="dialogDelete"
    :project="project"
    @close="dialogDelete = false"
  />
</template>

<script>
import DialogProject from "@/components/app/itroom/director/projects/DialogProject.vue";
import DialogDeleteProject from "@/components/app/itroom/director/projects/DialogDeleteProject.vue";
export default {
  components: {
    DialogProject,
    DialogDeleteProject,
  },
  emits: ["loading"],
  data() {
    return {
      project: null,

      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    async openDialog(id) {
      await this.$store.dispatch("itroom/director/requestProject", id);
      this.project = this.$store.getters["itroom/director/getProject"];
      this.dialog = true;
    },
    openDialogDelete(item) {
      this.project = item;
      this.dialogDelete = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/director/requestProjects");
    setTimeout(() => {
      this.$emit("loading");
    }, 500);
  },
};
</script>

<style>
</style>