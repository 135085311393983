<template>
  <svg height="20" width="20">
    <path
      d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h4.25v.584h-4.25q-.187 0-.354.166-.166.167-.166.354v9.876q0 .187.166.354.167.166.354.166h9.876q.187 0 .354-.166.166-.167.166-.354v-4.25h.584v4.25q0 .479-.313.791-.312.313-.791.313Zm3.063-3.709-.458-.458 7.354-7.333h-3.125v-.584h4.146v4.146h-.584V4.979Z"
    />
  </svg>
</template>

<script>
export default {
  name:'IconUncover',
  
}
</script>

<style lang="scss" scoped>
svg {
    cursor:pointer;
}
</style>
