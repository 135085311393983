const getHourlessDate = (date_string) => {
  let today = date_string ? new Date(date_string) : new Date();
  let year = today.getFullYear() + '',
    month = (today.getMonth() + 1 + '').padStart(2, '0'),
    day = (today.getDate() + '').padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const getLocaleTime = (dateString) => {
  let [date] = new Date(dateString).toLocaleString('ru-Ru').split(', ');
  return `${date}`;
};

const isToday = (date) => {
  if (!date) return;
  let today = getLocaleTime(new Date());
  return date === today;
};

const isBefore = (date1, date2) => {
  if (!date1 || !date2) return;
  return new Date(date1) < new Date(date2);
};

export { getHourlessDate, getLocaleTime, isToday, isBefore };
