import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    users: null,
    user: null,
    settingsUser: null,
    managersAndTeamleads: null,
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getUser(state) {
      return state.user
    },
    getSettingsUser(state) {
      return state.settingsUser
    },
    getManagersAndTeamleads(state) {
      return state.managersAndTeamleads
    }
  },
  mutations: {
    updateUsers(state, res) {
      state.users = res
    },
    updateUser(state, id) {
      state.users.filter(item => {
        if (item.id == id) {
          return state.user = new Object(item)
        }
      })
    },
    updateSettingUser(state, res) {
      state.settingsUser = res
    },
    updateManagersAndTeamleads(state, res) {
      state.managersAndTeamleads = res
    }
  },
  actions: {
    async requestUsers({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/users`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateUsers', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestSettingsUser({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/settingsUser`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateSettingUser', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestManagersAndTeamleads({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/managerAndTeamlead`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateManagersAndTeamleads', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async createUser({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/users`,
          headers: {
            Authorization: localStorage.getItem('token')
          },
          data: {
            name: formData.name,
            surname: formData.surname,
            lastName: formData.lastName,
            dateBirth: formData.dateBirth,
            login: formData.login,
            password: formData.password,
            email: formData.email,
            roleId: formData.role,
            jobId: formData.job,
            stackId: formData.stack,
          }
        })
        console.log(result);
        await dispatch('requestUsers')
      } catch (error) {
        console.error(error);
        throw error
      }
    },

    async editUser({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/users/update`,
          headers: {
            Authorization: localStorage.getItem('token')
          },
          data: {
            id: formData.id,
            name: formData.name,
            surname: formData.surname,
            lastName: formData.lastName,
            dateBirth: formData.dateBirth,
            login: formData.login,
            password: formData.password,
            email: formData.email,
            roleId: formData.roles,
            jobId: formData.jobs,
            stackId: formData.stacks,
          }
        })
        console.log(result);
        await dispatch('requestUsers')
      } catch (error) {
        console.error(error);
      }
    },

    async removeUser({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/deleteUser`,
          headers: {
            Authorization: localStorage.getItem('token')
          },
          data: { id }
        })
        console.log(result);
        await dispatch('requestUsers')
      } catch (error) {
        console.error(error);
      }
    }
  }
}