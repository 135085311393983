<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import ItroomLayout from '@/layouts/ItroomLayout';
import TechroomLayout from '@/layouts/TechroomLayout';
import OrganizationLayout from '@/layouts/OrganizationLayout';
import EmptyLayout from '@/layouts/EmptyLayout';

export default {
  components: { ItroomLayout, TechroomLayout, OrganizationLayout, EmptyLayout },
  computed: {
    layout() {
      return this.$route.meta.layout + '-layout';
    },
  },
  mounted() {
    this.$store.dispatch('resizeWidth');
  },
};
</script>

<script setup></script>

<style lang="scss"></style>
