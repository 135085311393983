<template>
  <div>
    <v-card
      class="table-block mb-2"
      v-for="item in $store.getters['techroom/manager/getLeads'].filter(
        (lead) => lead.delete === archive
      )"
      :key="item.id"
      :title="item.head"
      :subtitle="item.title ? 'Юридическое лицо' : 'Физическое лицо'"
    >
      <template v-slot:text>
        <div>Телефон: {{ $filters.filterNumber(item.phone) }}</div>
        <div>Электронная почта: {{ item.email }}</div>
        <div>
          Название компании: {{ item.company ? item.company : 'не указано' }}
        </div>
        <div>
          Комментарий:
          {{ item.description ? item.description : 'не указано' }}
        </div>
      </template>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          variant="tonal"
          class="w-100 ma-0 mb-3"
          color="orange"
          @click="$emit('openDialogEdit', item)"
        >
          Изменить
        </v-btn>
        <v-btn
          variant="tonal"
          class="w-100 ma-0 mb-3"
          color="green"
          @click="$emit('openDialogAddClient', item)"
        >
          Загрузить договор
        </v-btn>
        <v-btn
          v-if="!archive"
          variant="tonal"
          class="w-100 ma-0"
          color="red"
          @click="$emit('openDialogRemoveLead', item)"
        >
          Перенести в архив
        </v-btn>
        <v-btn
          v-else
          variant="tonal"
          class="w-100 ma-0"
          color="green"
          @click="$emit('restore', item)"
        >
          Восстановить
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    archive: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
