<template>
  <div class="wrapper" page="authorization">
    <div class="separator">
      <div class="window__wrap">
        <v-progress-linear v-if="loadingLine" indeterminate rounded height="3"></v-progress-linear>
        <v-window v-model="tab">
          <v-window-item value="AuthForm">
            <AuthForm @loadingLineOff="loadingLine = false" @loadingOff="loading = false"
              @loadingOn="loading = true; loadingLine = true"></AuthForm>
          </v-window-item>
          <v-window-item value="SelectForm">
            <SelectForm></SelectForm>
          </v-window-item>
        </v-window>
      </div>
      <div class="right">
        <svg fill="#f4553d" height="20" width="20">
          <path
            d="M10 12.583q.229 0 .396-.156.166-.156.166-.406 0-.229-.156-.396T10 11.458q-.229 0-.396.157-.166.156-.166.406 0 .229.156.396.156.166.406.166Zm-.5-2.375h1V6.417h-1Zm.5 7.188q-2.562-.688-4.25-2.979-1.688-2.292-1.688-5.146V4.917L10 2.625l5.938 2.292v4.354q0 2.854-1.688 5.146-1.688 2.291-4.25 2.979Zm0-1.042q2.146-.666 3.542-2.656 1.396-1.99 1.396-4.427V5.583L10 3.688 5.062 5.583v3.688q0 2.437 1.396 4.427 1.396 1.99 3.542 2.656Zm0-6.312Z" />
        </svg>
        Никому не сообщайте свои данные! Сайт хранит персональные данные пользователей.
      </div>
    </div>
  </div>
</template>

<script>
import SelectForm from "@/components/authorization/SelectForm.vue";
import AuthForm from "@/components/authorization/AuthForm.vue";
export default {
  components: {
    SelectForm,
    AuthForm,
  },
  data() {
    return {
      connection: false,
      date: new Date(),
      loading: false,
      loadingLine: false,
    };
  },
  mounted() {
    setInterval(async () => {
      // this.connection = await axios.get(`${url}`);
      this.date = new Date();
      // console.log(this.connection);
    }, 1000);
  },
  computed: {
    tab() {
      if (this.$store.state.authorization.isAuthenticated) {
        return "SelectForm";
      }
      return "AuthForm";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.window__wrap {
  border-radius: var(--border-radius);
  border: 1px solid var(--block-border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(32);
  background-color: var(--block-background-color);
  box-shadow: 0px 0px 15px 0px rgba(34, 60, 80, 0.05);

  @media screen and (max-width: $medium) {
    height: 100%;
  }

  .v-window {
    @media screen and (max-width: $medium) {
      width: 100%;
      height: 100%;
    }
  }

  .v-window-item {
    width: rem(550);
    height: rem(500);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $medium) {
      width: 100%;
      height: 100%;
    }
  }
}

.wrapper[page="authorization"] {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media screen and (max-width: $x-small) {
  //   display: block;
  // }

  .separator {
    display: flex;
    position: relative;

    @media screen and (max-width: $medium) {
      height: 100%;
      width: 100%;
    }

    .v-progress-linear {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .v-progress-linear__background {
        background: hsl(227deg 86% 87%);
      }

      .v-progress-linear__indeterminate {
        background: var(--loading-line-color);
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $large) {
      flex-direction: column;
    }

    .bottom {
      position: absolute;
      bottom: rem(-65);
      background-color: var(--block-background-color);
      border: 1px solid var(--block-border-color);
      width: 100%;
      padding: rem(14);
      font-size: rem(13);
      color: var(--white-12);
      letter-spacing: rem(1.1);
      font-family: monospace;
      text-align: center;
      border-radius: var(--border-radius);
    }

    .top {
      position: absolute;
      top: rem(-65);
      background-color: var(--block-background-color);
      border: 1px solid var(--block-border-color);
      width: 100%;
      padding: rem(14);
      font-size: rem(13);
      color: var(--white-12);
      letter-spacing: rem(1.1);
      font-family: monospace;
      text-align: center;
      border-radius: var(--border-radius);
    }

    .right {
      position: absolute;
      right: rem(-335);
      background-color: var(--block-background-color);
      border: 1px solid var(--block-border-color);
      padding: rem(14);
      max-width: rem(320);
      border-radius: var(--border-radius);
      font-size: rem(14);
      color: var(--text-hint-color);

      &.right-bottom {
        top: rem(100);
      }

      svg {
        vertical-align: middle;
      }

      @media screen and (max-width: $large) {
        position: inherit;
        right: 0;
        max-width: none;
        text-align: center;
      }
    }
  }
}

.v-input__details {
  margin-bottom: -5px;
}
</style>