<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование {{ personal ? 'личной' : '' }} задачи {{ formData.date ? 'на ' +
    formData.date : ''
}}</div>
        <v-form ref="form" class="form_cabinet" @submit.prevent="submitHandler" v-model="valid">
          <v-text-field label="Название" v-model="formData.name" :rules="[() => !!formData.name || 'Обязательное поле']"
            maxlength="30" />
          <v-textarea no-resize label="Описание" v-model="formData.description" />
          <v-select v-if="!personal" label="Исполнители" :items="$store.getters['techroom/manager/getSpecialists']"
            item-value="id" item-title="fio" multiple clearable no-data-text="Нет доступных данных"
            v-model="formData.workers" />
          <v-checkbox label="Срочная задача" color="red" v-model="formData.urgentTask" />
          <v-date-picker :min-date="new Date()" :attributes='attrs' v-model="formData.deadline" mode="dateTime" is24hr="true" />
          <v-btn type="submit">Редактировать</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { wsUrl } from '@/store';
export default {
  props: {
    personal: Boolean,
    task: Object,
  },
  data() {
    return {
      valid: false,
      attrs: [{
        key: 'today',
        highlight: 'red',
        dates: new Date(),
        popover: {
          label: 'Сегодня',
        }
      }],
      formData: {
        date: this.personal ? this.Today() : null,
        name: null,
        description: null,
        workers: null,
        urgentTask: false,
        deadline: null,
        personal: this.personal,
      },
    };
  },
  watch: {
    task() {
      this.formData.name = this.task.name
      this.formData.description = this.task.description
      this.formData.workers = this.task.user.map(user => user.id)
      this.formData.urgentTask = this.task.type == 2 ? true : false,
      this.formData.deadline = this.task.deadline
    }
  },
  methods: {
    Today() {
      const today = new Date()
      const month = today.getMonth() + 1
      return today.getFullYear() + '-' + month + '-' + today.getDate()
    },
    async submitHandler() {
      this.$refs.form.validate();
      if (this.valid) {
        try {
          const ws = new WebSocket(wsUrl);
          setTimeout(() => {
            ws.send(JSON.stringify({ event: 'task' }));
          }, 500)
          await this.$store.dispatch(
            'techroom/manager/editPlanTask',
            {
              id: this.task.id,
              name: this.formData.name,
              deadline: this.formData.deadline,
              workers: this.formData.workers,
              description: this.formData.description,
              urgentTask: this.formData.urgentTask,
            }
          );
          this.$emit('close');
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>
