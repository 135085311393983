<template>
    <v-dialog class="dialog">
        <v-card class="dialog_content">
            <div class="action">
                <v-card-actions>
                    <v-btn color="#ff5757" block @click="$emit('close')">
                        <v-icon size="40">mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </div>
            <v-card-text>
                <div class="dialog_title">Банковские реквизиты</div>
                <v-form ref="form" class="form_cabinet" @submit.prevent="editClient">
                    <v-text-field class="text_field_input" v-model="formData.bankTitle" label="Наименование банка"
                        :rules="[
                            () => !!formData.bankTitle || 'Обязательно',
                        ]" maxlength="24" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.bankAccount" label="Расчётный счёт" :rules="[
                        () => !!formData.bankAccount || 'Обязательно',
                        () => formData.bankAccount.length === 20 || 'Расчётный счёт состоит из 20 цифр',
                    ]" maxlength="20" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.correspondentAccount"
                        label="Корреспондентский счёт" :rules="[
                            () => !!formData.correspondentAccount || 'Обязательно',
                            () => formData.correspondentAccount.length === 20 || 'Корреспондентский счёт состоит из 20 цифр',
                        ]" maxlength="20" required>
                    </v-text-field>
                    <v-text-field class="text_field_input" v-model="formData.BIC" label="БИК" :rules="[
                        () => !!formData.BIC || 'Обязательно',
                        () => formData.BIC.length === 9 || 'БИК состоит из 9 цифр',
                    ]" maxlength="9" required>
                    </v-text-field>
                    <v-btn type="submit" variant="outlined" class="cabinet_btn" @click="validate($event)"> Редактировать
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        client: {
            type: Object,
        },
    },
    computed: {
        formData() {
            return this.client;
        },
    },
    methods: {
        async editClient() {
            try {
                await this.$store.dispatch("techroom/manager/editClient", this.formData);
                this.$emit("close");
            } catch (error) {
                console.error(error);
            }
        },
        async validate(e) {
            let a = await this.$refs.form.validate();
            if (!a.valid) {
                e.preventDefault();
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.form_cabinet {
    width: 100%;
    justify-content: unset;
    align-items: unset;
    display: grid !important;
    grid-template: repeat(2, auto) / repeat(2, auto);
    gap: rem(24);

    @media screen and (max-width: $medium) {
        display: flex !important;
        flex-direction: column;
    }

    .v-btn {
        width: 100%;
        grid-area: 3 / 1 / 4 / 3;
        background-color: unset;
        color: #ff5757;
        border-radius: 0;
    }

}
</style>