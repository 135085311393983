<template>
  <svg height="20" width="20" fill="#f94c39">
    <path
      d="M.417 14.833v-1.104q0-.875.895-1.427.896-.552 2.313-.552.25 0 .458.01.209.011.396.052-.25.417-.406.865-.156.448-.156.969v1.187Zm5 0v-1.145q0-1.209 1.271-1.928 1.27-.718 3.312-.718 2.062 0 3.323.718 1.26.719 1.26 1.928v1.145Zm10.666 0v-1.187q0-.521-.145-.969-.146-.448-.417-.865.208-.041.417-.052.208-.01.437-.01 1.437 0 2.323.552.885.552.885 1.427v1.104ZM10 12.375q-1.167 0-2.031.292-.865.291-1.094.729v.104h6.25v-.104q-.229-.438-1.083-.729-.854-.292-2.042-.292Zm-6.417-1.187q-.625 0-1.062-.448-.438-.448-.438-1.052 0-.626.438-1.073.437-.448 1.062-.448t1.073.448q.448.447.448 1.073 0 .604-.448 1.052-.448.448-1.073.448Zm12.834 0q-.625 0-1.063-.448-.437-.448-.437-1.052 0-.626.437-1.073.438-.448 1.063-.448t1.062.448q.438.447.438 1.073 0 .604-.438 1.052-.437.448-1.062.448ZM10 10.417q-.896 0-1.531-.636-.636-.635-.636-1.531 0-.917.636-1.542.635-.625 1.531-.625.917 0 1.542.625t.625 1.542q0 .896-.625 1.531-.625.636-1.542.636Zm0-3q-.312 0-.573.239-.26.24-.26.594 0 .312.26.573.261.26.594.26.333 0 .573-.26.239-.261.239-.594 0-.312-.239-.562-.24-.25-.594-.25Zm0 6.083Zm0-5.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEntity",
};
</script>