import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    projects: null,
    project: null,
  },
  getters: {
    getProjects(state) {
      return state.projects
    },
    getProject(state) {
      return state.project
    }
  },
  mutations: {
    updateProjects(state, res) {
      state.projects = res
    },
    updateProject(state, res) {
      state.project = res
    }
  },
  actions: {
    async requestProjects({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/projects`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateProjects', result.data)
      } catch (error) {
        console.error(error);
      }
    },
    async requestProject({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/project/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateProject', result.data)
      } catch (error) {
        console.error(error);
      }
    },
    async deleteProject({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/project/delete`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: { id }
        })
        console.log(result);
        await dispatch('requestProjects')
      } catch (error) {
        console.error(error);
      }
    }
  }
}