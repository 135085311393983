<template>
  <Loading v-show="loadingPage"></Loading>
  <section v-show="!loadingPage" class="page__clients clients">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="clients__title itroom__title">Клиенты</h1>
    </div>
    <ManagerClients
      v-if="$store.getters.USER.selectedRole.role === 'MANAGER'"
      @loading="loadingPage = false"
    />
    <DirectorClients
      v-if="$store.getters.USER.selectedRole.role === 'DIRECTOR'"
      @loading="loadingPage = false"
    />
  </section>
</template>

<script>
import ManagerClients from "@/components/app/itroom/manager/clients/ManagerClients.vue";
import DirectorClients from "@/components/app/itroom/director/clients/DirectorClients.vue";
export default {
  components: { ManagerClients, DirectorClients },
  data() {
    return {
      loadingPage: true,
    };
  },
};
</script>

<style>
</style>