import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    clients: null,
    client: null,
  },
  getters: {
    getClients(state) {
      return state.clients
    },
    getClient(state) {
      return state.client
    },
  },
  mutations: {
    updateClients(state, res) {
      state.clients = res
    },
    updateClient(state, res) {
      state.client = res
    },
  },
  actions: {
    async requestClients({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/clients`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateClients', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestClient({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/clients/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateClient', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestDogovor(ctx, fileName) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/client/${fileName}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        window.location.href = result.request.responseURL
      } catch (error) {
        console.error(error);
      }
    },

    async updateStatus({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/client/updateStatus`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: { id }
        })
        console.log(result);
        await dispatch('requestClients')
      } catch (error) {
        console.error(error);
      }
    }
  }
}