import axios from 'axios'
import {filterRole} from "@/filters";
import { url } from '@/store'

export default {
    state: {
        users: [],
        roles: [],
    },
    getters: {
        getTechUsers(state) {
            return state.users
        },
        getTechRoles(state) {
            return state.roles.map(item => {
                item.name = filterRole(item.name)
                return item
            })
        }
    },
    mutations: {
        updateTechUsers(state, res) {
            state.users = res
        },
        updateTechRoles(state, res) {
            state.roles = res
        }
    },
    actions: {
        async requestUsers({commit}) {
            try {
                const result = await axios({
                    method: 'get',
                    url: `${url}/api/techroom/users`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                })
                commit('updateTechUsers', result.data);
                console.log(result.data)
            } catch (err) {
                console.error(err)
            }
        },
        async requestRoles({commit}) {
            try {
                const result = await axios({
                    method: 'get',
                    url: `${url}/api/techroom/roles`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                })
                commit('updateTechRoles', result.data);
                console.log(result.data)
            } catch (err) {
                console.error(err)
            }
        },
        async createUser(ctx, formData) {
            try {
                const result = await axios({
                    method: 'post',
                    url: `${url}/api/create`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: formData
                })
                console.log(result.data)
            } catch (err) {
                console.error(err)
                return err.response
            }
        }
    }
}