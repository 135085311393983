<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Вы действительно хотите удалить?</h2>
        <div class="modal_buttons_block">
          <v-btn @click="deleteLead" color="success" class="w-50"> Да </v-btn>
          <v-btn @click="$emit('close')" color="error" class="w-50">
            Нет
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    lead: {
      type: Object,
    },
  },
  computed: {
    formData() {
      return this.lead;
    },
  },
  methods: {
    async deleteLead() {
      await this.$store.dispatch('techroom/manager/removeLead', this.lead.id);
      await this.$store.dispatch('techroom/manager/requestLeads');
      this.$emit('close');
    },
  },
};
</script>

<style>
.mb-5 {
  text-align: center;
}
.modal_buttons_block {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
</style>
