<template>
  <div class="item_header">
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title flex-column align-start">
        <h2 class="font-weight-bold">Оборудование</h2>
        <small>по адресу {{ getAddress.title }}</small>
      </div>
      <div class="header_floors">
        <SelectFloor />
        <div class="page_menu_title">
          <div
            class="link"
            :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }"
            @click="this.archive = null"
          >
            Все
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
            @click="this.archive = false"
          >
            Активные
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }"
            @click="this.archive = true"
          >
            В архиве
          </div>
        </div>
      </div>
    </div>
    <div class="header_right">
      <div
        class="d-flex justify-space-around align-center flex-column flex-md-row"
      >
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              color="#ff5757"
              class="button-add"
              v-bind="props"
              icon="mdi-plus"
            >
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openDialogCreate(false)">
              Добавить в кабинет
            </v-list-item>
            <v-list-item @click="openDialogCreate(true)">
              Добавить на склад
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
  <v-table>
    <thead>
      <tr>
        <th class="text-left start">Тип</th>
        <th class="text-left">Модель</th>
        <th class="text-left">Состояние</th>
        <th class="text-left">Статус</th>
        <th class="text-center">
          <v-icon>mdi-cogs</v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in getEquipments"
        :key="item.id"
        :style="{
          color: item.delete ? '#7c7c7c' : '#000',
          transition: '.15s ease',
        }"
        @click="openDialog(item)"
      >
        <td class="text-left start">{{ item.type }}</td>
        <td class="text-left">{{ item.model }}</td>
        <td class="text-left">{{ item.status?.title }}</td>
        <td class="text-left" v-if="item.cabinet.delete">
          {{
            !isNaN(item.cabinet.num)
              ? `В удаленном кабинете №${item?.cabinet.num}`
              :  `Удаленный ${item?.cabinet.num}`
          }}
        </td>
        <td class="text-left" v-else>
          {{
            !isNaN(item.cabinet.num)
              ? `В кабинете №${item?.cabinet.num}`
              :  `${item?.cabinet.num}`
          }}
        </td>
        <td class="text-center">
          <v-menu class="actions-list">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small">
                <v-icon size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialog(item)"
              >
                <v-list-item-title>Подробнее</v-list-item-title>
              </v-list-item>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialogEdit(item)"
              >
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="stock(item)">
                <v-list-item-title>Перенести на склад</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.delete"
                min-height="1"
                :value="item.id"
                @click="removeEquipment(item.id, true)"
              >
                <v-list-item-title>Удалить</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                min-height="1"
                :value="item.id"
                @click="removeEquipment(item.id, false)"
              >
                <v-list-item-title>Восстановить</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <v-snackbar v-model="snackbar" timeout="2000">
    Оборудование успешно добавлено на склад!

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="snackbar = false">
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
  <DialogAddEquipment
    v-model="dialogAddEquipment"
    @close="dialogAddEquipment = false"
    @snackbar="snackbar = true"
    :stock="addStockEquipment"
  />
  <DialogEquipment
    v-model="dialogEquipment"
    @close="dialogEquipment = false"
    :equipment="equipment"
  />
  <DialogEditEquipment
    v-model="dialogEditEquipment"
    @close="dialogEditEquipment = false"
    :equipment="equipment"
  />
</template>

<script>
import SelectFloor from '@/components/app/techroom/specialist/organization/SelectFloor.vue';
import DialogAddEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogAddEquipment.vue';
import DialogEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogEquipment.vue';
import DialogEditEquipment from '@/components/app/techroom/specialist/organization/equipment/DialogEditEquipment.vue';

export default {
  components: {
    DialogAddEquipment,
    DialogEquipment,
    DialogEditEquipment,
    SelectFloor,
  },
  data() {
    return {
      snackbar: false,
      equipment: null,
      addStockEquipment: false,
      archive: null,
      dialogAddEquipment: false,
      dialogEquipment: false,
      dialogEditEquipment: false,
    };
  },

  computed: {
    getAddress() {
      return this.$store.getters['techroom/specialist/getAddress'];
    },
    floorId() {
      return this.$store.getters['techroom/specialist/getFloorId'];
    },
    getEquipments() {
      if (this.archive === true) {
        return this.$store.getters['techroom/specialist/getEquipments'].filter(
          (item) => item.delete
        );
      }
      if (this.archive === false) {
        return this.$store.getters['techroom/specialist/getEquipments'].filter(
          (item) => !item.delete
        );
      }
      return this.$store.getters['techroom/specialist/getEquipments'];
    },
  },

  watch: {
    async getAddress() {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressEquipments',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    },

    async floorId() {
      if (this.floorId) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorEquipments',
          this.floorId
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressEquipments',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },

  methods: {
    async openDialogCreate(stock) {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressCabinets',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      this.dialogAddEquipment = true;
      this.addStockEquipment = stock;
    },
    openDialog(item) {
      this.equipment = item;
      this.dialogEquipment = true;
    },
    async openDialogEdit(item) {
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      await this.$store.dispatch(
        'techroom/specialist/requestAddressCabinets',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
      this.equipment = item;
      this.dialogEditEquipment = true;
    },
    async removeEquipment(id, status) {
      await this.$store.dispatch('techroom/specialist/removeEquipment', {
        id,
        status,
      });

      if (this.$store.getters['techroom/specialist/getFloorId']) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorEquipments',
          this.$store.getters['techroom/specialist/getFloorId']
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressEquipments',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
    async stock(item) {
      await this.$store.dispatch('techroom/specialist/stockEquipment', item.id);
      if (this.$store.getters['techroom/specialist/getFloorId']) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorEquipments',
          this.$store.getters['techroom/specialist/getFloorId']
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressEquipments',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },
  async mounted() {
    if (this.$store.getters['techroom/specialist/getFloorId']) {
      await this.$store.dispatch(
        'techroom/specialist/requestFloorEquipments',
        this.$store.getters['techroom/specialist/getFloorId']
      );
    } else {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressEquipments',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    }
  },
};
</script>
<style></style>
