<template>
  <Loading v-show="loadingPage"></Loading>
  <section v-show="!loadingPage" class="page__calls calls">
    <div class="d-flex justify-space-between align-center mb-8">
      <h1 class="calls__title itroom__title">Лиды</h1>
      <v-tooltip text="Добавить лид">
        <template v-slot:activator="{ props }">
          <v-btn
            @click="openDialog"
            v-bind="props"
            class="account__add-tasks"
            color="var(--blue-0)"
            icon
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <ManagerCalls
      v-if="$store.getters.USER.selectedRole.role === 'MANAGER'"
      @loading="loadingPage = false"
    />
    <DirectorCalls
      v-if="$store.getters.USER.selectedRole.role === 'DIRECTOR'"
      @loading="loadingPage = false"
    />
  </section>
  <DialogCreateCall v-model="dialogCreate" @close="dialogCreate = false" />
</template>

<script>
import ManagerCalls from "@/components/app/itroom/manager/calls/ManagerCalls.vue";
import DirectorCalls from "@/components/app/itroom/director/calls/DirectorCalls.vue";

import DialogCreateCall from "@/components/app/itroom/DialogCreateCall.vue";

export default {
  components: { ManagerCalls, DirectorCalls, DialogCreateCall },
  data() {
    return {
      loadingPage: true,
      dialogCreate: false,
    };
  },
  methods: {
    async openDialog() {
      this.dialogCreate = true;
    },
  },
};
</script>

<style>
</style>