import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    leads: [],
  },
  getters: {
    getLeads(state) {
      return state.leads
    },

  },
  mutations: {
    updateLeads(state, leads) {
      state.leads = leads
    },
    updateNormalLeads(state) {
      state.leads = state.leads.filter(lead => !lead.delete)
      console.log(state.leads);
    },
    updateArchiveLeads(state) {
      state.leads = state.leads.filter(lead => lead.delete)
      console.log(state.leads);
    }
  },
  actions: {
    async requestLeads({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/leads`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result)
        commit('updateLeads', result.data.leads)
      } catch (error) {
        console.error(error)
      }
    },
    async createLead(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/lead`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            head: formData.head,
            phone: formData.phone.replace(/[^0-9]/g, ''),
            email: formData.email,
            description: formData.description,
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error);
        throw error
      }
    },
    async editLead(ctx, formData) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/lead/${formData.id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            head: formData.head,
            phone: formData.phone.replace(/[^0-9]/g, ''),
            email: formData.email,
            description: formData.description,
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error);
        throw error
      }
    },
    async removeLead(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/lead/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            deleteStatus: true
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },
    async sendMail(ctx, data) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/mail`,
          data: {
            email: data.email,
            text: data.text,
          }
        })
        console.log(result);
      } catch(error) {
        console.log(error);
      }
    },
    async restoreLead(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/lead/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            deleteStatus: false
          }
        })
        console.log(result);
        return true
      } catch (error) {
        console.error(error);
        return false
      }
    }
  }
}