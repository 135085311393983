<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Информация о клиенте</h2>
        <div>Название компании: {{ client.nameCompany }}</div>
        <div>ФИО: {{ client.individual }}</div>
        <div>Номер телефона: {{ $filters.filterNumber(client.phone) }}</div>
        <div>Договор: {{ client.dogovor }}</div>
        <div>
          Проекты:
          <div v-for="item in client.projectsClient" :key="item">
            <div>{{ item.nameProject }}</div>
          </div>
        </div>
        <div>
          <!-- <v-btn v-if="client.status === 'Активный'" @click="updateStatus"
            >Сменить статус на не активный</v-btn
          >
          <v-btn v-if="client.status === 'Не активный'" @click="updateStatus"
            >Сменить статус на активный</v-btn
          > -->
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
    },
  },
};
</script>