<template>
  <Loading v-if="loadingPage"></Loading>
  <section v-else class="page__create-project create-project">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="create-project__title itroom__title">Создать проект</h1>
    </div>
    <v-form ref="form" @submit.prevent="submitHandler">
      <v-text-field
        v-model="nameProject"
        label="Название проекта"
        required
        :rules="rules"
      ></v-text-field>

      <v-select
        v-model="clientId"
        :items="clients"
        label="Физическое лицо ФИО"
        :rules="rules"
      ></v-select>

      <v-text-field
        v-model="phone"
        label="Номер телефона"
        required
        maxlength="18"
        v-maska="'+7 (###) ###-##-##'"
        :rules="rules"
      ></v-text-field>

      <v-text-field
        v-model="nameCompany"
        label="Название компании"
        required
        :rules="rules"
      ></v-text-field>

      <v-text-field
        v-model="timeline"
        label="Сроки"
        required
        :rules="rules"
      ></v-text-field>

      <v-select
        v-model="teamLeadId"
        :items="teamLeads"
        label="Лидер проекта"
        :rules="rules"
      >
      </v-select>

      <v-text-field
        v-model="comment"
        label="Комментарий"
        required
        :rules="rules"
      ></v-text-field>

      <v-text-field
        v-model="description"
        label="Описание"
        required
        :rules="rules"
      ></v-text-field>

      <v-btn type="submit" color="success" class="mr-4"> Создать </v-btn>
    </v-form>
  </section>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      nameProject: null,
      clients: [],
      clientId: null,
      phone: null,
      nameCompany: null,
      timeline: null,
      teamLeads: [],
      teamLeadId: null,
      comment: null,
      description: null,
      loadingPage: true,

      rules: [(value) => !!value || "Обязательное поле"],
    };
  },
  validations() {
    return {
      formData: {
        nameProject: { required },
        clients: { required },
        phone: { required },
        nameCompany: { required },
        timeline: { required },
        teamLeads: { required },
        comment: { required },
        description: { required },
      },
    };
  },
  methods: {
    async submitHandler() {
      // if (this.v$.$invalid) {
      //   return;
      // }
      const formData = {
        nameProject: this.nameProject,
        phone: this.phone,
        nameCompany: this.nameCompany,
        timeline: this.timeline,
        comment: this.comment,
        description: this.description,
        teamLeadId: this.teamLeadId,
        clientId: this.clientId,
      };

      await this.$store.dispatch("itroom/manager/createProject", formData);
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/manager/requestTeamLeads");
    await this.$store.dispatch("itroom/manager/requestClients");
    const allTeamLeads = this.$store.getters["itroom/manager/getTeamLeads"];
    const allClients = this.$store.getters["itroom/manager/getClients"];
    allTeamLeads.forEach((e) => {
      this.teamLeads.push({
        title: e.name,
        value: e.id,
      });
    });
    allClients.forEach((e) => {
      this.clients.push({
        title: `${e.individual} (${e.nameCompany})`,
        value: e.id,
      });
    });
    setTimeout(() => {
      this.loadingPage = false;
    }, 500);
  },
};
</script>

<style>
</style>