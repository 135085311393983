<template>
  <v-table density="comfortable" class="style-scoped">
    <thead>
      <tr>
        <th class="text-left start">Контактное лицо</th>
        <th class="text-left">Номер телефона</th>
        <th class="text-left">Электронная почта</th>
        <th class="text-left">Название компании</th>
        <th class="text-left">Комментарий</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in $store.getters['techroom/manager/getLeads'].filter(
          (lead) => lead.delete === archive
        )"
        :key="item.id"
        @click="$emit('openDialogAddClient', item)"
      >
        <td class="td-item td-item-head start">
          <div>
            <svg v-if="!item.title" height="20" width="20" fill="#00f593">
              <path
                d="M10 9.75q-1.083 0-1.833-.76-.75-.761-.75-1.823 0-1.084.75-1.834T10 4.583q1.083 0 1.833.75t.75 1.834q0 1.062-.75 1.823-.75.76-1.833.76Zm-5.583 5.667v-1.521q0-.375.229-.729.229-.355.646-.625 1.083-.625 2.281-.959Q8.771 11.25 10 11.25q1.208 0 2.417.333 1.208.334 2.291.959.417.25.646.614.229.365.229.74v1.521ZM5.5 14.333h9v-.437q0-.125-.094-.24-.094-.114-.26-.198-.917-.541-1.969-.833-1.052-.292-2.177-.292t-2.177.292q-1.052.292-1.969.833-.166.125-.26.23-.094.104-.094.208ZM10 8.667q.625 0 1.062-.448.438-.448.438-1.073 0-.604-.438-1.042-.437-.437-1.062-.437t-1.062.437Q8.5 6.542 8.5 7.167t.438 1.062q.437.438 1.062.438Zm0-1.5Zm0 7.166Z"
              />
            </svg>
            <svg v-else height="20" width="20" fill="#f94c39">
              <path
                d="M.417 14.833v-1.104q0-.875.895-1.427.896-.552 2.313-.552.25 0 .458.01.209.011.396.052-.25.417-.406.865-.156.448-.156.969v1.187Zm5 0v-1.145q0-1.209 1.271-1.928 1.27-.718 3.312-.718 2.062 0 3.323.718 1.26.719 1.26 1.928v1.145Zm10.666 0v-1.187q0-.521-.145-.969-.146-.448-.417-.865.208-.041.417-.052.208-.01.437-.01 1.437 0 2.323.552.885.552.885 1.427v1.104ZM10 12.375q-1.167 0-2.031.292-.865.291-1.094.729v.104h6.25v-.104q-.229-.438-1.083-.729-.854-.292-2.042-.292Zm-6.417-1.187q-.625 0-1.062-.448-.438-.448-.438-1.052 0-.626.438-1.073.437-.448 1.062-.448t1.073.448q.448.447.448 1.073 0 .604-.448 1.052-.448.448-1.073.448Zm12.834 0q-.625 0-1.063-.448-.437-.448-.437-1.052 0-.626.437-1.073.438-.448 1.063-.448t1.062.448q.438.447.438 1.073 0 .604-.438 1.052-.437.448-1.062.448ZM10 10.417q-.896 0-1.531-.636-.636-.635-.636-1.531 0-.917.636-1.542.635-.625 1.531-.625.917 0 1.542.625t.625 1.542q0 .896-.625 1.531-.625.636-1.542.636Zm0-3q-.312 0-.573.239-.26.24-.26.594 0 .312.26.573.261.26.594.26.333 0 .573-.26.239-.261.239-.594 0-.312-.239-.562-.24-.25-.594-.25Zm0 6.083Zm0-5.25Z"
              />
            </svg>
            <span>
              {{
                $store.state.width > 1199.98 ? item.head : formatHead(item.head)
              }}
            </span>
          </div>
        </td>
        <td class="td-item td-item-phone">
          {{ $filters.filterNumber(item.phone) }}
        </td>
        <td class="td-item td-item-email" :class="{ empty: !item.email }">
          {{ item.email ? item.email : '- не указано -' }}
        </td>
        <td class="td-item td-item-title" :class="{ empty: !item.title }">
          {{ item.title ? item.title : '- не указано -' }}
        </td>
        <td v-if="!item.description" class="td-item td-item-comment empty">
          - не указано -
        </td>
        <td v-else class="td-item td-item-comment">
          <div>
            <v-btn
              v-if="count(item.description) > 50"
              variant="text"
              size="x-small"
              :value="item.id"
              @click="handlerComment($event)"
            >
              Посмотреть больше
              <svg height="20" width="20">
                <path
                  d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"
                />
              </svg>
            </v-btn>
            <v-btn
              v-if="count(item.description) > 50"
              variant="text"
              size="x-small"
              class="manage-item-more manage-item fill-height"
              :value="item.id"
              @click="$emit('openDialogShowCommentLead', item)"
            >
              <svg height="20" width="20">
                <path
                  d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h4.25v.584h-4.25q-.187 0-.354.166-.166.167-.166.354v9.876q0 .187.166.354.167.166.354.166h9.876q.187 0 .354-.166.166-.167.166-.354v-4.25h.584v4.25q0 .479-.313.791-.312.313-.791.313Zm3.063-3.709-.458-.458 7.354-7.333h-3.125v-.584h4.146v4.146h-.584V4.979Z"
                />
              </svg>
            </v-btn>
          </div>
          {{ item.description }}
        </td>
        <td class="text-right">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                size="small"
                class="setting_btn"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list v-if="archive" class="style-scoped">
              <v-list-item
                min-height="1"
                value="2"
                class="list_item"
                @click="$emit('restore', item)"
              >
                <svg fill="#00f593" height="20" width="20">
                  <path
                    d="M6.333 15.583v-.666h5.584q1.229 0 2.073-.844.843-.844.843-2.073t-.843-2.073q-.844-.844-2.073-.844H5.792l2.354 2.355-.479.479L4.5 8.75l3.167-3.167.479.479-2.354 2.355h6.125q1.479 0 2.531 1.041Q15.5 10.5 15.5 12q0 1.5-1.052 2.542-1.052 1.041-2.531 1.041Z"
                  />
                </svg>
                <v-list-item-title class="button_title">
                  Восстановить
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else class="style-scoped">
              <v-list-item
                min-height="1"
                value="2"
                class="list_item"
                @click="$emit('openDialogEdit', item)"
              >
                <svg fill="#fb9209" height="20" width="20">
                  <path
                    d="M4.583 15.667h.896l8.083-8.084-.895-.916-8.084 8.083Zm10.771-8.625-2.125-2.167.479-.5q.417-.396.969-.396t.969.396l.583.583q.229.25.209.636-.021.385-.25.635Zm-.542.541-8.958 8.938H3.729v-2.146l8.938-8.917Zm-1.687-.458-.458-.458.895.916Z"
                  />
                </svg>
                <v-list-item-title class="button_title">
                  Изменить
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.clientId"
                min-height="1"
                value="2"
                class="list_item"
                @click="$emit('restoreClient', item.clientId, index)"
              >
                <svg fill="#00f593" height="20" width="20">
                  <path
                    d="M11.708 15.833H5.854v-1.75h5.854q1.292 0 2.25-.791.959-.792.959-2.021 0-1.229-.959-2.031-.958-.802-2.25-.802H6.667l2.125 2.124-1.23 1.23-4.229-4.23 4.229-4.229 1.23 1.229-2.125 2.126h5.041q2.021 0 3.49 1.302 1.469 1.302 1.469 3.26 0 1.958-1.469 3.271-1.469 1.312-3.49 1.312Z"
                  />
                </svg>
                <v-list-item-title class="button_title">
                  Восстановить клиента
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                min-height="1"
                value="2"
                class="list_item"
                
              >
                <svg fill="#00f593" height="20" width="20">
                  <path
                    d="M9.708 14.479v-4.187H5.521v-.604h4.187V5.5h.604v4.188H14.5v.604h-4.188v4.187Z"
                  />
                </svg>
                <v-list-item-title class="button_title">
                  Загрузить договор
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                min-height="1"
                value="2"
                class="list_item"
                @click="$emit('openDialogRemoveLead', item)"
              >
                <svg fill="#f94c39" height="20" width="20">
                  <path
                    d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z"
                  />
                </svg>
                <v-list-item-title class="button_title">
                  Перенести в архив
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  props: {
    archive: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    count(str) {
      if (str) {
        return str.length;
      }
    },
    formatHead(head) {
      const r = head.split(' ');
      if (r.length === 3) {
        return `${r[2]} ${r[0].charAt()}. ${r[1].charAt()}.`;
      } else if (r.length === 2) {
        return `${r[0]} ${r[1].charAt()}`;
      } else {
        return `${r[0]}`;
      }
    },
    handlerComment(event) {
      const td = event.target.parentElement.parentElement;
      const tdRow = event.target.parentElement.parentElement.parentElement;
      if (event.target.children[2].innerHTML.includes('Свернуть комментарий')) {
        console.log('hide');
        td.classList.remove('more');
        event.target.children[2].innerHTML = '';
        event.target.children[2].insertAdjacentHTML(
          'beforeend',
          `Посмотреть больше
          <svg height="20" width="20">
            <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          _this.classList.remove('top');
        }
      } else {
        td.classList.add('more');
        console.log(event.target.children);
        event.target.children[2].innerHTML = '';
        event.target.children[2].insertAdjacentHTML(
          'beforeend',
          `Свернуть комментарий
          <svg height="20" width="20">
            <path d="m6.104 12.396-.458-.458L10 7.604l4.354 4.334-.458.458L10 8.479Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          if (!_this.classList.contains('td-item-comment')) {
            _this.classList.add('top');
          }
        }
      }
    },
  },
};
</script>
