<template>
  <v-dialog fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <!-- Шапка -->
      <HeaderView :date="date" @closeOverView="closeOverView"/>


      <!-- Фильтры -->
      <!-- <FiltersTasks @Success="Success" /> -->


      <!-- Обращения -->
      <OrganizationTasks :date="date"/>
      
    </v-card>
  </v-dialog>
</template>
<script>
// import FiltersTasks from './elements/FiltersTasks.vue'
import HeaderView from './elements/HeaderView.vue'
import OrganizationTasks from './elements/OrganizationTasks.vue'
export default {
  name: "DialogOverViewAppeals",

  components: { /*FiltersTasks*/ HeaderView, OrganizationTasks },

  props: {
    date: String,
  },

  

  data() {
    return {
      tasks: null,
    };
  },



  methods: {
  
// ============  Filters Logic  ==============
    

    // =========  Emits Logic  =====
    closeOverView() {
      this.$emit("closeOverView");
    },
    Success(){
      console.log('success')
     return this.appeals.filter(item=> item.status === 2)
    }
  },
};
</script>
<style lang="scss" scoped></style>