<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование лида</div>
        <v-form ref="form" class="form_cabinet" @submit.prevent="editLead">
          <v-select
            class="text_field_input"
            v-model="typeLead"
            :items="typesLeads"
            label="Выберите тип"
          ></v-select>
          <v-text-field
            v-if="typeLead === 'Юридическое лицо'"
            class="text_field_input"
            v-model="formData.title"
            label="Название компании"
            :rules="[() => !!formData.title || 'Обязательно']"
            required
          ></v-text-field>
          <v-text-field
            class="text_field_input"
            v-model="formData.head"
            label="Контактное лицо"
            :rules="[() => !!formData.head || 'Обязательно']"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            maxlength="18"
            v-model="formData.phone"
            label="Номер телефона"
            v-maska="'+7 (###) ### ##-##'"
            :rules="[
              () => !!formData.phone || 'Обязательное поле',
              () => formData.phone.length === 18 || 'Не верный формат телефона',
            ]"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            v-model="formData.email"
            :rules="[
              () =>
                !formData.email ||
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) ||
                'Неверный формат электронной почты',
            ]"
            label="Почта"
            maxlength="100"
          >
          </v-text-field>
          <v-textarea
            class="text_field_input"
            v-model="formData.description"
            label="Описание звонка"
          >
          </v-textarea>
          <v-btn
            variant="outlined"
            type="submit"
            class="cabinet_btn"
            @click="validate($event)"
          >
            Редактировать
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    lead: {
      type: Object,
    },
  },
  data() {
    return {
      typesLeads: ["Физическое лицо", "Юридическое лицо"],
      typeLead: null,
    };
  },
  computed: {
    formData() {
      return this.lead;
    },
  },
  watch: {
    lead() {
      this.lead.title
        ? (this.typeLead = "Юридическое лицо")
        : (this.typeLead = "Физическое лицо");
    },
    typeLead() {
      if (this.typeLead === "Физическое лицо") this.formData.title = null;
    },
  },
  methods: {
    async editLead() {
      try {
        await this.$store.dispatch("techroom/manager/editLead", this.formData);
        this.$emit("close");
      } catch (error) {
        console.error(error);
      }
    },
    async validate(e) {
      let a = await this.$refs.form.validate();
      if (!a.valid) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_content {
  border: 2px solid #ff5757;
  width: 60vw !important;
}
.form_cabinet {
  align-items: unset;
  justify-content: unset;
  display: grid !important;
  grid-template: repeat(5, auto) / repeat(2, auto);
  gap: rem(24);

  .v-select {
    width: 100%;
    grid-area: 1 / 1 / 2 / 3;
  }

  .v-textarea {
    width: 100%;
    grid-area: 4 / 1 / 5 / 3;
  }

  .v-btn {
    width: 100%;
    grid-area: 5 / 1 / 6 / 3;
    background-color: unset;
    color: #ff5757;
    border-radius: 0;
  }
}
@media screen and (max-width: $medium) {
  .dialog_content {
    width: 90vw !important;
  }
  .form_cabinet {
    display: block !important;
  }
  .text_field_input {
    margin-bottom: 20px;
  }
}
</style>