<template>
  <div class="header__block">
    <div class="item_header">
      <div class="page_menu_title">
        <svg height="22" width="22" viewBox="0 0 24 24">
          <path
              d="M2.6 18.625v-1.65q0-.7.363-1.2.362-.5.962-.8 1.3-.625 2.588-.987 1.287-.363 3.087-.363 1.8 0 3.088.363 1.287.362 2.587.987.6.3.963.8.362.5.362 1.2v1.65Zm16 0v-1.7q0-.875-.35-1.65-.35-.775-1-1.325.725.15 1.413.425.687.275 1.362.6.65.325 1.013.837.362.513.362 1.113v1.7Zm-9-7.25q-1.25 0-2.125-.875T6.6 8.375q0-1.225.875-2.113.875-.887 2.125-.887 1.225 0 2.113.887.887.888.887 2.113 0 1.25-.887 2.125-.888.875-2.113.875Zm7.275-3q0 1.25-.887 2.125-.888.875-2.113.875-.075 0-.175-.012-.1-.013-.15-.013.5-.625.775-1.388.275-.762.275-1.587 0-.8-.288-1.55-.287-.75-.762-1.4.075-.025.15-.038.075-.012.175-.012 1.225 0 2.113.887.887.888.887 2.113ZM3.6 17.625h12v-.65q0-.35-.175-.612-.175-.263-.625-.513-1.125-.6-2.362-.912-1.238-.313-2.838-.313t-2.837.313q-1.238.312-2.363.912-.45.25-.625.513-.175.262-.175.612Zm6-7.25q.825 0 1.413-.588.587-.587.587-1.412t-.587-1.412q-.588-.588-1.413-.588-.825 0-1.412.588Q7.6 7.55 7.6 8.375t.588 1.412q.587.588 1.412.588Zm0 7.25Zm0-9.25Z" />
        </svg>
        <span>Пользователи</span>
      </div>
      <div class="d-flex justify-space-around align-center flex-column flex-md-row">
        <v-btn @click="openDialogAddUser()" class="button-add" color="#ff5757" icon="mdi-plus"></v-btn>
      </div>
    </div>
  </div>
  <v-table density="comfortable">
    <thead>
    <tr>
      <th class="text-left">Фамилия</th>
      <th class="text-left">Имя</th>
      <th class="text-left">Отчество</th>
      <th class="text-left">Дата рождения</th>
      <th class="text-left">Эл. почта</th>
      <th class="text-left">Телефон</th>
      <th class="text-left">Логин</th>
      <th class="text-left">Роли</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in $store.getters['techroom/director/getTechUsers']"
        :key="item.index">
      <td class="td-item">{{ item.surname }}</td>
      <td class="td-item">{{ item.name }}</td>
      <td class="td-item">{{ item.lastName }}</td>
      <td class="td-item">{{ $filters.filterDate(item.dateBirth) }}</td>
      <td class="td-item">{{ item.email }}</td>
      <td class="td-item">{{ $filters.filterNumber(item.phone) }}</td>
      <td class="td-item">{{ item.login }}</td>
      <td class="td-item">
        <div v-for="(role, index) in item.roles" :key="index">
          {{ $filters.filterRole(role.name) }}
        </div>
      </td>
      <td class="text-right">
<!--        <v-menu>-->
<!--          <template v-slot:activator="{ props }">-->
<!--            <v-btn v-bind="props" variant="text" size="small" class="setting_btn">-->
<!--              <v-icon>mdi-dots-vertical</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <v-list class="style-scoped">-->
<!--            <v-list-item min-height="1" value="2" class="list_item" @click="openDialogEditUser(item)">-->
<!--              <svg fill="#fb9209" height="20" width="20">-->
<!--                <path-->
<!--                    d="M4.583 15.667h.896l8.083-8.084-.895-.916-8.084 8.083Zm10.771-8.625-2.125-2.167.479-.5q.417-.396.969-.396t.969.396l.583.583q.229.25.209.636-.021.385-.25.635Zm-.542.541-8.958 8.938H3.729v-2.146l8.938-8.917Zm-1.687-.458-.458-.458.895.916Z" />-->
<!--              </svg>-->
<!--              <v-list-item-title class="button_title">Редактировать</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item min-height="1" value="2" class="list_item">-->
<!--              <svg fill="#f94c39" height="20" width="20">-->
<!--                <path-->
<!--                    d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z" />-->
<!--              </svg>-->
<!--              <v-list-item-title class="button_title" @click="moveArchiveAppeal(item.id)">Перенести в-->
<!--                архив</v-list-item-title>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->
      </td>
    </tr>
    </tbody>
  </v-table>
  <DialogAddUser v-model="dialogAddUser" @close="dialogAddUser = false"></DialogAddUser>
  <DialogEditUser :user="user" v-model="dialogEditUser" @close="dialogEditUser = false"></DialogEditUser>
</template>

<script>
import DialogAddUser from "@/components/app/techroom/director/users/DialogAddUser";
import DialogEditUser from "@/components/app/techroom/director/users/DialogEditUser";
export default {
  components: {DialogAddUser, DialogEditUser},
  name: "Users",
  data() {
    return {
      dialogAddUser: false,
      dialogEditUser: false,
    }
  },
  methods: {
    async openDialogAddUser() {
      await this.$store.dispatch('techroom/director/requestRoles');
      this.dialogAddUser = true;
    },
    async openDialogEditUser(item) {
      this.user = item;
      this.user.roles.filter(item => {
        item.name = this.$filters.filterRole(item.name)
      })
      await this.$store.dispatch('techroom/director/requestRoles');
      this.dialogEditUser = true;
    },
  },
  async mounted() {
    await this.$store.dispatch('techroom/director/requestUsers');
  },
}
</script>

<style scoped>

</style>