<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Информация о звонке</h2>
        <div>Название компании: {{ call.nameCompany }}</div>
        <div>ФИО: {{ call.individual }}</div>
        <div>Номер телефона: {{ $filters.filterNumber(call.phone) }}</div>
        <div>Комментарий: {{ call.comment }}</div>
        <!-- <div>
          Менеджер:
          {{ `${call.user.surname} ${call.user.name} ${call.user.lastName}` }}
        </div> -->
        <v-file-input
          v-model="file"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          accept=".doc,.docx,.pdf"
          label="Загрузить договор"
          hide-details="true"
        ></v-file-input>
        <v-btn v-if="file.length" @click="sendFile">Отправить</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    call: {
      type: Object,
    },
  },
  data() {
    return {
      file: [],
    };
  },
  methods: {
    async sendFile() {
      await this.$store.dispatch("itroom/director/sendFile", {
        file: this.file[0],
        id: this.call.id,
      });

      this.$emit("close");
    },
  },
};
</script>