import { account } from "./account"
import { developer } from "./developer"
import { manager } from "./manager"
import { teamlead } from "./teamlead"
import { director } from "./director"

export const itroom = {
  namespaced: true,
  modules: {
    account,
    developer,
    manager,
    teamlead,
    director,
  }
}