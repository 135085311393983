<template>
  <div>
    <div class="header__block">
      <div class="item_header">
        <div class="page_menu_title">
          <svg height="22" width="22" viewBox="0 0 24 24">
            <path
              d="M12 11.375q-1.25 0-2.125-.875T9 8.375q0-1.225.875-2.113.875-.887 2.125-.887t2.125.887Q15 7.15 15 8.375q0 1.25-.875 2.125T12 11.375Zm-7 7.25v-1.65q0-.625.363-1.163.362-.537.962-.837 1.425-.675 2.838-1.013 1.412-.337 2.837-.337 1.425 0 2.838.337 1.412.338 2.837 1.013.6.3.963.837.362.538.362 1.163v1.65Zm1-1h12v-.65q0-.35-.212-.638-.213-.287-.588-.487-1.25-.6-2.562-.912-1.313-.313-2.638-.313t-2.637.313q-1.313.312-2.563.912-.375.2-.587.487-.213.288-.213.638Zm6-7.25q.825 0 1.413-.588Q14 9.2 14 8.375t-.587-1.412q-.588-.588-1.413-.588-.825 0-1.412.588Q10 7.55 10 8.375t.588 1.412q.587.588 1.412.588Zm0-2Zm0 9.25Z"
            />
          </svg>
          Планы
        </div>
        <div
          class="d-flex justify-space-around align-center flex-column flex-md-row"
        >
          <v-btn
            @click="openDialogTask()"
            class="button-add"
            color="#ff5757"
            icon="mdi-plus"
          />
        </div>
      </div>
      <div class="item_header">
        <div class="page_menu_title">
          <router-link to="/techroom/plans" class="v-navigation-icon">
            Задачи
          </router-link>
          <router-link to="/techroom/plans/calendar" class="v-navigation-icon">
            Календарь
          </router-link>
          <router-link to="/techroom/plans/archive" class="v-navigation-icon">
            Архив
          </router-link>
        </div>
      </div>
    </div>
    <div class="account__page">
      <div class="plans">
        <v-btn @click="changeDay(-7)" class="back-week elevation-1">
          <v-icon icon="mdi-arrow-left" />
        </v-btn>
        <v-btn @click="changeDay(7)" class="forward-week elevation-1">
          <v-icon icon="mdi-arrow-right" />
        </v-btn>
        <div class="plans__week">
          <v-card
            class="d-flex flex-column week_item"
            v-for="day in days"
            :key="day.value"
            :style="isDayBefore(day.value) ? 'opacity: 50% !important' : ''"
            @click="
              !$event.target.closest('.chip-delete')
                ? dayHandler(new Date(day.value).toLocaleDateString(), day)
                : null
            "
            @drop="onDrop($event, day.value)"
            @dragover.prevent
            @dragenter.prevent
          >
            <template v-slot:subtitle>
              <span
                v-if="_isToday(day.value)"
                :style="_isToday(day.value) ? 'color: #E53E3E;' : ''"
              >
                Текущий день
              </span>
              <div>
                {{
                  new Date(day.value).toLocaleString('ru-RU', {
                    weekday: 'long',
                  })
                }}
              </div>
              <div>{{ new Date(day.value).toLocaleDateString() }}</div>
            </template>
            <template
              v-if="
                store.getters.USER?.selectedRole.role === 'MANAGER' ||
                store.getters.USER?.selectedRole.role === 'DIRECTOR'
              "
            >
              <div class="week_item_plans">
                <v-tooltip
                  v-for="item in store.getters[
                    'techroom/manager/getPlanTask'
                  ].filter((i) => String(i.date) === String(day.value))"
                  :key="item.id"
                  :text="item.description || 'Описания нет'"
                  location="top"
                  class="tooltip"
                >
                  <template v-slot:activator="{ props }">
                    <v-chip
                      draggable
                      v-bind="props"
                      :color="getValueChips(item.status).color"
                      @dragstart="onDragStartTask($event, item.id)"
                      :class="getType(item.type)?.class"
                    >
                      {{ item.name }}
                      <v-icon
                        class="chip-delete"
                        icon="mdi-delete"
                        @click="onToEmpty(item.id)"
                      ></v-icon>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
            </template>
            <template v-else>
              <div class="week_item_plans">
                <v-tooltip
                  v-for="item in store.getters[
                    'techroom/manager/getPlanTask'
                  ].filter((i) => String(i.date) === String(day.value))"
                  :key="item.id"
                  :text="item.description ? item.description : 'Описания нет'"
                  location="top"
                  class="tooltip"
                >
                  <template v-slot:activator="{ props }">
                    <v-chip
                      v-bind="props"
                      :color="getValueChips(item.status).color"
                      :class="getType(item.type)?.class"
                    >
                      {{ item.name }}
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-card>
        </div>
        <v-card v-if="store.getters['techroom/manager/getPlanTask'].filter((i) => i.date === null)[0]"
          variant="tonal"
          class="plans__task"
          @drop="onDropToEmpty($event)"
          @dragover.prevent
          @dragenter.prevent
        >
          <template
            v-if="
              store.getters.USER?.selectedRole.role === 'MANAGER' ||
              store.getters.USER?.selectedRole.role === 'DIRECTOR'
            "
          >
            <div
              class="plan_delete_block"
              @drop.stop="onDropDeletePlan($event, (trash = false))"
              @dragenter.prevent="trash = true"
              @dragleave.prevent="trash = false"
              @mouseenter="trash = true"
              @mouseleave="trash = false"
              @click="clickDeleteTask"
            >
              <v-icon size="32" v-if="trash" color="red">
                mdi-delete-empty
              </v-icon>
              <v-icon size="32" v-if="trash === false"> mdi-delete </v-icon>
            </div>

            <v-chip
              draggable
              v-for="task in store.getters[
                'techroom/manager/getPlanTask'
              ].filter((item) => !item.date)"
              :key="task.id"
              :color="getValueChips(task.status).color"
              :class="getType(task.type)?.class"
              @dragstart="onDragStartTask($event, task.id)"
              @click="test($event, task.id)"
            >
              {{ task.name }}
            </v-chip>
          </template>
          <template v-else>
            <v-chip
              v-for="task in store.getters[
                'techroom/manager/getPlanTask'
              ].filter((item) => !item.date)"
              :key="task.id"
              :color="getValueChips(task.status).color"
              :class="getType(task.type)?.class"
            >
              {{ task.name }}
            </v-chip>
          </template>
        </v-card>
        <div v-else>Нет задач</div>
      </div>
      <DialogAddTask
        :personal="false"
        v-model="dialogTask"
        @close="dialogTask = false"
      />
      <DialogViewTasks
        v-model="dialogView"
        @closeOverView="dialogView = false"
        :date="selectedDate"
      />
      <v-snackbar v-model="snackbar" timeout="3000">
        {{ message }}
        <template v-slot:actions>
          <v-btn
            color="var(--red-0)"
            variant="text"
            size="small"
            @click="snackbar = false"
          >
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { wsUrl } from '@/store';

const store = useStore();
const tasks = ref([]);

let selected = [];
let selectedDate = ref(new Date().toLocaleDateString());
let dialogView = ref(false);
let snackbar = ref(false);
let message = ref('');
const myWs = new WebSocket(wsUrl);

onMounted(async () => {
  myWs.onopen = function () {
    console.log('подключился');
  };

  myWs.onmessage = async function (message) {
    if (message.data === 'task') {
      await store.dispatch('techroom/manager/requestPlanTask');
    }
  };

  await store.dispatch('techroom/manager/requestPlanTask');
  await store.dispatch('techroom/director/requestRoles');
  await store.dispatch('techroom/manager/requestSpecialists');
  tasks.value = store.getters['techroom/manager/getPlanTask'];
});

onUnmounted(() => {
  myWs.close();
  console.log('отключился');
});

function onDragStartTask(e, task) {
  if (selected.length === 0) {
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('task', JSON.stringify(task));
  } else {
    e.preventDefault();
  }
}

function test(event, taskID) {
  if (event.target.classList.contains('active')) {
    event.target.classList.remove('active');
    for (var i = 0; i < selected.length; i++) {
      if (selected[i] === taskID) {
        selected.splice(i, 1);
        i--;
      }
    }
    if (selected.length <= 0) {
      document.querySelector(`.button-add`).classList.remove('blur');
    }
  } else {
    event.target.classList.add('active');
    selected.push(taskID);
    if (selected.length > 0) {
      document.querySelector(`.button-add`).classList.add('blur');
    }
  }
}

function clickDeleteTask(taskID) {
  if (selected.length > 0) {
    for (var i = 0; i < selected.length; i++) {
      store.dispatch('techroom/manager/deletePlanTask', selected[i]);
      selected.splice(i, 1);
      i--;
    }
    setTimeout(() => {
      myWs.send(JSON.stringify({ event: 'task' }));
    }, 500);
    if (selected.length <= 0) {
      document.querySelector(`.button-add`).classList.remove('blur');
    }
  }
}

async function dayHandler(date, day) {
  if (selected.length === 0) {
    selectedDate.value = date;
    const selectedDayTasks = store.getters['techroom/manager/getPlanTask'].find(
      (item) => item.date == day.value
    );
    if (selectedDayTasks) {
      dialogView.value = true;
    }
  } else {
    if (
      new Date(day.value).getTime() < new Date().getTime() &&
      new Date(day.value).getDate() !== new Date().getDate()
    ) {
      snackbar.value = true;
      message.value = 'Задачу на предыдущий день назначить нельзя';
    } else {
      selected.forEach(async (i) => {
        await store.dispatch('techroom/manager/editPlanTask', {
          id: i,
          date: day.value,
          statusId: 2,
        });
        for (var t = 0; t < selected.length; t++) {
          if (selected[t] === i) {
            selected.splice(t, 1);
            t--;
          }
        }
        if (selected.length <= 0) {
          document.querySelector(`.button-add`).classList.remove('blur');
        }
      });
      setTimeout(() => {
        myWs.send(JSON.stringify({ event: 'task' }));
      }, 500);
    }
  }
}

async function onToEmpty(taskID) {
  if (taskID) {
    await store.dispatch('techroom/manager/editPlanTask', {
      id: taskID,
      date: null,
    });
    setTimeout(() => {
      myWs.send(JSON.stringify({ event: 'task' }));
    }, 500);
  }
}

async function onDrop(e, date) {
  const itemId = parseInt(e.dataTransfer.getData('task'));
  if (itemId) {
    if (
      new Date(date).getTime() < new Date().getTime() &&
      new Date(date).getDate() !== new Date().getDate()
    ) {
      snackbar.value = true;
      message.value = 'Задачу на предыдущий день назначить нельзя';
      e.preventDefault();
      e.stopPropagation();
    } else {
      store.getters['techroom/manager/getPlanTask'].map((item) => {
        if (item.id == itemId) {
          item.date = date;
        }
        return item;
      });
      await store.dispatch('techroom/manager/editPlanTask', {
        id: itemId,
        date,
        statusId: 2,
      });
      setTimeout(() => {
        myWs.send(JSON.stringify({ event: 'task' }));
      }, 500);
    }
  }
}

async function onDropDeletePlan(e) {
  const itemId = parseInt(e.dataTransfer.getData('task'));
  if (itemId) {
    await store.dispatch('techroom/manager/deletePlanTask', itemId);
    setTimeout(() => {
      myWs.send(JSON.stringify({ event: 'task' }));
    }, 500);
  }
}

async function onDropToEmpty(e) {
  const itemId = parseInt(e.dataTransfer.getData('task'));
  if (itemId) {
    store.getters['techroom/manager/getPlanTask'].map((item) => {
      if (item.id == itemId) {
        item.date = null;
      }
      return item;
    });
    await store.dispatch('techroom/manager/editPlanTask', {
      id: itemId,
      date: null,
    });
    setTimeout(() => {
      myWs.send(JSON.stringify({ event: 'task' }));
    }, 500);
  }
}
</script>

<script>
import DialogAddTask from '@/components/app/techroom/manager/plans/DialogAddTask.vue';
import DialogViewTasks from '@/components/app/techroom/specialist/tasks/DialogViewTasks.vue';

import {
  getHourlessDate,
  getLocaleTime,
  isToday,
  isBefore,
} from '@/utils/weekly-calendar';

export default {
  data() {
    return {
      testf: false,
      trash: false,
      dialogTask: false,
      selectedTasks: [],
      days: [],
      currentDay: getHourlessDate(),
    };
  },
  components: {
    DialogAddTask,
    DialogViewTasks,
  },
  methods: {
    getType(item) {
      switch (item) {
        case 1:
          item = null;
          break;
        case 2:
          item = {
            class: 'danger__task',
          };
          break;
      }
      return item;
    },
    getValueChips(item) {
      switch (item) {
        case 0:
          item = {
            title: 'В обработке',
            color: 'gray',
          };
          break;
        case 1:
          item = {
            title: 'Просрочено',
            color: 'red',
          };
          break;
        case 2:
          item = {
            title: 'Утверждение',
            color: 'gray',
          };
          break;
        case 3:
          item = {
            title: 'В процессе',
            color: '#F4A900',
          };
          break;
        case 4:
          item = {
            title: 'Готово',
            color: 'green',
          };
          break;
      }
      return item;
    },
    constructWeek() {
      const date = this.currentDay;
      this.getDays(date);
    },
    getDays(dayString) {
      let date = new Date(`${dayString}`);
      // let dayOfWeek = date.getUTCDay() - 1;
      let days = [];
      for (let idx = 0; idx < 7; idx++) {
        days.push({
          value: this.addDays(date, idx).toISOString().slice(0, 10),
          index: idx,
        });
      }
      console.log(days);
      this.days = days;
    },
    addDays(date, days) {
      let dateObj = new Date(date);
      dateObj.setUTCHours(0, 0, 0, 0);
      dateObj.setDate(dateObj.getDate() + days);
      return dateObj;
    },
    _isToday(day) {
      return isToday(getLocaleTime(day));
    },
    isDayBefore(day) {
      let now = getHourlessDate();
      return isBefore(day, now);
    },
    changeDay(numDays) {
      this.currentDay = this.addDays(this.currentDay, numDays)
        .toISOString()
        .slice(0, 10);
      this.constructWeek();
    },
    openDialogTask() {
      this.dialogTask = true;
    },
  },
  created() {
    this.constructWeek();
  },
};
</script>

<style lang="scss">
.account__page {
  position: relative;
}
.danger__task {
  -webkit-animation: pulsate 1.5s linear infinite;
  animation: pulsate 1.5s linear infinite;
}
@keyframes pulsate {
  50% {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(250, 50, 50, 1) inset;
    -moz-box-shadow: 0px 0px 10px 0px rgba(250, 50, 50, 1) inset;
    box-shadow: 0px 0px 10px 0px rgba(250, 50, 50, 1) inset;
    transform: scale(1.02);
  }
}

.plans {
  display: flex;
  flex-direction: column;
  gap: rem(35);
  padding-top: rem(60);

  @media screen and (max-width: $large) {
    padding-left: 0;
    padding-right: 0;
  }

  .back-week {
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .forward-week {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.plans__week {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(24);

  @media screen and (max-width: $medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $small) {
    grid-template-columns: repeat(1, 1fr);
  }

  .week_item {
    gap: rem(8);
    padding: rem(16);

    &_plans {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 180px;
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(3, auto);

      @media screen and (max-width: $medium) {
        grid-template-columns: repeat(2, auto);
      }
      .v-chip {
        position: relative;
        padding-right: 20px;

        .chip-delete {
          position: absolute;
          right: 2px;

          &:hover {
            color: var(--red-0);
            cursor: pointer;
          }
        }
      }
    }

    .v-chip {
      max-width: 100%;
      height: auto;
      min-height: 32px;
      white-space: pre-wrap;
      // word-break: break-all;
      border-radius: 0;
    }

    .v-card-item {
      padding: 0 !important;
    }
  }
}

.plans__task {
  padding: rem(20);
  display: flex;
  flex-wrap: wrap;
  gap: rem(18);
  align-content: flex-start;

  .v-chip {
    transition: 0.14s ease;

    &.active {
      margin: 0px 5px;
      transition: 0.28s ease;
      transform: scale(1.2);
    }
  }
}
.plans__day {
  flex: 1;
  border: 1px solid;

  &.active {
    border: 1px solid red;
  }
}

.plan_delete_block {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  cursor: pointer;
}

.v-card-subtitle {
  opacity: 100% !important;
}

@media screen and (max-width: $small) {
  .plans__week {
    flex-direction: column;
  }

  .week_item {
    width: 100%;
  }
}
</style>
