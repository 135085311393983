<template>
  <v-layout>
    <v-navigation-drawer>
      <div class="v-navigation-icon">
        <svg viewBox="0 0 363 226" width="150" height="150">
          <g id="Layer">
            <path
              id="Layer"
              fill-rule="evenodd"
              fill="#000000"
              d="m232.6 102.2c0.4 1.2 0 2.4-0.4 3.2-0.3 1.1-1.5 1.5-2.3 1.9-0.4 0.4-0.8 0.4-1.2 0.8l-46.8 26.9c-0.4 0-0.8 0.4-1.2 0.4-0.7 0.8-1.5 1.2-2.7 1.2-1.2 0-2-0.4-2.7-1.2-0.4 0-0.8 0-1.2-0.4l-46.8-26.9c-0.4-0.4-0.8-0.4-1.2-0.8-0.8-0.4-2-1.2-2.3-1.9-0.4-1.2-0.8-2.4-0.4-3.2 0-0.4 0-54.6 0-55q0-0.6 0-1.2c-0.4-1.1 0-2.3 0.4-3.1 0.3-0.8 1.5-1.5 2.3-1.9 0.4 0 0.8-0.4 1.2-0.8l46.8-26.9c0.4 0 0.8-0.4 1.2-0.4 0.7-0.8 1.9-1.2 2.7-1.2 0.8 0 2 0.4 2.3 1.2 0.4 0 0.8 0.4 1.2 0.4l46.8 26.9c0.4 0.4 0.8 0.4 1.2 0.8 0.8 0.4 2 1.1 2.4 1.9 0.3 1.2 0.7 2.4 0.3 3.1 0 0.4 0 54.7 0 55.1 0 0.4 0 0.7 0.4 1.1zm-50.7-59.7l21.5 12.5 17.5-10.1-39-22.7zm-46.8 2.4l17.5 10.1 21.5-12.5v-20.3zm-3.9 51.5l17.6-10.1v-24.6l-17.6-10.1zm42.9 9.3l-21.4-12.5-17.6 10.2 39 22.6zm0-29.2l-17.5-10.2v20.3l17.5 10.2zm21.5-17.2l-17.6-10.2-17.5 10.2 17.5 10.1zm-14 17.2v20.3l17.5-10.2v-20.2zm39.5 26.9l-17.6-10.2-21.5 12.5v20.3zm3.5-51.5l-17.5 10.1v24.6l17.5 10.2z"
            />
          </g>
          <g id="Layer">
            <path
              id="Layer"
              fill-rule="evenodd"
              fill="#5272ff"
              d="m4 213.3v-34.2l14.6-14.7h29.3v48.9h-9.7v-14.6h-24.4v14.6zm34.2-24.4v-14.8h-14.7l-9.7 9.9v4.9z"
            />
            <path
              id="Layer"
              fill="#5272ff"
              d="m59.7 144.9h9.8v6.3h14.7v-6.3h9.8v9.7l-4.9 4.9h-24.4l-5-4.9zm-4.8 68.5v-49h9.8v32.6l24.4-32.6h9.7v49h-9.7v-32.7l-24.4 32.7z"
            />
            <path
              id="Layer"
              fill="#5272ff"
              d="m120.8 213.3v-39.2h-17.1v-9.7h43.9v9.7h-17v39.2z"
            />
            <path
              id="Layer"
              fill="#5272ff"
              d="m152.5 213.3v-48.9h9.8v32.5l24.4-32.5h9.7v48.9h-9.7v-32.7l-24.4 32.7z"
            />
          </g>
          <g id="Layer">
            <path
              id="Layer"
              fill-rule="evenodd"
              fill="#000000"
              d="m205.7 213.3v-48.9h39.1l4.8 4.9v24.5l-4.8 4.9h-29.3v14.6zm34.2-24.4v-14.8h-24.4v14.8z"
            />
            <path
              id="Layer"
              fill="#000000"
              d="m258.6 203.5h19.6l9.8-9.7h-19.6l-14.6-14.7v-14.7h9.8v9.8l9.7 9.8h14.7v-19.6h9.7v34.3l-14.6 14.6h-24.5z"
            />
            <path
              id="Layer"
              fill="#000000"
              d="m304 213.3v-48.9h9.8l17 23.6 17.4-23.6h9.4v48.9h-9.8v-33l-12.8 18.8h-8.1l-13.1-18.8v33z"
            />
          </g>
        </svg>
      </div>

      <v-list>
        <v-list-item
          v-for="item in listItems"
          :key="item"
          :prepend-icon="item.icon"
          :title="item.title"
          :value="item.title"
          :active="isActive(item.url)"
          @click="$router.push(item.url)"
        ></v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="current__user">
          <svg viewBox="0 0 24 24">
            <path
              d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,3c4.96,0,9,4.04,9,9 c0,1.42-0.34,2.76-0.93,3.96c-1.53-1.72-3.98-2.89-7.38-3.03C14.57,12.6,16,10.97,16,9c0-2.21-1.79-4-4-4C9.79,5,8,6.79,8,9 c0,1.97,1.43,3.6,3.31,3.93c-3.4,0.14-5.85,1.31-7.38,3.03C3.34,14.76,3,13.42,3,12C3,7.04,7.04,3,12,3z M9,9c0-1.65,1.35-3,3-3 s3,1.35,3,3c0,1.65-1.35,3-3,3S9,10.65,9,9z M12,21c-3.16,0-5.94-1.64-7.55-4.12C6.01,14.93,8.61,13.9,12,13.9 c3.39,0,5.99,1.03,7.55,2.98C17.94,19.36,15.16,21,12,21z"
            ></path>
          </svg>
          {{
            `${
              $store.getters.USER.lastName
            } ${$store.getters.USER.name.charAt()}. ${$store.getters.USER.surname.charAt()}.`
          }}
        </div>
        <paper-button @click="exit">
          <svg height="24" width="24">
            <path
              d="M5.4 21q-.75 0-1.275-.525Q3.6 19.95 3.6 19.2V5.3q0-.75.525-1.275Q4.65 3.5 5.4 3.5h6.725V5H5.4q-.1 0-.2.1t-.1.2v13.9q0 .1.1.2t.2.1h6.725V21Zm10.725-4.475-1.025-1.1L17.525 13h-8.4v-1.5h8.4L15.1 9.075l1.025-1.1L20.4 12.25Z"
            />
          </svg>
          <span>Выйти из аккаунта</span>
        </paper-button>
        <div class="current__date">
          {{ $filters.filterDate(date, "datetime") }}
        </div>
      </template>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    date: new Date(),
    interval: null,
  }),
  methods: {
    async exit() {
      await this.$store.commit("updateIsAuthenicated", false);
      localStorage.removeItem("token");
      await this.$router.push("/");
      await this.$store.commit("exit", null);
    },
    isActive(url) {
      return url === this.$route.path;
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.date = new Date();
    }, 1000);
  },
  computed: {
    listItems() {
      if (
        this.$store.getters.USER?.selectedRole.role === "MANAGER" &&
        this.$store.getters.USER?.selectedRole.organization === "itroom"
      ) {
        return [
          { title: "Личный кабинет", icon: "mdi-account", url: "/itroom" },
          // { title: "Мессенджер", icon: "mdi-forum", url: "/itroom/messenger" },
          { title: "Лиды", icon: "mdi-view-list", url: "/itroom/calls" },
          {
            title: "Клиенты",
            icon: "mdi-account-group",
            url: "/itroom/clients",
          },
          {
            title: "Все проекты",
            icon: "mdi-folder-multiple",
            url: "/itroom/projects",
          },
          // {
          //   title: "Создать проект",
          //   icon: "mdi-folder-plus",
          //   url: "/itroom/create-project",
          // },
        ];
      }

      if (
        this.$store.getters.USER?.selectedRole.role === "DIRECTOR" &&
        this.$store.getters.USER?.selectedRole.organization === "itroom"
      ) {
        return [
          { title: "Личный кабинет", icon: "mdi-account", url: "/itroom" },
          // { title: "Мессенджер", icon: "mdi-forum", url: "/itroom/messenger" },
          { title: "Лиды", icon: "mdi-view-list", url: "/itroom/calls" },
          {
            title: "Клиенты",
            icon: "mdi-account-tie",
            url: "/itroom/clients",
          },
          {
            title: "Все проекты",
            icon: "mdi-folder-multiple",
            url: "/itroom/projects",
          },
          {
            title: "Пользователи",
            icon: "mdi-account-group",
            url: "/itroom/users",
          },
          {
            title: "Статистика",
            icon: "mdi-chart-line",
            url: "/itroom/statistics",
          },
          {
            title: "Персональные задачи",
            icon: "mdi-card-account-details-outline",
            url: "/itroom/tasks",
          },
        ];
      }

      return [
        { title: "Личный кабинет", icon: "mdi-account", url: "/itroom" },
        // { title: "Мессенджер", icon: "mdi-forum", url: "/itroom/messenger" },
        {
          title: "Мои проекты",
          icon: "mdi-folder-multiple",
          url: "/itroom/projects",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.v-navigation-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

paper-button {
  width: 100%;
  @include --body-button-set;
}

.v-navigation-drawer__append {
  color: var(--button-color-accent) !important;
  padding: rem(24) rem(8);
  text-align: center;
}

.current__user {
  margin: 0 0 rem(4) 0;
  font-size: rem(14);
  color: var(--white-12);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
  width: 100%;

  svg {
    max-width: rem(16);
    max-height: rem(16);
  }
}

.current__date {
  font-size: rem(14);
  color: var(--white-12);
}
</style>