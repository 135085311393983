import { manager } from './manager'
import { specialist } from './specialist'
import { director } from "./director";

export const techroom = {
  namespaced: true,
  modules: {
    manager,
    specialist,
    director,
  }
}