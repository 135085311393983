<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование Адресов</div>
        <v-form ref="form" class="form_cabinet addresses" @submit.prevent="editAddress">
          <div class="form_cabinet_row" v-for="(address, index) in formData.addresses" :key="index">
            <div class="address">
              <v-text-field @change="address.edit = true" v-model="address.title" class="text_field_input"
                label="Адрес обслуживания" :rules="[
                  () => !!address.title || 'Обязательное поле',
                ]" required>
              </v-text-field>
              <v-text-field @change="address.edit = true" v-model="address.head" class="text_field_input"
                label="Контактное лицо на адресе" :rules="[
                  () => !!address.head || 'Обязательное поле',
                ]" required>
              </v-text-field>
              <v-text-field @change="address.edit = true" v-model="address.phone" class="text_field_input"
                v-maska="'+7 (###) ###-##-##'" label="Номер контактного лица" :rules="[
                  () => !!address.phone || 'Обязательное поле',
                  () => address.phone.length === 18 || 'Не верный формат телефона',
                ]" required>
              </v-text-field>
            </div>
            <div class="floors">
              <div class="floor_item" v-for="floor in address.floors" :key="floor.id">
                <v-chip>
                  <span>Этаж {{ floor.floor }}</span>
                </v-chip>
                <svg fill="#f94c39" height="20" width="20" @click="removeFloor(floor)">
                  <path
                    d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z" />
                </svg>
              </div>
              <v-select clearable chips v-model="addedFloors[index]" name="floor" label="Добавить этажи"
                v-if="address.floors.length == 0 || !address.floors" :rules="[
                  () => !!addedFloors[index] || 'Обязательное поле',
                  () => !!addedFloors[index].length > 0 || 'Обязательное поле',
                ]" :items="address.filteredFloors" item-title="floor" multiple return-object></v-select>

              <v-select v-else clearable chips v-model="addedFloors[index]" name="floor" label="Добавить этажи"
                :items="address.filteredFloors" item-title="floor" multiple return-object>
              </v-select>
              <!-- <v-select clearable chips v-model="addedFloors[index]" name="floor" label="Добавить этажи"
                v-if="address.floors.length > 0" :items="address.filteredFloors" item-title="floor" multiple
                return-object></v-select> -->
            </div>
            <paper-button @click="removeAddress(address.id)" class="techroom_button">
              <svg height="24" width="24">
                <path
                  d="M7.3 20.5q-.75 0-1.275-.525Q5.5 19.45 5.5 18.7V6h-1V4.5H9v-.875h6V4.5h4.5V6h-1v12.7q0 .75-.525 1.275-.525.525-1.275.525ZM17 6H7v12.7q0 .125.088.213.087.087.212.087h9.4q.1 0 .2-.1t.1-.2ZM9.4 17h1.5V8H9.4Zm3.7 0h1.5V8h-1.5ZM7 6V19v-.3Z" />
              </svg>
              <span>Удалить</span>
            </paper-button>
          </div>
          <div class="form_cabinet_row" v-for="(item, index) in this.addedAddresses" :key="index">
            <v-text-field class="text_field_input" v-model="item.title" label="Адрес обслуживания" :rules="[
              () => !!item.title || 'Обязательное поле',
            ]" required>
            </v-text-field>
            <v-select v-model="item.floors" :items="[
              { floor: 1 },
              { floor: 2 },
              { floor: 3 },
              { floor: 4 },
              { floor: 5 },
              { floor: 6 },
              { floor: 7 },
              { floor: 8 },
              { floor: 9 },
              { floor: 10 },
              { floor: 11 },
              { floor: 12 }
            ]" chips item-title="floor" label="Этажи" return-object :rules="[
  () => !!item.floors || 'Обязательное поле',
  () => !!item.floors.length > 0 || 'Обязательное поле',
]" multiple>
            </v-select>
            <v-text-field class="text_field_input" v-model="item.head" label="Контактное лицо на адресе" :rules="[
              () => !!item.head || 'Обязательное поле',
            ]" required>
            </v-text-field>
            <v-text-field class="text_field_input" v-model="item.phone" label="Номер контактного лица" maxlength="18"
              v-maska="'+7 (###) ###-##-##'" :rules="[
                () => !!item.phone || 'Обязательное поле',
                () => item.phone.length === 18 || 'Не верный формат телефона',
              ]" required>
            </v-text-field>
            <paper-button @click="removeNewAddress(index)" class="techroom_button">
              <svg height="24" width="24">
                <path
                  d="M7.3 20.5q-.75 0-1.275-.525Q5.5 19.45 5.5 18.7V6h-1V4.5H9v-.875h6V4.5h4.5V6h-1v12.7q0 .75-.525 1.275-.525.525-1.275.525ZM17 6H7v12.7q0 .125.088.213.087.087.212.087h9.4q.1 0 .2-.1t.1-.2ZM9.4 17h1.5V8H9.4Zm3.7 0h1.5V8h-1.5ZM7 6V19v-.3Z" />
              </svg>
              <span>Удалить</span>
            </paper-button>
          </div>
          <v-btn class="add__address" @click="addAddress(formData.id)">
            Нажмите для добавления
          </v-btn>
          <v-btn type="submit" variant="outlined" class="cabinet_btn" @click="validate($event)">
            Сохранить изменения
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      removedFloors: [],
      addedFloors: [],
      addedAddresses: [],
    }
  },
  props: {
    client: {
      type: Object,
    },
  },
  computed: {
    formData() {
      return this.client;
    },
  },

  methods: {
    async validate(e) {
      this.formData.addresses.forEach(address => {
        console.log(address.floors.length);
      });
      let a = await this.$refs.form.validate();
      if (!a.valid) {
        e.preventDefault();
      }
    },
    async editAddress() {
      try {
        if (this.addedFloors.length > 0) {
          await this.$store.dispatch('techroom/manager/createFloor', this.addedFloors)
          this.$store.getters["techroom/manager/newFloors"].forEach(floor => {
            this.formData.addresses.forEach(address => {
              if (address.id === floor.addressId) {
                address.floors.push(floor)
              }
            })
          })
          this.addedFloors = []
        }
        if (this.addedAddresses.length > 0) {
          await this.$store.dispatch('techroom/manager/createAddress', this.addedAddresses)
          this.$store.getters['techroom/manager/newAddresses'].forEach(address => {
            this.formData.addresses.push(address)
          })
          this.addedAddresses = []
        }

        for (let address of this.formData.addresses) {
          if (address.edit === true) {
            await this.$store.dispatch('techroom/manager/editAddress', address)
          }
        }
        this.$emit("close");
      } catch (error) {
        console.error(error)
      }
    },

    async removeFloor(floor) {
      await this.$store.dispatch('techroom/manager/removeFloor', floor.id)
      this.formData.addresses.forEach(address => {
        address.floors = address.floors.filter(item => item.id !== floor.id)
        if (floor.addressId === address.id) {
          address.filteredFloors.push(floor)
          address.filteredFloors.sort((a, b) => a.floor > b.floor ? 1 : -1);
        }
      })
    },

    addAddress(id) {
      this.addedAddresses.push({
        title: "",
        head: "",
        phone: "",
        clientId: id,
        floors: [],
      })
    },

    removeNewAddress(index) {
      this.addedAddresses.splice(index, 1)
    },

    async removeAddress(id) {
      await this.$store.dispatch('techroom/manager/removeAddress', id)
      this.formData.addresses = this.formData.addresses.filter(address => address.id !== id)
    },
  },
};
</script>

<style lang="scss">
.form_cabinet.addresses {
  paper-button {
    color: var(--red-0);
    border: 1px solid var(--red-0);
    padding: 0 rem(5) 0 0;
    transition: 0.3s ease;
    width: max-content;
    align-self: flex-end;
    padding: 0 rem(6);

    svg {
      fill: var(--red-0);
    }
  }


  .form_cabinet_row {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    border-bottom: 1px solid var(--white-10);
    padding: 0 0 rem(45) 0;
  }

  display: flex;
  flex-direction: column;
  gap: rem(50);

  .address {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: rem(21);
      }
    }
  }

  .floors {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    .title {
      font-size: rem(18);
    }

    .floor_item {
      display: flex;
      gap: rem(4);
      align-items: center;
      width: 100%;

      span {
        width: 100%;
        text-align: center;
      }

      .v-select {
        width: 100%;

        .v-field {
          border-radius: rem(50);
          background-color: rgba(0, 0, 0, 0.07);
          height: rem(32);

          .v-field__input {
            width: 100%;
          }

          .v-select__selection {
            width: 100%;

            &-text {
              color: #000;
              font-size: rem(14);
              margin: 0 0 0 rem(12);
            }
          }

          .v-field__outline {
            display: none;
          }

          .v-field__field {
            padding-top: rem(5);
          }

          .v-field__append-inner {
            padding-top: rem(5);
          }

          .v-field-label {
            top: rem(5);
          }
        }
      }
    }


  }

  .add__address {
    width: rem(380);
    height: rem(314);
    box-shadow: none;
    background-color: hsl(0, 0%, 96%);
    color: #000;

    .v-btn__content {
      text-transform: none;
      font-size: rem(14);
      letter-spacing: rem(0.5);
      font-family: monospace;
    }

    @media screen and (max-width: $medium) {
      width: 100%;
      height: rem(175);
    }
  }

  .cabinet_btn {
    width: 100%;
    background-color: unset;
    color: #ff5757;
    border-radius: 0;
  }

  .form_cabinet_row {
    width: 100%;
  }
}
</style>