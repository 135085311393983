<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text class="main">
        <div class="dialog_title">
          Информация о компьютере №{{ computer?.num }}
        </div>
        <div class="main_blocks">
          <div class="block">
            <div class="block__info">
              <div class="block__info_container_main computer">
                <div class="block__info_item">
                  <div class="block__info_item_label">ЦП</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.cpu || 'Не указано' }} -->
                    <div>{{ computer.cpu_brand }}</div>
                    <div>{{ computer.cpu_core }}</div>
                    <div>{{ computer.cpu_model }}</div>
                    <div>{{ computer.cpu_socket }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">ГП</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.gpu || 'Не указано' }} -->
                    <div>{{ computer.gpu_brand }}</div>
                    <div>{{ computer.gpu_model }}</div>
                    <div>{{ computer.gpu_vol }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">HDD</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.hdd || 'Не указано' }} -->
                    <div>{{ computer.hdd_brand }}</div>
                    <div>{{ computer.hdd_form }}</div>
                    <div>{{ computer.hdd_vol }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">SSD</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.ssd || 'Не указано' }} -->
                    <div>{{ computer.ssd_brand }}</div>
                    <div>{{ computer.ssd_form }}</div>
                    <div>{{ computer.ssd_vol }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">ОЗУ</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.ram || 'Не указано' }} -->
                    <div>{{ computer.ram_brand }}</div>
                    <div>{{ computer.ram_form }}</div>
                    <div>{{ computer.ram_freq }}</div>
                    <div>{{ computer.ram_vol }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">БП</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.power || 'Не указано' }} -->
                    <div>{{ computer.power_brand }}</div>
                    <div>{{ computer.power_pw }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">МП</div>
                  <div class="block__info_item_value">
                    <!-- {{ computer?.motherboard || 'Не указано' }} -->
                    <div>{{ computer.motherboard_model }}</div>
                    <div>{{ computer.motherboard_socket }}</div>
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Удаленный доступ</div>
                  <div class="block__info_item_value">
                    {{ computer?.remote }}
                  </div>
                </div>
              </div>
            </div>
            <div class="secondary">
              <div class="block__info" v-if="computer.misc">
                <div class="block__info_title">
                  <h5>Заметки</h5>
                </div>
                <div style="white-space: break-spaces">
                  {{ computer?.misc || 'Не указано' }}
                </div>
              </div>
              <div class="block__info" v-if="computer.cabinet">
                <div class="block__info_title">
                  <h5>Адрес</h5>
                </div>
                <div class="block__info_container_main address">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Улица</div>
                    <div class="block__info_item_value">
                      {{ computer?.cabinet.floor.address?.title }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Этаж</div>
                    <div class="block__info_item_value">
                      {{ computer?.cabinet.floor?.floor }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Ответственный</div>
                    <div class="block__info_item_value">
                      {{ computer?.cabinet.floor.address?.head }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Номер телефона</div>
                    <div class="block__info_item_value">
                      {{ computer?.cabinet.floor.address?.phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!computer.stock" class="block__info">
                <div class="block__info_title">
                  <h5>Кабинет</h5>
                </div>
                <div class="block__info_container_main cabinet">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Номер №</div>
                    <div class="block__info_item_value">
                      {{ computer.cabinet?.num }}
                    </div>
                  </div>
                </div>
                <!-- <h5>QR code</h5>
                <qrcode-vue :value="value" :size="size" level="H" /> -->
              </div>
              <div v-else>
                <h5>На складе</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="dialog_title">Просмотр компьютера</div>
        <div>
          <div>Номер компьютера: {{ computer.num }}</div>
          <div>Сотрудник: {{ computer.head }}</div>
          <div>
            Местоположение:
            {{
              `${computer.cabinet.floor.address.title}, Этаж ${computer.cabinet.floor.floor}, Кабинет №${computer.cabinet.num}`
            }}
          </div>
          <div>CPU: {{ computer.cpu }}</div>
          <div>GPU: {{ computer.gpu }}</div>
          <div>HDD: {{ computer.hdd }}</div>
          <div>SDD: {{ computer.ssd }}</div>
          <div>RAM: {{ computer.ram }}</div>
          <div>POWER: {{ computer.power }}</div>
          <div>MOTHER: {{ computer.motherboard }}</div>
        </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import QrcodeVue from 'qrcode.vue'
export default {
  props: {
    computer: {
      type: Object,
    },
  },

  data() {
    return {
      stock: null,
      value: 'https://crm.techroom18.ru/techroom/organization/1/computers',
      size: 300,
    };
  },
  // components: {
  //   QrcodeVue,
  // },
};
</script>
<style lang="scss" scoped>
.secondary {
  display: flex;
  flex-direction: column;
  gap: rem(24);
}

.computer {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  width: 100%;
  gap: rem(1);
  background: var(--white-12);
  @media screen and (max-width: $small) {
    grid-template: 1fr / repeat(1, 1fr);
  }

  .block__info_item {
    background: var(--white-6);
    padding: 10px 15px;
  }
}

.block__info_item_value {
  // text-overflow: ellipsis;
  width: max-content;
}

.block__info_title {
  h6 {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: rem(12);
  }
}
</style>
