<template>
  <v-dialog fullscreen transition="dialog-bottom-transition">
    <v-card class="project">
      <v-toolbar color="var(--blue-12)">
        <v-btn icon @click="close">
          <v-icon color="var(--white-0)">mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ project.nameProject }}
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs fixed-tabs background-color="indigo-darken-2" v-model="tab">
        <v-tab value="1"> Информация о проекте </v-tab>
        <v-tab value="2" @click="getDevelopers"> Управление проектом </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="1">
          <ProjectInfo :project="project" />
        </v-window-item>
        <v-window-item value="2">
          <ProjectEdit :project="project" :developers="developers" />
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import ProjectInfo from "@/components/app/itroom/teamlead/projects/ProjectInfo";
import ProjectEdit from "@/components/app/itroom/teamlead/projects/ProjectEdit";

export default {
  components: { ProjectInfo, ProjectEdit },
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {
      tab: null,
      developers: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.tab = null;
    },

    async getDevelopers() {
      if (!this.$store.getters["itroom/teamlead/getDevelopers"]) {
        await this.$store.dispatch("itroom/teamlead/requestDevelopers");
        let allDevelopers =
          this.$store.getters["itroom/teamlead/getDevelopers"];
        allDevelopers.forEach((e) => {
          this.developers.push({
            title: `${e.surname} ${e.name} ${e.lastName}`,
            value: e.id,
          });
        });
      }
    },
  },
};
</script>