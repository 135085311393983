<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Добавить задачу</h2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="valid ? addTask() : validate()"
        >
          <v-text-field
            v-model="formData.titleTask"
            label="Задача"
            :rules="[() => !!formData.titleTask || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.timelineTask"
            label="Срок"
            :rules="[() => !!formData.timelineTask || 'Обязательное поле']"
          ></v-text-field>
          <v-btn type="submit">Добавить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,

      formData: {
        titleTask: null,
        timelineTask: null,
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async addTask() {
      try {
        await this.$store.dispatch("itroom/account/addTask", {
          title: this.formData.titleTask,
          timeline: this.formData.timelineTask,
        });
        await this.$store.dispatch("itroom/account/requestTasks");
        this.$emit("close");
        this.$refs.form.reset();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>