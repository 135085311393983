import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    map: null,
  },
  getters: {
    getMap(state) {
      return state.map
    },
  },
  mutations: {
    updateMap(state, res) {
      state.map = res
    },
  },
  actions: {
    async requestMap({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/map/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateMap', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async editMap(ctx, { canvas, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/map/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: { canvas }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async sendMap(ctx, map) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/map`,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem("token")
          },
          data: {
            floorId: map.floorId,
            map: map.map,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  }
}