import axios from 'axios';
import { url } from '@/store';

import cabinet from './cabinet';
import computers from './computers';
import equipment from './equipment';
import organizations from './organizations';
import workers from './workers';
import software from './software';
import services from './services';
import appeals from './appeals';
import map from './map';

export const specialist = {
  namespaced: true,
  state: {
    address: {},
    floorId: null,
    objects: [],
    statuses: [],
  },
  getters: {
    getAddress(state) {
      return state.address;
    },
    getFloorId(state) {
      return state.floorId;
    },
    getObjects(state) {
      // const colors = ['lightblue', 'blue', 'lightgreen', 'red', 'pink'];
      const attributes = [];
      state.objects.forEach((_this) => {
        attributes.push({
          key: _this.id,
          customData: {
            title: _this.name,
            class: `text-white red`,
          },
          dates: {start: new Date(_this.start), end: new Date(_this.end)},
        });
      });
      return attributes;
    },
    getStatuses(state) {
      return state.statuses;
    },
  },
  actions: {
    async requestObjects({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/objects`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateObjects', result.data);
      } catch (error) {
        console.error(error);
      }
    },
    async createObjects(
      { dispatch },
      { name, start, end }
    ) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/object`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            name,
            start,
            end: end ? end : start,
          },
        });
        console.log(result);
        dispatch('requestObjects');
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteObject({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'delete',
          url: `${url}/api/techroom/object/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        await dispatch('requestObjects');
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async requestStatuses({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/computers/statuses`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateStatuses', result.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    updateObjects(state, res) {
      state.objects = res;
    },
    updateAddress(state, res) {
      state.address = res;
    },
    updateFloorId(state, res) {
      state.floorId = res;
    },
    pushCabinet(state, res) {
      state.address.cabinets.push({
        id: res.id,
        num: res.num,
      });
    },
    filterCabinet(state, res) {
      state.address.cabinets = state.address.cabinets.filter(
        (item) => item.id != res
      );
    },
    updateStatuses(state, payload) {
      state.statuses = payload;
    },
  },
  modules: {
    cabinet,
    computers,
    equipment,
    organizations,
    workers,
    software,
    services,
    appeals,
    map,
  },
};
