<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактировать пользователя</div>
        <v-form ref="form" lazy-validation class="form_cabinet">
          <v-text-field
            class="text_field_input"
            label="Электронная почта"
            required
            v-model="$store.getters.USER.email"
          ></v-text-field>
          <v-text-field
            class="text_field_input"
            label="Дата рождения"
            required
            v-model="$store.getters.USER.dateBirth"
            v-maska="'##.##.####'"
          ></v-text-field>
          <v-text-field
              v-model="dateBirth"
              class="text_field_input"
              label="Дата рождения"
              required
              v-maska="'##.##.####'"
          ></v-text-field>
          <v-btn type="button" @click="updateUser()" class="cabinet_btn">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      dateBirth: null,
    }
  },
  methods: {
    async updateUser() {
      const data = {
        email: this.email,
        dateBirth: this.dateBirth,
      }
      await this.$store.dispatch('updateUser', data)
      await this.$store.dispatch('requestUserInfo')
    }
  },
  // data() {
  //   return {
  //     dateBirth = $filters.filterDateBirth($store.getters.USER.dateBirth)
  //   };
  // },
  props: {
    user: {
      type: Object,
    },
  },
}
</script>

<style>

</style>

