import leads from './leads';
import clients from './clients';
import projects from './projects';
import appeals from './appeals';
import floors from './floors';
import address from './address';
import plans from './plans';
import specialists from './specialists';

export const manager = {
  namespaced: true,
  modules: {
    leads,
    clients,
    projects,
    appeals,
    floors,
    address,
    plans,
    specialists,
  },
};
