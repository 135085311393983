<template>
  <div class="item_header">
    <div class="page_menu_title">Выберите организацию для продолжения</div>
  </div>
  <div class="content">
    <div class="content_block">
      <v-table density="comfortable" class="style-scoped">
        <thead>
          <tr>
            <th class="text-left start">Организация</th>
            <th class="text-left">Директор</th>
            <th class="text-left">Адреса</th>
            <th class="text-left">Комментарий</th>
          </tr>
        </thead>
        <tbody class="table__org">
          <tr v-for="item in organizations.filter(org => !org.delete)" :key="item.id" @click="!$event.target.closest('.address_btn') && !$event.target.closest('button') && !$event.target.closest('.td-item-address') ?
  $router.push(`/techroom/organization/${item.id}/general`) : null">
            <td class="td-item td-item-smalltitle start">
              <svg height="18" width="18" viewBox="0 -3 20 20">
                <path
                  d="M2.604 16.292V3.625h7.167v3h7.625v9.667Zm.667-.667h5.833v-2.333H3.271Zm0-3h5.833v-2.333H3.271Zm0-3h5.833V7.292H3.271Zm0-3h5.833V4.292H3.271Zm6.5 9h6.958V7.292H9.771Zm1.833-5.333v-.667h2.979v.667Zm0 3v-.667h2.979v.667Z" />
              </svg>
              {{ item.smallTitle ? item.smallTitle : item.fullTitle }}
            </td>
            <td class="td-item td-item-head">{{ item.head }}</td>
            <td class="td-item td-item-address">
              <a target="_blank" :href="'https://2gis.ru/izhevsk/search/' + address.title"
                v-for="address in item.addresses.filter(val => !val.delete)" :key="address.id">
                {{ address.title }}
              </a>
            </td>
            <td v-if="!item.description" class="td-item td-item-comment empty">- не указан -</td>
            <td v-else class="td-item td-item-comment">
              <div>
                <v-btn v-if="count(item.description) > 50" variant="text" size="x-small" :value="item.id"
                  @click="handlerComment($event)">
                  Посмотреть больше
                  <svg height="20" width="20">
                    <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z" />
                  </svg>
                </v-btn>
                <v-btn v-if="count(item.description) > 50" variant="text" size="x-small"
                  class="manage-item-more manage-item fill-height" :value="item.id"
                  @click="openDialogShowCommentOrg(item)" title="Открыть в окне">
                  <IconUncover></IconUncover>
                </v-btn>
              </div>
              {{ item.description }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
  <DialogShowCommentOrg v-model="dialogShowCommentOrg" @close="dialogShowCommentOrg = false"
    :organization="organization" />
</template>

<script>
import DialogShowCommentOrg from "@/components/app/techroom/specialist/organization/DialogShowCommentOrg.vue"
export default {
  name: "Organizations",
  components: { DialogShowCommentOrg },
  data() {
    return {
      organizations: [],
      organization: null,
      dialogShowCommentOrg: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("techroom/specialist/requestOrganizations");
    this.organizations =
      this.$store.getters["techroom/specialist/getOrganizations"];
  },
  methods: {
    openDialogShowCommentOrg(organization) {
      this.organization = organization;
      this.dialogShowCommentOrg = true;
    },
    count(str) {
      if (str) {
        return str.length;
      }
    },
    handlerComment(event) {
      const td = event.target.parentElement.parentElement;
      const tdRow = event.target.parentElement.parentElement.parentElement;
      if (event.target.children[2].innerHTML.includes("Свернуть комментарий")) {
        console.log("hide");
        td.classList.remove("more");
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Посмотреть больше
          <svg height="20" width="20">
            <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          _this.classList.remove("top");
        }
      } else {
        td.classList.add("more");
        console.log(event.target.children);
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Свернуть комментарий
          <svg height="20" width="20">
            <path d="m6.104 12.396-.458-.458L10 7.604l4.354 4.334-.458.458L10 8.479Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          if (!_this.classList.contains("td-item-comment")) {
            _this.classList.add("top");
          }
          // console.log(_this);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.table__org {
  .td-item-address {

    a {
      color: var(--red-0);
      font-weight: 400;
      font-size: rem(13);
      display: block;
      padding: 0;
      width: max-content;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .address_btn {
    z-index: 2;
  }

  tr {

    &:hover {
      td {
        &:first-child {
          &::before {
            display: inline-block;
          }
        }
      }
    }

    td {

      &:first-child {
        &::before {
          display: none;
          content: url("data: image/svg+xml, %3Csvg xmlns=%27http://www.w3.org/2000/svg%27 height=%2716%27 width=%2716%27 viewBox=%270 -5 24 24%27 %3E%3Cpath d=%27m8 14.417-.479-.479L11.458 10 7.521 6.062 8 5.583 12.417 10Z%27 /%3E%3C/svg%3E");
          vertical-align: initial;
        }
      }

    }

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "1";
      position: absolute;
      left: rem(-5);
      top: 0;
    }
  }
}
</style>
