<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Просмотр оборудования</div>
        <div class="main_blocks">
          <div class="block">
            <div class="block__info">
              <div class="block__info_container_main equipment">
                <div class="block__info_item">
                  <div class="block__info_item_label">Тип</div>
                  <div class="block__info_item_value">
                    {{ equipment?.type }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Модель</div>
                  <div class="block__info_item_value">
                    {{ equipment?.model || 'Не указано' }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Состояние</div>
                  <div class="block__info_item_value">
                    <!-- {{ equipment?.condition || 'Не указано' }} -->
                    {{ equipment.status?.title }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Инвентарный номер</div>
                  <div class="block__info_item_value">
                    <!-- {{ equipment?.condition || 'Не указано' }} -->
                    {{ equipment?.num || 'Не указано' }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Серийный номер</div>
                  <div class="block__info_item_value">
                    <!-- {{ equipment?.condition || 'Не указано' }} -->
                    {{ equipment?.serialnum || 'Не указано' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="secondary">
              <div class="block__info" v-if="equipment.misc">
                <div class="block__info_title">
                  <h5>Заметки</h5>
                </div>
                <div style="white-space: break-spaces">
                  {{ equipment.misc }}
                </div>
              </div>
              <div class="block__info" v-if="equipment.cabinet">
                <div class="block__info_title">
                  <h5>Адрес</h5>
                </div>
                <div class="block__info_container_main address">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Улица</div>
                    <div class="block__info_item_value">
                      {{
                        equipment.cabinet.floor.address?.title || 'Не указано'
                      }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Этаж</div>
                    <div class="block__info_item_value">
                      {{ equipment.cabinet.floor?.floor || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Ответственный</div>
                    <div class="block__info_item_value">
                      {{
                        equipment.cabinet.floor.address?.head || 'Не указано'
                      }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Номер телефона</div>
                    <div class="block__info_item_value">
                      {{
                        equipment.cabinet.floor.address?.phone || 'Не указано'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!equipment.stock" class="block__info">
                <div class="block__info_title">
                  <h5>Кабинет</h5>
                </div>
                <div class="block__info_container_main cabinet">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Номер №</div>
                    <div class="block__info_item_value">
                      {{ equipment.cabinet?.num || 'Не указано' }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <h5>На складе</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- <div>
          <div>Тип: {{ equipment.type }}</div>
          <div>Модель: {{ equipment.model }}</div>
          <div>Состояние: {{ equipment.condition }}</div>
          <div>
            Местоположение:
            {{
              `${equipment.cabinet.floor.address.title}, Этаж ${equipment.cabinet.floor.floor}, Кабинет №${equipment.cabinet.num}`
            }}
          </div>
        </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    equipment: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.secondary {
  display: flex;
  flex-direction: column;
  gap: rem(24);
}

.equipment {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: rem(24);

  @media screen and (max-width: 767px) {
    grid-template: 1fr / repeat(1, 1fr);
  }
}

.block__info_item_value {
  // text-overflow: ellipsis;
  width: max-content;
}

.block__info_title {
  h6 {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: rem(12);
  }
}
</style>
