import { createApp } from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router/router';
import PaperButtonElement from '@polymer/paper-button';
import { detect } from 'detect-browser';
import store from '@/store';
import * as filters from '@/filters';
import icons from '@/components/app/icons';
import Maska from 'maska';
import Loading from '@/components/app/itroom/Loading.vue';
import LoadingTechroom from '@/components/app/techroom/LoadingTechroom.vue';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import '@/scss/index.scss';

const browser = detect();

const app = createApp(App);

app.component('Loading', Loading);
app.component('LoadingTechroom', LoadingTechroom);
icons.forEach((icon) => {
  app.component(icon.name, icon);
});

app
  .use(router)
  .use(PaperButtonElement)
  .use(vuetify)
  .use(VCalendar)
  .use(store)
  .use(Maska)
  .mount('#app');

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$browser = browser;
