<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление обращения</div>
        <v-form ref="form" lazy-validation class="form_cabinet" @submit.prevent="createAppeal()" v-model="valid">
          <v-select v-model="formData.addressId" :items="$store.getters['techroom/manager/getClientsAddresses']"
            label="Адрес" item-title="title" item-value="id" :rules="[
              () => !!formData.addressId || 'Обязательное поле',
            ]" required></v-select>
          <v-text-field class="text_field_input" v-model="formData.task" label="Задача" :rules="[
            () => !!formData.task || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-select v-model="formData.statusId" :items="items" item-title="title" item-value="id" label="Статус заявки"
            :rules="[
              () => !!formData.statusId || 'Обязательное поле',
            ]" required></v-select>
          <v-btn type="submit" class="cabinet_btn">Добавить обращение</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      formData: {
        addressId: null,
        statusId: 1,
        task: null,
      },
      specialists: null,
      documentProject: "",
      documentProjectRules: [(v) => !!v || "Обязательное поле"],
      namedocument: "",
      namedocumentRules: [(v) => !!v || "Обязательное поле"],
      datadocument: "",
      datadocumentRules: [(v) => !!v || "Обязательное поле"],
      sumdocument: "",
      sumdocumentRules: [(v) => !!v || "Обязательное поле"],
      email: "",
      emailRules: [(v) => !!v || "Обязательное поле"],
      describtion: "",
      describtionRules: [(v) => !!v || "Обязательное поле"],
      items: [
        {
          id: 1,
          title: "В обработке",
        },
        {
          id: 2,
          title: "Утверждение",
        },
        {
          id: 3,
          title: "В процессе",
        },
        {
          id: 4,
          title: "Готово",
        },
        {
          id: 5,
          title: "Что-то нужно для решения",
        },
        {
          id: 6,
          title: "Не получается сделать",
        },
      ],
    };
  },

  methods: {
    async createAppeal() {
      this.$refs.form.validate();
      if (this.valid) {
        await this.$store.dispatch(
          "techroom/manager/createAppeal",
          this.formData
        );
        await this.$store.dispatch('techroom/manager/requestAppeals');
        this.$emit('close')
        this.formData.addressId = null;
        this.formData.status = null;
        this.formData.task = null;
      }
    },
  }
};

</script>

<style>
.v-file-input {
  width: 380px;
}

.v-input__prepend {
  display: none;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.8);
}

.action {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.dialog_content {
  border: 2px solid #ff5757;
  /* min-width: 40vw !important; */
}

.dialog_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ff5757;
  padding-bottom: 25px;
  margin: 0px 50px 50px 50px;
}

.text_field_input {
  width: 380px;
}

.v-select {
  width: 380px;
}

.form_cabinet {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.cabinet_btn {
  background-color: #ff5757;
  color: white;
  border-radius: 20px;
  width: 250px;
  text-transform: none;
}
</style>
