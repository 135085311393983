export default function filterRole(role) {
  switch (role) {
    case 'MANAGER':
      role = 'Менеджер'
      break

    case 'DEVELOPER':
      role = 'Разработчик'
      break

    case 'DIRECTOR':
      role = 'Директор'
      break

    case 'TEAMLEAD':
      role = 'Тимлид'
      break

    case 'TECHNICAL':
      role = 'Специалист'
      break
  }

  return role
}