<template>
  <v-table density="comfortable">
    <thead>
      <tr>
        <!-- <th class="text-left">Название компании</th> -->
        <th class="text-left">Контактное лицо</th>
        <th class="text-left">Номер телефона</th>
        <th class="text-left">Электронная почта</th>
        <th class="text-left">Название компании</th>
        <th class="text-left">Комментарий</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in $store.getters['itroom/director/getCalls']"
        :key="item.id"
      >
        <td v-if="item.typeCall === 2">
          <div>
            <v-tooltip activator="parent" location="top">
              Юридическое лицо
            </v-tooltip>
            <IconEntity />
          </div>
          {{ item.individual }}
        </td>
        <td v-else>
          <div>
            <v-tooltip activator="parent" location="top">
              Физическое лицо
            </v-tooltip>
            <IconIndividual />
          </div>
          {{ item.individual }}
        </td>
        <td>{{ $filters.filterNumber(item.phone) }}</td>
        <td v-if="!item.email" class="empty">- не указана -</td>
        <td v-else>{{ item.email }}</td>
        <td v-if="!item.nameCompany" class="empty">- не указано -</td>
        <td v-else>{{ item.nameCompany }}</td>
        <td v-if="!item.comment" class="empty">- не указан -</td>
        <td v-else class="td-item-comment">
          <div>
            <v-btn
              v-if="count(item.comment) > 50"
              variant="text"
              size="x-small"
              :value="item.id"
              @click="handlerComment($event)"
            >
              <!-- <v-tooltip activator="parent" location="top">Подробнее</v-tooltip> -->
              Посмотреть больше
              <svg height="20" width="20">
                <path
                  d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"
                />
              </svg>
            </v-btn>
            <v-btn
              v-if="count(item.comment) > 50"
              variant="text"
              size="x-small"
              class="manage-item-more manage-item fill-height"
              :value="item.id"
              @click="openDialogComment(item.id)"
            >
              <v-tooltip activator="parent" location="top"
                >Открыть в окне</v-tooltip
              >
              <svg height="20" width="20">
                <path
                  d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h4.25v.584h-4.25q-.187 0-.354.166-.166.167-.166.354v9.876q0 .187.166.354.167.166.354.166h9.876q.187 0 .354-.166.166-.167.166-.354v-4.25h.584v4.25q0 .479-.313.791-.312.313-.791.313Zm3.063-3.709-.458-.458 7.354-7.333h-3.125v-.584h4.146v4.146h-.584V4.979Z"
                />
              </svg>
            </v-btn>
          </div>
          {{ item.comment }}
        </td>
        <td class="text-right">
          <!-- Важно -->
          <!-- <v-btn variant="text" size="x-small" class="fill-height">
              <svg height="20" width="20">
                <path
                  d="M7.292 14.958 10 13.354l2.729 1.604-.708-3.041 2.354-2.021-3.125-.292L10 6.708 8.75 9.625l-3.125.271 2.396 2Zm-.938 1.25.979-4.062-3.229-2.729 4.229-.375L10 5.208l1.667 3.854 4.229.355-3.229 2.729.979 4.062L10 14.042ZM10 11.042Z"
                />
              </svg>
            </v-btn> -->
          <!-- Важно -->

          <!-- Подробнее -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-more manage-item fill-height"
            :value="item.id"
            @click="openDialog(item.id)"
          >
            <v-tooltip activator="parent" location="top">Подробнее</v-tooltip>
            <IconView />
          </v-btn>
          <!-- Подробнее -->

          <!-- Редактировать -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-edit manage-item fill-height"
            :value="item.id"
            @click="openDialogEdit(item.id)"
          >
            <v-tooltip activator="parent" location="top">
              Редактирование
            </v-tooltip>
            <IconEdit />
          </v-btn>
          <!-- Редактировать -->

          <!-- Удаление -->
          <v-btn
            variant="text"
            size="x-small"
            class="manage-item-delete manage-item fill-height"
            :value="item.id"
            @click="openDialogDelete(item)"
          >
            <v-tooltip activator="parent" location="top">
              Перенести в архив
            </v-tooltip>
            <IconDelete />
          </v-btn>
          <!-- Удаление -->
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogCall v-model="dialog" :call="call" @close="dialog = false" />
  <DialogEditCall
    v-model="dialogEdit"
    :call="call"
    @close="dialogEdit = false"
  />
  <DialogDeleteCall
    v-model="dialogDelete"
    :call="call"
    @close="dialogDelete = false"
  />
</template>

<script>
import DialogCall from "@/components/app/itroom/director/calls/DialogCall.vue";
import DialogEditCall from "@/components/app/itroom/director/calls/DialogEditCall.vue";
import DialogDeleteCall from "@/components/app/itroom/director/calls/DialogDeleteCall.vue";

export default {
  emits: ["loading"],
  components: {
    DialogCall,
    DialogEditCall,
    DialogDeleteCall,
  },
  data() {
    return {
      calls: null,
      call: null,

      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
    };
  },
  methods: {
    async openDialog(id) {
      await this.$store.dispatch("itroom/director/requestCall", id);
      this.call = this.$store.getters["itroom/director/getCall"];
      this.dialog = true;
    },
    async openDialogEdit(id) {
      await this.$store.dispatch("itroom/director/requestCall", id);
      this.call = this.$store.getters["itroom/director/getCall"];
      this.dialogEdit = true;
    },
    async openDialogDelete(item) {
      this.call = item;
      this.dialogDelete = true;
    },
    count(str) {
      if (str) {
        return str.length;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/director/requestCalls");
    setTimeout(() => {
      this.$emit("loading");
    }, 500);
  },
};
</script>

<style>
</style>