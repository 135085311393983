import { createRouter, createWebHistory } from 'vue-router';
import Authorization from '@/pages/Authorization';
import NotFound from '@/pages/NotFound';

// itroom
import Account from '@/pages/Itroom/Account';
import Clients from '@/pages/Itroom/Clients';
import Calls from '@/pages/Itroom/Calls';
import Projects from '@/pages/Itroom/Projects';
import CreateProject from '@/pages/Itroom/CreateProject';
import Messenger from '@/pages/Itroom/Messenger';
import Users from '@/pages/Itroom/Users';
import Tasks from '@/pages/Itroom/Tasks';
import Statistics from '@/pages/Itroom/Statistics';
import Stock from "@/pages/Techroom/specialist/Stock";

// techroom
import AccountTechroom from '@/pages/Techroom/AccountTechroom';
import Plans from '@/pages/Techroom/Plans';
import Archive from '@/pages/Techroom/Archive';
import PlansCalendar from '@/pages/Techroom/PlansCalendar';

// TECHSPECIALIST
import Organizations from '@/pages/Techroom/specialist/Organizations';
import OrganizationGeneral from '@/pages/Techroom/specialist/General';
import OrganizationCabinets from '@/pages/Techroom/specialist/Cabinets';
import OrganizationComputers from '@/pages/Techroom/specialist/Computers';
import OrganizationEquipment from '@/pages/Techroom/specialist/Equipment';
import OrganizationSoftware from '@/pages/Techroom/specialist/Software';
import OrganizationService from '@/pages/Techroom/specialist/ServiceOrganization';

import Structure from '@/pages/Techroom/specialist/Drug';
// TECHMANAGER
import LeadsManager from '@/pages/Techroom/manager/leads/LeadsManager';
import ClientsManager from '@/pages/Techroom/manager/clients/ClientsManager';
import AppealsManager from '@/pages/Techroom/manager/appeals/AppealsManager';
import ProjectsManager from '@/pages/Techroom/manager/ProjectsManager';
import store from '@/store/';
// TECHDIRECTOR
import UsersTechroom from '@/pages/Techroom/director/Users'

const routes = [
  {
    path: '/',
    component: Authorization,
    name: 'Authorization',
    meta: { layout: 'empty', auth: false },
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFound,
    name: '404',
    meta: { auth: false },
  },
  // itroom
  {
    path: '/itroom',
    component: Account,
    name: 'Account',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/:pathMatch(.*)',
    component: NotFound,
    name: '404i',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/clients',
    component: Clients,
    name: 'Clients',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/calls',
    component: Calls,
    name: 'Calls',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/projects',
    component: Projects,
    name: 'Projects',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/create-project',
    component: CreateProject,
    name: 'CreateProject',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/messenger',
    component: Messenger,
    name: 'Messenger',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/users',
    component: Users,
    name: 'Users',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/tasks',
    component: Tasks,
    name: 'Tasks',
    meta: { layout: 'itroom', auth: true },
  },
  {
    path: '/itroom/statistics',
    component: Statistics,
    name: 'Statistics',
    meta: { layout: 'itroom', auth: true },
  },

  // techroom
  {
    path: '/techroom',
    component: AccountTechroom,
    name: 'AccountTechroom',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/plans/archive',
    component: Archive,
    name: 'Archive',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/plans',
    component: Plans,
    name: 'Plans',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/plans/calendar',
    component: PlansCalendar,
    name: 'PlansCalendar',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/:pathMatch(.*)',
    component: NotFound,
    name: '404t',
    meta: { layout: 'techroom', auth: true },
  },
  // // TECHSPECIALIST
  {
    path: '/techroom/organizations',
    component: Organizations,
    name: 'Organizations',
    meta: { layout: 'organization', auth: true },
  },
  {
    path: '/techroom/stock',
    component: Stock,
    name: 'Stock',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/organization/:id/general',
    component: OrganizationGeneral,
    name: 'OrganizationGeneral',
    meta: { layout: 'organization', auth: true },
  },
  {
    path: '/techroom/organization/:id/cabinets',
    component: OrganizationCabinets,
    name: 'OrganizationCabinets',
    meta: { layout: 'organization', tab: 1, auth: true },
  },
  {
    path: '/techroom/organization/:id/computers',
    component: OrganizationComputers,
    name: 'OrganizationComputers',
    meta: { layout: 'organization', tab: 2, auth: true },
  },
  {
    path: '/techroom/organization/:id/equipment',
    component: OrganizationEquipment,
    name: 'OrganizationEquipment',
    meta: { layout: 'organization', tab: 3, auth: true },
  },
  // {
  //   path: '/techroom/organization/:id/workers',
  //   component: OrganizationWorkers,
  //   name: 'OrganizationWorkers',
  //   meta: { layout: 'organization', tab: 4, auth: true },
  // },
  {
    path: '/techroom/organization/:id/software',
    component: OrganizationSoftware,
    name: 'OrganizationSoftware',
    meta: { layout: 'organization', tab: 5, auth: true },
  },
  {
    path: '/techroom/organization/:id/service',
    component: OrganizationService,
    name: 'OrganizationService',
    meta: { layout: 'organization', tab: 6, auth: true },
  },
  // {
  //   path: '/techroom/organization/:id/appeals',
  //   component: OrganizationAppeals,
  //   name: 'OrganizationAppeals',
  //   meta: { layout: 'organization', tab: 7, auth: true },
  // },
  {
    path: '/techroom/organization/:id/structure',
    component: Structure,
    name: 'Structure',
    meta: { layout: 'organization', tab: 8, auth: true },
  },
  // // TECHMANAGER
  {
    path: '/techroom/leads',
    component: LeadsManager,
    name: 'LeadsManager',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/clients',
    component: ClientsManager,
    name: 'ClientsManager',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/appeals',
    component: AppealsManager,
    name: 'AppealsManager',
    meta: { layout: 'techroom', auth: true },
  },
  {
    path: '/techroom/projects',
    component: ProjectsManager,
    name: 'ProjectsManager',
    meta: { layout: 'techroom', auth: true },
  },
  // // TECHDIRECTOR
  {
    path: '/techroom/users',
    component: UsersTechroom,
    name: 'UsersDirector',
    meta: { layout: 'techroom', auth: true },
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth) {
    try {
      await store.dispatch('requestUserInfo');
      next();
    } catch (error) {
      next('/');
    }
  } else {
    next();
  }
  if (localStorage.getItem('token') && to.name === 'Authorization') {
    await store.dispatch('requestUserInfo');
    store.commit('updateIsAuthenicated', true);
  }
});

export default router;
