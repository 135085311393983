import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    services: [],
  },
  getters: {
    getServices(state) {
      return state.services;
    },
  },
  mutations: {
    updateServices(state, res) {
      state.services = res;
    },
  },
  actions: {
    async requestAddressServices({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/services/address/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateServices', result.data.services);
      } catch (error) {
        console.error(error);
      }
    },

    async createService({ getters }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/service`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            organization: formData.organization,
            service: formData.service,
            price: formData.price,
            contractNumber: formData.contractNumber,
            addressId: getters.getAddress.id,
            misc: formData.misc,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editService({ getters }, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/service/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            organization: formData.organization,
            service: formData.service,
            price: formData.price,
            contractNumber: formData.contractNumber,
            addressId: getters.getAddress.id,
            misc: formData.misc,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async removeService(ctx, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/service/${data.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            deleteStatus: data.status,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
