<template>
  <v-layout>
    <v-app-bar-nav-icon
      v-if="width < 1280 && width !== 0"
      @click="drawer = true"
      variant="text"
    />
    <v-navigation-drawer v-model="drawer" class="sidebar">
      <a href="/techroom" class="v-navigation-icon">
        <IconTechroom viewBox="0 0 305 203" width="150" height="150" />
      </a>
      <UserMenu />
      <v-list>
        <v-list-item
          v-for="item in listItems"
          :key="item"
          :prepend-icon="item.icon"
          :title="item.title"
          :active="isActive(item.url)"
          @click="$router.push(item.url)"
        />
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import UserMenu from '@/components/app/techroom/UserMenu';
export default {
  components: { UserMenu },
  data: () => ({
    date: new Date(),
    interval: null,
    drawer: window.innerWidth >= 1280 && window.innerWidth !== 0 ? true : false,
    width: window.innerWidth,
    panel: null,
    colors: ['brown'],
  }),
  methods: {
    isActive(url) {
      return url === this.$route.path;
    },
    select(router) {
      setTimeout(() => {
        this.$router.push(router);
      }, 250);
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  mounted() {
    if (this.$route.path.includes('leads')) {
      this.panel = 0;
    }
    if (this.$route.path.includes('clients')) {
      this.panel = 1;
    }
    this.interval = setInterval(() => {
      this.date = new Date();
    }, 1000);
    window.addEventListener('resize', this.updateWidth);
  },
  computed: {
    listItems() {
      if (
        this.$store.getters.USER?.selectedRole.role === 'DIRECTOR' &&
        this.$store.getters.USER?.selectedRole.organization === 'techroom'
      ) {
        return [
          {
            title: 'Личный кабинет',
            icon: 'mdi-account',
            url: '/techroom',
          },
          {
            title: 'Лиды',
            icon: 'mdi-view-list',
            url: '/techroom/leads',
          },
          {
            title: 'Клиенты',
            icon: 'mdi-account-group',
            url: '/techroom/clients',
          },
          {
            title: 'Организации',
            icon: 'mdi-office-building-marker-outline',
            url: '/techroom/organizations',
          },
          // {
          //   title: "Обращения",
          //   icon: "mdi-bell-ring",
          //   url: "/techroom/appeals",
          // },
          {
            title: 'Пользователи',
            icon: 'mdi-account-multiple',
            url: '/techroom/users',
          },
          {
            title: 'Планы',
            icon: 'mdi-calendar',
            url: '/techroom/plans',
          },
          // {
          //   title: "Архив",
          //   icon: "mdi-archive",
          //   url: "/techroom/archive",
          // },
          {
            title: 'Склад',
            icon: 'mdi-garage-variant',
            url: '/techroom/stock',
          },
          // {
          //   title: "Проекты",
          //   icon: "mdi-folder-multiple",
          //   url: "/techroom/projects",
          // },
        ];
      } else if (
        this.$store.getters.USER?.selectedRole.role === 'MANAGER' &&
        this.$store.getters.USER?.selectedRole.organization === 'techroom'
      ) {
        return [
          {
            title: 'Личный кабинет',
            icon: 'mdi-account',
            url: '/techroom',
          },
          {
            title: 'Лиды',
            icon: 'mdi-view-list',
            url: '/techroom/leads',
          },
          {
            title: 'Клиенты',
            icon: 'mdi-account-group',
            url: '/techroom/clients',
          },
          // {
          //   title: "Обращения",
          //   icon: "mdi-bell-ring",
          //   url: "/techroom/appeals",
          // },
          {
            title: 'Планы',
            icon: 'mdi-calendar',
            url: '/techroom/plans',
          },
          // {
          //   title: "Архив",
          //   icon: "mdi-alarm",
          //   url: "/techroom/archive",
          // },

          // {
          //   title: "Проекты",
          //   icon: "mdi-folder-multiple",
          //   url: "/techroom/projects",
          // },
        ];
      }

      return [
        { title: 'Личный кабинет', icon: 'mdi-account', url: '/techroom' },
        {
          title: 'Организации',
          icon: 'mdi-office-building-marker-outline',
          url: '/techroom/organizations',
        },
        {
          title: 'Планы',
          icon: 'mdi-calendar',
          url: '/techroom/plans',
        },
        // {
        //   title: "Архив",
        //   icon: "mdi-alarm",
        //   url: "/techroom/archive",
        // },
        {
          title: 'Склад',
          icon: 'mdi-folder-outline',
          url: '/techroom/stock',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  .select {
    margin-top: rem(32);

    .v-list-item__append {
      i {
        display: none;
      }
    }

    .panel__icon {
      transition: var(--transition-out);
    }

    .v-list-item--active {
      .panel__icon {
        transition: var(--transition-in);
        transform: rotate(-180deg);
      }
    }

    .v-list-item-title {
      display: flex;
      align-items: center;
      gap: rem(2);
    }

    .v-list-item--density-default {
      &:not(.v-list-item--nav).v-list-item--one-line {
        gap: rem(22);
      }
    }

    .v-list {
      padding: rem(8) 0 0 0;
    }

    .v-list-item__prepend > .v-icon {
      margin-inline-end: rem(8);
    }

    .v-expansion-panel-title__icon {
      transition: var(--transition-in);
    }

    .panel__icon {
      transition: var(--transition-out);

      &.active {
        transition: var(--transition-in);
        transform: rotate(-180deg);
      }
    }

    .v-expansion-panel-title {
      min-height: auto;
      gap: rem(8);

      .panel__icon {
        transition: var(--transition-out);
      }

      &--active {
        .panel__icon {
          transition: var(--transition-in);
          transform: rotate(-180deg);
        }
      }
    }

    .v-expansion-panel__shadow {
      display: none;
    }

    .v-expansion-panel--active:not(:first-child),
    .v-expansion-panel--active + .v-expansion-panel {
      margin-top: 0;
    }

    .v-expansion-panel-text__wrapper {
      padding: 0 rem(24) 0;
    }
  }
}

.v-navigation-icon {
  cursor: pointer;
}

.techroom_button {
  border-color: #ff5757;
  color: #ff5757;

  svg {
    fill: #ff5757 !important;
  }
}
</style>
