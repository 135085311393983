<template>
  <div class="header__block">
    <div class="item_header">
      <div class="page_menu_title">
        <svg height="22" width="22" viewBox="0 0 24 24">
          <path
            d="M12 11.375q-1.25 0-2.125-.875T9 8.375q0-1.225.875-2.113.875-.887 2.125-.887t2.125.887Q15 7.15 15 8.375q0 1.25-.875 2.125T12 11.375Zm-7 7.25v-1.65q0-.625.363-1.163.362-.537.962-.837 1.425-.675 2.838-1.013 1.412-.337 2.837-.337 1.425 0 2.838.337 1.412.338 2.837 1.013.6.3.963.837.362.538.362 1.163v1.65Zm1-1h12v-.65q0-.35-.212-.638-.213-.287-.588-.487-1.25-.6-2.562-.912-1.313-.313-2.638-.313t-2.637.313q-1.313.312-2.563.912-.375.2-.587.487-.213.288-.213.638Zm6-7.25q.825 0 1.413-.588Q14 9.2 14 8.375t-.587-1.412q-.588-.588-1.413-.588-.825 0-1.412.588Q10 7.55 10 8.375t.588 1.412q.587.588 1.412.588Zm0-2Zm0 9.25Z"
          />
        </svg>
        Календарь
      </div>
    </div>
    <div class="item_header">
      <div class="page_menu_title">
        <router-link to="/techroom/plans" class="v-navigation-icon"
          >Задачи</router-link
        >
        <router-link to="/techroom/plans/calendar" class="v-navigation-icon"
          >Календарь</router-link>
        <router-link to="/techroom/plans/archive" class="v-navigation-icon">
          Архив
        </router-link>
      </div>
    </div>
  </div>
  <div class="account__page">
    <div class="plans style-scoped">
      <Calendar />
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/app/techroom/Calendar.vue';
export default {
  components: {
    Calendar,
  },
};
</script>

<style lang="scss">
.plans.style-scoped {
  display: block;

  .custom-calendar {
    width: 100%;
    height: 100%;
  }
}
</style>
