<template>
  <div class="item_header">
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title flex-column align-start">
        <h2 class="font-weight-bold">Кабинеты</h2>
        <small>по адресу {{ getAddress.title }}</small>
      </div>
      <div class="header_floors">
        <SelectFloor />
        <div class="page_menu_title">
          <div
            class="link"
            :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }"
            @click="this.archive = null"
          >
            Все
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
            @click="this.archive = false"
          >
            Активные
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }"
            @click="this.archive = true"
          >
            В архиве
          </div>
        </div>
      </div>
    </div>
    <div class="header_right">
      <div
        class="d-flex justify-space-around align-center flex-column flex-md-row"
      >
        <v-btn
          class="button-add"
          color="#ff5757"
          @click="openDialogCreate"
          icon="mdi-plus"
        ></v-btn>
      </div>
    </div>
  </div>
  <v-table>
    <thead>
      <tr>
        <th class="text-left start">Кабинеты</th>
        <th class="text-left">Компьютеры</th>
        <th class="text-left">Оборудование</th>
        <th class="text-center">
          <v-icon>mdi-cogs</v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in getCabinets"
        :key="item.id"
        :style="{
          color: item.delete ? '#7c7c7c' : '#000',
          transition: '.15s ease',
        }"
        @click="openDialog(item)"
      >
        <td class="text-left start">{{ item.num }}</td>
        <td class="text-left">{{ item.computers.length }}</td>
        <td class="text-left">{{ item.equipments.length }}</td>
        <td class="text-center">
          <v-menu class="actions-list">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small">
                <v-icon size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialog(item)"
              >
                <v-list-item-title> Подробнее </v-list-item-title>
              </v-list-item>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialogEdit(item)"
              >
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.delete"
                min-height="1"
                :value="item.id"
                @click="removeCabinet(item.id, true)"
              >
                <v-list-item-title> Удалить </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                min-height="1"
                :value="item.id"
                @click="removeCabinet(item.id, false)"
              >
                <v-list-item-title> Восстановить </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogAddCabinet
    v-model="dialogAddCabinet"
    @close="dialogAddCabinet = false"
  />
  <DialogCabinet
    v-model="dialogCabinet"
    @close="dialogCabinet = false"
    :cabinet="cabinet"
  />
  <DialogEditCabinet
    v-model="dialogEditCabinet"
    @close="dialogEditCabinet = false"
    :cabinet="cabinet"
  />
</template>

<script>
import SelectFloor from '@/components/app/techroom/specialist/organization/SelectFloor.vue';
import DialogAddCabinet from '@/components/app/techroom/specialist/organization/cabinets/DialogAddCabinet.vue';
import DialogCabinet from '@/components/app/techroom/specialist/organization/cabinets/DialogCabinet.vue';
import DialogEditCabinet from '@/components/app/techroom/specialist/organization/cabinets/DialogEditCabinet.vue';

export default {
  components: {
    DialogAddCabinet,
    DialogCabinet,
    DialogEditCabinet,
    SelectFloor,
  },
  data() {
    return {
      cabinet: null,
      archive: null,
      dialogAddCabinet: false,
      dialogCabinet: false,
      dialogEditCabinet: false,
    };
  },

  computed: {
    getAddress() {
      return this.$store.getters['techroom/specialist/getAddress'];
    },
    floorId() {
      return this.$store.getters['techroom/specialist/getFloorId'];
    },
    getCabinets() {
      if (this.archive === true) {
        return this.$store.getters['techroom/specialist/getCabinets'].filter(
          (item) => item.delete
        );
      }
      if (this.archive === false) {
        return this.$store.getters['techroom/specialist/getCabinets'].filter(
          (item) => !item.delete
        );
      }
      return this.$store.getters['techroom/specialist/getCabinets'];
    },
  },

  watch: {
    async getAddress() {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressCabinets',
        this.$store.getters['techroom/specialist/getAddress'].id
      );

      await this.$store.commit('techroom/specialist/updateFloorId', null);
    },

    async floorId() {
      if (this.floorId) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorCabinets',
          this.floorId
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressCabinets',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },

  methods: {
    async openDialogCreate() {
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      this.dialogAddCabinet = true;
    },
    async openDialog(item) {
      this.cabinet = item;
      this.dialogCabinet = true;
    },
    async openDialogEdit(item) {
      this.cabinet = item;
      this.dialogEditCabinet = true;
    },
    async removeCabinet(id, status) {
      await this.$store.dispatch('techroom/specialist/removeCabinet', {
        id,
        status,
      });

      if (this.$store.getters['techroom/specialist/getFloorId']) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorCabinets',
          this.$store.getters['techroom/specialist/getFloorId']
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressCabinets',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },
  async mounted() {
    if (this.$store.getters['techroom/specialist/getFloorId']) {
      await this.$store.dispatch(
        'techroom/specialist/requestFloorCabinets',
        this.$store.getters['techroom/specialist/getFloorId']
      );
    } else {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressCabinets',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    }
  },
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}

.header_search {
  padding: 0px 55px 4px 20px;
  width: 280px;
  height: 40px;
  border: 1px solid black;
  border-radius: 100px;
  font-size: 20px;
  outline: none;
}

.mx-auto {
  min-width: 250px;
  color: #ff5757;
}

.v-field:focus {
  color: #ff5757;
}

.v-field--variant-filled .v-field__overlay:focus {
  color: red;
}

/* content */

.item_content_row {
  margin-top: 40px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.item_content_row_block {
  width: 100%;
  font-size: 20px;
  color: #737373;
}

.item_content_row_out {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 2px solid #ff5757;
  text-align: center;
}

.item_content_row_icon {
  display: flex;
  justify-content: center;
  width: 20%;
}

.item_content_row_block_out {
  width: 100%;
  font-size: 16px;
  color: black;
  line-height: 25px;
}
</style>
