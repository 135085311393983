<template>
  <v-table density="comfortable">
    <thead>
      <tr>
        <th class="text-left">Контактное лицо</th>
        <th class="text-left">Номер телефона</th>
        <th class="text-left">Электронная почта</th>
        <th class="text-left">Название компании</th>
        <th class="text-left">Комментарий</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in $store.getters['itroom/manager/getCalls']" :key="item.id">
        <td v-if="item.typeCall === 2">
          <div>
            <v-tooltip activator="parent" location="top">
              Юридическое лицо
            </v-tooltip>
            <IconEntity />
          </div>
          {{ item.individual }}
        </td>
        <td v-else>
          <div>
            <v-tooltip activator="parent" location="top">
              Физическое лицо
            </v-tooltip>
            <IconIndividual />
          </div>
          {{ item.individual }}
        </td>
        <td>{{ $filters.filterNumber(item.phone) }}</td>
        <td v-if="!item.email" class="empty">- не указана -</td>
        <td v-else>{{ item.email }}</td>
        <td v-if="!item.nameCompany" class="empty">- не указано -</td>
        <td v-else>{{ item.nameCompany }}</td>
        <td v-if="!item.comment" class="empty">- не указан -</td>
        <td v-else class="td-item-comment">
          <div>
            <v-btn v-if="count(item.comment) > 50" variant="text" size="x-small" :value="item.id"
              @click="handlerComment($event)">
              Посмотреть больше
              <svg height="20" width="20">
                <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z" />
              </svg>
            </v-btn>
            <v-btn v-if="count(item.comment) > 50" variant="text" size="x-small"
              class="manage-item-more manage-item fill-height" :value="item.id" @click="openDialogComment(item.id)">
              <v-tooltip activator="parent" location="top">
                Открыть в окне
              </v-tooltip>
              <IconOpen />
            </v-btn>
          </div>
          {{ item.comment }}
        </td>
        <td class="text-right">
          <!-- Важно -->
          <!-- <v-btn variant="text" size="x-small" class="fill-height">
              <svg height="20" width="20">
                <path
                  d="M7.292 14.958 10 13.354l2.729 1.604-.708-3.041 2.354-2.021-3.125-.292L10 6.708 8.75 9.625l-3.125.271 2.396 2Zm-.938 1.25.979-4.062-3.229-2.729 4.229-.375L10 5.208l1.667 3.854 4.229.355-3.229 2.729.979 4.062L10 14.042ZM10 11.042Z"
                />
              </svg>
            </v-btn> -->
          <!-- Важно -->

          <!-- Подробнее -->
          <v-btn variant="text" size="x-small" class="manage-item-more manage-item fill-height" :value="item.id"
            @click="openDialog(item.id)">
            <v-tooltip activator="parent" location="top">Подробнее</v-tooltip>
            <IconView />
          </v-btn>
          <!-- Подробнее -->

          <!-- Редактировать -->
          <v-btn variant="text" size="x-small" class="manage-item-edit manage-item fill-height" :value="item.id"
            @click="openDialogEdit(item.id)">
            <v-tooltip activator="parent" location="top">
              Редактирование
            </v-tooltip>
            <IconEdit />
          </v-btn>
          <!-- Редактировать -->

          <!-- Удаление -->
          <v-btn variant="text" size="x-small" class="manage-item-delete manage-item fill-height" :value="item.id"
            @click="openDialogDelete(item)">
            <v-tooltip activator="parent" location="top">
              Перенести в архив
            </v-tooltip>
            <IconDelete />
          </v-btn>
          <!-- Удаление -->
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogCall :call="call" v-model="dialog" @close="dialog = false" />
  <DialogEditCall :call="call" v-model="dialogEdit" @close="dialogEdit = false" />
  <DialogDeleteCall :call="call" v-model="dialogDelete" @close="dialogDelete = false" />
  <DialogCommentCall :call="call" v-model="dialogComment" @close="dialogComment = false" />
  <!-- <Botbar /> -->
</template>

<script>
import DialogCall from "@/components/app/itroom/manager/calls/DialogCall.vue";
import DialogEditCall from "@/components/app/itroom/manager/calls/DialogEditCall.vue";
import DialogDeleteCall from "@/components/app/itroom/manager/calls/DialogDeleteCall.vue";
import DialogCommentCall from "@/components/app/itroom/manager/calls/DialogCommentCall.vue";
// import Botbar from "@/components/app/itroom/manager/calls/BotbarCreateCall.vue";
export default {
  components: {
    DialogCall,
    DialogEditCall,
    DialogDeleteCall,
    DialogCommentCall,
  },
  emits: ["loading"],
  data() {
    return {
      call: null,
      file: null,
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogComment: false,
    };
  },
  methods: {
    count(str) {
      if (str) {
        return str.length;
      }
    },
    handlerComment(event) {
      const td = event.target.parentElement.parentElement;
      const tdRow = event.target.parentElement.parentElement.parentElement;
      if (event.target.children[2].innerHTML.includes("Свернуть комментарий")) {
        console.log("hide");
        td.classList.remove("more");
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Посмотреть больше
          <svg height="20" width="20">
            <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          _this.classList.remove("top");
        }
      } else {
        td.classList.add("more");
        console.log(event.target.children);
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Свернуть комментарий
          <svg height="20" width="20">
            <path d="m6.104 12.396-.458-.458L10 7.604l4.354 4.334-.458.458L10 8.479Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          if (!_this.classList.contains("td-item-comment")) {
            _this.classList.add("top");
          }
          // console.log(_this);
        }
      }
    },
    async openDialog(id) {
      await this.$store.dispatch("itroom/manager/requestCall", id);
      this.call = this.$store.getters["itroom/manager/getCall"];
      this.dialog = true;
    },
    async openDialogEdit(id) {
      await this.$store.dispatch("itroom/manager/requestCall", id);
      this.call = this.$store.getters["itroom/manager/getCall"];
      this.dialogEdit = true;
    },
    async openDialogDelete(item) {
      this.call = item;
      this.dialogDelete = true;
    },
    async openDialogComment(id) {
      await this.$store.dispatch("itroom/manager/requestCall", id);
      this.call = this.$store.getters["itroom/manager/getCall"];
      this.dialogComment = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("itroom/manager/requestCalls");
    setTimeout(() => {
      this.$emit("loading");
    }, 500);
  },
};
</script>

<style lang="scss">
// 20 символов
.td-item-comment {
  text-overflow: ellipsis;
  max-width: rem(250);
  overflow: hidden;
  position: relative;

  &.more {
    line-break: anywhere;
    max-width: rem(250);
  }

  div {
    display: flex;
    justify-content: center;
  }

  button {
    padding: 0 !important;
    height: auto !important;
    color: var(--blue-0);

    svg {
      fill: var(--blue-0);
    }

    &:nth-child(1) {
      // width: 100%;
      height: rem(20) !important;
      padding: 0 rem(8) !important;
    }

    &:nth-child(2) {
      order: -1;
      z-index: 2;
    }
  }

  .v-btn__content {
    pointer-events: none;
    text-transform: none;
  }
}

.manage-item {
  transition: var(--transition-out);

  svg {
    transition: var(--transition-in);
  }
}

.manage-item-more {
  &:hover {
    svg {
      fill: var(--blue-0);
    }
  }
}

.manage-item-edit {
  &:hover {
    svg {
      fill: var(--yellow-0);
    }
  }
}

.manage-item-delete {
  &:hover {
    svg {
      fill: var(--red-0);
    }
  }
}

.page__calls {
  overflow: scroll;
  max-height: calc(100vh - rem(25) - rem(161));

  .v-table__wrapper {
    margin: 0 0 rem(25) 0;
  }

  tbody {
    tr {
      td {
        &.top {
          padding-top: rem(10) !important;
          vertical-align: top;

          button {
            height: auto !important;
          }

          &:first-child {
            padding-top: rem(8) !important;
          }
        }

        &:first-child {
          div {
            float: left;
            margin: 0 rem(8) 0 0;
          }
        }
      }
    }
  }
}

.empty {
  user-select: none;
  color: var(--white-10);
}

.botbar {
  width: calc(100% - rem(256));
  position: fixed;
  bottom: 0;
  left: rem(255);
  background-color: #fff;
  padding: rem(24);
  z-index: 1005;
  border-top: rem(1) solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: rem(18);

  &__row:nth-child(1) {
    display: flex;
    gap: rem(32);
  }

  &__start {
    display: flex;
    gap: rem(16);
  }

  &__center {
    display: flex;
    gap: rem(16);
    width: 100%;
  }

  &__end {
    paper-button {
      padding: 0 rem(16);
      border: rem(1) solid var(--green-14);
      color: var(--green-14);

      svg {
        fill: var(--green-14);
      }
    }
  }
}

.v-select {
  width: rem(250);

  .v-field--variant-solo {
    box-shadow: none;
    background: #fafafa;
    border: rem(1) solid #e0e0e0;
  }
}

.v-input {
  position: relative;

  &__control {
    margin-bottom: rem(6);
  }

  &--error {
    &:not(.v-field--disabled) {
      .v-field__outline {
        color: var(--input-error-color);
      }

      .v-label.v-field-label {
        color: var(--input-error-color);
      }
    }

    .v-field {}

    .v-messages {
      &__message {
        display: flex;
        align-items: center;
        gap: rem(4);
        color: var(--input-error-color);

        &::before {
          content: url("data:image/svg+xml, %3Csvg fill='%23f4553d' width='16px' height='16px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }
  }
}

.v-input__details {
  // min-height: unset;
  // padding-top: 0;
  // padding-inline-start: 0;
  // padding-inline-end: 0;
  position: absolute;
  bottom: rem(-18);
}
</style>