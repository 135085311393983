<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Информация о пользователе</h2>
        <v-form
          ref="form"
          class="form_cabinet"
          v-model="valid"
          @submit.prevent="valid ? submitHandler() : validate()"
        >
          <v-text-field
            v-model="formData.surname"
            label="Фамилия"
            :rules="[() => !!formData.surname || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.name"
            label="Имя"
            :rules="[() => !!formData.name || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.lastName"
            label="Отчество"
            :rules="[() => !!formData.lastName || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.email"
            label="Почта"
            :rules="[
              () => !!formData.email || 'Обязательное поле',
              () =>
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) || 'Недействительный адрес электронной почты',
            ]"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.dateBirth"
            label="Дата рождения"
            :rules="[() => !!formData.dateBirth || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.login"
            label="Логин"
            :rules="[() => !!formData.login || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.password"
            label="Пароль"
            :rules="[() => !!formData.password || 'Обязательное поле']"
          ></v-text-field>
          <v-select
            v-model="formData.roles"
            label="Роль"
            :items="
              this.$store.getters['itroom/director/getSettingsUser'].roles
            "
            item-title="name"
            item-value="id"
            multiple
            :rules="[() => !!formData.roles.length || 'Обязательное поле']"
          ></v-select>
          <v-select
            v-model="formData.jobs"
            label="Работа"
            :items="this.$store.getters['itroom/director/getSettingsUser'].jobs"
            item-title="jobName"
            item-value="id"
            multiple
          ></v-select>
          <v-select
            v-model="formData.stacks"
            label="Стек"
            :items="
              this.$store.getters['itroom/director/getSettingsUser'].stacks
            "
            item-title="stackName"
            item-value="id"
            multiple
          >
          </v-select>
          <v-btn type="submit" class="cabinet_btn">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,

      formData: {
        id: null,
        surname: null,
        name: null,
        lastName: null,
        email: null,
        dateBirth: null,
        login: null,
        password: null,
        roles: [],
        jobs: [],
        stacks: [],
      },
    };
  },

  watch: {
    user() {
      this.formData.id = this.user.id;
      this.formData.surname = this.user.surname;
      this.formData.name = this.user.name;
      this.formData.lastName = this.user.lastName;
      this.formData.email = this.user.email;
      this.formData.dateBirth = this.user.dateBirth;
      this.formData.login = this.user.login;
      this.formData.password = this.user.password;
      this.formData.roles = this.user.roles.map((item) => item.id);
      this.formData.jobs = this.user.itroom_jobs.map((item) => item.id);
      this.formData.stacks = this.user.itroom_stacks.map((item) => item.id);
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async submitHandler() {
      try {
        await this.$store.dispatch("itroom/director/editUser", this.formData);
        this.$emit("close");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>