<template>
  <div>
    <div class="header__block mb-3">
      <div class="item_header">
        <div class="page_menu_title">
          <svg height="22" width="22" viewBox="0 0 24 24">
            <path
              d="M12 11.375q-1.25 0-2.125-.875T9 8.375q0-1.225.875-2.113.875-.887 2.125-.887t2.125.887Q15 7.15 15 8.375q0 1.25-.875 2.125T12 11.375Zm-7 7.25v-1.65q0-.625.363-1.163.362-.537.962-.837 1.425-.675 2.838-1.013 1.412-.337 2.837-.337 1.425 0 2.838.337 1.412.338 2.837 1.013.6.3.963.837.362.538.362 1.163v1.65Zm1-1h12v-.65q0-.35-.212-.638-.213-.287-.588-.487-1.25-.6-2.562-.912-1.313-.313-2.638-.313t-2.637.313q-1.313.312-2.563.912-.375.2-.587.487-.213.288-.213.638Zm6-7.25q.825 0 1.413-.588Q14 9.2 14 8.375t-.587-1.412q-.588-.588-1.413-.588-.825 0-1.412.588Q10 7.55 10 8.375t.588 1.412q.587.588 1.412.588Zm0-2Zm0 9.25Z" />
          </svg>
          <span v-if="!archive">Активные лиды</span>
          <span v-else>Лиды в архиве</span>
        </div>
        <div class="d-flex justify-space-around align-center flex-column flex-md-row">
          <v-btn class="button-add" color="#ff5757" @click="dialogAddLead = true" icon="mdi-plus" />
        </div>
      </div>
      <div class="item_header">
        <div class="page_menu_title">
          <div class="link" @click="archive = false">Активные</div>
          <div class="link" @click="archive = true">В архиве</div>
        </div>
      </div>
    </div>
    <desktop v-if="$store.state.width >= 991" @openDialogEdit="openDialogEdit"
      @openDialogAddClient="openDialogAddClient" @openDialogRemoveLead="openDialogRemoveLead"
      @openDialogShowCommentLead="openDialogShowCommentLead" @restoreClient="restoreClient" @restore="restore"
      :archive="archive" />
    <mobile v-else @openDialogEdit="openDialogEdit" @openDialogAddClient="openDialogAddClient"
      @openDialogRemoveLead="openDialogRemoveLead" @restoreClient="restoreClient" @restore="restore"
      :archive="archive" />

    <v-snackbar v-model="snackbar" timeout="3000">
      {{ message }}
      <template v-slot:actions>
        <v-btn color="var(--red-0)" variant="text" size="small" @click="snackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>

    <DialogAddLead v-model="dialogAddLead" @close="dialogAddLead = false" />
    <DialogEditLead v-model="dialogEditLead" @close="dialogEditLead = false" :lead="lead" />
    <DialogAddClient v-model="dialogAddClient" @close="dialogAddClient = false" :lead="lead" />
    <DialogShowCommentLead v-model="dialogShowCommentLead" @close="dialogShowCommentLead = false" :lead="lead" />
    <DialogRemoveLead v-model="dialogRemoveLead" @close="dialogRemoveLead = false" :lead="lead" />
  </div>
</template>

<script>
import DialogAddLead from '@/components/app/techroom/manager/leads/DialogAddLead.vue';
import DialogEditLead from '@/components/app/techroom/manager/leads/DialogEditLead.vue';
import DialogAddClient from '@/components/app/techroom/manager/leads/DialogAddClient.vue';
import DialogShowCommentLead from '@/components/app/techroom/manager/leads/DialogShowCommentLead.vue';
import DialogRemoveLead from '@/components/app/techroom/manager/leads/DialogRemoveLead.vue';
import desktop from './views/desktop.vue';
import mobile from './views/mobile.vue';

export default {
  components: {
    DialogAddLead,
    DialogAddClient,
    DialogEditLead,
    DialogShowCommentLead,
    DialogRemoveLead,
    desktop,
    mobile,
  },
  data() {
    return {
      archive: false,
      leads: null,
      lead: null,
      dialogAddLead: false,
      dialogEditLead: false,
      dialogAddClient: false,
      dialogShowCommentLead: false,
      dialogRemoveLead: false,
      snackbar: false,
      message: '',
    };
  },
  methods: {
    async restore(lead) {
      const request = await this.$store.dispatch(
        'techroom/manager/restoreLead',
        lead.id
      );
      await this.$store.dispatch('techroom/manager/requestLeads');
      this.snackbar = true;
      if (!request) {
        this.message = 'Ошибка при восстановлении';
      } else {
        this.message = 'Успешное восстановление';
      }
    },
    async restoreClient(id, index) {
      await this.$store.dispatch('techroom/manager/restoreClient', id);
      this.leads.splice(index, 1);
    },
    openDialogEdit(item) {
      this.lead = item;
      this.dialogEditLead = true;
    },
    openDialogAddClient(item) {
      this.lead = item;
      this.dialogAddClient = true;
    },
    openDialogShowCommentLead(item) {
      this.lead = item;
      this.dialogShowCommentLead = true;
    },
    openDialogRemoveLead(item) {
      this.lead = item;
      this.dialogRemoveLead = true;
    },
  },
  async mounted() {
    await this.$store.dispatch('techroom/manager/requestLeads');
  },
};
</script>

<style lang="scss">
.tables {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page_menu_title {
  display: flex;
  align-items: center;
  gap: rem(8);
  font-size: rem(16);

  a,
  .link {
    color: var(--red-0);
    font-weight: 400;
    font-size: rem(13);
    display: block;
    padding: 0;
    width: max-content;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.v-list-item__content {
  display: flex;
  align-items: center;
}

.page__leads {
  max-height: 100vh;
  overflow: scroll;
}

.header__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  .filters {
    font-size: 20px;
    display: flex;
    gap: 16px;
    padding: 15px;

    .filter__block {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .checkboxleads {
      width: 1em;
      height: 1em;
    }
  }
}

.item_rows_out {
  overflow-y: scroll;
  max-height: 94vh;
}

.btn__menu__row {
  font-size: 12px;
}
</style>
