<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление оборудование</div>
        <v-form
          ref="form"
          @submit.prevent="submitHandler"
          class="form_cabinet"
          v-model="valid"
        >
          <v-select
            v-if="!stock"
            label="Выберите кабинет"
            v-model="formData.cabinetId"
            :items="
              $store.getters['techroom/specialist/getCabinets'].filter(
                (item) => !item.delete
              )
            "
            item-title="num"
            item-value="id"
            :rules="[() => !!formData.cabinetId || 'Обязательно']"
            required
            no-data-text="Нет"
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.num"
            label="Инвентарный №"
            :rules="[() => !!formData.num || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.serialnum"
            label="Серийный №"
            :rules="[() => !!formData.serialnum || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.type"
            label="Тип оборудования"
            :rules="[() => !!formData.type || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.model"
            label="Модель"
            :rules="[() => !!formData.model || 'Обязательно']"
            required
          />
          <v-select
            label="Состояние оборудования"
            v-model="formData.statusId"
            :items="$store.getters['techroom/specialist/getStatuses']"
            item-title="title"
            item-value="id"
            no-data-text="Нет"
            clearable
          />
          <v-textarea
            class="text_field_input"
            v-model="formData.misc"
            :rules="[
              () => formData.statusId !== 4 || !!formData.misc || 'Обязательно',
            ]"
            label="Заметки"
          />
          <v-btn type="submit" class="cabinet_btn">Добавить оборудование</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    stock: {
      type: Boolean,
    },
  },
  data: () => ({
    valid: false,
    formData: {
      type: null,
      model: null,
      num: null,
      serialnum: null,
      cabinetId: null,
      misc: null,
      statusId: null,
    },
  }),

  methods: {
    async submitHandler() {
      await this.$refs.form.validate();
      if (this.valid) {
        try {
          if (this.stock) {
            this.formData.stock = true;
            this.$emit('snackbar');
          }
          await this.$store.dispatch(
            'techroom/specialist/createEquipment',
            this.formData
          );

          if (this.$store.getters['techroom/specialist/getFloorId']) {
            await this.$store.dispatch(
              'techroom/specialist/requestFloorEquipments',
              this.$store.getters['techroom/specialist/getFloorId']
            );
          } else {
            await this.$store.dispatch(
              'techroom/specialist/requestAddressEquipments',
              this.$store.getters['techroom/specialist/getAddress'].id
            );
          }
          if (this.stock) {
            await this.$store.dispatch(
              'techroom/specialist/requestStockEquipments'
            );
          }
          this.$refs.form.reset();
          this.$emit('close');
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>
