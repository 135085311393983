<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Редактирование Пользователя</div>
        <v-form ref="form" lazy-validation class="form_cabinet" @submit.prevent="editAppeal">
          <v-text-field class="text_field_input" v-model="formData.surname" label="Фамилия" :rules="[
            () => !!formData.surname || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.name" label="Имя" :rules="[
            () => !!formData.name || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.lastName" label="Отчество" :rules="[
            () => !!formData.lastName || 'Обязательное поле',
          ]" required>
          </v-text-field>
<!--          <v-text-field class="text_field_input" v-model="formData.dateBirth" label="Дата рождения" :rules="[-->
<!--            () => !!formData.dateBirth || 'Обязательное поле',-->
<!--          ]" required v-maska="'##.##.####'">-->
<!--          </v-text-field>-->
          <v-text-field class="text_field_input" v-model="formData.email" label="Эл. почта" :rules="[
            () => !!formData.email || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.phone" label="Телефон" :rules="[
            () => !!formData.phone || 'Обязательное поле',
          ]" required>
          </v-text-field>
          <v-text-field class="text_field_input" v-model="formData.login" label="Логин" :rules="[
            () => !!formData.login || 'Обязательное поле',
          ]" required>
          </v-text-field>
<!--          <v-text-field class="text_field_input" v-model="formData.password" label="Пароль" :rules="rules.password"-->
<!--                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"-->
<!--                        @click:append="show = !show" required>-->
<!--          </v-text-field>-->
          <v-select
              v-model="formData.roles"
              :items="$store.getters['techroom/director/getTechRoles']"
              item-title="name"
              item-value="roleId"
              filled
              chips
              label="Выберите роли"
              multiple
              :rules="[
            () => !!formData.roles[0] || 'Минимум 1 роль']"
              required
          ></v-select>
          <v-btn type="submit" @click="validate($event)" class="cabinet_btn">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogEditUser",
  data() {
    return {

    }
  },
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    formData() {
      return this.user;
    },
  },
}
</script>

<style scoped>

</style>