import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    clients: null,
    client: null,
  },
  getters: {
    getClients(state) {
      return state.clients
    },
    getClient(state) {
      return state.client
    }
  },
  mutations: {
    updateClients(state, res) {
      state.clients = res
    },
    updateClient(state, res) {
      state.client = res
    }
  },
  actions: {
    async requestClients({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/clients`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateClients', result.data.Message.clients)
      } catch (error) {
        console.error(error);
      }
    },

    async requestClient({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/clients/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateClient', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async editClient({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/client/edit`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            id: formData.id,
            nameCompany: formData.nameCompany,
            individual: formData.individual,
            email: formData.email,
            phone: formData.phone.replace(/[^0-9]/g, ""),
            comment: formData.comment,
          }
        })
        console.log(result);
        await dispatch('requestClients')
      } catch (error) {
        console.error(error);
      }
    },

    async updateStatusClient({ dispatch }, { id, status }) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/client/updateStatus`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            id,
            status,
          }
        })
        await dispatch('requestClients')
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    }
  },
};
