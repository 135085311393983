import axios from 'axios'
import { url } from '@/store'


export default {
  state: {
    software: null,
  },
  getters: {
    getSoftware(state) {
      return state.software
    },
  },
  mutations: {
    updateSoftware(state, res) {
      state.software = res
    },
  },
  actions: {
    async requestAddressSoftware({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/software/address/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateSoftware', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async createSoftware({ getters }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/software`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            price: formData.price,
            subscriptionDate: formData.subscriptionDate,
            debitDate: formData.debitDate,
            addressId: getters.getAddress.id,
            misc: formData.misc,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error
      }
    },

    async editSoftware({ getters }, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/software/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            price: formData.price,
            subscriptionDate: formData.subscriptionDate,
            debitDate: formData.debitDate,
            addressId: getters.getAddress.id,
            misc: formData.misc,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error
      }
    },

    async removeSoftware(ctx, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/software/${data.id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            deleteStatus: data.status,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error
      }
    },
  }
}