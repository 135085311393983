<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление объекта</div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="form_cabinet"
          @submit.prevent="submitHandler"
        >
          <v-text-field
            class="text_field_input"
            v-model="formData.name"
            :rules="[() => !!formData.name || 'Обязательное поле']"
            label="Объект"
           
          ></v-text-field>
          <div class="date d-flex col-5 ">
            <v-text-field
              class="date_input ml-2 mr-2 d-flex"
              :rules="[() => !!formData.dateStart || 'Обязательное поле']"
              v-model="formData.dateStart"
              label="Старт"
              type="date"
          
            ></v-text-field>
            <v-text-field
              class="date_input  ml-2 mr-2 d-flex"
              v-model="formData.dateEnd"
              label="Конец"
            
              type="date"
            ></v-text-field>
          </div>

          <v-btn type="submit" class="cabinet_btn">Добавить объект</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    day: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      formData: {
        name: "",
        dateStart: "",
        dateEnd: "",
      },
    };
  },
  watch: {
    day() {
      this.formData.dateStart = this.day.id;
    },
  },
  methods: {
    async submitHandler() {
      await this.$refs.form.validate();
      if (this.valid) {
        try {
          await this.$store.dispatch("techroom/specialist/createObjects", {
          name: this.formData.name,
          start: this.formData.dateStart,
          end:this.formData.dateEnd
          })
          this.$emit("close");
          this.formData.name = "";
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style>
.v-file-input {
  width: 380px;
}

.v-input__prepend {
  display: none;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.8);
}

.action {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.dialog_content {
  border: 2px solid #ff5757;
  /* min-width: 40vw !important; */
}

.dialog_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ff5757;
  padding-bottom: 25px;
  margin: 0px 50px 50px 50px;
}

.text_field_input {
  width: 380px;
}
.date_input {
  width: 500px;
}
.v-select {
  width: 380px;
}

.form_cabinet {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.cabinet_btn {
  background-color: #ff5757;
  color: white;
  border-radius: 20px;
  width: 250px;
  text-transform: none;
}
</style>
