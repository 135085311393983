import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    clients: [],
    clientsAddresses: []
  },
  getters: {
    getClients(state) {
      return state.clients
    },
    getClientsAddresses(state) {
      return state.clientsAddresses
    }
  },
  mutations: {
    updateClients(state, clients) {
      state.clients = clients
    },
    updateClientsAddresses(state, clientAddresses) {
      state.clientsAddresses = clientAddresses
    }
  },
  actions: {
    async requestClients({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/manager/clients`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result)
        const clientAddresses = []
        result.data.clients.forEach(item => {
          if (!item.delete) {
            item.addresses.forEach(address => {
              clientAddresses.push({
                title: `${item.fullTitle} - ${address.title}`,
                id: address.id
              })
            })
          }
        })
        commit('updateClients', result.data.clients)
        commit('updateClientsAddresses', clientAddresses)
      } catch (error) {
        console.error(error)
      }
    },
    async requestClient(ctx, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/manager/clients/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error)
      }
    },
    async removeClient(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/manager/client/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            deleteStatus: true,
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async restoreClient(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/manager/client/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            deleteStatus: false,
          }
        })
        console.log(result);
        return true
      } catch (error) {
        console.error(error);
        return false
      }
    },
    async createClient(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/manager/client`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            leadId: formData?.leadId,
            fullTitle: formData.fullTitle,
            smallTitle: formData.smallTitle,
            address: formData.address,
            phone: formData.phone.replace(/[^0-9]/g, ''),
            head: formData.head,
            email: formData.email,
            description: formData.description,
            ITN: formData.ITN,
            KPP: formData.KPP,
            PSRN: formData.PSRN,
            bankTitle: formData.bankTitle,
            bankAccount: formData.bankAccount,
            correspondentAccount: formData.correspondentAccount,
            BIC: formData.BIC,
            addresses: formData.addresses,
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async editClient(ctx, formData) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/manager/client/${formData.id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            fullTitle: formData.fullTitle,
            smallTitle: formData.smallTitle,
            address: formData.address,
            phone: formData.phone,
            head: formData.head,
            email: formData.email,
            description: formData.description,
            ITN: formData.ITN,
            KPP: formData.KPP,
            PSRN: formData.PSRN,
            bankTitle: formData.bankTitle,
            bankAccount: formData.bankAccount,
            correspondentAccount: formData.correspondentAccount,
            BIC: formData.BIC,
            // addresses: formData.addresses,
          }
        })
        console.log(result)
      } catch (error) {
        console.error(error);
        throw error
      }
    },

  }
}