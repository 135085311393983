import axios from 'axios'
import { url } from '@/store'

export default {
    state: {
        addresses: []
    },
    getters: {
      newAddresses(state) {
          return state.addresses
      }
    },
    mutations: {
        updateAddresses(state, addresses) {
            state.addresses = addresses
        }
    },
    actions: {
        async createAddress({ commit }, addresses) {
            try {
                const result = await axios({
                    method: 'post',
                    url: `${url}/api/techroom/address`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: {
                        addresses
                    }
                })
                console.log(result)
                commit('updateAddresses', result.data)
            } catch (error) {
                console.error(error);
                throw error
            }
        },

        async removeAddress(ctx, id) {
            try {
                const result = await axios({
                    method: 'put',
                    url: `${url}/api/techroom/address/${id}`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: {
                        deleteStatus: true
                    }
                })
                console.log(result)
            } catch (error) {
                console.error(error);
                throw error
            }
        },

        async editAddress(ctx, address) {
            try {
                const result = await axios({
                    method: 'put',
                    url: `${url}/api/techroom/address/${address.id}`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: {
                        title: address.title,
                        phone: address.phone,
                        head: address.head,
                    }
                })
                console.log(result)
            } catch (error) {
                console.error(error);
                throw error
            }
        },
    }
}