import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    teamLeads: null,
  },
  getters: {
    getTeamLeads(state) {
      return state.teamLeads
    }
  },
  mutations: {
    updateTeamLeads(state, res) {
      state.teamLeads = res
    }
  },
  actions: {
    async requestTeamLeads({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/users/teamlead`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateTeamLeads', result.data.TeamLeads)
      } catch (error) {
        console.error(error);
      }
    },

    async createProject({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/projects`,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: {
            nameCompany: formData.nameCompany,
            nameProject: formData.nameProject,
            phone: formData.phone.replace(/[^0-9]/g, ''),
            comment: formData.comment,
            description: formData.description,
            timeline: formData.timeline,
            teamLeadId: formData.teamLeadId,
            clientId: formData.clientId,
          }
        })
        console.log(result);
        await dispatch('requestProjects')
      } catch (error) {
        console.error(error);
        throw error
      }
    }
  },
};
