import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    organizations: [],
    organization: []
  },
  getters: {
    getOrganizations(state) {
      return state.organizations
    },
    getOrganization(state) {
      return state.organization
    },
  },
  mutations: {
    updateOrganizations(state, res) {
      state.organizations = res
    },
    updateOrganization(state, res) {
      state.organization = res
    }
  },
  actions: {
    async requestOrganizations({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/tech/clients`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateOrganizations', result.data.clients)
      } catch (error) {
        console.error(error);
      }
    },
    async requestOrganization({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/tech/clients/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateOrganization', result.data.client)
      } catch (error) {
        console.error(error);
      }
    },
  },
};
