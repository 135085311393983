import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    taskDirector: null
  },
  getters: {
    getDirectorTask(state) {
      return state.taskDirector
    }
  },
  mutations: {
    updateDirectorTask(state, res) {
      state.taskDirector = res
    }
  },
  actions: {
    async requestDirectorTasks({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/tasks/manager`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })

        console.log(result)
        commit('updateDirectorTask', result.data)
      } catch (error) {
        console.log(error)
      }
    }

  },
};
