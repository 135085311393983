<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Добавить пользователя</h2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="valid ? submitHandler() : validate()"
        >
          <v-text-field
            v-model="formData.surname"
            label="Фамилия"
            :rules="[() => !!formData.surname || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.name"
            label="Имя"
            :rules="[() => !!formData.name || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.lastName"
            label="Отчество"
            :rules="[() => !!formData.lastName || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.email"
            label="Электронная почта"
            :rules="[
              () => !!formData.email || 'Обязательное поле',
              () =>
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) || 'Недействительный адрес электронной почты',
            ]"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.dateBirth"
            label="Дата рождения"
            :rules="[() => !!formData.dateBirth || 'Обязательное поле']"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.login"
            label="Логин"
            :rules="[() => !!formData.login || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.password"
            label="Пароль"
            :rules="[() => !!formData.password || 'Обязательное поле']"
          ></v-text-field>
          <v-select
            v-model="formData.role"
            label="Роль"
            :items="settingUser.roles"
            item-title="name"
            item-value="id"
            multiple
            :rules="[() => !!formData.role.length || 'Обязательное поле']"
          >
          </v-select>
          <v-select
            v-model="formData.job"
            label="Работа"
            :items="settingUser.jobs"
            item-title="jobName"
            item-value="id"
            multiple
          >
          </v-select>
          <v-select
            v-model="formData.stack"
            label="Стек"
            :items="settingUser.stacks"
            item-title="stackName"
            item-value="id"
            multiple
          >
          </v-select>
          <v-btn type="submit">Добавить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    settingUser: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,

      formData: {
        surname: null,
        name: null,
        lastName: null,
        email: null,
        dateBirth: null,
        login: null,
        password: null,
        role: [],
        job: [],
        stack: [],
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async submitHandler() {
      try {
        await this.$store.dispatch("itroom/director/createUser", this.formData);
        this.$emit("close");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>