<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Информация о пользователе</h2>
        <div>
          ФИО:
          {{ `${user.surname} ${user.name} ${user.lastName}` }}
        </div>
        <div>
          Роль:
          <span v-for="role in user.roles" :key="role.id">
            {{ $filters.filterRole(role.name) }}
          </span>
        </div>
        <div>
          Работа:
          <span v-for="job in user.itroom_jobs" :key="job.id">
            {{ job.jobName }}
          </span>
        </div>
        <div>
          Стек:
          <span v-for="stacks in user.itroom_stacks" :key="stacks.id">
            {{ stacks.stackName }}
          </span>
        </div>
        <div>
          Почта:
          {{ user.email }}
        </div>
        <div>
          Логин:
          {{ user.login }}
        </div>
        <div>
          Пароль:
          {{ user.password }}
        </div>
        <div>
          Дата рождения:
          {{ $filters.filterDate(user.dateBirth) }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style lang='scss'>
</style>