<template>
  <div class="header__block">
    <div class="item_header">
      <div class="page_menu_title">
        <svg height="22" width="22" viewBox="0 0 24 24">
          <path
            d="M2.6 18.625v-1.65q0-.7.363-1.2.362-.5.962-.8 1.3-.625 2.588-.987 1.287-.363 3.087-.363 1.8 0 3.088.363 1.287.362 2.587.987.6.3.963.8.362.5.362 1.2v1.65Zm16 0v-1.7q0-.875-.35-1.65-.35-.775-1-1.325.725.15 1.413.425.687.275 1.362.6.65.325 1.013.837.362.513.362 1.113v1.7Zm-9-7.25q-1.25 0-2.125-.875T6.6 8.375q0-1.225.875-2.113.875-.887 2.125-.887 1.225 0 2.113.887.887.888.887 2.113 0 1.25-.887 2.125-.888.875-2.113.875Zm7.275-3q0 1.25-.887 2.125-.888.875-2.113.875-.075 0-.175-.012-.1-.013-.15-.013.5-.625.775-1.388.275-.762.275-1.587 0-.8-.288-1.55-.287-.75-.762-1.4.075-.025.15-.038.075-.012.175-.012 1.225 0 2.113.887.887.888.887 2.113ZM3.6 17.625h12v-.65q0-.35-.175-.612-.175-.263-.625-.513-1.125-.6-2.362-.912-1.238-.313-2.838-.313t-2.837.313q-1.238.312-2.363.912-.45.25-.625.513-.175.262-.175.612Zm6-7.25q.825 0 1.413-.588.587-.587.587-1.412t-.587-1.412q-.588-.588-1.413-.588-.825 0-1.412.588Q7.6 7.55 7.6 8.375t.588 1.412q.587.588 1.412.588Zm0 7.25Zm0-9.25Z" />
        </svg>
        <span v-if="!archive">Активные клиенты</span>
        <span v-else>Клиенты в архиве</span>
        <v-btn class="button-add" color="#ff5757" @click="dialogAdd = true" icon="mdi-plus" />
      </div>
    </div>
    <div class="item_header">
      <div class="page_menu_title">
        <div class="link" @click="this.archive = false">Активные</div>
        <div class="link" @click="this.archive = true">В архиве</div>
      </div>
    </div>
  </div>

  <v-table density="comfortable" class="style-scoped">
    <thead>
      <tr>
        <th class="text-left start">Контактное лицо</th>
        <th class="text-left">Название компании</th>
        <th class="text-left">Номер телефона</th>
        <th class="text-left">Электронная почта</th>
        <th class="text-left">Комментарий</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="!archive">
      <tr v-for="item in $store.getters['techroom/manager/getClients'].filter(client => !client.delete)" :key="item.id"
      @click="openDialogEditClientInfo(item)">
        <td v-if="!item.fullTitle" class="td-item td-item-head start">
          <div>
            <svg height="20" width="20" fill="#00f593">
              <path
                d="M10 9.75q-1.083 0-1.833-.76-.75-.761-.75-1.823 0-1.084.75-1.834T10 4.583q1.083 0 1.833.75t.75 1.834q0 1.062-.75 1.823-.75.76-1.833.76Zm-5.583 5.667v-1.521q0-.375.229-.729.229-.355.646-.625 1.083-.625 2.281-.959Q8.771 11.25 10 11.25q1.208 0 2.417.333 1.208.334 2.291.959.417.25.646.614.229.365.229.74v1.521ZM5.5 14.333h9v-.437q0-.125-.094-.24-.094-.114-.26-.198-.917-.541-1.969-.833-1.052-.292-2.177-.292t-2.177.292q-1.052.292-1.969.833-.166.125-.26.23-.094.104-.094.208ZM10 8.667q.625 0 1.062-.448.438-.448.438-1.073 0-.604-.438-1.042-.437-.437-1.062-.437t-1.062.437Q8.5 6.542 8.5 7.167t.438 1.062q.437.438 1.062.438Zm0-1.5Zm0 7.166Z" />
            </svg>
            <span v-if="this.wi > 1199.98">{{ item.head }}</span>
            <span v-else>{{ formatHead(item.head) }}</span>
          </div>
        </td>
        <td v-else class="td-item td-item-head start">
          <div>
            <svg height="20" width="20" fill="#f94c39">
              <path
                d="M.417 14.833v-1.104q0-.875.895-1.427.896-.552 2.313-.552.25 0 .458.01.209.011.396.052-.25.417-.406.865-.156.448-.156.969v1.187Zm5 0v-1.145q0-1.209 1.271-1.928 1.27-.718 3.312-.718 2.062 0 3.323.718 1.26.719 1.26 1.928v1.145Zm10.666 0v-1.187q0-.521-.145-.969-.146-.448-.417-.865.208-.041.417-.052.208-.01.437-.01 1.437 0 2.323.552.885.552.885 1.427v1.104ZM10 12.375q-1.167 0-2.031.292-.865.291-1.094.729v.104h6.25v-.104q-.229-.438-1.083-.729-.854-.292-2.042-.292Zm-6.417-1.187q-.625 0-1.062-.448-.438-.448-.438-1.052 0-.626.438-1.073.437-.448 1.062-.448t1.073.448q.448.447.448 1.073 0 .604-.448 1.052-.448.448-1.073.448Zm12.834 0q-.625 0-1.063-.448-.437-.448-.437-1.052 0-.626.437-1.073.438-.448 1.063-.448t1.062.448q.438.447.438 1.073 0 .604-.438 1.052-.437.448-1.062.448ZM10 10.417q-.896 0-1.531-.636-.636-.635-.636-1.531 0-.917.636-1.542.635-.625 1.531-.625.917 0 1.542.625t.625 1.542q0 .896-.625 1.531-.625.636-1.542.636Zm0-3q-.312 0-.573.239-.26.24-.26.594 0 .312.26.573.261.26.594.26.333 0 .573-.26.239-.261.239-.594 0-.312-.239-.562-.24-.25-.594-.25Zm0 6.083Zm0-5.25Z" />
            </svg>
            <span v-if="this.wi > 1199.98">{{ item.head }}</span>
            <span v-else>{{ formatHead(item.head) }}</span>
          </div>
        </td>
        <td class="td-item td-item-title">{{ item?.fullTitle || item?.smallTitle || 'Не указано' }}</td>
        <td class="td-item td-item-phone">{{ $filters.filterNumber(item.phone) }}</td>
        <td class="td-item td-item-email">{{ item.email }}</td>
        <td v-if="!item.description" class="td-item empty">- не указан -</td>
        <td v-else class="td-item td-item-comment">
          <div>
            <v-btn v-if="count(item.description) > 50" variant="text" size="x-small" :value="item.id"
              @click="handlerComment($event)">
              Посмотреть больше
              <svg height="20" width="20">
                <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z" />
              </svg>
            </v-btn>
            <v-btn v-if="count(item.description) > 50" variant="text" size="x-small"
              class="manage-item-more manage-item fill-height" :value="item.id"
              @click="openDialogShowCommentClient(item)">
              <svg height="20" width="20">
                <path
                  d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h4.25v.584h-4.25q-.187 0-.354.166-.166.167-.166.354v9.876q0 .187.166.354.167.166.354.166h9.876q.187 0 .354-.166.166-.167.166-.354v-4.25h.584v4.25q0 .479-.313.791-.312.313-.791.313Zm3.063-3.709-.458-.458 7.354-7.333h-3.125v-.584h4.146v4.146h-.584V4.979Z" />
              </svg>
            </v-btn>
          </div>
          {{ item.description }}
        </td>
        <!-- <td v-if="!item.email" class="empty">- не указана -</td>
            <td v-else>{{ item.email }}</td>
            <td v-if="!item.title" class="empty">- не указано -</td>
            <td v-else>{{ item.title }}</td>
            <td v-if="!item.description" class="empty">- не указан -</td>
            <td v-else>{{ item.description }}</td> -->
        <td class="text-right">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small" class="setting_btn">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="style-scoped">
              <v-list-item min-height="1" value="2" class="list_item" @click="openDialogEditClientInfo(item)">
                <svg fill="#5274ff" height="20" width="20">
                  <path
                    d="M9.708 13.521h.604v-4.5h-.604ZM10 8.042q.167 0 .281-.115.115-.115.115-.302 0-.167-.104-.281-.104-.115-.292-.115-.167 0-.281.104-.115.105-.115.292 0 .167.104.292.104.125.292.125Zm0 9q-1.438 0-2.729-.552-1.292-.552-2.25-1.521-.959-.969-1.511-2.24-.552-1.271-.552-2.729 0-1.458.552-2.74.552-1.281 1.521-2.239Q6 4.062 7.271 3.51 8.542 2.958 10 2.958q1.458 0 2.74.552 1.281.552 2.239 1.521.959.969 1.511 2.24.552 1.271.552 2.729 0 1.438-.552 2.729-.552 1.292-1.521 2.25-.969.959-2.24 1.511-1.271.552-2.729.552Zm0-.604q2.667 0 4.552-1.886 1.886-1.885 1.886-4.552t-1.886-4.552Q12.667 3.562 10 3.562T5.448 5.448Q3.562 7.333 3.562 10t1.886 4.552Q7.333 16.438 10 16.438ZM10 10Z" />
                </svg>
                <v-list-item-title class="button_title">Основная информация</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" value="2" class="list_item" @click="openDialogEditClientBank(item)">
                <svg fill="#00f593" height="20" width="20">
                  <path
                    d="M5.375 14.5V7.083h.604V14.5Zm4.333 0V7.083h.604V14.5Zm-6.27 1.542v-.604h13.124v.604ZM14.021 14.5V7.083h.604V14.5ZM3.438 6.146v-.958L10 2.062l6.562 3.126v.958Zm.708-.604h11.708Zm0 0h11.708L10 2.75Z" />
                </svg>
                <v-list-item-title class="button_title">Банковские реквизиты</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" value="2" class="list_item" @click="openDialogEditClientAddresses(item)">
                <svg fill="#ffc929" height="20" width="20">
                  <path
                    d="M10 9.583q.542 0 .906-.364.365-.365.365-.886 0-.541-.365-.906-.364-.365-.906-.365t-.896.365q-.354.365-.354.906 0 .542.365.896.364.354.885.354Zm0 7.209q2.604-2.25 3.99-4.5 1.385-2.25 1.385-3.792 0-2.479-1.542-4.01Q12.292 2.958 10 2.958q-2.271 0-3.812 1.532Q4.646 6.021 4.646 8.5q0 1.542 1.375 3.792t3.979 4.5Zm0 1.166q-3.146-2.833-4.677-5.187Q3.792 10.417 3.792 8.5q0-2.938 1.864-4.667Q7.521 2.104 10 2.104t4.354 1.729q1.875 1.729 1.875 4.667 0 1.917-1.542 4.271-1.541 2.354-4.687 5.187ZM10 8.5Z" />
                </svg>
                <v-list-item-title class="button_title">Адреса обслуживания</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" value="2" class="list_item" @click="openDialogRemoveClient(item)">
                <svg fill="#f94c39" height="20" width="20">
                  <path
                    d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z" />
                </svg>
                <v-list-item-title class="button_title">Перенести в архив</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="(item) in $store.getters['techroom/manager/getClients'].filter(client => client.delete)"
        :key="item.id">
        <td v-if="!item.title" class="td-item td-item-head start">
          <div>
            <svg height="20" width="20" fill="#00f593">
              <path
                d="M10 9.75q-1.083 0-1.833-.76-.75-.761-.75-1.823 0-1.084.75-1.834T10 4.583q1.083 0 1.833.75t.75 1.834q0 1.062-.75 1.823-.75.76-1.833.76Zm-5.583 5.667v-1.521q0-.375.229-.729.229-.355.646-.625 1.083-.625 2.281-.959Q8.771 11.25 10 11.25q1.208 0 2.417.333 1.208.334 2.291.959.417.25.646.614.229.365.229.74v1.521ZM5.5 14.333h9v-.437q0-.125-.094-.24-.094-.114-.26-.198-.917-.541-1.969-.833-1.052-.292-2.177-.292t-2.177.292q-1.052.292-1.969.833-.166.125-.26.23-.094.104-.094.208ZM10 8.667q.625 0 1.062-.448.438-.448.438-1.073 0-.604-.438-1.042-.437-.437-1.062-.437t-1.062.437Q8.5 6.542 8.5 7.167t.438 1.062q.437.438 1.062.438Zm0-1.5Zm0 7.166Z" />
            </svg>
            <span v-if="this.wi > 1199.98">{{ item.head }}</span>
            <span v-else>{{ formatHead(item.head) }}</span>
          </div>
        </td>
        <td v-else class="td-item td-item-head start">
          <div>
            <svg height="20" width="20" fill="#f94c39">
              <path
                d="M.417 14.833v-1.104q0-.875.895-1.427.896-.552 2.313-.552.25 0 .458.01.209.011.396.052-.25.417-.406.865-.156.448-.156.969v1.187Zm5 0v-1.145q0-1.209 1.271-1.928 1.27-.718 3.312-.718 2.062 0 3.323.718 1.26.719 1.26 1.928v1.145Zm10.666 0v-1.187q0-.521-.145-.969-.146-.448-.417-.865.208-.041.417-.052.208-.01.437-.01 1.437 0 2.323.552.885.552.885 1.427v1.104ZM10 12.375q-1.167 0-2.031.292-.865.291-1.094.729v.104h6.25v-.104q-.229-.438-1.083-.729-.854-.292-2.042-.292Zm-6.417-1.187q-.625 0-1.062-.448-.438-.448-.438-1.052 0-.626.438-1.073.437-.448 1.062-.448t1.073.448q.448.447.448 1.073 0 .604-.448 1.052-.448.448-1.073.448Zm12.834 0q-.625 0-1.063-.448-.437-.448-.437-1.052 0-.626.437-1.073.438-.448 1.063-.448t1.062.448q.438.447.438 1.073 0 .604-.438 1.052-.437.448-1.062.448ZM10 10.417q-.896 0-1.531-.636-.636-.635-.636-1.531 0-.917.636-1.542.635-.625 1.531-.625.917 0 1.542.625t.625 1.542q0 .896-.625 1.531-.625.636-1.542.636Zm0-3q-.312 0-.573.239-.26.24-.26.594 0 .312.26.573.261.26.594.26.333 0 .573-.26.239-.261.239-.594 0-.312-.239-.562-.24-.25-.594-.25Zm0 6.083Zm0-5.25Z" />
            </svg>
            <span v-if="this.wi > 1199.98">{{ item.head }}</span>
            <span v-else>{{ formatHead(item.head) }}</span>
          </div>
        </td>
        <td class="td-item td-item-phone">{{ $filters.filterNumber(item.phone) }}</td>
        <td v-if="!item.email" class="td-item td-item-email empty">- не указана -</td>
        <td v-else class="td-item td-item-email">{{ item.email }}</td>
        <td v-if="!item.smallTitle" class="td-item td-item-title empty">- не указано -</td>
        <td v-else class="td-item td-item-title">{{ item.smallTitle }}</td>
        <td v-if="!item.description" class="td-item td-item-comment empty">- не указан -</td>
        <td v-else class="td-item td-item-comment">
          <div>
            <v-btn v-if="count(item.description) > 50" variant="text" size="x-small" :value="item.id"
              @click="handlerComment($event)">
              Посмотреть больше
              <svg height="20" width="20">
                <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z" />
              </svg>
            </v-btn>
            <v-btn v-if="count(item.description) > 50" variant="text" size="x-small"
              class="manage-item-more manage-item fill-height" :value="item.id"
              @click="openDialogShowCommentLead(item)">
              <svg height="20" width="20">
                <path
                  d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h4.25v.584h-4.25q-.187 0-.354.166-.166.167-.166.354v9.876q0 .187.166.354.167.166.354.166h9.876q.187 0 .354-.166.166-.167.166-.354v-4.25h.584v4.25q0 .479-.313.791-.312.313-.791.313Zm3.063-3.709-.458-.458 7.354-7.333h-3.125v-.584h4.146v4.146h-.584V4.979Z" />
              </svg>
            </v-btn>
          </div>
          {{ item.description }}
        </td>
        <td class="text-right">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small" class="setting_btn">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="style-scoped">
              <v-list-item min-height="1" value="2" class="list_item" @click="restore(item)">
                <svg fill="#00f593" height="20" width="20">
                  <path
                    d="M6.333 15.583v-.666h5.584q1.229 0 2.073-.844.843-.844.843-2.073t-.843-2.073q-.844-.844-2.073-.844H5.792l2.354 2.355-.479.479L4.5 8.75l3.167-3.167.479.479-2.354 2.355h6.125q1.479 0 2.531 1.041Q15.5 10.5 15.5 12q0 1.5-1.052 2.542-1.052 1.041-2.531 1.041Z" />
                </svg>
                <v-list-item-title class="button_title">Восстановить</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <v-snackbar v-model="snackbar" timeout="3000">
    {{ message }}
    <template v-slot:actions>
      <v-btn color="var(--red-0)" variant="text" size="small" @click="snackbar = false">
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
  <DialogEditClientInfo v-model="dialogEditClientInfo" @close="dialogEditClientInfo = false" :client="client">
  </DialogEditClientInfo>
  <DialogEditClientBank v-model="dialogEditClientBank" @close="dialogEditClientBank = false" :client="client">
  </DialogEditClientBank>
  <DialogEditClientAddresses v-model="dialogEditClientAddresses" @close="dialogEditClientAddresses = false"
    :client="client"></DialogEditClientAddresses>
  <DialogShowCommentClient v-model="dialogShowCommentClient" @close="dialogShowCommentClient = false" :client="client">
  </DialogShowCommentClient>
  <DialogRemoveClient v-model="dialogRemoveClient" @close="dialogRemoveClient = false" :client="client">
  </DialogRemoveClient>
  <DialogAddClient @close="dialogAdd = false" v-model="dialogAdd"/>
</template>

<script>
import DialogShowCommentClient from "@/components/app/techroom/manager/clients/DialogShowCommentClient.vue"
import DialogEditClientInfo from "@/components/app/techroom/manager/clients/DialogEditClientInfo.vue"
import DialogEditClientBank from "@/components/app/techroom/manager/clients/DialogEditClientBank.vue"
import DialogEditClientAddresses from "@/components/app/techroom/manager/clients/DialogEditClientAddresses.vue"
import DialogRemoveClient from "@/components/app/techroom/manager/clients/DialogRemoveClient.vue"
import DialogAddClient from "@/components/app/techroom/manager/leads/DialogAddClient.vue"

export default {
  components: { DialogShowCommentClient, DialogAddClient, DialogEditClientInfo, DialogEditClientBank, DialogEditClientAddresses, DialogRemoveClient },
  data() {
    return {
      archive: false,
      clients: null,
      client: null,
      dialogEditClientInfo: false,
      dialogEditClientBank: false,
      dialogShowCommentClient: false,
      dialogEditClientAddresses: false,
      dialogRemoveClient: false,
      dialogAdd: false,
      snackbar: false,
      message: "",
      wi: document.documentElement.clientWidth,
    };
  },
  methods: {
    count(str) {
      if (str) {
        return str.length;
      }
    },
    async restore(client) {
      const request = await this.$store.dispatch("techroom/manager/restoreClient", client.id);
      await this.$store.dispatch("techroom/manager/requestClients");
      this.snackbar = true;
      if (!request) {
        this.message = "Ошибка при восстановлении";
      } else {
        this.message = "Успешное восстановление";
      }
    },
    openDialogEditClientInfo(item) {
      this.client = item;
      this.dialogEditClientInfo = true;
    },
    formatHead(head) {
      const r = head.split(" ");
      if (r.length === 3) {
        return `${r[2]} ${r[0].charAt()}. ${r[1].charAt()}.`
      } else if (r.length === 2) {
        return `${r[0]} ${r[1].charAt()}`
      } else {
        return `${r[0]}`
      }
    },
    openDialogEditClientAddresses(item) {
      this.client = item;
      this.client.addresses.forEach(address => {
        const floors = [
          { floor: 1, addressId: address.id },
          { floor: 2, addressId: address.id },
          { floor: 3, addressId: address.id },
          { floor: 4, addressId: address.id },
          { floor: 5, addressId: address.id },
          { floor: 6, addressId: address.id },
          { floor: 7, addressId: address.id },
          { floor: 8, addressId: address.id },
          { floor: 9, addressId: address.id },
          { floor: 10, addressId: address.id },
          { floor: 11, addressId: address.id },
          { floor: 12, addressId: address.id }
        ]
        address.filteredFloors = floors.filter(floor => address.floors.every(item => item.floor !== floor.floor));
      })
      this.dialogEditClientAddresses = true;
    },
    openDialogEditClientBank(item) {
      this.client = item;
      this.dialogEditClientBank = true;
    },
    openDialogShowCommentClient(item) {
      this.client = item;
      this.dialogShowCommentClient = true;
    },
    openDialogRemoveClient(item) {
      this.client = item;
      this.dialogRemoveClient = true;
    },
    handlerComment(event) {
      const td = event.target.parentElement.parentElement;
      const tdRow = event.target.parentElement.parentElement.parentElement;
      if (event.target.children[2].innerHTML.includes("Свернуть комментарий")) {
        console.log("hide");
        td.classList.remove("more");
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Посмотреть больше
          <svg height="20" width="20">
            <path d="M10 12.458 5.646 8.104l.458-.437L10 11.562l3.896-3.895.458.437Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          _this.classList.remove("top");
        }
      } else {
        td.classList.add("more");
        console.log(event.target.children);
        event.target.children[2].innerHTML = "";
        event.target.children[2].insertAdjacentHTML(
          "beforeend",
          `Свернуть комментарий
          <svg height="20" width="20">
            <path d="m6.104 12.396-.458-.458L10 7.604l4.354 4.334-.458.458L10 8.479Z"/>
          </svg>`
        );
        for (let i = 0; i < tdRow.children.length; i++) {
          const _this = tdRow.children[i];
          if (!_this.classList.contains("td-item-comment")) {
            _this.classList.add("top");
          }
          // console.log(_this);
        }
      }
    },
    scrolldown() {
      setTimeout(() => {
        document.querySelector(`.page__calls`).scroll({
          top: document.querySelector(`.page__calls`).scrollHeight,
          behavior: "smooth",
        });
      }, 250);
    },
    async removeClient(item) {
      await this.$store.dispatch("techroom/manager/removeClient", item.id);
    }
  },
  async mounted() {
    window.addEventListener("resize", () => {
      this.wi = document.documentElement.clientWidth
      console.log(this.wi)
    });
    try {
      await this.$store.dispatch("techroom/manager/requestClients");
      this.clients = this.$store.getters["techroom/manager/getClients"];
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>

</style>