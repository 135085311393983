<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление проекта</div>
        <v-form ref="form" v-model="valid" lazy-validation class="form_cabinet" @submit.prevent="">
          <!-- <v-select v-model="testValue" :items="items" label="Выберите из клиентов"></v-select> -->
          <div class="dropdown-wrapper">
            <div class="selected-item" @click="isVisible = !isVisible">
              <span v-if="!selectedItem">Выберите клиента</span>
              <span v-else>{{ selectedItem.name }}</span>
              <svg class="drop-down-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
              </svg>
            </div>
            <div v-if="isVisible" class="dropdown-popover">
              <v-text-field label="Поиск по клиентам" hide-details="auto" v-model="searchQuery" class="search">
              </v-text-field>
              <span v-if="filteredUser.length === 0">Нет данных</span>
              <div class="options">
                <ul>
                  <li @click="selectItem(user)" v-for="(user, index) in filteredUser" :key="`user-${index}`">
                    {{ user.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <v-select :items="typeproject" label="Выберите тип проекта"></v-select>
          <v-text-field class="text_field_input" v-model="namedocument" :rules="namedocumentRules"
            label="Номер договора" required></v-text-field>
          <v-text-field class="text_field_input" v-model="datadocument" :rules="datadocumentRules" label="Дата договора"
            required></v-text-field>
          <v-text-field class="text_field_input" v-model="sumdocument" :rules="sumdocumentRules" label="Сумма договора"
            required></v-text-field>
          <!-- <v-file-input multiple label="Добавить договор"></v-file-input> -->
          <v-file-input accept="application/pdf, application/docx" @change="test($event)" class="file_input"
            v-model="documentProject" :rules="documentProjectRules" multiple label="Добавить дополнительный файл"
            show-size>
          </v-file-input>
          <!-- <a href="{{ url }}">asd</a>
                    <a href="#" @click="check">Просмотр</a> -->
          <v-btn type="submit" class="cabinet_btn">Добавить проект</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      documentProject: "",
      documentProjectRules: [(v) => !!v || "Обязательное поле"],
      namedocument: "",
      namedocumentRules: [(v) => !!v || "Обязательное поле"],
      datadocument: "",
      datadocumentRules: [(v) => !!v || "Обязательное поле"],
      sumdocument: "",
      sumdocumentRules: [(v) => !!v || "Обязательное поле"],
      email: "",
      emailRules: [(v) => !!v || "Обязательное поле"],
      describtion: "",
      describtionRules: [(v) => !!v || "Обязательное поле"],
      items: ["Клиент 1", "Клиент 2", "Клиент 3"],
      typeproject: ["Разовый", "Ежемесячный"],
      testValue: [],
      searchQuery: "",
      selectedItem: null,
      isVisible: false,
      userArray: [],
    };
  },
  computed: {
    filteredUser() {
      const query = this.searchQuery.toLowerCase();
      if (this.searchQuery === "") {
        return this.userArray;
      }
      return this.userArray.filter((user) => {
        return Object.values(user).some((word) =>
          String(word).toLowerCase().includes(query)
        );
      });
    },
  },
  methods: {
    test(event) {
      console.log(event.target.files);
    },
    selectItem(user) {
      this.selectedItem = user;
      this.isVisible = false;
    },
  },
  mounted() {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.userArray = json;
      });
  },
};
</script>

<style lang="scss">
.dropdown-wrapper {
  // max-width: 300px;
  position: relative;
  margin: 0 auto;

  .selected-item {
    height: 40px;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    min-width: 380px;
  }

  .dropdown-popover {
    border: 2px solid #000;
    top: 45px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;

    .options {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        padding-left: 8px;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #000;

        li {
          width: 100%;
          border-bottom: 1px solid #000;
          padding: 10px;
          background-color: #f1f1f1;
          cursor: pointer;

          &:hover {
            background-color: #70878a;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.v-file-input {
  width: 380px;
}

.v-input__prepend {
  display: none;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.8);
}

.action {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.dialog_content {
  // border: 2px solid #ff5757;
  // min-width: 40vw !important;
}

.dialog_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ff5757;
  padding-bottom: 25px;
  margin: 0px 50px 50px 50px;
}

.text_field_input {
  width: 380px;
}

.v-select {
  width: 380px;
}

.form_cabinet {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.cabinet_btn {
  background-color: #ff5757;
  color: white;
  border-radius: 20px;
  width: 250px;
  text-transform: none;
}

// @media screen and (max-width: 1024px) {
//   .dialog_content {
//     width: 90vw !important;
//   }
// }

@media screen and (max-width: 500px) {
  .dropdown-wrapper {
    min-width: 100% !important;
  }

  .selected-item {
    min-width: 100% !important;
  }

  .dialog_title {
    width: 100%;
    margin: 0px 0px 25px 0px !important;
  }

  .v-text-field {
    width: 100%;
  }

  .file_input {
    width: 100%;
  }
}
</style>
