import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    isAuthenticated: false,
    user: {},
  },
  getters: {
    USER(state) {
      return state.user;
    },
    AUTH(state) {
      return state.isAuthenticated;
    }
  },
  mutations: {
    updateUser(state, response) {
      state.user = response
    },
    updateIsAuthenicated(state, response) {
      state.isAuthenticated = response;
    },
    exit(state, response) {
      state.user = response
    }
  },
  actions: {
    async authorize({ commit }, formData) {
      try {
        const response = await axios({
          method: "post",
          url: `${url}/api/auth`,
          data: {
            login: formData.login,
            password: formData.password,
          },
        });
        commit("updateUser", response.data.user);
        commit("updateIsAuthenicated", true);
        localStorage.setItem("token", response.data.token)
        console.log(response)
      } catch (error) {
        console.error(error);
        throw error
      }
    },

    async requestUserInfo({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/userInfo`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateUser', result.data)
      } catch (error) {
        console.err(error);
      }
    },
    async checkAuthorize({ commit }, token) {
      try {
        const response = await axios({
          method: "post",
          url: `${url}/api/itroom/check`,
          data: {
            token,
          },
        });
        commit("updateUser", response.data.user);
      } catch (error) {
        commit("updateIsAuthenicated", false);
        commit("updateUser", {});
        console.error(error);
        throw error
      }
    },
    async refreshToken({ commit }, userRoleId) {
      try {
        const response = await axios({
          method: 'post',
          url: `${url}/api/selectRole`,
          data: {
            userRoleId,
            token: localStorage.getItem('token')
          }
        });
        console.log(response);
        commit("updateUser", response.data.user);
        commit("updateIsAuthenicated", response.data.check);
      } catch (err) {
        console.error(err)
      }
    },
    async logout({ commit, getters }) {
      try {
        await axios({
          method: 'post',
          url: `${url}/api/logout`,
          data: { id: getters.USER.id }
        });

        commit("updateIsAuthenicated", false)
        commit("updateUser", {})
        localStorage.removeItem("token")

      } catch (err) {
        console.error(err)
      }
    },
    async updateUser(ctx, data) {
      try {
        console.log(data)
        const res = await axios({
          method: 'put',
          url: `${url}/api/editUser`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            email: data.email,
            dateBirth: data.dateBirth,
          }
        });
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    }
  },
};
