<template>
    <svg v-if="mini">
        <g transform="matrix(15.024186001742784 0 0 15.024186001742784 250.00000000000003 250)">
            <path transform=" translate(-17.3, -18)"
                d="M 31.2 24.9 V 11.1 c 0 -0.1 0 -0.2 0 -0.3 c 0 0 0 0 0 0 c 0.1 -0.2 0 -0.5 -0.1 -0.8 c -0.1 -0.2 -0.4 -0.4 -0.6 -0.5 c 0 0 0 0 0 0 c -0.1 -0.1 -0.2 -0.1 -0.3 -0.2 l -12 -6.9 c -0.1 0 -0.2 -0.1 -0.3 -0.1 c 0 0 0 0 0 0 C 17.8 2.1 17.5 2 17.3 2 s -0.5 0.1 -0.7 0.3 c 0 0 0 0 0 0 c -0.1 0 -0.2 0.1 -0.3 0.1 l -12 6.9 C 4.2 9.4 4.1 9.5 4 9.5 c 0 0 0 0 0 0 C 3.8 9.6 3.5 9.8 3.4 10 c -0.1 0.2 -0.2 0.5 -0.1 0.8 c 0 0 0 0 0 0 c 0 0.1 0 0.2 0 0.3 v 13.8 c 0 0.1 0 0.2 0 0.3 c 0 0 0 0 0 0 c -0.1 0.2 0 0.5 0.1 0.8 c 0.1 0.2 0.4 0.4 0.6 0.5 c 0 0 0 0 0 0 c 0.1 0.1 0.2 0.1 0.3 0.2 l 12 6.9 c 0.1 0.1 0.2 0.1 0.3 0.1 c 0.2 0.2 0.4 0.3 0.7 0.3 s 0.5 -0.1 0.7 -0.3 c 0.1 0 0.2 -0.1 0.3 -0.1 l 12 -6.9 c 0.1 -0.1 0.2 -0.1 0.3 -0.2 c 0 0 0 0 0 0 c 0.2 -0.1 0.5 -0.2 0.6 -0.5 c 0.1 -0.2 0.2 -0.5 0.1 -0.8 c 0 0 0 0 0 0 C 31.2 25.1 31.2 25 31.2 24.9 z M 18.3 4.7 l 10 5.8 l -4.5 2.6 l -5.5 -3.2 V 4.7 z M 16.3 4.7 v 5.2 l -5.5 3.2 l -4.5 -2.6 L 16.3 4.7 z M 5.3 12.2 l 4.5 2.6 v 6.3 l -4.5 2.6 V 12.2 z M 16.3 31.3 l -10 -5.8 l 4.5 -2.6 l 5.5 3.2 V 31.3 z M 16.3 23.8 l -4.5 -2.6 V 16 l 4.5 2.6 V 23.8 z M 17.3 16.8 l -4.5 -2.6 l 4.5 -2.6 l 4.5 2.6 L 17.3 16.8 z M 22.7 16 v 5.2 l -4.5 2.6 v -5.2 L 22.7 16 z M 18.3 31.3 v -5.2 l 5.5 -3.2 l 4.5 2.6 L 18.3 31.3 z M 29.2 23.8 l -4.5 -2.6 v -6.3 l 4.5 -2.6 V 23.8 z" />
        </g>
    </svg>
    <svg v-else>
        <g id="Layer">
            <path id="Layer" fill-rule="evenodd" fill="#000000"
                d="m200.6 94.2c0.4 1.2 0 2.4-0.4 3.2-0.3 1.1-1.5 1.5-2.3 1.9-0.4 0.4-0.8 0.4-1.2 0.8l-46.8 26.9c-0.4 0-0.8 0.4-1.2 0.4-0.7 0.8-1.5 1.2-2.7 1.2-1.2 0-2-0.4-2.7-1.2-0.4 0-0.8 0-1.2-0.4l-46.8-26.9c-0.4-0.4-0.8-0.4-1.2-0.8-0.8-0.4-2-1.2-2.3-1.9-0.4-1.2-0.8-2.4-0.4-3.2 0-0.4 0-54.6 0-55q0-0.6 0-1.2c-0.4-1.1 0-2.3 0.4-3.1 0.3-0.8 1.5-1.5 2.3-1.9 0.4 0 0.8-0.4 1.2-0.8l46.8-26.9c0.4 0 0.8-0.4 1.2-0.4 0.7-0.8 1.9-1.2 2.7-1.2 0.8 0 2 0.4 2.3 1.2 0.4 0 0.8 0.4 1.2 0.4l46.8 26.9c0.4 0.4 0.8 0.4 1.2 0.8 0.8 0.4 2 1.1 2.4 1.9 0.3 1.2 0.7 2.4 0.3 3.1 0 0.4 0 54.7 0 55.1 0 0.4 0 0.7 0.4 1.1zm-50.7-59.7l21.5 12.5 17.5-10.1-39-22.7zm-46.8 2.4l17.5 10.1 21.5-12.5v-20.3zm-3.9 51.5l17.6-10.1v-24.6l-17.6-10.1zm42.9 9.3l-21.4-12.5-17.6 10.2 39 22.6zm0-29.2l-17.5-10.2v20.3l17.5 10.2zm21.5-17.2l-17.6-10.2-17.5 10.2 17.5 10.1zm-14 17.2v20.3l17.5-10.2v-20.2zm39.5 26.9l-17.6-10.2-21.5 12.5v20.3zm3.5-51.5l-17.5 10.1v24.6l17.5 10.2z" />
        </g>
        <g id="Layer">
            <path id="Layer" fill="#ff5757" d="m20.8 201.3v-39.2h-17.2v-9.7h43.9v9.7h-17v39.2z" />
            <path id="Layer" fill="#ff5757" d="m52.4 201.3v-48.9h41.5v9.7h-31.7v9.9h29.3v9.8h-29.3v9.7h31.7v9.8z" />
            <path id="Layer" fill="#ff5757"
                d="m99.5 201.3v-14.6l9.8-9.8-9.8-9.8v-14.7h9.8v9.8l9.7 9.8h4.9l9.8-9.8v-9.8h9.7v14.7l-9.7 9.8 9.7 9.8v14.6h-9.7v-9.8l-9.8-9.7h-4.9l-9.7 9.7v9.8z" />
        </g>
        <g id="Layer">
            <path id="Layer" fill-rule="evenodd" fill="#000000"
                d="m151.7 201.3v-48.9h39.1l4.8 4.9v24.5l-4.8 4.9h-29.3v14.6zm34.2-24.4v-14.8h-24.4v14.8z" />
            <path id="Layer" fill="#000000"
                d="m204.6 191.5h19.6l9.8-9.7h-19.6l-14.6-14.7v-14.7h9.8v9.8l9.7 9.8h14.7v-19.6h9.7v34.3l-14.6 14.6h-24.5z" />
            <path id="Layer" fill="#000000"
                d="m250 201.3v-48.9h9.8l17 23.6 17.4-23.6h9.4v48.9h-9.8v-33l-12.8 18.8h-8.1l-13.1-18.8v33z" />
        </g>
    </svg>
</template>
<script>
export default {
    name: "IconTechroom",
    props: { mini: { type: Boolean } }
};
</script>
<style>

</style>