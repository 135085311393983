import IconDelete from './IconDelete'
import IconEdit from './IconEdit'
import IconView from './IconView'
import IconEntity from './IconEntity'
import IconIndividual from './IconIndividual'
import IconOpen from './IconOpen'
import IconItroom from './IconItroom'
import IconTechroom from './IconTechroom'
import IconAccount from './IconAccount'
import IconError from './IconError'
import IconSuccess from './IconSuccess'
import IconChevron from './IconChevron'
import IconExit from './IconExit'
import IconUncover from './IconUncover'
import IconAdd from './IconAdd'
export default [
  IconDelete,
  IconEdit,
  IconView,
  IconEntity,
  IconIndividual,
  IconOpen,
  IconItroom,
  IconTechroom,
  IconAccount,
  IconError,
  IconSuccess,
  IconChevron,
  IconExit,
  IconUncover,
  IconAdd
]