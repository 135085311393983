import calls from "./calls";
import clients from "./clients";
import createProject from "./createProject";
import projects from "./projects";
import tasks from './tasks'
export const manager = {
  namespaced: true,
  modules: {
    calls,
    clients,
    createProject,
    projects,
    tasks
  }
}