<template>
  <div class="account__page">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="account__title techroom__title">Личный кабинет</h1>
    </div>
    <div class="account__user">
      <div class="user__info">
        <span>ФИО:</span>
        <div>
          {{
    `${$store.getters.USER?.surname} ${$store.getters.USER?.name} ${$store.getters.USER?.lastName}`
}}
        </div>
      </div>
      <div class="user__info">
        <span>Email:</span>
        <div>{{ $store.getters.USER.email }}</div>
      </div>
      <div class="user__info">
        <span>Дата рождения:</span>
        <div>{{ $filters.filterDateBirth($store.getters.USER.dateBirth) }}</div>
      </div>
      <!-- <paper-button @click="openDialogEdit()" :user="$store.getters.USER">
        <v-icon>mdi-pencil-box-outline</v-icon>
        <span>Редактировать</span>
      </paper-button> -->
    </div>
    <!-- <v-calendar />
    <v-date-picker v-model="date" :min-date='new Date()'></v-date-picker> -->
    <!-- <Calendar></Calendar> -->
    <!-- <NotificationBlock class="my-5"/> -->
    <!-- <v-card width="300" title="Личные задачи" variant="tonal"
      v-if="$store.getters['techroom/manager/getPersonalTask']?.length > 0">
      <v-card :title="task.name" :text="task.description"
        v-for="task in $store.getters['techroom/manager/getPersonalTask']" :key="task.id">
        <div v-if="task.type == 2">Срочно!</div>
        <div v-if="task.deadline">{{ task.deadline ? 'До ' + new Date(task.deadline).toLocaleString() : '' }}</div>
      </v-card>
    </v-card> -->
    <DialogEditUser v-model="dialogEditUser" @close="dialogEditUser = false"></DialogEditUser>
  </div>
</template>

<script>
import DialogEditUser from '@/components/app/techroom/DialogEditUser.vue';
// import NotificationBlock from '@/components/app/techroom/account/notification.vue'
// import Calendar from "@/components/app/techroom/Calendar.vue";
export default {
  data() {
    return {
      user: {},
      dialogEditUser: false,
      dialogTask: false,
    };
  },
  components: {
    DialogEditUser,
    // NotificationBlock,
  },
  methods: {
    openDialogTask() {
      this.dialogTask = true;
    },
    openDialogEdit() {
      this.dialogEditUser = true;
    },
  },
  // async mounted() {
  //   await this.$store.dispatch("itroom/user/requestUser");
  //   this.user = await this.$store.getters["itroom/user/getUser"];
  //   // await this.$store.dispatch("itroom/user/requestTasks");
  //   // this.tasks = await this.$store.getters["itroom/user/getTasks"];
  // },
};
</script>

<style lang="scss">
.techroom__title {
  font-weight: 500;
  letter-spacing: 0.125rem;
  color: #ff5757;
}

.modal-comment {
  white-space: break-spaces;
}

.v-list.style-scoped {
  box-shadow: none !important;
  border: 1px solid var(--block-border-color) !important;

  .v-list-item__content {
    gap: rem(14);
  }

  .v-list-item-title {
    font-size: rem(14);
    font-weight: 400;
    text-transform: none;
    letter-spacing: rem(0.25);
    font-family: revert;
  }
}

.v-table.style-scoped {
  table {
    .td-item:not(.td-item-comment) {
      max-width: rem(250);
      line-break: anywhere;
      white-space: break-spaces;

      @media screen and (max-width: $large) {
        max-width: none;
        white-space: nowrap;
      }
    }

    .td-item-comment {
      white-space: nowrap;

      &.more {
        white-space: break-spaces;
        line-break: anywhere;
        max-width: rem(250);
      }
    }

    tr {
      td {
        &:first-child {
          div {
            display: grid;
            grid-template: auto / max-content auto;
            gap: rem(8);
          }
        }

        &:last-child {
          color: #000;

          button {
            color: var(--red-0);
          }

          svg {
            fill: var(--red-0);
          }

          i {
            color: var(--red-0);
          }
        }

        &.top {
          padding-top: rem(10) !important;
          vertical-align: top;

          button {
            height: auto !important;
          }
        }
      }

      button {
        color: var(--red-0);

        svg {
          fill: var(--red-0);
        }
      }

      svg {
        fill: var(--red-0);

        &:hover {
          fill: var(--red-0);
        }
      }

      .manage-item {
        &:hover {
          svg {
            fill: var(--red-0);
          }
        }
      }
    }
  }
}

.account__page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;

  .account__user {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .user__info {
      display: flex;
      gap: 5px;

      span {
        color: #ff5757;
      }
    }

    @media screen and (max-width: 345px) {
      .user__info {
        display: block;
      }
    }
  }

  paper-button {
    width: 240px;
    border-color: #ff5757;
    color: #ff5757;
  }
}
</style>
