<template>
  <div class="header__block">
    <div class="item_header">
      <div>
        <!-- <v-responsive class="mx-auto" max-width="344">
                        <v-text-field label="Поиск по обращениям" hide-details="auto"></v-text-field>
                    </v-responsive> -->
      </div>
      <div class="page_menu_title">Список проектов</div>
      <div>
        <div>
          <div
            class="
              d-flex
              justify-space-around
              align-center
              flex-column flex-md-row
            "
          >
            <v-btn
              color="#ff5757"
              @click="dialogAddProject = true"
              icon="mdi-briefcase-plus-outline"
            >
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="filters">
                <div class="oneproject filter__block">
                    <input id="oneproject" type="checkbox" name="oneproject" class="checkboxleads">
                    <label for="oneproject">Разовый проект</label>
                </div>
                <div class="monthproject filter__block">
                    <input id="monthproject" type="checkbox" name="monthproject" class="checkboxleads">
                    <label for="monthproject">Ежемесячный проект</label>
                </div>
            </div> -->
  </div>

  <v-table density="comfortable">
    <thead>
      <tr>
        <!-- <th class="text-left">Название компании</th> -->
        <th class="text-left">Контактное лицо</th>
        <th class="text-left">Договор</th>
        <th class="text-left">Сумма договора</th>
        <th class="text-left">Дата договора</th>
        <th class="text-left">Номер договора</th>
        <th class="text-left">Другие файлы</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in projects" :key="item.id">
        <td v-if="!item.client.fullTitle">{{ item.client.head }}</td>
        <td v-else>{{ item.client.fullTitle }}</td>
        <td>
          <div
            v-for="specification in item.specifications"
            :key="specification.id"
          >
            {{ specification.originalname }}
          </div>
        </td>
        <td>{{ item.contractSum }}</td>
        <td style="white-space: nowrap">{{ item.contractDate }}</td>
        <td>{{ item.contractNum }}</td>
        <td>
          <div v-for="other in item.others" :key="other.id">
            {{ other.originalname }}
          </div>
        </td>
        <!-- <td v-if="!item.email" class="empty">- не указана -</td>
              <td v-else>{{ item.email }}</td>
              <td v-if="!item.title" class="empty">- не указано -</td>
              <td v-else>{{ item.title }}</td>
              <td v-if="!item.description" class="empty">- не указан -</td>
              <td v-else>{{ item.description }}</td> -->
        <td class="text-right">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                size="small"
                class="setting_btn"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                min-height="1"
                value="2"
                class="list_item"
                @click="openDialogEdit(item.id)"
              >
                <v-icon>mdi-pencil-box-outline</v-icon>
                <v-list-item-title class="button_title"
                  >Редактировать</v-list-item-title
                >
              </v-list-item>
              <v-list-item min-height="1" value="2" class="list_item">
                <v-icon>mdi-history</v-icon>
                <v-list-item-title class="button_title"
                  >История</v-list-item-title
                >
              </v-list-item>
              <v-list-item min-height="1" value="2" class="list_item">
                <v-icon color="red">mdi-window-close</v-icon>
                <v-list-item-title class="button_title"
                  >Удалить</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <DialogAddProject
    v-model="dialogAddProject"
    @close="dialogAddProject = false"
  ></DialogAddProject>
</template>

<script>
import DialogAddProject from "@/components/app/techroom/manager/projects/DialogAddProject.vue";
export default {
  components: {
    DialogAddProject,
  },
  data() {
    return {
      projects: null,
      dialogAddProject: false,
    };
  },
  methods: {
    openDialogAddProject() {
      this.dialogAddProject = true;
    },
  },
  async mounted() {
    await this.$store.dispatch("techroom/manager/requestProjects");
    this.projects = this.$store.getters["techroom/manager/getProjects"];
  },
};
</script>

<style>
</style>