import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    computers: null,
    stockComputers: null,
  },
  getters: {
    getComputers(state) {
      return state.computers;
    },
    getStockComputers(state) {
      return state.stockComputers;
    },
  },
  mutations: {
    updateComputers(state, res) {
      state.computers = res;
    },
    updateStockComputers(state, res) {
      state.stockComputers = res;
    },
  },
  actions: {
    async requestStockComputers({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/computers`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateStockComputers', result.data);
      } catch (error) {
        console.error(error);
      }
    },

    async requestAddressComputers({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/computers/address/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateComputers', result.data.computers);
      } catch (error) {
        console.error(error);
      }
    },

    async requestFloorComputers({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/computers/floor/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        commit('updateComputers', result.data.computers);
      } catch (error) {
        console.error(error);
      }
    },

    async createComputer(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/computer`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            // num: formData.num,
            // workerId: formData.workerId,
            // gpu: formData.gpu,
            // cpu: formData.cpu,
            // ram: formData.ram,
            // power: formData.power,
            // motherboard: formData.motherboard,
            // hdd: formData.hdd,
            // ssd: formData.ssd,
            // misc: formData.misc,
            // cabinetId: formData.cabinetId,
            // stock: formData.stock ? formData.stock : 0,
            cabinetId: formData.cabinetId,
            num: formData.num,
            remote: formData.remote,
            case_size: formData.caseSize,
            cpu_brand: formData.cpu.brand,
            cpu_core: formData.cpu.core,
            cpu_socket: formData.cpu.socket,
            cpu_model: formData.cpu.model,
            motherboard_model: formData.motherboard.model,
            motherboard_socket: formData.motherboard.socket,
            ram_brand: formData.ram.brand,
            ram_vol: formData.ram.vol,
            ram_freq: formData.ram.frq,
            ram_form: formData.ram.form,
            power_brand: formData.power.brand,
            power_pw: formData.power.pw,
            gpu_brand: formData.gpu.brand,
            gpu_model: formData.gpu.model,
            gpu_vol: formData.gpu.vol,
            hdd_brand: formData.hdd.brand,
            hdd_vol: formData.hdd.vol,
            hdd_form: formData.hdd.form,
            ssd_brand: formData.ssd.brand,
            ssd_vol: formData.ssd.vol,
            ssd_form: formData.ssd.form,
            statusId: formData.statusId,
            workerId: null,
            misc: formData.misc,
            stock: formData.stock ? formData.stock : 0,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async editComputer(ctx, { formData, id }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/computer/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            // num: formData.num,
            // workerId: formData.workerId,
            // gpu: formData.gpu,
            // cpu: formData.cpu,
            // ram: formData.ram,
            // power: formData.power,
            // motherboard: formData.motherboard,
            // hdd: formData.hdd,
            // ssd: formData.ssd,
            // misc: formData.misc,
            // cabinetId: formData.cabinetId?.id || formData.cabinetId,

            cabinetId: formData.cabinetId?.id || formData.cabinetId,
            num: formData.num,
            remote: formData.remote,
            case_size: formData.caseSize,
            cpu_brand: formData.cpu.brand,
            cpu_core: formData.cpu.core,
            cpu_socket: formData.cpu.socket,
            cpu_model: formData.cpu.model,
            motherboard_model: formData.motherboard.model,
            motherboard_socket: formData.motherboard.socket,
            ram_brand: formData.ram.brand,
            ram_vol: formData.ram.vol,
            ram_freq: formData.ram.frq,
            ram_form: formData.ram.form,
            power_brand: formData.power.brand,
            power_pw: formData.power.pw,
            gpu_brand: formData.gpu.brand,
            gpu_model: formData.gpu.model,
            gpu_vol: formData.gpu.vol,
            hdd_brand: formData.hdd.brand,
            hdd_vol: formData.hdd.vol,
            hdd_form: formData.hdd.form,
            ssd_brand: formData.ssd.brand,
            ssd_vol: formData.ssd.vol,
            ssd_form: formData.ssd.form,
            statusId: formData.statusId,
            misc: formData.misc,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async removeComputer(ctx, data) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/computer/${data.id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            deleteStatus: data.status,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async stockComputer(ctx, id) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/computer/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            stock: true,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async outOfStockComputers(ctx, { ids, cabinetId, stock }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/massStock/computer`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            ids,
            cabinetId,
            stock,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async editComputerCabinet(ctx, { id, cabinetId, stock }) {
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/computer/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            cabinetId,
            stock,
          },
        });
        console.log(result);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
