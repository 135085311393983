<template>
  <section class="page__messenger messenger">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="messenger__title itroom__title">Мессенджер</h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>