<template>
  <div class="container d-flex flex-wrap tasks-card">
    <v-card
      v-for="task in $store.getters['techroom/manager/getPlanTask'].filter(
        (i) => new Date(i.date).toLocaleDateString() === date
      )"
      :key="task.id"
      max-width="700"
      class="ml-2 mr-2 mt-2 mb-2"
    >
    </v-card>
  <div class="container tasks-card">
    <v-card v-for="task in $store.getters['techroom/manager/getPlanTask'].filter(
  (i) => new Date(i.date).toLocaleDateString() === date
)" :key="task.id" max-width="700" class="ml-2 mr-2 mt-2 mb-2  ">
      <!-- Chips -->
      <div class="justify-space-between align-center">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-chip class="ma-2" :color="getValueChips(task.status).color" text-color="white" v-bind="props">
              {{ getValueChips(task.status).title }}
            </v-chip>
          </template>
          <v-list v-if="task.status != 4 || $store.getters.USER.selectedRole.role != 'TECHNICAL'">
            <v-list-item min-height="1" @click="updateStatus(task.id, 2)">
              <v-list-item-title> В ожидании </v-list-item-title>
            </v-list-item>
            <v-list-item min-height="1" @click="updateStatus(task.id, 3)">
              <v-list-item-title> В процессе </v-list-item-title>
            </v-list-item>
            <v-list-item min-height="1" @click="updateStatus(task.id, 4)">
              <v-list-item-title> Готово </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-title>Задача просрочена</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        {{ task.deadline ? 'До ' + new Date(task.deadline).toLocaleString() : '' }}
        <v-chip v-if="task.type == 2" class="ma-2" color="red">
          <v-icon start icon="mdi-alert-circle-outline" />
          Срочно
        </v-chip>
      </div>

      <!-- Title -->
      <v-card-title class="title">
        {{ task.name }}
      </v-card-title>
      <v-card-subtitle>
        {{ $filters.filterDate(task.date) }}
      </v-card-subtitle>
      <v-card-text>
        {{ task.description }}
        <div v-if="task.user.length != 0">
          Исполнители:
          <div v-for="user in task.user" :key="user.id">
            {{ user.surname }} {{ user.name }} {{ user.lastName }}
          </div>
        </div>
        {{ task.deadline ? 'До ' + new Date(task.deadline).toLocaleString() : '' }}
        <v-btn v-if="$store.getters.USER.selectedRole.role != 'TECHNICAL'"
          prepend-icon="mdi-archive-arrow-up" @click="openEdit(task)">
          Редактировать
        </v-btn>
        <div v-if="task.deleted == 1">В архиве</div>
        <v-btn v-if="task.status == 4 && $store.getters.USER.selectedRole.role != 'TECHNICAL' && task.deleted == 0"
          prepend-icon="mdi-archive-arrow-up" @click="toArchive(task.id)">
          В архив
        </v-btn>
      </v-card-text>
      <DialogEditTask @close="DialogEdit = false" v-model="DialogEdit" :task="selectedTask"/>
    </v-card>
    </div>
  </div>
</template>

<script setup>
</script>

<script>
import DialogEditTask from "../../../manager/plans/DialogEditTask.vue";
import { wsUrl } from "@/store";
export default {
  data() {
    return {
      selectedTask: null,
      DialogEdit: false,
    }
  },
  props: {
    date: String,
  },
  components: {
    DialogEditTask,
  },
  methods: {
    // timer(deadline) {
    //   let date = new Date();
    //   console.log(date);
    //   console.log(deadline);
    //   let timerTime = deadline - date;
    //   console.log(timerTime);
    //   setInterval(() => {}, 1000);
    //   return timerTime;
    // },
    openEdit(task) {
      this.selectedTask = task
      this.DialogEdit = true
    },
    async updateStatus(task, value) {
      const ws = new WebSocket(wsUrl);
      setTimeout(() => {
        ws.send(JSON.stringify({ event: "task" }));
      }, 500);
      await this.$store.dispatch("techroom/manager/editPlanTask", {
        id: task,
        statusId: value,
      });
    },
    async toArchive(task) {
      const ws = new WebSocket(wsUrl);
      setTimeout(() => {
        ws.send(JSON.stringify({ event: "task" }));
      }, 500);
      await this.$store.dispatch("techroom/manager/editPlanTask", {
        id: task,
        deleted: 1,
      });
    },
    //============  Filter Logics  ===============
    getValueChips(item) {
      switch (item) {
        case 0:
          item = {
            title: 'В обработке',
            color: 'gray',
          };
          break;
        case 1:
          item = {
            title: 'Просрочено',
            color: 'red',
          };
          break;
        case 2:
          item = {
            title: 'Утверждение',
            color: 'gray',
          };
          break;
        case 3:
          item = {
            title: 'В процессе',
            color: '#F4A900',
          };
          break;
        case 4:
          item = {
            title: 'Готово',
            color: 'green',
          };
          break;
      }
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: $large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: $small) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $x-small) {
    grid-template-columns: repeat(1, 1fr);
  }

  .title {
    white-space: break-spaces;
  }
}
</style>
