import axios from 'axios'
import { url } from '@/store'

export default {
    state: {
        floors: [],
    },
    getters: {
        newFloors(state) {
            return state.floors
        }
    },
    mutations: {
        updateFloors(state, floors) {
            state.floors = floors
        }
    },
    actions: {
        async createFloor({ commit }, floorsData) {
            try {
                const result = await axios({
                    method: 'post',
                    url: `${url}/api/techroom/floor`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: {
                        floorsData
                    }
                })
                console.log(result)
                commit('updateFloors', result.data)
            } catch (error) {
                console.error(error);
                throw error
            }
        },

        async removeFloor(ctx, id) {
            try {
                const result = await axios({
                    method: 'put',
                    url: `${url}/api/techroom/floor/${id}`,
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    data: {
                        deleteStatus: true
                    }
                })
                console.log(result)
            } catch (error) {
                console.error(error);
                throw error
            }
        },
    }
}