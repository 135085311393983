<template>
    <svg v-if="bottom">
        <path d="m10 11.417-3-3h6Z" />
    </svg>
    <svg v-else-if="left">
        <path d="m11.583 13-3-3 3-3Z" />
    </svg>
</template>

<script>
export default {
    name: "IconChevron",
    props: {
        left: Boolean,
        bottom: Boolean,
    }
};
</script>