<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление кабинета</div>
        <v-form
          ref="form"
          class="form_cabinet"
          @submit.prevent="submitHandler"
          v-model="valid"
        >
          <v-select
            label="Выберите этаж"
            v-model="formData.floorId"
            :items="
              $store.getters['techroom/specialist/getAddress'].floors.filter(
                (item) => !item.delete
              )
            "
            item-title="floor"
            item-value="id"
            :rules="[() => !!formData.floorId || 'Обязательно']"
            required
          />
          <v-text-field
            class="text_field_input"
            v-model="formData.numCabinet"
            label="Номер кабинета"
            :rules="[() => !!formData.numCabinet || 'Обязательно']"
            required
          />
          <v-textarea
            class="text_field_input"
            v-model="formData.misc"
            label="Заметки"
          />
          <h3 v-if="formData.computers.length">Компьютер</h3>
          <div
            v-for="(item, index) in formData.computers"
            :key="index"
            class="d-flex flex-column text-center w-100"
            style="gap: 26px"
          >
            <v-icon @click="removeComputer(index)">
              mdi-close-circle-outline
            </v-icon>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].num"
              label="Инвентарный №"
              :rules="[() => !!formData.computers[index].num || 'Обязательно']"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].remote"
              label="Удаленный доступ"
              required
            />
            <h3>Корпус</h3>
            <v-select
              label="Размер корпуса"
              v-model="formData.computers[index].case_size"
              :items="['Стандартный', 'Компактный', 'Ноутбук']"
              :rules="[
                () => !!formData.computers[index].case_size || 'Обязательно',
              ]"
              no-data-text="Нет"
              clearable
            />
            <h3>Процессор</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].cpu_brand"
              label="Бренд"
              :rules="[
                () => !!formData.computers[index].cpu_brand || 'Обязательно',
              ]"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].cpu_core"
              label="Ядра"
              :rules="[
                () => !!formData.computers[index].cpu_core || 'Обязательно',
              ]"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].cpu_socket"
              label="Сокет"
              :rules="[
                () => !!formData.computers[index].cpu_socket || 'Обязательно',
              ]"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].cpu_model"
              label="Модель"
              :rules="[
                () => !!formData.computers[index].cpu_model || 'Обязательно',
              ]"
              required
            />
            <h3>Материнская плата</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].motherboard_model"
              label="Модель"
              :rules="[
                () =>
                  !!formData.computers[index].motherboard_model ||
                  'Обязательно',
              ]"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].motherboard_socket"
              label="Сокет"
              :rules="[
                () =>
                  !!formData.computers[index].motherboard_socket ||
                  'Обязательно',
              ]"
              required
            />
            <h3>Оперативная память</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ram_brand"
              :rules="[
                () => !!formData.computers[index].ram_brand || 'Обязательно',
              ]"
              label="Название"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ram_vol"
              :rules="[
                () => !!formData.computers[index].ram_vol || 'Обязательно',
              ]"
              label="Объем"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ram_freq"
              :rules="[
                () => !!formData.computers[index].ram_freq || 'Обязательно',
              ]"
              label="Частота"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ram_form"
              :rules="[
                () => !!formData.computers[index].ram_form || 'Обязательно',
              ]"
              label="Форм фактор"
              required
            />
            <h3>Блок питания</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].power_pw"
              :rules="[
                () => !!formData.computers[index].power_pw || 'Обязательно',
              ]"
              label="Мощность"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].power_brand"
              :rules="[
                () => !!formData.computers[index].power_brand || 'Обязательно',
              ]"
              label="Название"
              required
            />
            <h3>Видеокарта</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].gpu_brand"
              label="Бренд"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].gpu_model"
              label="Модель"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].gpu_vol"
              label="Объем видеопамяти"
              required
            />
            <h3>Жесткий диск</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].hdd_vol"
              label="Объем"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].hdd_brand"
              label="Название"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].hdd_form"
              label="Форм фактор"
              required
            />
            <h3>SSD диск</h3>
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ssd_vol"
              label="Объем"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ssd_brand"
              label="Название"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.computers[index].ssd_form"
              label="Форм фактор"
              required
            />
            <h3>Состояние компьютера</h3>
            <v-select
              label="Состояние компьютера"
              v-model="formData.computers[index].statusId"
              :items="$store.getters['techroom/specialist/getStatuses']"
              item-title="title"
              item-value="id"
              no-data-text="Нет"
              clearable
            />
            <v-textarea
              class="text_field_input"
              v-model="formData.computers[index].misc"
              :rules="[
                () =>
                  formData.computers[index].statusId !== 4 ||
                  !!formData.computers[index].misc ||
                  'Обязательно',
              ]"
              label="Заметки"
            />
          </div>
          <v-btn @click="addComputer" class="cabinet_btn">
            Добавить компьютер
          </v-btn>
          <h3 v-if="formData.equipments.length">Оборудование</h3>
          <div
            v-for="(item, index) in formData.equipments"
            :key="index"
            class="d-flex flex-column w-100"
            style="gap: 26px"
          >
            <v-icon @click="removeEquipment(index)">
              mdi-close-circle-outline
            </v-icon>
            <v-text-field
              class="text_field_input"
              v-model="formData.equipments[index].type"
              label="Тип оборудования"
              :rules="[
                () => !!formData.equipments[index].type || 'Обязательно',
              ]"
              required
            />
            <v-text-field
              class="text_field_input"
              v-model="formData.equipments[index].model"
              label="Модель"
              :rules="[
                () => !!formData.equipments[index].model || 'Обязательно',
              ]"
              required
            />
            <v-select
              label="Состояние компьютера"
              v-model="formData.equipments[index].statusId"
              :items="$store.getters['techroom/specialist/getStatuses']"
              item-title="title"
              item-value="id"
              no-data-text="Нет"
              clearable
            />
            <v-textarea
              class="text_field_input"
              v-model="formData.equipments[index].misc"
              :rules="[
                () =>
                  formData.equipments[index].statusId !== 4 ||
                  !!formData.equipments[index].misc ||
                  'Обязательно',
              ]"
              label="Заметки"
            />
          </div>
          <v-btn @click="addEquipment" class="cabinet_btn">
            Добавить оборудование
          </v-btn>
          <v-btn type="submit">Добавить кабинет</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    formData: {
      floorId: null,
      numCabinet: null,
      misc: null,
      computers: [],
      equipments: [],
    },
  }),

  methods: {
    addComputer() {
      this.formData.computers.push({
        cabinetId: null,
        num: null,
        remote: null,
        case_size: null,
        cpu_brand: null,
        cpu_core: null,
        cpu_socket: null,
        cpu_model: null,
        motherboard_model: null,
        motherboard_socket: null,
        ram_brand: null,
        ram_vol: null,
        ram_freq: null,
        ram_form: null,
        power_brand: null,
        power_pw: null,
        gpu_brand: null,
        gpu_model: null,
        gpu_vol: null,
        hdd_brand: null,
        hdd_vol: null,
        hdd_form: null,
        ssd_brand: null,
        ssd_vol: null,
        ssd_form: null,
        statusId: null,
        misc: null,
      });
    },
    removeComputer(index) {
      this.formData.computers.splice(index, 1);
    },
    addEquipment() {
      this.formData.equipments.push({
        type: null,
        model: null,
        misc: null,
        statusId: null,
      });
    },
    removeEquipment(index) {
      this.formData.equipments.splice(index, 1);
    },
    async submitHandler() {
      this.$refs.form.validate();
      if (this.valid) {
        try {
          await this.$store.dispatch(
            'techroom/specialist/createCabinet',
            this.formData
          );

          if (this.$store.getters['techroom/specialist/getFloorId']) {
            await this.$store.dispatch(
              'techroom/specialist/requestFloorCabinets',
              this.$store.getters['techroom/specialist/getFloorId']
            );
          } else {
            await this.$store.dispatch(
              'techroom/specialist/requestAddressCabinets',
              this.$store.getters['techroom/specialist/getAddress'].id
            );
          }
          this.$refs.form.reset();
          this.formData.computers = [];
          this.formData.equipments = [];
          this.$emit('close');
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.v-tabs.v-slide-group--is-overflowing:not(.v-slide-group--has-affixes)
  .v-tab:first-child {
  margin-inline-start: 18px !important;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.8);
}

.action {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.dialog_content {
  border: none;
}

.dialog_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ff5757;
  padding-bottom: 25px;
  margin: 0px 50px 50px 50px;
}

.text_field_input {
  width: 380px;
}

.form_cabinet {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.cabinet_btn {
  background-color: #ff5757;
  color: white;
  border-radius: 20px;
  width: 250px;
  text-transform: none;
}
</style>
