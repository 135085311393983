<template>
  <svg height="20" width="20" fill="#00f593">
    <path
      d="M10 9.75q-1.083 0-1.833-.76-.75-.761-.75-1.823 0-1.084.75-1.834T10 4.583q1.083 0 1.833.75t.75 1.834q0 1.062-.75 1.823-.75.76-1.833.76Zm-5.583 5.667v-1.521q0-.375.229-.729.229-.355.646-.625 1.083-.625 2.281-.959Q8.771 11.25 10 11.25q1.208 0 2.417.333 1.208.334 2.291.959.417.25.646.614.229.365.229.74v1.521ZM5.5 14.333h9v-.437q0-.125-.094-.24-.094-.114-.26-.198-.917-.541-1.969-.833-1.052-.292-2.177-.292t-2.177.292q-1.052.292-1.969.833-.166.125-.26.23-.094.104-.094.208ZM10 8.667q.625 0 1.062-.448.438-.448.438-1.073 0-.604-.438-1.042-.437-.437-1.062-.437t-1.062.437Q8.5 6.542 8.5 7.167t.438 1.062q.437.438 1.062.438Zm0-1.5Zm0 7.166Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconIndividual",
};
</script>