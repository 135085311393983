<template>
  <svg height="20" width="20">
    <path
      d="M5.062 16.042q-.479 0-.791-.313-.313-.312-.313-.791V5.062q0-.479.313-.791.312-.313.791-.313h7.542l3.438 3.438v7.542q0 .479-.313.791-.312.313-.791.313Zm0-.584h9.876q.229 0 .374-.146.146-.145.146-.374V8.021h-3.479V4.542H5.062q-.229 0-.374.146-.146.145-.146.374v9.876q0 .229.146.374.145.146.374.146Zm1.417-2.375h7.042v-.604H6.479Zm.25-5.312H10v-.604H6.729Zm-.25 3.021h7.042v-.604H6.479Zm-1.937-6.25v3.229-3.229V15.458 4.542Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconView",
};
</script>