import axios from 'axios';
import { url } from '@/store';

export default {
  state: {
    planTask: [],
    personalTasks: [],
    dateFilter: null,
    archiveTask:{
      pages:null,
      
    }
  },
  getters: {
    getPersonalTask(state) {
      return state.personalTasks;
    },
    getPlanTask(state) {
      return state.planTask;
    },
    getDateFilter(state) {
      console.log('==2=2=2=2=22=');
      return state.dateFilter;
    },
    getTaskPages(state){
      return state.archiveTask
    }
  },
  mutations: {
    updatePersonalTask(state, payload) {
      state.personalTasks = payload;
    },
    updatePlanTask(state, payload) {
      state.planTask = payload;
    },
    updateDateFilter(state, payload) {
      console.log(String(payload));
      state.dateFilter = state.planTask.filter(
        (i) => new Date(i.date).toLocaleDateString() === payload
      );
    },
    updateInfoArchivePages(state,payload){
      state.archiveTask.pages = payload.pages
    },
    updateInfoArchiveTasks(state,payload){
      state.archiveTask.tasksArchive = payload
    }
  },
  actions: {
    async createTask({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/techroom/task`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            name: formData.name,
            dateTask: formData.date,
            description: formData.description,
            urgentTask: formData.urgentTask,
            workers: formData.workers,
            deadline: formData.deadline,
            personal: formData.personal,
            // dateObjective: formData.dateObjective,
          },
        });
        console.log(result);
        if(formData.personal) {
          dispatch('requestPersonalTasks')
        }
        dispatch('requestPlanTask');
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async requestPlanTask({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/tasks`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log('result');
        console.log(result);
        console.log('result');
        commit('updatePlanTask', result.data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async deletePlanTask({ dispatch }, id) {
      console.log(id);
      try {
        const result = await axios({
          method: 'delete',
          url: `${url}/api/techroom/task/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result);
        dispatch('requestPlanTask');
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editPlanTask({ dispatch }, { id, date, name, workers, urgentTask, description, statusId, deadline, deleted }) {
      console.log(id, date, statusId);
      try {
        const result = await axios({
          method: 'put',
          url: `${url}/api/techroom/task/${id}`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          data: {
            dateTask: date,
            name,
            workers,
            urgentTask,
            description,
            statusId,
            deadline,
            deleted,
          },
        });

        console.log(result);
        dispatch('requestPlanTask');
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async requestPersonalTasks({commit}) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/personaltasks`,
          headers: {
            Authorization: localStorage.getItem('token'),
          }
        });
        console.log(result);
        commit('updatePersonalTask', result.data)
      } catch (error) {
        console.log(error);
      }
    },
    async requestTaskPages({ commit }){
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/techroom/taskpages`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        console.log(result.data);
        commit('updateInfoArchivePages', result.data);
      } catch (error) {
        console.error(error);
      }
    },
    async requestTaskPage({commit}, id){
      try {
          const result = await axios({
            method:'get',
            url:`${url}/api/techroom/taskpage/${id}`,
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          })
          console.log(result.data.tasks)
          commit('updateInfoArchiveTasks', result.data.tasks)
      } catch (error) {
        console.log(error)
      }
    }
  },
};
