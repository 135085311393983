<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Редактирование лида</h2>
        <v-form
          class="v-form-edit"
          v-model="valid"
          ref="form"
          @submit.prevent="valid ? submitHandler() : validate()"
          @input="validate()"
        >
          <v-select
            :items="leadTypes"
            v-model="formData.typeCall"
            label="Тип лида"
            variant="solo"
            density="compact"
            @update:modelValue="validate()"
          >
          </v-select>
          <v-text-field
            v-if="formData.typeCall === 2"
            v-model="formData.nameCompany"
            label="Название компании"
            :rules="[
              () => !!formData.nameCompany || 'Поле не может быть пустым',
            ]"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.individual"
            label="Контактное лицо"
            :rules="[
              () => !!formData.individual || 'Поле не может быть пустым',
            ]"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.email"
            label="Электронная почта"
            :rules="[
              () =>
                !!formData.email ||
                'Адрес электронной почты обязателен для заполнения',
              () =>
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) || 'Недействительный адрес электронной почты',
            ]"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.phone"
            label="Номер телефона"
            :rules="[
              () => !!formData.phone || 'Поле не может быть пустым',
              () => formData.phone.length === 18 || 'Не верный формат телефона',
            ]"
            maxlength="18"
            v-maska="'+7 (###) ###-##-##'"
            required
          ></v-text-field>

          <v-textarea
            multi-line
            label="Комментарий"
            variant="outlined"
            v-model="formData.comment"
            density="compact"
          >
          </v-textarea>

          <paper-button
            :disabled="!valid"
            @click="valid ? submitHandler() : validate()"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
            ></v-progress-circular>
            <span v-if="loading">Загрузка</span>
            <span v-else-if="loading == null">Сохранено</span>
            <span v-else>Сохранить</span>
          </paper-button>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  props: {
    call: {
      type: Object,
    },
  },
  data() {
    return {
      leadTypes: [
        { value: 2, title: "Юридическое лицо" },
        { value: 1, title: "Физическое лицо" },
      ],
      valid: false,
      loading: false,
    };
  },
  computed: {
    formData() {
      return this.call;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async submitHandler() {
      if (this.formData.typeCall === 2) {
        this.formData.nameCompany = null;
      }
      await this.$store.dispatch("itroom/director/editCall", this.formData);
      this.$emit("close");
    },
  },
};
</script>
