<template>
  <Loading v-show="loadingPage"></Loading>
  <section v-show="!loadingPage" class="page__projects projects">
    <div class="d-flex justify-space-between align-center mb-6">
      <h1 class="projects__title itroom__title">Все проекты</h1>
      <v-tooltip
        v-if="$store.getters.USER.selectedRole.role === 'MANAGER'"
        text="Добавить проект"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            @click="openDialogCreate"
            v-bind="props"
            color="var(--blue-0)"
            icon
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <ManagerProjects
      v-if="$store.getters.USER.selectedRole.role === 'MANAGER'"
      @loading="loadingPage = false"
    />
    <DeveloperProjects
      v-if="$store.getters.USER.selectedRole.role === 'DEVELOPER'"
      @loading="loadingPage = false"
    />
    <TeamleadProjects
      v-if="$store.getters.USER.selectedRole.role === 'TEAMLEAD'"
      @loading="loadingPage = false"
    />
    <DirectorProjects
      v-if="$store.getters.USER.selectedRole.role === 'DIRECTOR'"
      @loading="loadingPage = false"
    />
    <DialogCreateProject v-model="openDialog" @close="openDialog = false" />
  </section>
</template>

<script>
import ManagerProjects from "@/components/app/itroom/manager/projects/ManagerProjects.vue";
import DeveloperProjects from "@/components/app/itroom/developer/projects/DeveloperProjects.vue";
import TeamleadProjects from "@/components/app/itroom/teamlead/projects/TeamleadProjects.vue";
import DirectorProjects from "@/components/app/itroom/director/projects/DirectorProjects.vue";

import DialogCreateProject from "@/components/app/itroom/manager/projects/DialogCreateProject.vue";

export default {
  components: {
    ManagerProjects,
    DeveloperProjects,
    TeamleadProjects,
    DirectorProjects,

    DialogCreateProject,
  },
  data() {
    return {
      loadingPage: true,
      openDialog: false,
    };
  },
  methods: {
    async openDialogCreate() {
      await this.$store.dispatch("itroom/manager/requestTeamLeads");
      await this.$store.dispatch("itroom/manager/requestClients");
      this.openDialog = true;
    },
  },
};
</script>

<style lang="scss">
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.v-toolbar {
  flex: 0 1 auto;
  color: var(--white-0);
}

.project {
  &__section {
    padding: rem(18);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }

  &__list-subheader {
    font-size: rem(22);
    margin: 0 0 rem(15) 0;
    font-weight: 500;
  }

  .project-item {
    display: flex;
    align-items: center;
    gap: rem(32);
    padding: 0 0 0 rem(12);

    &__title {
      font-size: rem(18);
    }

    &__subtitle {
      font-size: rem(16);
      opacity: 0.8;
    }
  }

  @media screen and (max-width: $small) {
    .project-item {
      flex-direction: column;
      align-items: flex-start;
      gap: rem(8);
    }
  }
}
</style>