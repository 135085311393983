import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    calls: null,
    call: null
  },
  getters: {
    getCalls(state) {
      return state.calls
    },
    getCall(state) {
      return state.call
    },
  },
  mutations: {
    updateCalls(state, res) {
      state.calls = res
    },
    updateCall(state, res) {
      state.call = res
    }
  },
  actions: {
    async requestCalls({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/calls`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateCalls', result.data.calls)
      } catch (error) {
        console.error(error);
      }
    },

    async requestCall({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/calls/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateCall', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async createCall({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/calls`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            nameCompany: formData.nameCompany,
            individual: formData.individual,
            phone: formData.phone,
            comment: formData.comment,
            email: formData.email,
            type: formData.leadTypeId
          }
        })
        console.log(result);
        await dispatch('requestCalls')
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCall({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/calls/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
        })
        console.log(result);
        await dispatch('requestCalls')
      } catch (error) {
        console.error(error);
      }
    },

    async editCall({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/calls/edit/${formData.id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            nameCompany: formData.nameCompany,
            individual: formData.individual,
            email: formData.email,
            phone: formData.phone.replace(/[^0-9]/g, ""),
            comment: formData.comment,
            type: formData.typeCall,
          }
        })
        console.log(result);
        await dispatch('requestCalls')
      } catch (error) {
        console.error(error);
      }
    },

    async sendFile({ dispatch }, { file, id }) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/downloadFile`,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem("token")
          },
          data: {
            dogovor: file,
            id,
          }
        })
        console.log(result);
        await dispatch('requestCalls')
      } catch (error) {
        console.error(error);
      }
    }
  },
};
