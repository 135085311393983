<template>
  <v-dialog class="dialog">
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <div class="dialog_title">Добавление лида</div>
        <v-form
          ref="form"
          class="form_cabinet"
          @submit.prevent="createLead"
          v-model="valid"
        >
          <v-select
            v-model="formData.leadTypeId"
            :items="typesLeads"
            label="Выберите тип"
          ></v-select>
          <v-text-field
            v-if="formData.leadTypeId == 1"
            maxlength="100"
            class="text_field_input"
            v-model="formData.title"
            label="Название компании"
            :rules="[() => !!formData.title || 'Обязательно']"
            required
          ></v-text-field>
          <v-text-field
            class="text_field_input"
            maxlength="100"
            v-model="formData.head"
            label="Контактное лицо"
            :rules="[() => !!formData.head || 'Обязательно']"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            maxlength="18"
            v-model="formData.phone"
            label="Номер телефона"
            v-maska="'+7 (###) ###-##-##'"
            :rules="[
              () => !!formData.phone || 'Обязательное поле',
              () => formData.phone.length === 18 || 'Не верный формат телефона',
            ]"
            required
          >
          </v-text-field>
          <v-text-field
            class="text_field_input"
            v-model="formData.email"
            :rules="[
              () =>
                !formData.email ||
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                  formData.email
                ) ||
                'Неверный формат электронной почты',
            ]"
            label="Электронная почта"
            maxlength="100"
          >
          </v-text-field>
          <v-textarea
            class="text_field_input_area"
            v-model="formData.description"
            label="Описание"
          >
          </v-textarea>
          <v-btn
            variant="outlined"
            :disabled="!valid"
            @click="validate()"
            type="submit"
            class="cabinet_btn"
          >
            Добавить лида
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    typesLeads: [
      { value: 1, title: 'Юридическое лицо' },
      { value: 2, title: 'Физическое лицо' },
    ],
    formData: {
      leadTypeId: 2,
      title: null,
      head: null,
      phone: null,
      email: null,
      description: null,
    },
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async createLead() {
      try {
        await this.$store.dispatch(
          'techroom/manager/createLead',
          this.formData
        );
        await this.$store.dispatch('techroom/manager/requestLeads');
        this.$refs.form.reset();
        this.formData.leadTypeId = 2;
        this.$emit('close');
        this.$emit('scrolldown');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
#priority {
  display: none;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.8);
}

.action {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.dialog_content {
  border: 2px solid #ff5757;
  min-width: 50vw !important;
}

.dialog_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ff5757;
  padding-bottom: 25px;
  margin: 0px 50px 50px 50px;
}

@media screen and (max-width: 460px) {
  .text_field_input {
    width: 100% !important;
  }

  .v-select {
    width: 100% !important;
  }

  .dialog_title {
    margin: 0px 0px 25px 0px !important;
  }
}

.form_cabinet {
  display: grid !important;
  grid-template: repeat(5, auto) / repeat(2, auto);
  gap: rem(24);

  @media screen and (max-width: 991.98px) {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .v-input {
      width: 100%;
    }
  }

  .v-input {
    width: 100%;
  }

  .v-select {
    width: 100%;
    grid-area: 1 / 1 / 2 / 3;
  }

  .v-textarea {
    width: 100%;
    grid-area: 4 / 1 / 5 / 3;
  }

  .v-btn {
    width: 100%;
    grid-area: 5 / 1 / 6 / 3;
    background-color: unset;
    color: #ff5757;
    border-radius: 0;
  }
}

.cabinet_btn {
  background-color: #ff5757;
  color: white;
  border-radius: 20px;
  width: 250px;
  text-transform: none;
}
</style>
