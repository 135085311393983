// import axios from 'axios';
// import { url } from '@/store';

export default {
  state: {
    // appeals: null,
    // organizationAppeals: [],
    // filterOrganizationAppels: [],
  },
  getters: {
    // getAppeals(state) {
    //   return state.appeals;
    // },
    // getOrganizationAppeals(state) {
    //   return state.organizationAppeals;
    // },
    // getFilterOrganizationsAppeals(state) {
    //   return state.filterOrganizationAppels;
    // },
  },
  mutations: {
    // updateAppeals(state, res) {
    //   state.appeals = res;
    // },
    // updateOgranizationAppeals(state, res) {
    //   state.organizationAppeals = res;
    // },
    // updateFilterOrganizationsAppeals(state, res) {
    //   if (res) {
    //     state.filterOrganizationAppels = state.organizationAppeals.filter(
    //       (item) => item.techroom_status.id === res
    //     );
    //   } else {
    //     state.filterOrganizationAppels = state.organizationAppeals;
    //   }
    // },
  },
  actions: {
    // async requestAddressAppeals({ commit }, id) {
    //   try {
    //     const result = await axios({
    //       method: 'get',
    //       url: `${url}/api/techroom/technical/appeals/address/${id}`,
    //       headers: {
    //         Authorization: localStorage.getItem('token'),
    //       },
    //     });
    //     console.log(result);
    //     commit('updateAppeals', result.data.appeals);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // async editAppeal({ dispatch }, { id, statusId, orgId }) {
    //   try {
    //     const result = await axios({
    //       method: 'put',
    //       url: `${url}/api/techroom/technical/appeal/${id}`,
    //       headers: {
    //         Authorization: localStorage.getItem('token'),
    //       },
    //       data: {
    //         statusId,
    //       },
    //     });
    //     await dispatch('requestOrganizationAppeals', orgId);
    //     console.log(result);
    //   } catch (error) {
    //     console.error(error);
    //     throw error;
    //   }
    // },
    // async requestOrganizationAppeals({ commit }, id) {
    //   try {
    //     const result = await axios({
    //       method: 'get',
    //       url: `${url}/api/techroom/technical/appeals/${id}`,
    //       headers: {
    //         Authorization: localStorage.getItem('token'),
    //       },
    //     });
    //     await commit('updateOgranizationAppeals', result.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async requestOrganizationAddressAppeals(ctx, id) {
    //   try {
    //     const result = await axios({
    //       method: 'get',
    //       url: `${url}/api/techroom/technical/appeals/address/${id}`,
    //       headers: {
    //         Authorization: localStorage.getItem('token'),
    //       },
    //     });
    //     console.log(result);
    //     ctx.commit('updateOgranizationAppeals', result.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
