import calls from "./calls";
import clients from "./clients";
import projects from "./projects";
import users from "./users";
import tasks from "./tasks";
import statistics from "./statistics";

export const director = {
  namespaced: true,
  modules: {
    calls,
    clients,
    projects,
    users,
    tasks,
    statistics,
  }
}