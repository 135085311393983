<template>
  <v-layout>
    <v-app-bar-nav-icon
      v-if="width < 1280 && width !== 0"
      @click="drawer = true"
    ></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" class="sidebar">
      <a href="/techroom" class="v-navigation-icon">
        <IconTechroom
          viewBox="0 0 305 203"
          width="150"
          height="150"
        ></IconTechroom>
      </a>
      <v-expansion-panels multiple variant="accordion">
        <v-expansion-panel
          @contextmenu.prevent="toOrg(org.id)"
          v-for="org in $store.getters[
            'techroom/specialist/getOrganizations'
          ].filter((i) => i.delete == false)"
          :key="org.id"
        >
          <v-expansion-panel-title>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="32"
              width="32"
              viewBox="0 0 40 40"
            >
              <path
                d="M5.833 33.583V11.958h6.75V5.25h14.875v13.458h6.709v14.875h-11.5v-6.75h-5.334v6.75Zm1.417-1.416h5.333v-5.334H7.25Zm0-6.709h5.333v-5.333H7.25Zm0-6.75h5.333v-5.333H7.25Zm6.75 6.75h5.292v-5.333H14Zm0-6.75h5.292v-5.333H14Zm0-6.75h5.292V6.667H14Zm6.708 13.5h5.334v-5.333h-5.334Zm0-6.75h5.334v-5.333h-5.334Zm0-6.75h5.334V6.667h-5.334Zm6.75 20.209h5.292v-5.334h-5.292Zm0-6.709h5.292v-5.333h-5.292Z"
              />
            </svg>
            <span>{{ org.smallTitle ? org.smallTitle : org.fullTitle }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list nav mandatory>
              <v-list-item
                style="margin-left: 10px"
                v-for="item in org.addresses"
                :key="item.id"
                :title="item.title"
                :active="isActive(item.id)"
                active-color="red"
                :prepend-icon="'mdi-office-building-marker-outline'"
                @click="getAddress(item, org.id)"
              >
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-list>
        <v-list-item v-for="item in listItems" :key="item" :prepend-icon="item.icon" :title="item.title"
          :value="item.title" @click="$router.push(item.url)"></v-list-item>
      </v-list> -->
      <template v-slot:append>
        <div class="current__date">
          {{ $filters.filterDate(date, 'datetime') }}
        </div>
      </template>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      drawer:
        window.innerWidth >= 1280 && window.innerWidth !== 0 ? true : false,
      width: window.innerWidth,
      date: new Date(),
    };
  },
  methods: {
    async toOrg(id) {
      this.$router.push({ path: `/techroom/organization/${id}/general/` });
      await this.$store.dispatch('techroom/specialist/requestOrganization', id);
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    isActive(item) {
      return item === this.$store.getters['techroom/specialist/getAddress'].id;
    },
    async exit() {
      await this.$router.push('/');
      await this.$store.dispatch('logout');
    },
    async changeRole() {
      await this.$router.push('/');
      await this.$store.commit('updateIsAuthenicated', true);
    },
    async getAddress(item, orgid) {
      if (this.$route.name === 'OrganizationGeneral') {
        this.$router.push(
          `/techroom/organization/${this.$route.params.id}/cabinets/`
        );
      }
      this.$router.push({
        path: `/techroom/organization/${this.$route.params.id}/cabinets/`,
        query: { address: `${item.address}` },
      });
      await this.$store.commit('techroom/specialist/updateAddress', item);
      if (window.innerWidth < 1280 && window.innerWidth !== 0) {
        this.drawer = false;
      }
      this.$router.push({ path: `/techroom/organization/${orgid}/cabinets/` });
    },
  },
  // computed: {
  //   listItems() {
  //     if (
  //       this.$store.getters.USER?.selectedRole.role === 'DIRECTOR' &&
  //       this.$store.getters.USER?.selectedRole.organization === 'techroom'
  //     ) {
  //       return [
  //         {
  //           title: 'Личный кабинет',
  //           icon: 'mdi-account',
  //           url: '/techroom',
  //         },
  //         {
  //           title: 'Лиды',
  //           icon: 'mdi-view-list',
  //           url: '/techroom/leads',
  //         },
  //         {
  //           title: 'Клиенты',
  //           icon: 'mdi-account-group',
  //           url: '/techroom/clients',
  //         },
  //         {
  //           title: 'Организации',
  //           icon: 'mdi-office-building-marker-outline',
  //           url: '/techroom/organizations',
  //         },
  //         {
  //           title: 'Обращения',
  //           icon: 'mdi-bell-ring',
  //           url: '/techroom/appeals',
  //         },
  //         {
  //           title: 'Планы',
  //           icon: 'mdi-calendar',
  //           url: '/techroom/plans',
  //         },
  //         {
  //           title: 'Склад',
  //           icon: 'mdi-folder-outline',
  //           url: '/techroom/stock',
  //         },
  //         {
  //           title: "Проекты",
  //           icon: "mdi-folder-multiple",
  //           url: "/techroom/projects",
  //         },
  //       ];
  //     }
  //     else if (
  //       this.$store.getters.USER?.selectedRole.role === 'MANAGER' &&
  //       this.$store.getters.USER?.selectedRole.organization === 'techroom'
  //     ) {
  //       return [
  //         {
  //           title: 'Личный кабинет',
  //           icon: 'mdi-account',
  //           url: '/techroom',
  //         },
  //         {
  //           title: 'Лиды',
  //           icon: 'mdi-view-list',
  //           url: '/techroom/leads',
  //         },
  //         {
  //           title: 'Клиенты',
  //           icon: 'mdi-account-group',
  //           url: '/techroom/clients',
  //         },
  //         {
  //           title: 'Обращения',
  //           icon: 'mdi-bell-ring',
  //           url: '/techroom/appeals',
  //         },
  //         {
  //           title: 'Планы',
  //           icon: 'mdi-calendar',
  //           url: '/techroom/plans',
  //         },
  //         {
  //           title: "Проекты",
  //           icon: "mdi-folder-multiple",
  //           url: "/techroom/projects",
  //         },
  //       ];
  //     }

  //     return [
  //       { title: 'Личный кабинет', icon: 'mdi-account', url: '/techroom' },
  //       {
  //         title: 'Организации',
  //         icon: 'mdi-office-building-marker-outline',
  //         url: '/techroom/organizations',
  //       },
  //       {
  //         title: 'Планы',
  //         icon: 'mdi-calendar',
  //         url: '/techroom/plans',
  //       },
  //       {
  //         title: 'Склад',
  //         icon: 'mdi-folder-outline',
  //         url: '/techroom/stock',
  //       },
  //     ];
  //   },
  // },
  async mounted() {
    await this.$store.dispatch('techroom/specialist/requestOrganizations');
    await this.$store.commit(
      'techroom/specialist/updateAddress',
      this.$store.getters['techroom/specialist/getOrganizations'][0]
        ?.addresses[0]
    );
    this.interval = setInterval(() => {
      this.date = new Date();
    }, 1000);
    window.addEventListener('resize', this.updateWidth);
  },
};
</script>

<style lang="scss">
.organization-statistic {
  display: flex;
  flex-direction: column;

  .statistic-item {
    display: flex;
    gap: rem(8);
    width: 100%;
    justify-content: center;
    color: var(--white-20);

    span {
      color: var(--red-0);
    }
  }
}

.sidebar {
  .organization {
    .v-btn__content {
      gap: rem(14);
    }
  }

  .addresses {
    margin: 10px 0px;

    &__title {
      text-align: center;
      font-size: rem(16);
      color: var(--red-0);
      margin: rem(6) 0 rem(12) 0;
    }
  }

  .organization__content {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 0;
    column-gap: rem(16);
    justify-items: start;

    svg {
      grid-area: 1 / 1 / 3 / 2;
    }

    div {
      letter-spacing: rem(0.9);
      font-size: rem(12);
      color: var(--white-14);
      font-weight: 300;
    }
  }

  .selected-organization {
    padding: rem(8);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(4);
    font-size: rem(14);
    color: var(--red-0);
    text-align: center;

    span {
      color: var(--white-20);
    }

    div {
      &:nth-child(2) {
        font-size: rem(12);

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.v-navigation-icon {
  cursor: pointer;
}

.v-navigation-back {
  text-align: center;
  padding: rem(5) 0;
  color: #ff5757;
  cursor: pointer;
}

.v-navigation-subtitle {
  text-align: center;
  font-size: rem(12);
}

.text-h6 {
  display: flex;
  flex-direction: column;

  a {
    color: #ff5757;

    &[focused] {
      background-color: #ffbeb4;
    }
  }
}

.v-list-item__content {
  display: flex;
  align-items: center;
  gap: rem(10);
}

.logo__main {
  display: flex;
  justify-content: center;

  .logo__tech {
    width: 110px;
    height: 110px;
  }
}

.v-list-item--nav .v-list-item-title {
  white-space: pre-wrap;
}
</style>
