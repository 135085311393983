<template>
  <div class="item_header">
    <div class="header_left pl-4 w-100">
      <div class="page_menu_title flex-column align-start">
        <h2 class="font-weight-bold">Компьютеры</h2>
        <small>по адресу {{ getAddress.title }}</small>
      </div>
      <div class="header_floors">
        <SelectFloor />
        <div class="page_menu_title">
          <div
            class="link"
            :style="{ fontWeight: archive === null ? 'bolder' : 'normal' }"
            @click="this.archive = null"
          >
            Все
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === false ? 'bolder' : 'normal' }"
            @click="this.archive = false"
          >
            Активные
          </div>
          <div
            class="link"
            :style="{ fontWeight: archive === true ? 'bolder' : 'normal' }"
            @click="this.archive = true"
          >
            В архиве
          </div>
        </div>
      </div>
    </div>
    <div class="header_right">
      <div
        class="d-flex justify-space-around align-center flex-column flex-md-row"
      >
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              color="#ff5757"
              class="button-add"
              v-bind="props"
              icon="mdi-plus"
            >
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openDialogCreate(false)">
              Добавить в кабинет
            </v-list-item>
            <v-list-item @click="openDialogCreate(true)">
              Добавить на склад
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
  <v-table>
    <thead>
      <tr>
        <th class="text-left start">Компьютер №</th>
        <th class="text-left">Мат. Плата</th>
        <th class="text-left">ЦП</th>
        <th class="text-left">ГП</th>
        <th class="text-left">Статус</th>
        <th class="text-center">
          <v-icon>mdi-cogs</v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in getComputers"
        :key="item.id"
        :style="{
          color: item.delete ? '#7c7c7c' : '#000',
          transition: '.15s ease',
        }"
        @click="openDialog(item)"
      >
        <td class="text-left start">{{ item.num }}</td>
        <td class="text-left">
          {{ item.motherboard_model }} {{ item.motherboard_socket }}
        </td>
        <td class="text-left">
          {{ item.cpu_brand }} {{ item.cpu_core }} {{ item.cpu_model }}
          {{ item.cpu_socket }}
        </td>
        <td class="text-left">
          {{ item.gpu_brand }} {{ item.gpu_model }} {{ item.gpu_vol }}
        </td>
        <td class="text-left" v-if="item.cabinet.delete">
          {{
            !isNaN(item.cabinet.num)
              ? `В удаленном кабинете №${item?.cabinet.num}`
              :  `Удаленный ${item?.cabinet.num}`
          }}
        </td>
        <td class="text-left" v-else>
          {{
            !isNaN(item.cabinet.num)
              ? `В кабинете №${item?.cabinet.num}`
              :  `${item?.cabinet.num}`
          }}
        </td>
        <td class="text-center">
          <v-menu class="actions-list">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" size="small">
                <v-icon size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialog(item)"
              >
                <v-list-item-title>Подробнее</v-list-item-title>
              </v-list-item>
              <v-list-item
                min-height="1"
                :value="item.id"
                @click="openDialogEdit(item)"
              >
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item min-height="1" :value="item.id" @click="stock(item)">
                <v-list-item-title>Перенести на склад</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.delete"
                min-height="1"
                :value="item.id"
                @click="removeComputer(item.id, true)"
              >
                <v-list-item-title>Удалить</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                min-height="1"
                :value="item.id"
                @click="removeComputer(item.id, false)"
              >
                <v-list-item-title>Восстановить</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-table>
  <v-snackbar v-model="snackbar" timeout="2000">
    Компьютер успешно добавлен на склад!

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="snackbar = false">
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
  <DialogAddComputer
    v-model="dialogAddComputer"
    @close="dialogAddComputer = false"
    @snackbar="snackbar = true"
    :stock="addStockComputer"
  />
  <DialogComputer
    v-model="dialogComputer"
    @close="dialogComputer = false"
    :computer="computer"
  />
  <DialogEditComputer
    v-model="dialogEditComputer"
    @close="dialogEditComputer = false"
    :computer="computer"
  />
</template>

<script>
import SelectFloor from '@/components/app/techroom/specialist/organization/SelectFloor.vue';
import DialogAddComputer from '@/components/app/techroom/specialist/organization/computers/DialogAddComputer.vue';
import DialogComputer from '@/components/app/techroom/specialist/organization/computers/DialogComputer.vue';
import DialogEditComputer from '@/components/app/techroom/specialist/organization/computers/DialogEditComputer.vue';

export default {
  components: {
    DialogAddComputer,
    DialogComputer,
    DialogEditComputer,
    SelectFloor,
  },
  data() {
    return {
      snackbar: false,
      addStockComputer: false,
      computer: null,
      archive: null,
      dialogAddComputer: false,
      dialogComputer: false,
      dialogEditComputer: false,
    };
  },

  computed: {
    getAddress() {
      return this.$store.getters['techroom/specialist/getAddress'];
    },
    floorId() {
      return this.$store.getters['techroom/specialist/getFloorId'];
    },
    getComputers() {
      if (this.archive === true) {
        return this.$store.getters['techroom/specialist/getComputers'].filter(
          (item) => item.delete
        );
      }
      if (this.archive === false) {
        return this.$store.getters['techroom/specialist/getComputers'].filter(
          (item) => !item.delete
        );
      }
      return this.$store.getters['techroom/specialist/getComputers'];
    },
  },

  watch: {
    async getAddress() {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressComputers',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    },

    async floorId() {
      if (this.floorId) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorComputers',
          this.floorId
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressComputers',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },

  methods: {
    async openDialogCreate(stock) {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressCabinets',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      this.dialogAddComputer = true;
      this.addStockComputer = stock;
    },
    openDialog(item) {
      this.computer = item;
      this.dialogComputer = true;
    },
    async openDialogEdit(item) {
      await this.$store.dispatch('techroom/specialist/requestStatuses');
      this.computer = item;
      this.dialogEditComputer = true;
    },
    async removeComputer(id, status) {
      await this.$store.dispatch('techroom/specialist/removeComputer', {
        id,
        status,
      });

      if (this.$store.getters['techroom/specialist/getFloorId']) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorComputers',
          this.$store.getters['techroom/specialist/getFloorId']
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressComputers',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
    async stock(item) {
      await this.$store.dispatch('techroom/specialist/stockComputer', item.id);
      if (this.$store.getters['techroom/specialist/getFloorId']) {
        await this.$store.dispatch(
          'techroom/specialist/requestFloorComputers',
          this.$store.getters['techroom/specialist/getFloorId']
        );
      } else {
        await this.$store.dispatch(
          'techroom/specialist/requestAddressComputers',
          this.$store.getters['techroom/specialist/getAddress'].id
        );
      }
    },
  },

  async mounted() {
    if (this.$store.getters['techroom/specialist/getFloorId']) {
      await this.$store.dispatch(
        'techroom/specialist/requestFloorComputers',
        this.$store.getters['techroom/specialist/getFloorId']
      );
    } else {
      await this.$store.dispatch(
        'techroom/specialist/requestAddressComputers',
        this.$store.getters['techroom/specialist/getAddress'].id
      );
    }
  },
};
</script>
<style></style>
