<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Информация о проекте</h2>
        <div>Название компании: {{ project.nameCompany }}</div>
        <div>ФИО клиетна: {{ project.individual }}</div>
        <div>Номер телефона: {{ $filters.filterNumber(project.phone) }}</div>
        <div>Договор: {{ project.dogovor }}</div>
        <div>Комментарий: {{ project.comment }}</div>
        <div>Имя проекта: {{ project.nameProject }}</div>
        <div>Сроки: {{ $filters.filterDate(project.timeline) }}</div>
        <div>Статус: {{ project.status }}</div>
        <div>
          Лидер проекта:
          <div v-for="item in project.teamLead" :key="item">
            <div>{{ `${item.name} ${item.surname} ${item.lastName}` }}</div>
            <div>{{ item.email }}</div>
          </div>
        </div>
        <div>
          Разработчики:
          <div v-for="item in project.developers" :key="item">
            <div>{{ `${item.name} ${item.surname} ${item.lastName}` }}</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>
</style>