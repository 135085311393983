<template>
    <v-dialog class="dialog">
        <v-card width="500" class="dialog_content">
            <div class="action">
                <v-card-actions>
                    <v-btn color="#ff5757" block @click="$emit('close')">
                        <v-icon size="40">mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </div>
            <v-card-text class="pb-10">
                <h2 class="mb-5">Комментарий</h2>
                <div class="modal-comment">{{ client.description }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        client: {
            type: Object,
        },
    },
};
</script>
<style scoped>
.dialog_content {
    border: 2px solid #ff5757;
    /* min-width: 40vw !important; */
}
</style>