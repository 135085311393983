import axios from "axios";
import { url } from '@/store'

export default {
  state: {
    projects: null,
    project: null,
    developers: null,
  },
  getters: {
    getProjects(state) {
      return state.projects
    },
    getProject(state) {
      return state.project
    },
    getDevelopers(state) {
      return state.developers
    },
  },
  mutations: {
    updateProjects(state, res) {
      state.projects = res
    },
    updateProject(state, res) {
      state.project = res
    },
    updateDevelopers(state, res) {
      state.developers = res
    }
  },
  actions: {
    async requestProjects({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/teamlead/projects`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateProjects', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestProject({ commit }, id) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/teamlead/project/${id}`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateProject', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async requestDevelopers({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/users/developers`,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
        console.log(result);
        commit('updateDevelopers', result.data)
      } catch (error) {
        console.error(error);
      }
    },

    async updateProject(ctx, { id, status, userId }) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/teamlead/project`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            id,
            status,
            userId,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async deleteMemberProject(ctx, { idProject, idUser }) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/teamlead/project/deleteUser`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            idProject,
            idUser,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async addMemberTask(ctx, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/temlead/project/task`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            descriptionTitle: formData.descriptionTitle,
            timeline: formData.timeline,
            projectId: formData.projectId,
            userId: formData.userId
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },

    async deleteMemberTask(ctx, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/temlead/project/task/delete`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            id,
          }
        })
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
