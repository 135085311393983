<template>
  <v-dialog
    class="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card class="dialog_content">
      <div class="action">
        <v-card-actions>
          <v-btn color="#ff5757" block @click="$emit('close')">
            <v-icon size="40">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text class="main">
        <div class="main_blocks">
          <div class="block">
            <div class="block__info">
              <div class="block__info_title">
                <h4>Общая информация о кабинете</h4>
              </div>
              <div class="block__info_container_main cabinet">
                <div class="block__info_item">
                  <div class="block__info_item_label">Номер кабинета</div>
                  <div class="block__info_item_value">
                    {{ formData?.num || 'Не указано' }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Кол-во компьютеров</div>
                  <div class="block__info_item_value">
                    {{ formData.computers?.length }}
                  </div>
                </div>
                <div class="block__info_item">
                  <div class="block__info_item_label">Кол-во оборудования</div>
                  <div class="block__info_item_value">
                    {{ formData.equipments?.length }}
                  </div>
                </div>
              </div>
            </div>
            <div class="blocks__secondary">
              <div class="block__info" v-if="formData.misc">
                <div class="block__info_title">
                  <h5>Заметки</h5>
                </div>
                <div style="white-space: break-spaces">
                  {{ formData.misc }}
                </div>
              </div>
              <div class="block__info">
                <div class="block__info_title">
                  <h5>Адрес</h5>
                </div>
                <div class="block__info_container_main address">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Улица</div>
                    <div class="block__info_item_value">
                      {{ formData.floor.address?.title || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Этаж</div>
                    <div class="block__info_item_value">
                      {{ formData.floor?.floor || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Ответственный</div>
                    <div class="block__info_item_value">
                      {{ formData.floor.address?.head || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Номер телефона</div>
                    <div class="block__info_item_value">
                      {{ formData.floor.address?.phone || 'Не указано' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="main__title">
          <v-icon class="main__title_icon">mdi-laptop</v-icon>
          Компьютеры в кабинете
        </h3>
        <div class="main_blocks computers">
          <div v-for="(computer, index) in formData.computers" :key="index">
            <div class="block">
              <div class="block__info">
                <div class="block__info_title">
                  <h4>Компьютер №{{ computer.num }}</h4>
                </div>
                <div class="block__info_container">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Процессор</div>
                    <div class="block__info_item_value">
                      <div>
                        {{ computer.cpu_brand }}
                      </div>
                      <div>
                        {{ computer.cpu_model }}
                      </div>
                      <div>
                        {{ computer.cpu_core }}
                      </div>
                      <div>
                        {{ computer.cpu_socket }}
                      </div>
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Видеокарта</div>
                    <div class="block__info_item_value">
                      <div>{{ computer.gpu_brand }}</div>
                      <div>{{ computer.gpu_model }}</div>
                      <div>{{ computer.gpu_vol }}</div>
                      <!-- {{
                        ( && computer.gpu_brand != 'Нет') ||
                        (computer.gpu_vol && computer.gpu_vol != 'Нет') ||
                        (computer.gpu_model && computer.gpu_model != 'Нет')
                          ? computer?.gpu_brand +
                            ' ' +
                            computer?.gpu_vol +
                            ' ' +
                            computer?.gpu_model
                          : 'Нет'
                      }} -->
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">HDD</div>
                    <div class="block__info_item_value">
                      <div>{{ computer.hdd_brand }}</div>
                      <div>{{ computer.hdd_vol }}</div>
                      <div>{{ computer.hdd_form }}</div>
                      <!-- {{
                        (computer.hdd_brand && computer.hdd_brand != 'Нет') ||
                        (computer.hdd_vol && computer.hdd_vol != 'Нет') ||
                        (computer.hdd_form && computer.hdd_form != 'Нет')
                          ? computer?.hdd_brand +
                            ' ' +
                            computer?.hdd_vol +
                            ' ' +
                            computer?.hdd_form
                          : 'Нет'
                      }} -->
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">SSD</div>
                    <div class="block__info_item_value">
                      <div>{{ computer.ssd_brand }}</div>
                      <div>{{ computer.ssd_vol }}</div>
                      <div>{{ computer.ssd_form }}</div>
                      <!-- {{
                        (computer.ssd_brand && computer.ssd_brand != 'Нет') ||
                        (computer.ssd_vol && computer.ssd_vol != 'Нет') ||
                        (computer.ssd_form && computer.ssd_form != 'Нет')
                          ? computer?.ssd_brand +
                            ' ' +
                            computer?.ssd_vol +
                            ' ' +
                            computer?.ssd_form
                          : 'Нет'
                      }} -->
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">ОЗУ</div>
                    <div class="block__info_item_value">
                      <div>
                        {{ computer.ram_brand }}
                      </div>
                      <div>
                        {{ computer.ram_freq }}
                      </div>
                      <div>
                        {{ computer.ram_form }}
                      </div>
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">БП</div>
                    <div class="block__info_item_value">
                      <div>
                        {{ computer.power_brand }}
                      </div>
                      <div>
                        {{ computer.power_pw }}
                      </div>
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Материнская плата</div>
                    <div class="block__info_item_value">
                      <div>
                        {{ computer.motherboard_model }}
                      </div>
                      <div>
                        {{ computer.motherboard_socket }}
                      </div>
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Корпус</div>
                    <div class="block__info_item_value">
                      {{ computer?.case_size || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Состояние</div>
                    <div class="block__info_item_value">
                      {{ computer?.status?.title }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Удаленный доступ</div>
                    <div class="block__info_item_value">
                      {{ computer.remote ? computer.remote : 'Нет' }}
                    </div>
                  </div>
                </div>
                <h5 v-if="computer.misc">Заметки</h5>
                <div v-if="computer.misc" style="white-space: break-spaces">
                  {{ computer.misc }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <v-form
          ref="formComp"
          class="form-cabinet__info"
          v-model="validComputers"
          @submit.prevent="addComputers"
        >
          <div class="main_blocks computers">
            <div v-for="(computer, index) in addedComputers" :key="index">
              <div class="block">
                <div class="block__info">
                  <div class="block__info_title_fields">
                    <v-text-field
                      class="block__info_title_field"
                      label="Компьютер №"
                      v-model="computer.num"
                      :rules="[() => !!computer.num || 'Обязательно']"
                      variant="underlined"
                    />
                    <v-icon @click="removeComputer(index)">mdi-close</v-icon>
                  </div>
                  <div class="block__info_container_fields">
                    <v-text-field
                      label="Процессор"
                      v-model="computer.cpu"
                      :rules="[() => !!computer.cpu || 'Обязательно']"
                      variant="underlined"
                    />
                    <v-text-field
                      label="Видеокарта"
                      v-model="computer.gpu"
                      variant="underlined"
                    />
                    <v-text-field
                      label="Жесткий диск"
                      v-model="computer.hdd"
                      variant="underlined"
                    />
                    <v-text-field
                      label="SSD"
                      v-model="computer.ssd"
                      variant="underlined"
                    />
                    <v-text-field
                      label="ОЗУ"
                      v-model="computer.ram"
                      variant="underlined"
                    />
                    <v-text-field
                      label="Блок питания"
                      v-model="computer.power"
                      variant="underlined"
                    />
                    <v-text-field
                      label="Материнская плата"
                      v-model="computer.motherboard"
                      :rules="[() => !!computer.motherboard || 'Обязательно']"
                      variant="underlined"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add_block" @click="addComputer">
              <v-icon>mdi-plus-thick</v-icon>
              <h5>Добавить компьютер</h5>
            </div>
          </div>
          <button
            type="submit"
            v-if="this.addedComputers.length"
            class="add_computers"
          >
            <h5>Сохранить изменения</h5>
          </button>
        </v-form> -->
        <h3 class="main__title">
          <v-icon class="main__title_icon">mdi-tools</v-icon>
          Оборудование в кабинете
        </h3>
        <div class="main_blocks equipments">
          <div v-for="equipment in formData.equipments" :key="equipment.id">
            <div class="block">
              <div class="block__info">
                <div class="block__info_title">
                  <h4>Оборудование</h4>
                </div>
                <div class="block__info_container">
                  <div class="block__info_item">
                    <div class="block__info_item_label">Тип</div>
                    <div class="block__info_item_value">
                      {{ equipment?.type || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Инвентарный номер</div>
                    <div class="block__info_item_value">
                      {{ equipment?.num || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Серийный номер</div>
                    <div class="block__info_item_value">
                      {{ equipment?.serialnum || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Модель</div>
                    <div class="block__info_item_value">
                      {{ equipment?.model || 'Не указано' }}
                    </div>
                  </div>
                  <div class="block__info_item">
                    <div class="block__info_item_label">Состояние</div>
                    <div class="block__info_item_value">
                      {{ equipment?.status?.title || 'Не указано' }}
                    </div>
                  </div>
                </div>
                <h5 v-if="equipment.misc">Заметки</h5>
                <div v-if="equipment.misc" style="white-space: break-spaces">
                  {{ equipment.misc }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <v-form
          ref="formEuip"
          class="form-cabinet__info"
          v-model="validEuip"
          @submit.prevent="addEquipments"
        >
          <div class="main_blocks equipments">
            <div v-for="(equipment, index) in addedEquipments" :key="index">
              <div class="block">
                <div class="block__info">
                  <div class="block__info_title_fields">
                    <h4>Оборудование</h4>
                    <v-icon @click="removeEquipment(index)">mdi-close</v-icon>
                  </div>
                  <div class="block__info_container_fields">
                    <v-text-field
                      label="Тип"
                      v-model="equipment.type"
                      :rules="[() => !!equipment.type || 'Обязательно']"
                      variant="underlined"
                    ></v-text-field>
                    <v-text-field
                      label="Модель"
                      v-model="equipment.model"
                      :rules="[() => !!equipment.model || 'Обязательно']"
                      variant="underlined"
                    ></v-text-field>
                    <v-text-field
                      label="Состояние"
                      v-model="equipment.condition"
                      :rules="[() => !!equipment.condition || 'Обязательно']"
                      variant="underlined"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="add_block" @click="addEquipment()">
              <v-icon>mdi-plus-thick</v-icon>
              <h5>Добавить оборудование</h5>
            </div>
          </div>
          <button
            type="submit"
            v-if="this.addedEquipments.length"
            class="add_computers"
          >
            <h5>Сохранить изменения</h5>
          </button>
        </v-form> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    cabinet: {
      type: Object,
    },
  },

  data() {
    return {
      addedComputers: [],
      addedEquipments: [],
      validComputers: false,
      validEuip: false,
    };
  },

  computed: {
    formData() {
      return this.cabinet;
    },
  },

  methods: {
    addComputer() {
      this.addedComputers.push({
        cpu: null,
        gpu: null,
        hdd: null,
        motherboard: null,
        num: null,
        power: null,
        ram: null,
        ssd: null,
        misc: null,
        cabinetId: this.cabinet.id,
      });
    },
    removeComputer(index) {
      this.addedComputers.splice(index, 1);
    },
    async addComputers() {
      this.$refs.formComp.validate();
      if (this.validComputers) {
        for (let computer of this.addedComputers) {
          await this.$store.dispatch(
            'techroom/specialist/createComputer',
            computer
          );
          this.formData.computers.push({
            cpu: computer.cpu,
            gpu: computer.gpu,
            hdd: computer.hdd,
            motherboard: computer.motherboard,
            num: computer.num,
            power: computer.power,
            ram: computer.ram,
            ssd: computer.ssd,
            cabinetId: computer.cabinetId,
            misc: computer.misc,
          });
        }
        this.addedComputers = [];
      }
    },
    addEquipment() {
      this.addedEquipments.push({
        type: null,
        model: null,
        condition: null,
        misc: null,
        cabinetId: this.cabinet.id,
      });
    },
    removeEquipment(index) {
      this.addedEquipments.splice(index, 1);
    },
    async addEquipments() {
      this.$refs.formEuip.validate();
      if (this.validEuip) {
        for (let equipment of this.addedEquipments) {
          await this.$store.dispatch(
            'techroom/specialist/createEquipment',
            equipment
          );
          this.formData.equipments.push({
            type: equipment.type,
            model: equipment.model,
            condition: equipment.condition,
            cabinetId: this.cabinet.id,
            misc: equipment.misc,
          });
        }
        this.addedEquipments = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-cabinet__info {
  .add_computers {
    margin-top: 25px;
    width: 100%;
  }
}

.dialog_content {
  border: none !important;
}

.equipments {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  gap: rem(28);

  @media screen and (max-width: $x-large) {
    grid-template: 1fr / repeat(3, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $large) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $medium) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $x-small) {
    grid-template: 1fr / repeat(1, 1fr);
    gap: rem(18);
  }
}

.block__info_item_value {
  text-overflow: ellipsis;
  width: max-content;
}

.computers {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: rem(28);

  @media screen and (max-width: $x-large) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $large) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $medium) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(18);
  }

  @media screen and (max-width: $x-small) {
    grid-template: 1fr / repeat(1, 1fr);
    gap: rem(18);
  }
}

.cabinet {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);

  @media screen and (max-width: $x-small) {
    grid-template: 1fr / repeat(1, 1fr);
    gap: rem(14);
  }
}

.address {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  gap: rem(24);

  @media screen and (max-width: $x-small) {
    grid-template: 1fr / repeat(2, 1fr);
    gap: rem(14);
  }
}

.worker {
  display: grid;
  gap: rem(24);
  grid-template: 1fr / repeat(3, 1fr);

  @media screen and (max-width: $x-small) {
    grid-template: 1fr / repeat(1, 1fr);
    gap: rem(14);
  }
}
</style>
