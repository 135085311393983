<template>
  <div class="wrapper">
    <sidebar></sidebar>
    <main class="page">
      <div class="page__container container">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "@/components/app/techroom/Sidebar";
export default {
  components: { Sidebar },
};
</script>
<style lang="scss">
</style>