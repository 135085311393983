import axios from 'axios'
import { url } from '@/store'

export default {
  state: {
    statistics: null,
  },
  getters: {
    getStatistics(state) {
      return state.statistics
    }
  },
  mutations: {
    updateStatistics(state, res) {
      return state.statistics = res
    }
  },
  actions: {
    async requestStatistics({ commit }) {
      try {
        const result = await axios({
          method: 'get',
          url: `${url}/api/itroom/director/statistics/all`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
        })
        console.log(result);
        commit('updateStatistics', result.data)
      } catch (error) {
        console.error(error);
        throw error
      }
    }
  }
}