<template>
  <v-dialog>
    <v-card width="500">
      <div class="d-flex justify-end pt-2 pr-2">
        <v-icon @click="$emit('close')"> mdi-window-close </v-icon>
      </div>
      <v-card-text class="pb-10">
        <h2 class="mb-5">Добавить задачу</h2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="valid ? submitHandler() : validate()"
        >
          <v-text-field
            v-model="formData.title"
            label="Название задачи"
            required
            :rules="[() => !!formData.title || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.description"
            label="Описание"
            required
            :rules="[() => !!formData.description || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
            v-model="formData.timeline"
            label="Срок"
            required
            :rules="[() => !!formData.timeline || 'Обязательное поле']"
          ></v-text-field>
          <v-select
            v-if="user.roles[0].name === 'TEAMLEAD'"
            label="Проект"
            v-model="formData.project"
            :items="$store.getters['itroom/director/getProjects']"
            item-title="nameProject"
            item-value="id"
          ></v-select>
          <v-btn type="submit" block color="success"> Добавить </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,

      formData: {
        title: null,
        description: null,
        timeline: null,
        project: null,
        userId: null,
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async submitHandler() {
      try {
        this.formData.userId = this.user.id;
        await this.$store.dispatch("itroom/director/createTask", this.formData);
        this.$emit("close");
        this.$refs.form.reset();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>