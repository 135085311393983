import axios from 'axios'
import { url } from '@/store'

export default {
  actions: {
    async createTask({ dispatch }, formData) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/task`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: {
            title: formData.title,
            description: formData.description,
            timeline: formData.timeline,
            project: formData.project,
            userId: formData.userId
          }
        })
        console.log(result);
        await dispatch('requestManagersAndTeamleads')
      } catch (error) {
        console.error(error);
        throw error
      }
    },
    async deleteTask({ dispatch }, id) {
      try {
        const result = await axios({
          method: 'post',
          url: `${url}/api/itroom/director/task/delete`,
          headers: {
            Authorization: localStorage.getItem("token")
          },
          data: { id }
        })
        console.log(result);
        await dispatch('requestManagersAndTeamleads')
      } catch (error) {
        console.error(error);
        throw error
      }
    }
  }
}