<template>
  <svg height="20" width="20">
    <path
      d="M4.583 15.667h.896l8.083-8.084-.895-.916-8.084 8.083Zm10.771-8.625-2.125-2.167.479-.5q.417-.396.969-.396t.969.396l.583.583q.229.25.209.636-.021.385-.25.635Zm-.542.541-8.958 8.938H3.729v-2.146l8.938-8.917Zm-1.687-.458-.458-.458.895.916Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEdit",
};
</script>